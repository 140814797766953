import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import MoradorAPI from '../../api/MoradorAPI';
import VisitanteAPI from '../../api/VisitanteAPI';
import { useBloco } from '../../hooks/Bloco.hook';
import { useMorador } from '../../hooks/Morador.hook';
import { useVisitante } from '../../hooks/Visitante.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { Morador, MoradorRequest } from '../../interfaces/Morador.interface';
import { Visitante, VisitanteRequest } from '../../interfaces/Visitante.interface';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import { ContainerFormMorador, InforPessoal, InforPessoalVisitante } from './styles/Moradores.style';

export interface TipoVisitante {
    id: string;
    descricao: string;
}

interface PropsVisitante {
    hide: () => void;
    show: boolean;
    visitanteSelecionado: Visitante | null;
}


const AtualizarVisitante: FC<PropsVisitante> = ({ show, hide, visitanteSelecionado }) => {

    let condominioId = localStorage.getItem('condominio')
    const { atualizar, atualizando } = useVisitante();
    const [form, setForm] = useState<VisitanteRequest>({});
    const [tipoVisitante, setTipoVisitante] = useState<TipoVisitante[]>([]);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await VisitanteAPI.listarTipoVisitante();
            if (data) setTipoVisitante(data)
            else setTipoVisitante([]);
        })()
    }, []);

    useEffect(() => {
        if (visitanteSelecionado) {
            setForm(visitanteSelecionado)
        }
    }, [visitanteSelecionado]);

    const atualizarVsitante = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Visitante, hide);
    }
    const FormMorador = () => {
        return (
            <ContainerFormMorador>
                <div className='container-div'>
                    <InforPessoalVisitante className='info-pessoal' onSubmit={(e) => atualizarVsitante(e)}>
                        <p className='title mt-3'>Informações pessoais do visitante</p>

                        <div className='mt-2'>
                            <p className='title-input'><small className='text-danger'>*</small>Nome completo</p>
                            <input required className='form-control shadow-none input' placeholder='Nome completo...' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e.target.value })} />
                        </div>

                        <div className='d-flex w-100'>
                            <div className='mt-2 w-100'>
                                <p className='title-input-cpf'><small className='text-danger'>*</small>CPF</p>
                                <input required className='form-control shadow-none input-cpf' placeholder='000.000.000-00' value={form.cpf || ''} onChange={(e) => setForm({ ...form, cpf: cpfMask(e.target.value) })} />
                            </div>
                            <div className='mt-2 w-100'>
                                <p className='title-input-nascimento'><small className='text-danger'>*</small>Tipo morador</p>
                                <select
                                    className="form-control shadow-none"
                                    value={form.tipoId || ''}
                                    onChange={(e) => {
                                        setForm({ ...form, tipoId: e.target.value })
                                    }}
                                    style={{
                                        marginLeft: 15,
                                        background: 'transparent',
                                        border: 0,
                                        borderBottom: '1px solid rgba(0,0,0,0.0800)',
                                        borderRadius: 0,
                                        padding: 5
                                    }}
                                    required
                                >
                                    <option value="" disabled>Selecione</option>
                                    {
                                        tipoVisitante.map(item => (
                                            <option key={item.id} value={item.id}>{item.descricao}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <p className='title-input'>Email</p>
                            <input type={'email'} className='form-control shadow-none input' placeholder='emailvisitantel@gmail.com' value={form.email || ''} onChange={(e) => setForm({ ...form, email: e.target.value })} />
                        </div>


                        <div className='d-flex justify-content-start mt-1 mb-1'>
                            <div className={'col-md-6'}>
                                <p className='title-form'><small className='text-danger'>*</small>Entrada</p>
                                <input type={'datetime-local'} required className='form-control shadow-none input-form' value={form.dataHoraEntrada?.toString() || ''} onChange={(e) => setForm({ ...form, dataHoraEntrada: e.target.value })} />
                            </div>
                            <div className='col-md-6' style={{ marginLeft: 15 }}>
                                <p className='title-form'><small className='text-danger'>*</small>Saída</p>
                                <input type={'datetime-local'} required className='form-control shadow-none input-form' value={form.dataHoraSaida || ''} onChange={(e) => setForm({ ...form, dataHoraSaida: e.target.value })} />
                            </div>
                        </div>

                        <p className='title mt-3'>Informações de contato</p>
                        <p className='subtitle'>Preencha os campos abaixo para criar contato do visitante se necessário</p>
                        <div className='d-flex'>
                            <div className='mt-2 w-100'>
                                <p className='title-input-cpf'>Celular</p>
                                <input className='form-control shadow-none input-cpf' maxLength={14} placeholder='(99) 9 9999-9999 ' value={maskCelular(form?.celular || '')} onChange={(e) => setForm({ ...form, celular: maskCelular(e.target.value) })} />
                            </div>
                            <div className='mt-2 w-100'>
                                <p className='title-input-nascimento'>Telefone</p>
                                <input className='form-control shadow-none input-nascimento' maxLength={13} placeholder='(99) 9999-9999' value={maskTelefoneFixo(form.telefone || '')} onChange={(e) => setForm({ ...form, telefone: maskTelefoneFixo(e.target.value) })} />
                            </div>
                        </div>

                        <div style={{ marginTop: 25 }}>
                            <button disabled={atualizando} className='btn btn-success shadow-none' style={{ width: 150, borderRadius: 11 }}>{atualizando ? 'Salvando...' : 'Salvar'}</button>
                            <button disabled={atualizando} type='button' className='btn btn-outline-danger shadow-none' style={{ width: 150, borderRadius: 11, marginLeft: 10 }} onClick={hide}>Voltar</button>
                        </div>

                    </InforPessoalVisitante>
                </div>

            </ContainerFormMorador>
        )
    }

    return (
        <Modal show={show} onHide={hide} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='d-flex justify-content-center'>
                    <Avatar variant="rounded" style={{ background: '#663b5f', marginRight: 10 }}>
                        <FaWpforms />
                    </Avatar>
                    <p>Atualizar morador {visitanteSelecionado?.nome.split(' ')[0]?.toUpperCase()} {visitanteSelecionado?.nome.split(' ')[1]?.toUpperCase()}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{FormMorador()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarVisitante);