import api from "../services/api/api";
import { Modulo } from "../interfaces/Modulo.interface";

class ModuloAPI {

    listarModulosPorCondominio = async (condominioId: string) => await api.get<Modulo[]>(`/NiceModulos/condominio/${condominioId}`);

    listarModulosProGuaritaId = async (guaritaId: string) => await api.get<Modulo[]>(`/NiceModulos/nice/${guaritaId}`);

    cadastrarModulo = async (request: Modulo) => await api.post(`/NiceModulos`, request);

    atualizarModulo = async (request: Modulo) => await api.put(`/NiceModulos`, request);

    deletarModulo = async (moduloId: string) => await api.delete(`/NiceModulos/${moduloId}`);

}

export default new ModuloAPI();