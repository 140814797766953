import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { InputPicker, SelectPicker, Pagination, Input, Button, Popover, Whisper, Modal, Loader, IconButton, InputGroup } from 'rsuite';
import { useDispositivo } from '../../hooks/Dispositivo.hook';
import { StoreContext } from '../../stores';
import { converterData, converterDataHora, converterHora, cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import NotFound from '../utils/NotFound';
import RenderTipoLog from '../utils/RenderTipoLog';
import { Container, Content, Header, Scroll } from './styles/Relatorio.style';
import { Listagem } from './styles/RelatorioListagem.style';
import { BsPeople } from 'react-icons/bs';
import SearchIcon from '@rsuite/icons/Search';
import Loading from './Loading';
import DetailIcon from '@rsuite/icons/Detail';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import Workbook from 'react-excel-workbook';


const RelatorioMoradores: FC = () => {

  const { condominioStore, unidadeStore, relatorioStore, themeStore } = useContext(StoreContext);
  const { listarControlePorUnidade, controleRelatorio, loading } = useDispositivo();

  const [unidadeSelecionado, setUnidadeSelecionado] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [mode, setMode] = useState('');
  const [containerHeight, setContainerHeight] = useState(0);

  const handleChangeLimit = dataKey => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    const setHeight = () => {
      const windowHeight = window.innerHeight;
      setContainerHeight(windowHeight);
    };
    setSearch('');
    relatorioStore.relatorioMorador = [];
    window.addEventListener('resize', setHeight);
    setHeight();

    return () => {
      window.removeEventListener('resize', setHeight);
    };
  }, []);

  const filtro = relatorioStore.relatorioMorador.filter(r =>
    r.nome?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    r.bloco?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.unidade?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.cpf?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.celular?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.tipo?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.telefone?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.rg?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.nascimento?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.email?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    r.tags?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    !search
).filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const listar = () =>
  {
    if(condominioStore.selecionado)
        relatorioStore.obterRelatorioMoradorPorCondominioId(condominioStore.selecionado.id);
}
  const renderEventos = () =>
  {

    if(relatorioStore.loading)
    {
      return <Loading />
    }

    if(relatorioStore.relatorioMorador.length === 0)
    {
      return <NotFound message={'Nenhum registro encontrado'} />
    }
    return(
        <Content>
            <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'}>
                <thead style={{padding: 15}} className='sticky-top'>
                    <tr> 
                        <th>Condominio</th>
                        <th>Nome</th>
                        <th>Bloco</th>
                        <th>Unidade</th>
                        <th>Cadastro</th>
                        <th>TipoMorador</th>
                        <th>Cpf</th>
                        <th>Nascimento</th>
                        <th>Email</th>
                        <th>Celular</th>
                        <th>Status</th>
                        <th>Tags</th>
                        <th>BiometriaDigital</th>
                        <th>BiometriaFacial</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filtro.map((morador, i) =>
                        (
                            <tr key={i} className={'p-2'}>
                                <td>{morador.condominio ? morador.condominio : '-'}</td>
                                <td>{morador.nome ? morador.nome : '-'}</td>
                                <td>{morador.bloco ? morador.bloco : '-'}</td>
                                <td>{morador.unidade ? morador.unidade : '-'}</td>
                                <td>{morador.dataCadastro ? morador.dataCadastro : '-'}</td>
                                <td>{morador.tipo ? morador.tipo : '-'}</td>
                                <td>{morador.cpf ? morador.cpf : '-'}</td>
                                <td>{morador.nascimento}</td>
                                <td>{morador.email ? morador.email : '-'}</td>
                                <td>{morador.celular ?? '-'}</td>
                                <td>{morador.statusMoradorDescricao}</td>
                                <td>{morador.tags ? morador.tags : '-'}</td>
                                <td>{morador.biometriaDigital ? morador.biometriaDigital : 'NÃO'}</td>
                                <td>{morador.biometriaFacial ? morador.biometriaFacial : 'NÃO'}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </Content>
      )
  }

  return (
    <Container>
        <Header>
            <div className='d-flex justify-content-between'>
                <div>
            </div>
            </div>

            <div className='d-flex box'>
                <div className='content d-flex'>
                    <BsPeople className='content-icon' />
                    <div>
                        <p className='content-title'>Relatório de moradores do condomínio</p>
                        <p className='content-subtitle'>Clique no botão abaixo para puxar todos os dados</p>
                    </div>
                </div>

                <div style={{marginLeft: 10}} className='align-self-center'>
                    <p className='info-title'>Clique no botão abaixo para realizar a busca:</p>
                    <IconButton disabled={relatorioStore.loading} appearance="primary" color="violet" icon={<SearchIcon />} onClick={listar}>
                        Buscar
                    </IconButton>
                </div>

                <div style={{marginLeft: '-60px'}} className='align-self-center mt-3'>
                    <p className='info-title-filtro'>Campo de filtragem:</p>
                    <InputGroup inside>
                        <Input disabled={!relatorioStore.relatorioMorador || relatorioStore.relatorioMorador.length === 0} placeholder='Buscar' value={search} onChange={(e) => 
                        {
                            setSearch(e);
                            setPage(1);
                        }} />
                        <InputGroup.Button disabled={!relatorioStore.relatorioMorador || relatorioStore.relatorioMorador.length === 0} onClick={() => setSearch('')}>
                            <CloseOutlineIcon />
                        </InputGroup.Button>
                    </InputGroup>
                </div>

                <div className='align-self-center' style={{marginLeft: 20}}>
                    <p className='info-title'>Exportar dados dos moradores(xlsx):</p>
                    {
                        !relatorioStore.relatorioMorador || relatorioStore.relatorioMorador.length === 0
                        ?
                        <IconButton disabled={!relatorioStore.relatorioMorador || relatorioStore.relatorioMorador.length === 0} appearance="primary" color="green" icon={<DetailIcon />}>
                            Exportar
                        </IconButton>
                        :
                        <Workbook
                            filename={`Relatório de moradores do condominio ${condominioStore.selecionado?.nome} do dia ${converterData(new Date())} às ${converterHora(new Date())}.xlsx`}
                            element={
                                <IconButton disabled={!relatorioStore.relatorioMorador} appearance="primary" color="green" icon={<DetailIcon />}>
                                    Exportar
                                </IconButton>
                            }
                            >
                            <Workbook.Sheet data={relatorioStore.relatorioMorador} name="First Sheet">
                                <Workbook.Column label="Condominio" value="condominio" />
                                <Workbook.Column label="Nome" value="nome" />
                                <Workbook.Column label="Bloco" value="bloco" />
                                <Workbook.Column label="Unidade" value="unidade" />
                                <Workbook.Column label="Cadastro" value="dataCadastro" />
                                <Workbook.Column label="TipoMorador" value="tipo" />
                                <Workbook.Column label="CPF" value="cpf" />
                                <Workbook.Column label="Nascimento" value="nascimento" />
                                <Workbook.Column label="Email" value="email" />
                                <Workbook.Column label="Celular" value="celular" />
                                <Workbook.Column label="Telefone" value="telefone" />
                                <Workbook.Column label="Status" value="statusMoradorDescricao" />
                                <Workbook.Column label="Tags" value="tags" />
                                <Workbook.Column label="BiometriaDigital" value="biometriaDigital" />
                                <Workbook.Column label="BiometriaFacial" value="biometriaFacial" />
                            </Workbook.Sheet>
                        </Workbook>
                    }
                    
                </div>
                
            </div>
        </Header>
        {renderEventos()}

        {
          relatorioStore.relatorioMorador.length > 0 ? 
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={10}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={relatorioStore.relatorioMorador.length}
            limitOptions={[10, 20, 30]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
          :
          ''
        }
    </Container>
  )
}

export default observer(RelatorioMoradores);