import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Modal, SelectPicker } from 'rsuite';
import { StoreContext } from '../../../../stores';
import { ZktecoRele, ZktecoReleRequest } from '../../../../interfaces/Zkteco.interface';
import { useZkteco } from '../../../../hooks/Zkteco.hook';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarRele: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<ZktecoReleRequest>({});
    const { atualizarRele, atualizando } = useZkteco();
    const { zktecoStore } = useContext(StoreContext);

    const numberList = Array.from({ length: 8 }, (_, index) => index + 1);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizarRele(form as ZktecoRele, hide);
    }

    useEffect(() =>
    {
        if(zktecoStore.selecionadoRele){
            let zk =  zktecoStore.selecionadoRele
            setForm({zktecoId: zktecoStore.selecionado?.id, id: zk.id, nome: zk.nome, tipo:zk.tipo, numeroRele: zk.numeroRele});
        }
    }, [zktecoStore.selecionadoRele]);

    const FormCard = () => {
        return (
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex row w-100'>
                    <Form.Group className="m-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" value={form.nome || ''} className='shadow-none' placeholder="Ex: Rele 1" onChange={(e) => setForm({ ...form, nome: e })} />
                    </Form.Group>
                    <Form.Group className="m-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Tipo</Form.Label>
                        <Input required type="text" value={form.tipo || ''} className='shadow-none' placeholder="Ex: Rele 1" onChange={(e) => setForm({ ...form, tipo: e })} />
                    </Form.Group>
                    <Form.Group className="m-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Saída</Form.Label><br/>
                        <SelectPicker block data={numberList.map(x => ({ label: x, value: x }))}  value={form.numeroRele} onChange={(e) => setForm({ ...form, numeroRele: e || undefined })} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance="primary" disabled={atualizando} type="submit">
                        {atualizando ? 'Salvando...' : 'Salvar'}
                    </Button>
                    <Button disabled={atualizando} type="button" onClick={hide} style={{ marginLeft: 10 }}>
                        Fechar
                    </Button>
                </div>
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar rele Zkteco</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormCard()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarRele);