import { makeAutoObservable, runInAction, configure } from "mobx";
import ReleAPI from "../api/ReleAPI";
import SipAPI from "../api/SipAPI";
import { Rele } from "../interfaces/Rele.interface";
import { DispositivoSip } from "../interfaces/Sip.interface";

class ReleStore {

    sips: DispositivoSip[] = [];
    selecionado: DispositivoSip | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
        this.definirSelecionado = this.definirSelecionado.bind(this);
        this.listar = this.listar.bind(this);
    }

    async listar() {
        this.loading = true;
        let condominioId = localStorage.getItem('condominio');
        try {
            if(condominioId) {
                const { data } = await SipAPI.listarDispositivoSipPorCondominioId(condominioId);
                runInAction(() => {
                    if(data)
                        this.sips = data;
                    else
                        this.sips = [];
                    this.loading = false;
                });
            }

        } catch(e) {
            
            this.loading = false;
        }
    }

    definirSelecionado(sipId: string) {
        let selecionado = this.sips.find((sip) => sip.id === sipId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }

}

export default ReleStore;