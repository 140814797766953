import { LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Modal, SelectPicker } from 'rsuite';
import useEquipamento from '../../../hooks/Equipamento.hook';
import { MODELOS_ZKTECO } from '../../../stores/EquipamentoStore';
import { Zkteco, ZktecoRequest } from '../../../interfaces/Zkteco.interface';
interface ModalProps {
    show: boolean;
    hide: () => void;
    condominioId?: string | null;
}

const CadastrarZkteco: FC<ModalProps> = ({ show, hide, condominioId }) => { 

    const { cadastrarZkteco, salvandoZkteco} = useEquipamento();
    const [form, setForm] = useState<ZktecoRequest>({});
    const [error, setError] = useState("")
    const modelosData = MODELOS_ZKTECO.STANDALONE.map((modelo) => ({
        label: modelo,
        value: modelo, 
      }));

    useEffect(() => {
        if(condominioId) {
            setForm({
                ...form,
                condominioId: condominioId,
            });
        }
    }, [condominioId]);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!form.modelo || !form.nome || !form.porta || !form.ip) {
            setError("Preencha todos os campos para cadastrar")
        } else {
            cadastrarZkteco(form as Zkteco, hide);
            setError("")
        }
    }

    const FormEquipamento = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip</Form.Label>
                        <Input type="text" className='shadow-none' placeholder="192.168.XXX.XXX" value={form.ip || ''} onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input type="number" className='shadow-none' placeholder="80" value={form.porta || ""} onChange={(e) => setForm({...form, porta: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input type="text" className='shadow-none' placeholder="Nome" value={form.nome || ''} onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label><br/>
                        <SelectPicker style={{width: '100%'}} className='shadow-none' placeholder="Modelo" searchable={false} data={modelosData} value={form.modelo || ''} onChange={(e) => setForm({...form, modelo: e || undefined})} />
                    </Form.Group>
                </div>
                <div className='d-flex justify-content-center mt-2'>
                    <p className='text-danger'>{error}</p>
                </div>

                <div className='d-flex justify-content-end mt-4 h-10'>
                    <Button appearance='primary' disabled={salvandoZkteco} type="submit">
                        { salvandoZkteco ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvandoZkteco} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {salvandoZkteco ? <LinearProgress color="primary" style={{height: 2, marginTop: 15, position: 'absolute',width: '100%', bottom: 0, left: 0}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size={'lg'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastre novo Zkteco</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormEquipamento()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarZkteco);