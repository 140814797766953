import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';
import { useBloco } from '../../hooks/Bloco.hook';
import { useSiam } from '../../hooks/Siam.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { Controladora, ControladoraRequest, Dispositivos, Siam, SiamRequest } from '../../interfaces/Siam.interface';
import { StoreContext } from '../../stores';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const CadastrarControladora: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<ControladoraRequest>({});
    const { cadastrarControladora, salvando } = useSiam();
    const { condominioStore, siamStore } = useContext(StoreContext);

    useEffect(() =>
    {
        if(show)
        {
            siamStore.definirSelecionado('');
            setForm({})
        }
    }, [show]);


    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(condominioStore.selecionado && siamStore.selecionado)
        {
            form.condominioId = condominioStore.selecionado.id;
            form.siamId = siamStore.selecionado.id;
            cadastrarControladora(form as Controladora, hide);
        }
        else
        {
            alert('erro')
        }
    }


    const renderMenu = (dispositivos : any) => {
        if (siamStore.loading) {
            return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                <Loader content={'Buscando dispositivos... Aguarde!'} />
                </p>
            );
        }
        
        return dispositivos;
    };


    const FormControladora = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                
                <div>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Equipamento</Form.Label>
                        <SelectPicker
                            block
                            data={siamStore.siam.map((s) => ({ label: s.nome, value: s.id }))}
                            placeholder={'Selecione o equipamento siam'}
                            onChange={(e) =>
                            {
                                siamStore.definirSelecionado(e as any);
                            }}
                        />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Dispositivo</Form.Label>
                        <SelectPicker
                            block
                            data={siamStore.dispositivos.map((d) => ({ label: d.nome, value: d.id }))}
                            placeholder={'Selecione o dispositivo'}
                            disabled={!siamStore.selecionado}
                            onOpen={() =>
                                {
                                  if(siamStore.selecionado)
                                  {
                                    siamStore.listarDispositivoPorSiamId(siamStore.selecionado.id);
                                  }
                            }}
                            renderMenu={renderMenu}
                            onChange={(e) =>
                            {
                                var selecionado = siamStore.dispositivos.find(x => x.id == e);
                                if(selecionado)
                                {
                                    setForm({ ...form, nome: selecionado.nome, dispositivoId: selecionado.id})
                                }
                                else
                                {
                                    alert('erro')
                                }
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" disabled className='shadow-none' value={form.nome || ''} placeholder="Nome do siam" onChange={(e) => setForm({...form, nome: e})}  />
                    </Form.Group>
                </div>

                {/* <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.modelo || ''} placeholder="Modelo do siam" onChange={(e) => setForm({...form, modelo: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip do servidor</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.ipServidor || ''} placeholder="Modelo do siam" onChange={(e) => setForm({...form, ipServidor: e})} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta do servidor</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.portaServidor || ''} placeholder="Ip do servidor" onChange={(e) => setForm({...form, portaServidor: parseInt(e)})} />
                    </Form.Group>
                </div> */}

                <div className='d-flex justify-content-end'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        { salvando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar nova controladora</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormControladora()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarControladora);