import styled from 'styled-components';
import { lighten } from 'polished';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
    padding: 10px;
    background: ${(props) => props.theme.colors.bgColor};
    border-radius: 10px;

    .content
    {
        margin: 10px;
    }
`;

