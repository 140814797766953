import react, { useEffect, useState, useContext, FormEvent, useRef, createRef } from 'react'
import { observer } from 'mobx-react-lite'
import { CardMessage, Container, CardInput, MessageLeft, MessageRight, CardRespondeMessage, Users } from './styles/Message.style';
import { Input,Whisper, Dropdown, Popover, IconButton, Loader, Button } from 'rsuite';
import { BsArrowReturnLeft, BsArrowReturnRight, BsChatSquareText, BsX } from 'react-icons/bs';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { HiDotsHorizontal, HiOutlinePhotograph } from 'react-icons/hi';
import { BsFileEarmarkArrowDown } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import Loading from '../utils/Loading';
import { converterDataHora } from '../utils/mask';

const Message = () => {

    const ref = useRef(null);
    const refScroll = createRef<any>();
    const setUsuarios = new Set();
    const messageRef = useRef<HTMLDivElement>(null);
    // const { EnviarMensagem } = useChat();
    const { authStore, moradorStore, chatStore } = useContext(StoreContext);
    const [mensagem, setMensagem] = useState("");
    const [temUsuario, setTemUsuario] = useState("");
    const [pinUser, setPinUser] = useState(false);
    const [prevScrollpos, setPrevScrollpos] = useState<number>();
    const [showButton, setShowButton] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalDescricao, setOpenModalDescricao] = useState(false);
    const [img, setImg] = useState({
        url: "",
        descricao: ""
    });
    

    const toggleOpenModal = () => setOpenModal(!openModal);
    const toggleOpenModalDescricao = () => setOpenModalDescricao(!openModalDescricao);

    const clear = () =>
    {
        setMensagem("");
    }

    useEffect(() => {
        if(messageRef.current) {
            messageRef.current.scrollIntoView({ block: 'end', behavior: 'auto' });
        }
    });


    // useEffect(() =>
    // {
    //     if(settingsStore.searchId !== null && chatStore.mensagens)
    //     {
    //         const element = document.getElementById(settingsStore.searchId);
    //         if (element) {
    //             element.scrollIntoView({ behavior: "auto" });
    //             // settingsStore.searchId = "";
    //         }
    //     }
    // }, [settingsStore.searchId])

    useEffect(() =>
    {
        setPrevScrollpos(document.getElementById("scroll")?.scrollTop ?? 0)
    }, [prevScrollpos])


    // useEffect(() =>
    // {
    //     settingsStore.searchId = null;
    //     settingsStore.searchMessage = null;
    // }, []);


    // useEffect(() =>
    // {
    //     if(chatStore.selecionado)
    //     {
    //         
    //     }
    // }, [chatStore.mensagens]);

    const filtroUsuario = chatStore.mensagens?.filter((usuario) =>
    {
        const usuarioDuplicado = setUsuarios.has(usuario.usuarioNome);
        setUsuarios.add(usuario.usuarioNome);
        usuario.usuarioNome.toLowerCase().includes(temUsuario);
        return !usuarioDuplicado;
    } );

    const EnviarMensagemOperador = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // if(authStore.usuario && localStorage.getItem("PA"))
        // {
        //     var novaCor = generateRandomColor();
        //     if(chatStore.selecionado === 'privado' && usuarioStore.selecionado)
        //     {
        //         await EnviarMensagem({
        //             usuarioNome: authStore.usuario.nome,
        //             maquina: localStorage.getItem("PA")!.toString(),
        //             remetenteId: authStore.usuario.id,
        //             destinatarioId: usuarioStore.selecionado.id,
        //             mensagem: mensagem,
        //             status: 0,
        //             cor: chatStore.mensagens.length === 0 ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id && !m.cor) ? generateRandomColor() : !chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id) ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id)?.cor,
        //             idResposta: chatStore.responder?.id ?? undefined
        //         }, clear);
        //         chatStore.definirMensagemParaResponder(null);
        //     } else if(authStore.usuario && grupoStore.selecionado)
        //     {
        //          await EnviarMensagem({
        //             usuarioNome: authStore.usuario.nome,
        //             maquina: localStorage.getItem("PA")!.toString(),
        //             remetenteId: authStore.usuario.id,
        //             mensagem: mensagem,
        //             status: 0,
        //             grupoSala: grupoStore.selecionado.id,
        //             cor: chatStore.mensagens.length === 0 ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id && !m.cor) ? generateRandomColor() : !chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id) ? generateRandomColor() : chatStore.mensagens.find((m) => m.remetenteId === authStore.usuario?.id)?.cor,
        //             idResposta: chatStore.responder?.id ?? undefined
        //         }, clear);
        //         // 
        //         // 
        //         // 
        //         chatStore.definirMensagemParaResponder(null);
        //     }
        // }
    }


    const renderMenu = ({ onClose, left, top, className }: any, ref: any)  =>
    {
      return(
        <Popover arrow={false} ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu style={{width: 300}}>
            <Dropdown.Item onClick={() =>
            {
                onClose();
                toggleOpenModal();
            }} style={{padding: 10}} icon={<HiOutlinePhotograph style={{marginRight: 19, fontSize: 22}} className='dropdown-icon'/>}>Foto</Dropdown.Item>
            {/* <Dropdown.Item onClick={onClose} style={{padding: 10}} icon={<BsFileEarmarkArrowDown style={{marginRight: 19, fontSize: 25}} className='dropdown-icon'/>}>Arquivo</Dropdown.Item> */}
          </Dropdown.Menu>
      </Popover>
      )
    }

    // if(chatStore.loading)
    // {
    //     return <Loading message='Carregando ultimas mensagens... Aguarde!' /> 
    // }

    useEffect(() =>
    {
        document?.querySelector('#streamWriter')?.addEventListener('keydown', function (e: any) {
            if (e.keyCode === 13) {
                // Ctrl + Enter
                if(e.ctrlKey) {
                    // 
        
                // Enter
                } else {
                    // 
                }
            }
        });
    }, [])

    return (
        <Container>
            {
                chatStore.loading
                ?
                <Loading message='Carregando ultimas mensagens... Aguarde!' /> 
                :
                <CardMessage className='d-flex justify-content-center' id={'scroll'}>
                    <div className='contentCardChat' ref={ref}>
                    {
                        chatStore.mensagens.length === 0
                        ?
                            <MessageLeft style={{width: 700}}>
                                <div className='content-chat'>
                                    <p className='usuario'>Chat principal</p>
                                    <div className='d-flex'>
                                        <p className='label'>
                                            Olá, Seja bem vindo ao chat principal! <br/> <br/> Você está enviando mesnagem para o morador <br/> <b>{moradorStore.selecionado?.nome}</b>!
                                           
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-end mt-2'>
                                        <p className='datahora align-self-center'>Chat</p> &nbsp; 
                                        <p className='datahora align-self-center'>-</p>
                                        &nbsp;
                                        <p className='datahora align-self-center'>{converterDataHora(new Date())}</p>
                                    </div>
                                </div>
                            </MessageLeft>
                        :
                        <>
                            {
                                chatStore.mensagens.map((mensagem, i) =>
                                    <div ref={messageRef} key={i} id={mensagem.id}>
                                        <div id={mensagem.texto}>
                                        {
                                            mensagem.usuarioNome === authStore.usuario?.nome
                                            ?
                                            <div className='d-flex justify-content-end'>
                                                <div>
                                                    {/* <div className='d-flex justify-content-end' role={'button'}>
                                                        <div style={{width: '80%'}}>
                                                        {
                                                            chatStore.mensagens.find((m) => m.id === mensagem.idResposta)
                                                            ?
                                                            <div className='contentResponse' role={'button'} onClick={() => {
                                                                // settingsStore.searchId = chatStore.mensagens.find((m) => m.id === mensagem.idResposta)!.id;
                                                                // settingsStore.searchMessage = ""
                                                            }}>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div>
                                                                        <p className='labelResponse'>{chatStore.mensagens.find((m) => m.id === mensagem.idResposta)?.usuarioNome.split(' ')[0]} {chatStore.mensagens.find((m) => m.id === mensagem.idResposta)?.usuarioNome.split(' ')[1]}</p>
                                                                        {chatStore.mensagens.find((m) => m.id === mensagem.idResposta)?.arquivo ? <p style={{margin: 0, fontSize: '9pt'}}>Foto <HiOutlinePhotograph size={20} /></p> : ''}
                                                                        <small>{chatStore.mensagens.find((m) => m.id === mensagem.idResposta)?.maquina}</small>
                                                                    </div>
                                                                    <BsArrowReturnLeft/>
                                                                </div>
                                                                <p className='messageResponse'>{chatStore.mensagens.find((m) => m.id === mensagem.idResposta) && chatStore.mensagens.find((m) => m.id === mensagem.idResposta)!.mensagem.length > 35 ? `${chatStore.mensagens.find((m) => m.id === mensagem.idResposta)?.mensagem.substring(0, 35)}...` : chatStore.mensagens.find((m) => m.id === mensagem.idResposta)?.mensagem}</p>
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                        </div>
                                                    </div> */}
                                                    <MessageRight mensagemId={chatStore.mensagens.find((m) => m.id === mensagem.id)?.id} >
                                                        <div className='d-flex justify-content-end'>
                                                            <p className='datahora align-self-center'>{localStorage.getItem('PA')}</p> &nbsp; 
                                                            <p className='datahora align-self-center'>-</p>
                                                            &nbsp;
                                                            {/* <p className='datahora align-self-center'>{converterData(new Date(mensagem.dataHoraEnvio))} - {converterHora(new Date(mensagem.dataHoraEnvio))}</p> */}
                                                        </div>
                                                    </MessageRight>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <MessageLeft>
                                                    <div >
                                                        {/* <div className='d-flex justify-content-between'>
                                                            <p style={{color: `#${mensagem.cor}`}} className='usuario'>{mensagem.usuarioNome.split(' ')[0]} {mensagem.usuarioNome.split(' ')[1]}</p>
                                                            <Whisper placement="bottomStart" trigger="click" speaker={({ onClose, left, top, className, ...rest } : any, ref: any) => {
                                                                return (
                                                                <Popover ref={ref} className={className} style={{ left, top }} full>
                                                                    <Dropdown.Menu >
                                                                        <Dropdown.Item onClick={() =>
                                                                        {
                                                                            onClose();
                                                                            chatStore.definirMensagemParaResponder(mensagem);
                                                                        }}><p style={{fontSize: 13, margin: 0}}>Responder</p></Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Popover>
                                                                );
                                                            }}>
                                                                <Button size={'xs'} appearance={'primary'} style={{background: 'transparent', color: '#5448c8'}}><HiDotsHorizontal/></Button>
                                                            </Whisper>
                                                        </div> */}
                                                        <div className=''>
                                                            {mensagem.texto}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='d-flex justify-content-ensd'>
                                                        <p className='datahora align-self-center'>{mensagem.texto}</p> &nbsp; 
                                                        <p className='datahora align-self-center'>-</p>
                                                        &nbsp;
                                                        {/* <p className='datahora align-self-center'>{converterData(new Date(mensagem.dataHoraEnvio))} - {converterHora(new Date(mensagem.dataHoraEnvio))}</p> */}
                                                    </div>
                                                </MessageLeft>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    }
                </div>
            </CardMessage>
            }

        <Whisper placement="topStart" open={pinUser} controlId="control-id-click" speaker={
            <Popover visible={pinUser} style={{margin: 0}}>
                {
                    filtroUsuario.map((user, i) =>
                    (
                        <Users className={'users'} onClick={() => {
                            setMensagem(mensagem =>  mensagem.concat(`${user.usuarioNome.split(' ')[0].toLocaleLowerCase()} ${user.usuarioNome.split(' ')[1].toLocaleLowerCase()} `));
                            setPinUser(false)
                        }}>
                            {user.usuarioNome}
                        </Users>
                    ))
                }
            </Popover>
        }>
            <CardInput onSubmit={EnviarMensagemOperador}>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                        <BsChatSquareText className='icon-message'/>
                        <Input id={"streamWriter"} ref={(input: any) => input && input.focus()} required style={{outline: 'none'}} className='input' placeholder='Mensagem' value={mensagem} onChange={(e) => 
                        {
                            setMensagem(e);
                            if(e.includes('@'))
                            {
                                if(e.replace('@', '') !== '' && filtroUsuario.find((u) => u.usuarioNome.toLocaleLowerCase().includes(e.replace('@', '').toLocaleLowerCase()))?.usuarioNome || filtroUsuario.find((u) => u.usuarioNome.toLocaleLowerCase().includes(e.split('@')[1].replace('@', '').toLocaleLowerCase()))?.usuarioNome)
                                {
                                    setPinUser(true);
                                }
                                else
                                {
                                    setPinUser(false);
                                }
                            }
                            else
                            {
                                // 
                                setPinUser(false);
                            }
                        }} />
                    </div>
                    <Whisper placement="topEnd" trigger="click" speaker={renderMenu}>
                        <IconButton style={{background: 'transparent'}} icon={<AiOutlinePaperClip role={'button'} size={25} className='icon-header' />} circle />
                    </Whisper>
                </div>
            </CardInput>
        </Whisper>
        

            

            {/* <ModalFile show={openModal} hide={toggleOpenModal} />
            <ModalImage show={openModalDescricao} hide={toggleOpenModalDescricao} descricao={img.descricao} url={img.url} /> */}
        </Container>
    )
}

export default observer(Message);