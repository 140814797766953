import React, { useContext, useState } from 'react';
import { Visitante } from '../interfaces/Visitante.interface';
import VisitanteAPI from '../api/VisitanteAPI';
import { StoreContext } from '../stores';
import { toast } from 'react-toastify';

export const useVisitante = () => {

    const { visitanteStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [_deletando, setDeletando] = useState(false);

    const cadastrar = async (visitante: Visitante, hide: () => void, clear: () => void) => {
        setSalvando(true);

        if (visitante.cpf) {
            visitante.cpf = visitante.cpf.replace(/[^0-9]/g, '');
        }
        
        if (visitante.celular) {
            visitante.celular = visitante.celular.replace(/[^0-9]/g, '');
        }
        
        if (visitante.telefone) {
            visitante.telefone = visitante.telefone.replace(/[^0-9]/g, '');
        }

        try {
            const { data, status } = await VisitanteAPI.salvar(visitante);
            if(status === 200) {
                visitanteStore.visitantes.push(data);
                visitanteStore.listarPorUnidade(data.unidadeId);
                toast.success('Visitante cadastrado com sucesso');
                hide();
                clear();
                setSalvando(false);
            }
        } catch(e) {
            toast.error('Erro ao cadastrar visitante');
            setSalvando(false);
        }
    }

    const atualizar = async (visitante: Visitante, hide: () => void) => {
        setAtualizando(true);
        try {
            const { data, status } = await VisitanteAPI.atualizar(visitante);
            if(status === 200) {
                visitanteStore.visitantes = visitanteStore.visitantes.map((vst) => vst.id === visitante.id ? visitante : vst); 
                toast.success('Visitante atualizado com sucesso');
                setAtualizando(false);
                hide()
            }
        } catch(e) {
            setAtualizando(false);
            toast.error('Erro ao atualizar visitante');
        }
    }

    const _deletar = async (visitante: Visitante) => {
        setDeletando(true);
        try {
            const { data, status } = await VisitanteAPI.deletar(visitante.id);
            if(status === 200) {
                visitanteStore.visitantes = visitanteStore.visitantes.filter((vst) => vst.id !== visitante.id); 
                setDeletando(false);
                toast.success('Visitante deletado com sucesso');
            }
        } catch(e) {
            setDeletando(false);
            toast.error('Erro ao deletar visitante');
        }
    }

    return {
        cadastrar,
        atualizar,
        _deletar,
        salvando,
        atualizando,
        _deletando
    }

}