import { useContext } from 'react';
import { Container } from './styles/Body.style';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import BlocoListagem from '../bloco/BlocoListagem';
import { observer } from 'mobx-react-lite';
import Morador from '../moradores/MoradoresListagem';
import Guarita from '../guarita/GuaritaListagem';
import EquipamentosCard from '../equipamentos/EquipamentoCard';
import AcionamentoListagem from '../acionamento/AcionamentoListagem';
import Rele from '../rele/Rele';
import Sip from '../dispositivo/sip/Sip';
import Ramal from '../ramal/Ramal';
import Layout from '../layout/LayoutListagem';
import VeiculoListagem from '../veiculo/VeiculoListagem';
import PerfilListagem from '../perfil/PerfilListagem';
import ListagemCameras from '../cameras/ListagemCameras';
import UsuariosListagem from '../usuarios/UsuariosListagem';
import RelatorioListagem from '../relatorios/RelatorioListagem';
import MaquinaListagem from '../maquina';
import RedeListagem from '../rede';
import NotasListagem from '../notas';
import Sincronizacao from '../sincronizacao';
import ListagemAutorizacao from '../autorizacao/AutorizacaoListagem';
import Header from '../layouts/Header';
import { StoreContext } from '../../stores';
import ListagemPerfisCameras from '../cameras/ListagemPerfisCameras';
import ChatListagem from '../chat';
import MessageGeral from '../chat/MessageGeral';
import ListagemAutomacoes from '../automacoes/ListagemAutomacoesAcionamentos';
import ConfiguracaoCondominio from '../condominios/ConfiguracaoCondominio';
import UnidadeRelatorio from '../unidadeRelatorio';
import RelatorioMoradores from '../relatorios/RelatorioMoradores';
import RelatorioVisitantes from '../relatorios/RelatorioVisitantes';
import RelatorioVeiculos from '../relatorios/RelatorioVeiculos';
import Octos from '../../views/octos';
import RelatorioEventos from '../relatorios/RelatorioEventos';
import AvisoListagem from '../aviso/AvisoListagem';
import MoradoresExcluidosListagem from '../moradores/MoradoresExcluidosListagem';

const Body = () => {

    const { condominioStore } = useContext(StoreContext);

    return(
        <Container>
            {
                condominioStore.selecionado
                ?
                <Header/>
                :
                <div/>
            }
            <Routes>
                <Route path={'/'} element={<Dashboard/>} />
                <Route path={'/bloco'} element={<BlocoListagem/>} />
                <Route path={'/morador'} element={<Morador/>} />
                <Route path={'/moradorexcluidos'} element={<MoradoresExcluidosListagem/>} />
                <Route path={'/unidaderelatorio'} element={<UnidadeRelatorio/>} />
                <Route path={'/chat'} element={<ChatListagem/>} />
                <Route path={'/notificacao'} element={<MessageGeral/>} />
                <Route path={'/veiculo'} element={<VeiculoListagem/>} />
                <Route path={'/autorizacao'} element={<ListagemAutorizacao/>} />
                <Route path={'/perfil'} element={<PerfilListagem/>} />
                <Route path={'/notas'} element={<NotasListagem/>} />
                <Route path={'/layout'} element={<Layout/>} />
                <Route path={'/cameras'} element={<ListagemCameras/>} />
                <Route path={'/octos'} element={<Octos/>} />
                <Route path={'/cameras/perfis'} element={<ListagemPerfisCameras/>} />
                <Route path={'/ramal'} element={<Ramal/>} />
                <Route path={'/maquina'} element={<MaquinaListagem/>} />
                <Route path={'/guaritas'} element={<Guarita/>} />
                <Route path={'/dispositivoSIP'} element={<Sip/>} />
                <Route path={'/equipamentos'} element={<EquipamentosCard/>} />
                <Route path={'/acionamento'} element={<AcionamentoListagem/>} />
                <Route path={'/automacoes'} element={<ListagemAutomacoes/>} />
                <Route path={'/rede'} element={<RedeListagem/>} />
                <Route path={'/sincronizacao'} element={<Sincronizacao/>} />
                <Route path={'/configuracao'} element={<ConfiguracaoCondominio/>} />
                <Route path={'/aviso'} element={<AvisoListagem/>} />


                <Route path={'/relatorio/moradoresgerais'} element={<RelatorioMoradores/>} />
                <Route path={'/relatorio/veiculosgerais'} element={<RelatorioVeiculos/>} />
                <Route path={'/relatorio/visitantesgerais'} element={<RelatorioVisitantes/>} />
                <Route path={'/relatorio/eventos'} element={<RelatorioEventos/>} />
            
            </Routes>
        </Container>
    );
}

export default observer(Body);