import { makeAutoObservable, runInAction } from "mobx";
import RedeAPI from "../api/RedeAPI";
import { Rede } from "../interfaces/Rede.interface";

class RedeStore {

    redes: Rede[] = [];
    selecionado: Rede | null = null;

    constructor() {
        makeAutoObservable(this);
    }
    
    async listarPorCondominio() {
        try {
            let condominioId = localStorage.getItem('condominio');
            if(condominioId)
            {
                const { data } = await RedeAPI.listarPorCondominio(condominioId);
                runInAction(() => {
                    if(data) this.redes = data;
                    else this.redes = [];
                });
            }
        } catch(e) {
            
        }
    }

    definirSelecionado(redeId: string) {
        let selecionado = this.redes.find((r) => r.id === redeId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }


}

export default RedeStore;