import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { Container, Header } from './styles/Funcionario.style';
import { Dropdown, IconButton, Input, InputGroup } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import { Table } from 'react-bootstrap';
import { StoreContext } from '../../stores';
import ModalCadastrar from './ModalCadastrar';
import { converterDataHora, cpfMask, maskCelular } from '../utils/mask';
import ModalAtualizar from './ModalAtualizar';
import { useFuncionario } from '../../hooks/Funcionario.hook';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import SkeletonList from '../utils/SkeletonList';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';

const FuncionarioListagem = () => {


    const token = process.env.REACT_APP_BASE_TOKEN_FUNCIONARIO;
    const { funcionarioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando } = useFuncionario();

    const [cadastrar, setCadastrar] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const [excluir, setExcluir] = useState(false);
    const [status, setStatus] = useState('');
    const [search, setSearch] = useState('');

    const toggleCadastrar = () => setCadastrar(!cadastrar);
    const toggleAtualizar = () => setAtualizar(!atualizar);
    const toggleExcluir = () => setExcluir(!excluir);

    const filtrar = funcionarioStore.funcionarios.filter(
        (i) =>
            i.nome.toLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
            i.cpf?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            i.matricula?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            i.email?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            i.celular?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            !search)

    const deletarComModal = () => {
        if (funcionarioStore.selecionado)
            deletar(funcionarioStore.selecionado);
    }

    useEffect(() => {
        funcionarioStore.listar();
    }, []);

    const renderFuncionario = () => {

        if (funcionarioStore.loading) {
            return <SkeletonList />
        }

        return (
            <div className='p-1'>
                <Table striped={themeStore.theme === 'dark' ? false : true}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Matricula</th>
                            <th>Nome</th>
                            <th>Descricao</th>
                            <th>CPF</th>
                            <th>Email</th>
                            <th>Celular</th>
                            <th>Data cadastro</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtrar.map((f, i) =>
                            (
                                <tr className='' key={i} onClick={() => funcionarioStore.definirSelecionado(f.id)}>
                                    <td className='p-2'>{f.id?.split('-')[4]}</td>
                                    <td className='p-2'>{f.matricula}</td>
                                    <td className='p-2'>{f.nome}</td>
                                    <td className='p-2'>{f.tipoFuncionario?.descricao}</td>
                                    <td className='p-2'>{cpfMask(f.cpf)}</td>
                                    <td className='p-2'>{f.email}</td>
                                    <td className='p-2'>{maskCelular(f.celular)}</td>
                                    <td className='p-2'>{converterDataHora(new Date(f.dataCadastro))}</td>
                                    <td>
                                        <Dropdown appearance='primary' color={'violet'} title="Ação" size={'xs'}>
                                            <Dropdown.Item onClick={toggleAtualizar}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleExcluir}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <Container>
            <Header className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <div>
                        <p className='title'>Funcionários</p>
                        <p className='subtitle'>Listagem dos funcionários cadastrados</p>
                    </div>
                    <div style={{ marginLeft: 10 }} className='align-self-center'>
                        <p className='info-title-filtro' style={{ width: 100 }}>Campo de busca</p>
                        <InputGroup inside>
                            <Input placeholder='Buscar' style={{ width: 350 }} value={search} onChange={(e) => setSearch(e)} />
                            <InputGroup.Button onClick={() => setSearch('')}>
                                <CloseOutlineIcon />
                            </InputGroup.Button>
                        </InputGroup>
                    </div>
                </div>
                <div className='mt-2'>
                    <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrar}>
                        Adicionar funcionário
                    </IconButton>
                </div>
            </Header>

            {renderFuncionario()}

            <ModalCadastrar show={cadastrar} hide={toggleCadastrar} />
            <ModalAtualizar show={atualizar} hide={toggleAtualizar} />
            <ModalConfirmacao show={excluir} hide={toggleExcluir} action={deletarComModal} content='Você realmente deseja deletar esse funcionário?' loading={deletando} />
        </Container>
    )
}

export default observer(FuncionarioListagem);