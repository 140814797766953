import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useState } from 'react';
import { AiOutlineTags } from 'react-icons/ai';
import { IoMdFingerPrint } from 'react-icons/io';
import { MdFace } from 'react-icons/md';
import { StoreContext } from '../../../../stores';
import CadastrarBiometria from '../../biometria/CadastrarBiometria';
import CadastrarFacial from './facial/CadastrarFacial';
import { Container } from './styles/hikvisionCard.style';

interface ModalProps {
    cadastrar: boolean; 
    setCadastrar: React.Dispatch<React.SetStateAction<boolean>>
}

const HikvisionCard: FC<ModalProps> = ({ cadastrar, setCadastrar }) => {

    const { equipamentoStore, moradorStore } = useContext(StoreContext);
    const [routes, setRoutes] = useState('');

    switch(routes) {
        case 'facial':
            return <CadastrarFacial setCadastrar={setCadastrar} setRoutes={setRoutes}/>
        case 'digital':
            return <CadastrarBiometria cadastrar={cadastrar} setCadastrar={setCadastrar} setRoutes={setRoutes} />
    }

    return(
        <Container className='animation'>
            <p className='title-hikvision'>Hikvision cadastro de biometria facial e digital</p>

            <div className='content' role={'button'} onClick={() => {
                if(moradorStore.selecionado?.urlImg)
                {
                    alert('Morador já tem facial cadastrado!')
                }
                else
                {
                    setRoutes('facial');
                }
            }}> 
                <MdFace className='icon' />
                <div>
                    <p className='title'>Cadastro de biometria facial</p>
                    <p className='subtitle'>
                        Faça o cadastro da biometria facial do morador pelo dispositivo selecionado.
                    </p>
                </div>
            </div>

            <div className='content' role={'button'} onClick={() => setRoutes('digital')}> 
                <IoMdFingerPrint className='icon' />
                <div>
                    <p className='title'>Cadastro de biometria digital</p>
                    <p className='subtitle'>
                        Cadastre a biometria do morador pelo dispositivo selecionado.
                    </p>
                </div>
            </div>

            <div className='d-flex justify-content-center m-1'>
                <button className='btn btn-danger shadow-none w-50' onClick={ () => {
                    equipamentoStore.definirSelecionado('');
                    setCadastrar(false);
                } }>
                    Voltar
                </button>
            </div>
        </Container>
    )
}

export default observer(HikvisionCard);