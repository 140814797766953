import { makeAutoObservable, runInAction } from "mobx";
import AcionamentoPerfilAPI from "../api/AcionamentoPerfilAPI";
import { IPerfil } from "../interfaces/AcionamentoPerfil.interface";

class AcionamentoPerfilStore {

    perfis: IPerfil[] = [];
    perfisCondominio: IPerfil[] = [];
    perfilMorador: IPerfil | null = null;
    selecionado: IPerfil | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    obterPorId = async (acionamentoPerfilId: string) => {
        this.loading = true;
        try {
            if(acionamentoPerfilId) {
                const { data } = await AcionamentoPerfilAPI.obterPorId(acionamentoPerfilId);
                runInAction(() => {
                    this.perfilMorador = data;
                    this.loading = false;
                })
            }
        } catch(e) {
            this.loading = false;
            
        }   
    }

    listar = async () => {
        let condominioId = localStorage.getItem('condominio');
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await AcionamentoPerfilAPI.listar(condominioId);
                runInAction(() => {
                    if(data)
                        this.perfis = data;
                    else
                        this.perfis = [];
                    this.loading = false;
                })
            }
        } catch(e) {
            this.loading = false;
            
        }   
    }

    listarPorCondominio = async (condominioId: string) => {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await AcionamentoPerfilAPI.listar(condominioId);
                runInAction(() => {
                    if(data) this.perfisCondominio = data;
                    else this.perfisCondominio = [];
                    this.loading = false;
                })
            }
        } catch(e) {
            this.loading = false;
            
        }   
    }

    definirSelecionado(perfilId: string | null) {
        
        if(perfilId !== null) {
            let selecionado = this.perfis.find((per) => per.id === perfilId);
            if(selecionado) {
                this.selecionado = selecionado;
            }
        } else {
            this.selecionado = null;
        }
    }

}


export default AcionamentoPerfilStore;