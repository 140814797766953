
import { observer } from 'mobx-react-lite';
import react, { FC, useContext, useEffect, useState } from 'react';
import MoradorAPI from '../../../api/MoradorAPI';
import { StoreContext } from '../../../stores';
import { useMorador } from '../../../hooks/Morador.hook';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../../utils/mask';
import { Button, Input, Loader, Modal, SelectPicker, Toggle } from 'rsuite';
import { AutomacaoAcionamentoIdentificacao, AutomacaoAcionamentoIdentificacaoRequest } from '../../../interfaces/AutomacaoAcionamentoIdentificacao.interface';
import useAutomacaoAcionamentoIdentificacao from '../../../hooks/AutomacaoAcionamentoIdentificacao.hook';



interface PropsMorador {
    show: boolean;
    hide: () => void;
}

const AtualizarAutomacaoAcionamento: FC<PropsMorador> = ({ show, hide }) => {

    let condominioId = localStorage.getItem('condominio');
    const { unidadeStore, moradorStore, automacaoAcionamentoIdentificacaoStore, condominioStore, cameraStore } = useContext(StoreContext);
    const [form, setForm] = useState<AutomacaoAcionamentoIdentificacaoRequest>({});
    const { atualizar, atualizando, deletar, deletando } = useAutomacaoAcionamentoIdentificacao();


    const salvar = () => {
        atualizar(form as AutomacaoAcionamentoIdentificacao, hide);
    }

    useEffect(() =>
    {
        if(automacaoAcionamentoIdentificacaoStore.selecionado)
            setForm(automacaoAcionamentoIdentificacaoStore.selecionado)
    }, [automacaoAcionamentoIdentificacaoStore.selecionado]);

    const renderForm = () =>
    {
      return(
        <div>
            <div className='card-form-morador'>
                <div className='mt-2'>
                    <p className='title-form'>Nome da automação do acionamento</p>
                    <Input className='' placeholder='Identificação' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })}  />
                </div>
            </div>
        </div>
      )
    }

    return(
        <Modal open={show} onClose={hide} size={'xs'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro de automação de acionamento</p>
                    <p className='modal-styled-subtitl'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                {renderForm()}
            </Modal.Body>

            <Modal.Footer>
            <div className='d-flex justify-content-between'>
                    <Button appearance='primary' color={'red'} disabled={deletando} onClick={() =>
                    {
                        if(automacaoAcionamentoIdentificacaoStore.selecionado)
                            deletar(automacaoAcionamentoIdentificacaoStore.selecionado, hide)
                    }}>
                        {
                            deletando
                            ?
                            <Loader content={'Excluindo... Agurade!'} />
                            :
                            'Excluir'
                        }
                    </Button>
                    <div>
                        <Button appearance={'primary'} color={'violet'} disabled={atualizando} onClick={salvar}>
                            {
                                atualizando
                                ?
                                <Loader content={'Salvando...'} />
                                :
                                'Salvar'
                            }
                        </Button>
                        <Button onClick={hide}>Fechar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarAutomacaoAcionamento);