import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    
    .header
    {
        background: ${(props) => props.theme.colors.bgBox};
        border-radius: 10px;
        padding: 10px;
    }
    .title
    {
        font-size: 13pt;
        font-weight: 550;
    }

    .box-content
    {
        padding: 10px;
        background: ${(props) => props.theme.colors.bgBox};
        margin-top: 10px;
        border-radius: 8px;
    }
    .info
    {
        font-weight: 550;
        font-size: 12pt;
    }
    .content
    {
        font-size: 10pt;
    }
`