import { readFile } from "fs";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import BlocoAPI from "../api/BlocoAPI";
import ReleAPI from "../api/ReleAPI";
import SipAPI from "../api/SipAPI";
import { StoreContext } from "../stores";
import { Bloco } from "../interfaces/Bloco.interface";
import { Rele } from "../interfaces/Rele.interface";
import { DispositivoSip } from "../interfaces/Sip.interface";


export const useSip = () => {

    const { sipStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const cadastrar = async (sip: DispositivoSip, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await SipAPI.cadastrarDispositivoSip(sip);
            if(status === 200) {
                sipStore.sips.push(data);
                setSalvando(false);
                hide();
                setSuccess(true);
                toast.success('SIP cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar SIP')
        }
    }

    const atualizar = async (_sip: DispositivoSip, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await SipAPI.atualizarDispositivoSip(_sip);
            
            if(status === 200) {
                sipStore.sips = sipStore.sips.map(sip => sip.id === _sip.id ? _sip : sip);
                setAtualizando(false);
                hide();
                setSuccess(true);
                toast.success('SIP atualizado com sucesso!')
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar SIP')
        }
    }

    const deletar = async (_sip: DispositivoSip) => {
        setDeletando(true);
        try {
            const { status } = await SipAPI.deletarDispositivoSip(_sip.id);
            if(status === 200) {
                sipStore.sips = sipStore.sips.filter(sip => sip.id !== _sip.id);
                setDeletando(false);
                toast.success('SIP deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar SIP')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        success,
    }

}