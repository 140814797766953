import { useContext, useState } from "react";
import { toast } from "react-toastify";
import GuaritaAPI from "../api/GuaritaAPI";
import { StoreContext } from "../stores";
import { Guarita } from "../interfaces/Guarita.interface";


const useGuarita = () => {

    const { guaritaStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [reiniciando, setReiniciando] = useState(false);
    const [resetando, setResetando] = useState(false);

    const reiniciar = async (guarita: Guarita, hide: () => void) => {
        setReiniciando(true);
        try {
            const { data, status } = await GuaritaAPI.reiniciar(guarita.id);
            if(status === 200) {
                toast.success('Guarita reiniciado com sucesso');
                hide();
                setReiniciando(false);
            } else {
                toast.error('Erro ao reiniciar guarita');
                setReiniciando(false);
            }
        } catch(e) {
            setReiniciando(false);
            toast.error('Erro ao reiniciar guarita');
            //
        }
    }

    const resetar = async (guarita: Guarita, hide: () => void) => {
        setResetando(true);
        try {
            const { data, status } = await GuaritaAPI.resetar(guarita.id);
            if(status === 200) {
                toast.success('Guarita resetado com sucesso');
                hide();
                setResetando(false);
            } else {
                toast.error('Erro ao resetar guarita');
                setResetando(false);
            }
        } catch(e) {
            setResetando(false);
            toast.error('Erro ao resetar guarita');
            //
        }
    }

    const atualizarReceptores = async (guarita: Guarita, hide: () => void) => {
        setAtualizando(true);
        try {
            const { data, status } = await GuaritaAPI.atualizarReceptores(guarita.id);
            if(status === 200) {
                toast.success('Guarita com os receptores atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar receptores do guarita');
                setAtualizando(false);
                toast.error('Erro ao atualizar receptores do guarita');
            }
        } catch(e) {
            setAtualizando(false);
            toast.error('Erro ao atualizar receptores do guarita');
            //
        }
    }

    const cadastrar = async (guarita: Guarita, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await GuaritaAPI.cadastrarGuarita(guarita);
            if(status === 200) {
                guaritaStore.guaritas.push(data);
                toast.success('Guarita cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar guarita');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            toast.error('Erro ao cadastrar guarita');
            //
        }
    }

    const atualizar = async (guarita: Guarita, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await GuaritaAPI.atualizarGuarita(guarita);
            if(status === 200) {
                guaritaStore.guaritas = guaritaStore.guaritas.map( gua => gua.id === guarita.id ? guarita : gua );
                toast.success('Guarita atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar guarita');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            toast.error('Erro ao atualizar guarita');
            //
        }
    }

    const deletar = async (guarita: Guarita) => {
        setDeletando(true);
        try {
            const { status } = await GuaritaAPI.deletarGuarita(guarita.id);
            if(status === 200) {
                guaritaStore.guaritas = guaritaStore.guaritas.filter( gua => gua.id !== guarita.id );
                toast.success('Guarita deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado guarita');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
            toast.error('Erro ao deletado guarita');
            //
        }
    }


    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        reiniciar,
        reiniciando,
        resetar,
        atualizarReceptores,
        resetando
    }

}


export default useGuarita