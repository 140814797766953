import styled from "styled-components";
import PerfcScrollBar from 'react-perfect-scrollbar';

export const Container = styled.div`
  padding: 10px;
  flex: 1;

  .result
  {
    padding: 10px;
    background: ${(props) => props.theme.colors.bgBox};
    border-radius: 10px;
    width: 150px;
    text-align: center;
    margin: 10px;
  }
  
  `;

export const Header = styled.div`
  background: ${(props) => props.theme.colors.bgBox};
  box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
  border-radius: 8px;
  padding: 10px;
  .title
  {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
  }
  .subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .box
  {
  }

  .content
  {
    align-self: center;
    width: 250px;
  }
  .content-icon
  {
    align-self: center;
    font-size: 25pt;
    margin-right: 20px;
  }
  .content-title
  {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
  }
  .content-subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .info-title
  {
    font-size: 8pt;
    width: 80%;
  }
  .info-title-filtro
  {
    font-size: 8pt;
    width: 50%;
  }

`;

export const Content = styled(PerfcScrollBar)`
  padding-right: 6px;
  width: 100%;
  height: 60vh;
  margin-bottom: 10px;
  margin-top: 20px;
  table td
  {
    text-transform: uppercase;
  }

  thead th {
    font-weight: 0;
    margin-top: 90px;
    background: ${(props) => props.theme.colors.bgColor};
    font-size: 13px;
    padding: 10px;
  } 
  tbody td {
    font-size: 12px;
  border: 0;
  padding: 15px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
  @media(max-height: 1300px)
  {
  height: 54vh;
  }

`;

export const Scroll = styled(PerfcScrollBar)`
  align-self: center;
  border-radius: 10px;
`;