import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_CHAT;
// const baseURL = 'http://192.168.19.101/outkeychat/api'

const chatApi = axios.create({ baseURL });

chatApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@token_chat');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return await config;
});

export default chatApi;
