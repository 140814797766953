import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import GuaritaAPI from "../api/GuaritaAPI";
import ModuloAPI from "../api/ModuloAPI";
import { Guarita, GuaritaModulos } from "../interfaces/Guarita.interface";
import { Modulo } from "../interfaces/Modulo.interface";

class GuaritaStore {

    guaritas: Guarita[] = [];
    modulos: Modulo[] = [];
    selecionado: Guarita | null = null;
    moduloSelecionado: Modulo | null = null;
    modulosPorCondominio: Modulo[] = [];
    modulosGuarita: GuaritaModulos[] = [];
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
        this.definirSelecionado = this.definirSelecionado.bind(this);
        this.listar = this.listar.bind(this);
        this.listarModulo = this.listarModulo.bind(this);
        this.listarModuloPorCondominio = this.listarModuloPorCondominio.bind(this);
    }

    async listar() {
        let condominioId = localStorage.getItem('condominio')
        try {
            if(condominioId) {
                const { data } = await GuaritaAPI.listarGuaritaPorCondominio(condominioId);
                runInAction(() => {
                    if(data)
                        this.guaritas = data;
                    else
                        this.guaritas = [];
            });
            }
        } catch(e) {
            
        }
    }

    async listarModuloPorCondominio() {
        let condominioId = localStorage.getItem('condominio')
        try {
            if(condominioId) {
                const { data } = await ModuloAPI.listarModulosPorCondominio(condominioId);
                runInAction(() => {
                    if(data)
                        this.modulosPorCondominio = data;
                    else
                        this.modulosPorCondominio = [];
                });
            }
        } catch(e) {
            
        }
    }

    async listarVersaoModuloPorGuarita(guaritaId: string) {
        this.loading = true;
        try {
            const { data } = await GuaritaAPI.versaoTodosModulos(guaritaId);
            runInAction(() => {
                if(data)
                    this.modulosGuarita = data;
                else
                    this.modulosGuarita = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
            
        }
    }

    async listarModulo(guaritaId: string) {
        try {
            if(guaritaId) {
                const { data } = await ModuloAPI.listarModulosProGuaritaId(guaritaId);
                runInAction(() => {
                    if(data)
                        this.modulos = data;
                    else
                        this.modulos = [];

                    
                });
            }
        } catch(e) {
            
        }
    }

    definirSelecionado(guaritaId: string) {
        let selecionado = this.guaritas.find((gua) => gua.id === guaritaId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
        else this.selecionado = null;
    }

    definirModuloSelecionado(modulo: Modulo) {
        this.moduloSelecionado = modulo;
    }

}

export default GuaritaStore;