import api from "../services/api/api";
import apiDispositivo from "../services/dispositivo/api";
import { Anviz, ControlId, Hikvision, IntelbrasFacial, Zkteco } from "../interfaces/Equipamento.interface";

class EquipamentoAPI {

    listarEquipamentoBiometria = async (condominioId: string) => await apiDispositivo.get(`/equipamento/${condominioId}`);
    listarEquipamentoPorMorador = async (moradorId: string) => await apiDispositivo.get(`/Equipamento/morador/${moradorId}`);

    // CONTROLID
    listarControlIdPorId = async (id: string) => await api.get<ControlId>(`/Controlid/${id}`);
    listarControlIdPorCondominio = async (condominioId: string) => await api.get<ControlId[]>(`/Controlid/condominio/${condominioId}`);
    salvarControlId = async (controlId: ControlId) => await api.post<ControlId>(`/Controlid`, controlId);
    atualizarControlId = async (controlId: ControlId) => await api.put<ControlId>(`/Controlid`, controlId);
    deletarControlId = async (controlIdId: string) => await api.delete<ControlId>(`/Controlid/${controlIdId}`);

    // HIKVISION
    listarHikvisionPorId = async (id: string) => await api.get<Hikvision>(`/Hikvision/${id}`);
    listarHikvisionPorCondominio = async (condominioId: string) => await api.get<Hikvision[]>(`/Hikvision/condominioId/${condominioId}`);
    salvarHikvision = async (hikvision: Hikvision) => await api.post<Hikvision>(`/Hikvision`, hikvision);
    atualizarHikvision = async (hikvision: Hikvision) => await api.put<Hikvision>(`/Hikvision`, hikvision);
    deletarHikvision = async (hikvisionId: string) => await api.delete<Hikvision>(`/Hikvision/${hikvisionId}`);

    // ANVIZ
    listarAnvizPorId = async (id: string) => await api.get<Anviz>(`/Anviz/${id}`);
    listarAnvizPorCondominio = async (condominioId: string) => await api.get<Anviz[]>(`/Anviz/condominioId/${condominioId}`);
    salvarAnviz = async (anviz: Anviz) => await api.post<Anviz>(`/Anviz`, anviz);
    atualizarAnviz = async (anviz: Anviz) => await api.put<Anviz>(`/Anviz`, anviz);
    deletarAnviz = async (anvizId: string) => await api.delete<Anviz>(`/Anviz/${anvizId}`);

     // INTELBRAS
    listarIntelbrasPorId = async (id: string) => await api.get<IntelbrasFacial>(`/Intelbras/${id}`);
    listarIntelbrasPorCondominio = async (condominioId: string) => await api.get<IntelbrasFacial[]>(`/Intelbras/condominio/${condominioId}`);
    salvarIntelbras = async (intelbras: IntelbrasFacial) => await api.post<IntelbrasFacial>(`/Intelbras`, intelbras);
    atualizarIntelbras = async (intelbras: IntelbrasFacial) => await api.put<IntelbrasFacial>(`/Intelbras`, intelbras);
    deletarIntelbras = async (intelbras: IntelbrasFacial) => await api.delete(`/Intelbras/${intelbras.id}`);

    // ZKTECO
    listarZktecoPorId = async (id: string) => await api.get<Zkteco>(`/Zkteco/${id}`);
    listarZktecoPorCondominio = async (condominioId: string) => await api.get<Zkteco[]>(`/Zkteco/condominio/${condominioId}`);
    salvarZkteco = async (zkteco: Zkteco) => await api.post<Zkteco>(`/Zkteco`, zkteco);
    atualizarZkteco = async (zkteco: Zkteco) => await api.put<Zkteco>(`/Zkteco`, zkteco);
    deletarZkteco = async (zktecoId: string) => await api.delete<Zkteco>(`/Zkteco/${zktecoId}`);
}

export default new EquipamentoAPI();