import { makeAutoObservable, runInAction } from "mobx";

import { Aviso } from "../interfaces/Aviso.interface";
import AvisoAPI from "../api/AvisoAPI";

class AvisoStore {
  avisos: Aviso[] = [];
  selecionado: Aviso | null = null;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async listar() {
    this.loading = true;
    try {
      let condominioId = localStorage.getItem("condominio");
      if (condominioId) {
        const { data } = await AvisoAPI.listarAvisos(condominioId);
        runInAction(() => {
          if (data) this.avisos = data;
          else this.avisos = [];
          this.loading = false;
        });
      }
    } catch (e) {
      this.loading = false;
    }
  }

  definirSelecionado(avisoId: string) {
    let selecionado = this.avisos.find((avi) => avi.id === avisoId);
    if (selecionado) {
      this.selecionado = selecionado;
    }
  }
}

export default AvisoStore;
