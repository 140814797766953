import { makeAutoObservable, runInAction } from "mobx";
import { Captura, Zkteco, ZktecoRele } from "../interfaces/Zkteco.interface";
import ZktecoAPI from "../api/ZktecoAPI";
import { toast } from "react-toastify";


class ZktecoStore {
  zkteco: Zkteco[] = [];
  reles: ZktecoRele[] = [];
  releZkteco: ZktecoRele[] = [];
  selecionado: Zkteco | null = null;
  selecionadoRele: ZktecoRele | null = null;
  infoRele: ZktecoRele | null = null;
  captura: Captura | null = null;
  loading: boolean = false;
  condominioId: string | null = null;
  zktecoID: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  async listar() {
    this.loading = true;
    let condominioId = localStorage.getItem('condominio')
    this.condominioId = condominioId;
    try {
        if(condominioId) {
            const { data } = await ZktecoAPI.listarPorCondominio(condominioId);
            runInAction(() => {
                if(data) this.zkteco = data;
                else this.zkteco = [];
                this.loading = false;
            });
        }
    } catch(e) {
        this.loading = false;
    }
}

  async listarPorCondominio(condominioId: string) {
    this.loading = true;
    try {
      if (condominioId) {
        const { data } = await ZktecoAPI.listarPorCondominio(condominioId);
        runInAction(() => {
          if (data) this.zkteco = data;
          else this.zkteco = [];
          this.loading = false;
        });
      }
    } catch (e) {
      // console.error(e)
    } finally {
      this.loading = false;
    }
  }

  async obterRelePorId(id: string) {
    this.loading = true;
    try {
      if (id) {
        const { data } = await ZktecoAPI.obterRelePorId(id);
        runInAction(() => {
          if (data) this.infoRele = data;
          else this.infoRele = null;
          this.loading = false;
        });
      }
    } catch (e) {
      // console.error(e)
    } finally {
      this.loading = false;
    }
  }

  async listarRelePorZkteco(ZktecoId: string) {
    this.loading = true;
    try {
      if (ZktecoId) {
        const { data } = await ZktecoAPI.listarRelePorZktecoId(ZktecoId);
        runInAction(() => {
          if (data) this.releZkteco = data;
          else this.releZkteco = [];
          this.loading = false;
        });
      }
    } catch (e) {
      // console.error(e);
    } finally {
      this.loading = false;
    }
  }

  definirSelecionadoRele(releId: string) {
    let selecionado = this.releZkteco.find((b) => b.id === releId);
    if (selecionado) {
      this.selecionadoRele = selecionado;
      this.selecionadoRele = {...this.selecionadoRele, zktecoId: this.zktecoID}
    } else this.selecionadoRele = null;
  }

  definirSelecionado(zktecoId: string) {
    let selecionado = this.zkteco.find((b) => b.id === zktecoId);
    if (selecionado) {
      this.selecionado = selecionado;
    } else this.selecionado = null;
  }

  async capturarSerial(equipamentoId: string) {
    this.loading = true;
    try {
      if(equipamentoId) {
        const { data } = await ZktecoAPI.capturarSerial(equipamentoId);
        runInAction(() => {
          this.captura = data;
        });
      }
    } catch(e) {
      toast.error("Falha ao Capturar Serial")
    } finally{
      this.loading = false
    }
  }

  async reiniciarZkInbio(equipamentoId: string) {
    try{
      if(equipamentoId){
        const { status } = await ZktecoAPI.reiniciarEquipamentoInbio(equipamentoId)
        if(status === 200){
          toast.success("Comando Enviado")
        }
      }
    }catch(e){
        toast.error("Falha ao Enviar comando")
        console.error(e)
    }
  }

  async reiniciarZkStandAlone(equipamentoId: string) {
    try{
      if(equipamentoId){
        const { status } = await ZktecoAPI.reiniciarEquipamentoInbio(equipamentoId)
        if(status === 200){
          toast.success("Comando Enviado")
        }
      }
    }catch(e){
        toast.error("Falha ao Enviar comando")
        console.error(e)
    }
  }
}

export default ZktecoStore;
