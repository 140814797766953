import { makeAutoObservable, runInAction } from "mobx";
import CameraAPI from "../api/CameraAPI";
import { Camera, CameraDGuard, CameraPerfil, CameraPerfilIdentificacao, CameraPerfilLista, CameraPerfilObj } from "../interfaces/Cameras.interface";

class CameraStore {

    cameras: Camera[] = [];
    camerasCondominio: Camera[] = [];
    camerasCondominioOrdem: Camera[] = [];
    camerasPerfisIdentificacao: CameraPerfilIdentificacao[] = [];
    camerasPerfil: CameraPerfil[] = [];
    camerasDGuard: CameraDGuard[] = [];
    selecionado: Camera | null = null;
    perfilSelecionado: CameraPerfilIdentificacao | null = null;
    camerasPerfilSelecionado: CameraPerfil | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listarPorCondominioId(condominioId: string) {
        this.loading = true;
        try {
            if(condominioId) {
                const { data } = await CameraAPI.listarPorCondominioId(condominioId);
                runInAction(() => {
                    if(data) this.camerasCondominio = data;
                    else this.camerasCondominio = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
            //
        }
    }

    async listarPorLayout(layoutId: string) {
        this.loading = true;
        try {
            if(layoutId) {
                const { data } = await CameraAPI.listarPorLayout(layoutId);
                runInAction(() => {
                    if(data)
                        this.cameras = data;
                    else
                        this.cameras = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
            //
        }
    }

    async listarCamerasDGuardPorLayout(layoutId: string) {
        this.loading = true;
        try {
            const { data } = await CameraAPI.listarCamerasDoServidorDGuardPorLayoutId(layoutId);
            runInAction(() => {
                if(!data) this.camerasDGuard = [];
                else this.camerasDGuard = data;
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listarCamerasPerfisIdentificacaoPorCondominioId(condominioId: string) {
        this.loading = true;
        console.log("aqui", condominioId)
        try {
            const { data } = await CameraAPI.listarCameraPerfilIdentificacaoPorCondominio(condominioId);
            runInAction(() => {
                if(data)
                    this.camerasPerfisIdentificacao = data;
                else
                    this.camerasPerfisIdentificacao = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    async listarCamerasPerfilPorCamerasPerfisIdentificacao(camerasPerfisIdentificacaoId: string) {
        this.loading = true;
        try {
            const { data } = await CameraAPI.listarCameraPerfilPorCameraPerfilIdentificacaoId(camerasPerfisIdentificacaoId);
            runInAction(() => {
                if(data)
                    this.camerasPerfil = data;
                else
                    this.camerasPerfil = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(cameraId: string | null) {
        if(cameraId !== null) {
            let selecionado = this.camerasCondominio.find((cam) => cam.id === cameraId);
            if(selecionado) {
                this.selecionado = selecionado;
            }
        } else {
            this.selecionado = null;
        }
    }

    definirPerfilIdentificacaoSelecionado(perfilId: string | null) {
        if(perfilId !== null) {
            let selecionado = this.camerasPerfisIdentificacao.find((p) => p.id === perfilId);
            if(selecionado) {
                this.perfilSelecionado = selecionado;
            }
        } else {
            this.perfilSelecionado = null;
        }
    }

    definirCameraPerfilSelecionado(cameraPerfilId: string | null) {
        if(cameraPerfilId !== null) {
            let selecionado = this.camerasPerfil.find((p) => p.id === cameraPerfilId);
            if(selecionado) {
                this.camerasPerfilSelecionado = selecionado;
            }
        } else {
            this.perfilSelecionado = null;
        }
    }

}

export default CameraStore;