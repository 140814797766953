import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { Button, Input, SelectPicker, Modal } from 'rsuite';
import SipAPI from '../../../api/SipAPI';
import { useSip } from '../../../hooks/Sip.hook';
import { DispositivoSip, DispositivoSipRequest } from '../../../interfaces/Sip.interface';

interface PropsModal {
    show: boolean;
    hide: () => void;
    sipSelecionado?: DispositivoSip | null;
}

interface Any
{
    any: any
}
interface Modelo
{
    modelos: Any[];
}

const AtualizarSip: FC<PropsModal> = ({ show, hide, sipSelecionado }) => {
    
    const { atualizar, atualizando } = useSip();
    
    const [form, setForm] = useState<DispositivoSipRequest>({});
    const [modelos, setModelos] = useState<any[]>();

    useEffect(() => 
    {
        if(show)
        {
            (async () => {
                const { data } = await SipAPI.listarModelos();
                setModelos(data);
            })();
        }
    }, [show]);
    useEffect(() => {
        if(sipSelecionado) {
            setForm(sipSelecionado)
        }
    }, [sipSelecionado]);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as DispositivoSip, hide)
    }


    const FormSip = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="192.168.xxx.xxx" value={form.ip || ''} onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="0000" value={form.porta || ''} onChange={(e) => setForm({...form, porta: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Usuário</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Acesso do usuário" value={form.usuario || ''} onChange={(e) => setForm({...form, usuario: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Senha</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="********" value={form.senha || ''} onChange={(e) => setForm({...form, senha: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Fabricante</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Fabricante do dispostivo" value={form.fabricante || ''} onChange={(e) => setForm({...form, fabricante: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <SelectPicker
                            block
                            searchable
                            placeholder={'Selecione o fabricante/modelo do equipamento'}
                            data={modelos ? modelos?.map((modelo) => ({ label: modelo, value: modelo?.split(' - ')[1] })) : []}
                            value={form.modelo || ''} 
                            onChange={(e) => setForm({...form, modelo: e})} 
                        />
                        {/* <select
                            value={form.modelo || ''} 
                            onChange={(e) => setForm({...form, modelo: e.target.value})} 
                            className='form-control'
                            required
                        >
                            <option value={''}>Selecione</option>
                            {
                                modelos?.map((modelo, i) => (
                                    <option key={i} value={modelo.split(' - ')[1]}>{modelo}</option>
                                ))
                            }
                        </select> */}
                    </Form.Group>
                </div>

                <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Tipo</Form.Label>
                        <SelectPicker
                            block
                            searchable={false}
                            placeholder={'Selecione o tipo do equipamento'}
                            data={
                                [
                                    {
                                        label: 'FXS',
                                        value: 'FXS'
                                    },
                                    {
                                        label: 'FXO',
                                        value: 'FXO',
                                    }
                                ]
                            }
                            value={form.tipo || ''}
                            onChange={(e) => setForm({ ...form, tipo: e as any})}
                        />
                        {/* <select
                            className='form-control shadow-none'
                            onChange={(e) => setForm({ ...form, tipo: e.target.value })}
                            value={form.tipo || ''}
                        >
                            <option>Selecio o tipo do dispostivo</option>
                            <option value={'FXS'}>FXS</option>
                            <option value={'FXO'}>FXO</option>
                        </select> */}
                    </Form.Group>


                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance='primary' disabled={atualizando} type="submit">
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button style={{marginLeft: 10}} disabled={atualizando} type="button" onClick={hide}>
                        Fechar
                    </Button>
                </div>
                {atualizando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
                </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size={'lg'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>atualização do SIP</p>
                    <p className='modal-styled-subtitle'>
                        Atualização do equipamento SIP
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormSip()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarSip);