import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled(PerfectScroll)`
  background: ${(props) => props.theme.colors.bgBox};
  margin: 20px;
  padding: 10px;
  height: 50vh;
  border-radius: 8px;
  .nome
  {
    margin: 0;
    align-self: center;

  }
`
