import { Permissoes } from "../interfaces/Permissoes.interface";
import { IRelatorioMorador, IRelatorioVeiculo, IRelatorioVisitante } from "../interfaces/Relatorio.interface";
import api from "../services/api/api";

class RelatorioAPI {
    obterMoradorPorCondominioId = async (condominioId: string) => await api.get<IRelatorioMorador[]>(`/Relatorio/moradores/condominio/${condominioId}`);
    obterVeiculoPorCondominioId = async (condominioId: string) => await api.get<IRelatorioVeiculo[]>(`/Relatorio/veiculos/condominio/${condominioId}`);
    obterVisitantesPorCondominioId = async (condominioId: string, dataInicio: string, dataFim: string) => await api.get<IRelatorioVisitante[]>(`/Relatorio/visitantes/condominio/${condominioId}?dataInicio=${dataInicio}&dataFinal=${dataFim}`);
}

export default new RelatorioAPI();