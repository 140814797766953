import api from "../services/api/api";
import { Maquina } from "../interfaces/Ramal.interface";


class MaquinaAPI
{

    listar = async () => await api.get<Maquina[]>(`/Maquina`);
    salvar = async (maquina: Maquina) => await api.post(`/Maquina`, maquina);
    atualizar = async (maquina: Maquina) => await api.put(`/Maquina`, maquina);
    deletar = async (id: string) => await api.delete(`/Maquina/${id}`);

}

export default new MaquinaAPI();