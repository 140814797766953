import styled from 'styled-components';
import PerfctScrollBar from 'react-perfect-scrollbar';

export const Container = styled.div`
    padding: 10px;
`

export const Header = styled.div`
padding-top: 10px;
padding-left: 30px;
padding-right: 30px;
.title
{
  font-size: 12pt;
  margin: 0;
  font-weight: 550;
}
.subtitle
{
  font-size: 9pt;
  margin: 0;
}

.content
{
  padding: 20px;
  margin-top: 10px;
  width: 250px;
}
.content-icon
{
  align-self: center;
  font-size: 25pt;
  margin-right: 20px;
}
.content-title
{
  font-size: 10pt;
  margin: 0;
  font-weight: 550;
}
.content-subtitle
{
  font-size: 9pt;
  margin: 0;
}

.info-title
{
  font-size: 8pt;
  width: 80%;
}
.info-title-filtro
{
  font-size: 8pt;
  width: 50%;
}
`

export const ListNotas = styled(PerfctScrollBar)`

    height: 80vh;
    margin-top: 10px;

    .descricao
    {
        margin: 5px;
        background: ${(props) => props.theme.colors.bgBox};
        padding: 10px;
        border-radius: 5px;
        // width: 20%;
        // margin: 10px;
        // transition: 0.2s;
        box-shadow: 1px 3px 5px ${(props) => props.theme.colors.bgColor};
    }
    .descricao-content
    {
        font-size: 10pt;
        margin: 0;
    }
    .datahora
    {
        font-size: 9pt;
        font-weight: 550;
        margin: 0;
    }
`