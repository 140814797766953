import { Permissoes } from "../interfaces/Permissoes.interface";
import api from "../services/api/api";

class PermissoesAPI {
    selecionarPorId = async (moradorId: string) => await api.get<Permissoes>(`/Permissoes/permissoes/${moradorId}`);
    cadastrar = async (permissoes: Permissoes) => await api.post(`/Permissoes`, permissoes);
    atualizar = async (permissoes: Permissoes) => await api.put(`/Permissoes/editar`, permissoes); 
    deletar = async (permissaoId: string) => await api.delete(`/Permissoes/${permissaoId}`); 
}

export default new PermissoesAPI();