import { observer } from 'mobx-react-lite'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { MdOutlinePassword } from 'react-icons/md'
import { useUsuario } from '../../hooks/usuario.hook'
import { Usuario, UsuarioRequest } from '../../interfaces/Usuario.interface'
import { StoreContext } from '../../stores'
import { Container, ContainerModal, InfoUser } from './styles/Usuario.style'

const UsuarioListagem = () => {

  const { authStore } = useContext(StoreContext);
  const { atualizar, atualizando } = useUsuario();
  const [usuario, setUsuario] = useState<UsuarioRequest>({});
  const [openModal, setOpenModal] = useState(false);

  const toggleOpenModal = () => setOpenModal(!openModal);
  
  useEffect(() => {
    if(authStore.usuario)
    {
      setUsuario(authStore.usuario);
    }
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }

  return (
    <Container>
      <p className='title'>Seu perfíl</p>
      <InfoUser>
        <div>
            <p className='title-input'>Nome</p>
            <input className='form-control input shadow-none' placeholder='Nome do usuário' value={usuario.nome || ''} onChange={(e) => setUsuario({ ...usuario, nome: e.target.value })}  />
            <p className='obs'>O nome do usuário é a sua identificação para acessar o painel adminstrativo e o sistema outkey</p>
        </div>

        <div>
            <p className='title-input'>Cpf</p>
            <input className='form-control input shadow-none' placeholder='000.000.000-00' value={usuario.cpf || ''} onChange={(e) => setUsuario({ ...usuario, cpf: e.target.value })}  />
            <p className='obs'>Você pode usar o seu cpf para acessar o painel administrativo e o sistema outkey</p>
        </div>

        <div>
            <p className='title-input'>Email</p>
            <input className='form-control input shadow-none' placeholder='seuemail@gmail.com' value={usuario.email || ''} onChange={(e) => setUsuario({ ...usuario, email: e.target.value })}  />
            <p className='obs'>Você pode usar o seu email para acessar o painel administrativo e o sistema outkey</p>
        </div>

        <p className='info'>Para atualizar senha preencha a nova senha e o campo confirmar senha</p>

        <div>
            <p className='title-input'>Nova senha</p>
            <input type={'password'} className='form-control input shadow-none' placeholder='********' value={usuario.novaSenha || ''} onChange={(e) => setUsuario({ ...usuario, novaSenha: e.target.value })}  />
        </div>

        <div>
            <p className='title-input'>Confirmar senha</p>
            <input type={'password'} className='form-control input shadow-none' placeholder='********' value={usuario.novaSenhaConfirma || ''} onChange={(e) => setUsuario({ ...usuario, novaSenhaConfirma: e.target.value })}  />
        </div>

        <button disabled={atualizando} className='btn btn-outline-success shadow-none mt-2' onClick={() => toggleOpenModal()}>{atualizando ? 'Atualizando...' : 'Salvar'}</button>
      </InfoUser>


      <Modal show={openModal} onHide={atualizando ? () => {} : () => toggleOpenModal()} centered>
        <Modal.Header closeButton={atualizando ? false : true}>
          <Modal.Title>Confirmar senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainerModal onSubmit={handleSubmit}> 
            <div className='d-flex justify-content-center'>
              <MdOutlinePassword className='icon' />
            </div>
            <p className='title'>Confirme sua senha</p>
            <div className='d-flex justify-content-center'>
              <p className='subtitle'>Para que as suas alterações seja feita é necessário informar a sua senha.</p>
            </div>
            <div className='d-flex justify-content-center'>
              <input required type={'password'} className='form-control input-pw shadow-none' minLength={6} placeholder='********' value={usuario.senhaAtual || ''} onChange={(e) => setUsuario({ ...usuario, senhaAtual: e.target.value })}  />
            </div>
            <div className='d-flex justify-content-center'>
              <button type='submit' disabled={atualizando} className={'btn btn-outline-success shadow-none mt-2'} onClick={() => atualizar(usuario as Usuario)}>{atualizando ? 'Atualizando...' : 'Salvar'}</button>
            </div>
          </ContainerModal>
        </Modal.Body>
      </Modal>

    </Container>
  )
}

export default observer(UsuarioListagem);
