import { Evento, EventoRelatorio } from '../interfaces/Evento.interface';
import api from '../services/api/api';

class EventoAPI {
  listarUltimosEventosPorCondominioId = async (condominioId: string, dataHoraInicial: string, dataHoraFinal: string) => await api.get<EventoRelatorio[]>(`/evento/relatorioGeral/${condominioId}?dataHoraInicial=${dataHoraInicial}&dataHoraFinal=${dataHoraFinal}`);
  listarUltimosEventosPorUnidadeId = async (unidadeId: string, dataHoraInicial: string, dataHoraFinal: string) => await api.get<Evento[]>(`/evento/unidade/${unidadeId}?dataHoraInicial=${dataHoraInicial}&dataHoraFinal=${dataHoraFinal}`);
  listarUltimosEventosPorCondominioIdEquantidade = (condominioId: string, quantidade: number) => api.get<Evento[]>(`/evento/ultimoscondominio/${condominioId}?quantidade=${quantidade}`);
}
 
export default new EventoAPI();
