import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, TextArea, DatePicker } from "./styles/NotaCadastrar.style";
import { Nota, NotaRequest } from "../../interfaces/Nota.interface";
import { StoreContext } from "../../stores";
import { useNotas } from "../../hooks/Nota.hook";
import ModalConfirmacao from "../utils/ModalConfirmacao";
import { Button, Modal, SelectPicker } from "rsuite";

interface NotaModalProps {
  show: boolean;
  hide: () => void;
}

var procedimentos = [
  {
    label: "Procedimento Eclusa",
    value: 1,
  },
  {
    label: "Procedimento Visitante",
    value: 2,
  },
  {
    label: "Procedimento Morador",
    value: 3,
  },
  {
    label: "Procedimento Entrega",
    value: 4,
  },
  {
    label: "Procedimento Prestador",
    value: 5,
  },
];

const NotaModal: React.FC<NotaModalProps> = ({ show, hide }) => {
  const { notaStore } = useContext(StoreContext);
  const [form, setForm] = useState<NotaRequest>({});
  const { atualizar, atualizando, deletar, deletando } = useNotas();
  const [modalDeletar, setModalDeletar] = useState(false);

  const toggleModalDeletar = () => setModalDeletar(!modalDeletar);

  useEffect(() => {
    if (notaStore.selecionado && show) {
      setForm(notaStore.selecionado);
    }
  }, [notaStore.selecionado, show]);

  const SalvarNota = () => {
    atualizar(form as Nota, hide);
  };

  const deletarNota = () => {
    if (notaStore.selecionado) {
      deletar(notaStore.selecionado, hide);
    }
  };

  return (
    <Modal open={show} onClose={hide}>
      <Modal.Body>
        <Box>
          <p style={{ fontWeight: "550" }}>Tipo de procedimento</p>
          <SelectPicker
            value={form.tipo}
            placeholder={"Selecione"}
            data={procedimentos}
            block
            onChange={(e) => {
              if (e) setForm({ ...form, tipo: e });
            }}
            onClean={() => {
              setForm({ ...form, tipo: 0 });
            }}
          />

          <p style={{ fontWeight: "550" }} className="title">
            Descrição
          </p>
          <TextArea
            value={form.descricao}
            placeholder="Escreva uma anotação aqui..."
            onChange={(e) => setForm({ ...form, descricao: e.target.value })}
            rows={9}
            maxLength={3000}
          />

          <div className="d-flex justify-content-between">
            <Button
              appearance="primary"
              disabled={deletando}
              color={"red"}
              onClick={toggleModalDeletar}
            >
              {deletando ? "Deletando..." : "Deletar"}
            </Button>
            <div className="d-flex">
              <Button
                appearance="primary"
                color={"violet"}
                style={{ marginRight: 10 }}
                onClick={SalvarNota}
                disabled={
                  form.descricao === "" || atualizando
                    ? true
                    : false || form.tipo === 0
                    ? true
                    : false || deletando
                }
              >
                {atualizando ? "Salvando..." : "Salvar"}
              </Button>
              <Button onClick={hide}>Fechar</Button>
            </div>
          </div>
        </Box>
        <p style={{ margin: 0 }}>{form.descricao?.length}</p>
        {form.descricao && form.descricao?.length >= 3000 ? (
          <p style={{ margin: 0 }}>Máximo de caracteres: 3000</p>
        ) : (
          ""
        )}
      </Modal.Body>
      <ModalConfirmacao
        show={modalDeletar}
        hide={toggleModalDeletar}
        action={deletarNota}
        content={"Você tem certeza que deseja deletar essa nota?"}
        loading={deletando}
      />
    </Modal>
  );
};

export default observer(NotaModal);
