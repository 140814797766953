import React, { FC, useContext, useEffect, useState } from 'react';
import { Drawer } from '@mui/material';
import { StoreContext } from '../../../../../stores';
import { observer } from 'mobx-react-lite';
import { useDispositivo } from '../../../../../hooks/Dispositivo.hook';
import { BiometriaFacial } from '../../../../../interfaces/Dispositivo.interface';
import { MdErrorOutline, MdFaceUnlock, MdFingerprint } from 'react-icons/md';
import { BsBookmarkCheck, BsCardChecklist, BsXCircle } from 'react-icons/bs';
import { Content, Notificacao, Sidebar, Title, TitleRelatorio, TitleResponse } from '../../../biometria/styles/ModalBiometria.stye';
import { ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { Icon } from '../../../../utils/RelatorioStyle';
import { Button, Modal, Uploader } from 'rsuite';
import NotFound from '../../../../utils/NotFound';
import Resizer from "react-image-file-resizer";
import { toast } from 'react-toastify';

interface HikvisionProps {
    setRoutes: React.Dispatch<React.SetStateAction<string>>
    setCadastrar: React.Dispatch<React.SetStateAction<boolean>>
}

interface IArquivo
{
    name: boolean;
    url: Blob;
}


const CadastrarFacial: FC<HikvisionProps> = ({ setCadastrar }) => {

    const { moradorStore, equipamentoStore, dispositivosStore } = useContext(StoreContext);
    const { cadastrarBiometriaFacial, coletando, statusColeta, setStatusColeta, openRelatorio, setOpenRelatorio, enviarFotoGeral,  enviando } = useDispositivo();
    const [openUpload, setOpenUpload] = useState(false);
    const [arquivo, setArquivo] = useState<IArquivo | null>(null);

    const get = async () => {
        try {
            if(equipamentoStore.selecionado && equipamentoStore.selecionado.imgUrl)
            await axios.get(equipamentoStore.selecionado.imgUrl);
        } catch {

        }
    }

    useEffect(() => {
        get();
    }, []);

    const clear = () => {
        // acionamentoStore.definirPerfilSelecionado(null);
    }

    const cadastrar = () => {
        let informacoesColetada: BiometriaFacial = {
            moradorId: moradorStore.selecionado?.id as string,
            equipamentoInicialId: equipamentoStore.selecionado?.equipamentoId as string,
          }
        cadastrarBiometriaFacial(informacoesColetada, clear);
    }

    const redimensionarImagem = (file: Blob): Promise<string> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                400,
                400,
                "JPEG",
                100,
                0,
                (uri) => resolve(uri as string),
                "base64",
            );
        });
    };

    const enviarFotoMorador = async () => {
        if (arquivo && equipamentoStore.selecionado?.tipoNome) {
            try {
                const fileType = arquivo.url.type;
                if (fileType !== "image/jpeg" && fileType !== "image/png") {
                    toast.error("Formato de arquivo inválido, utilize jpeg ou png");
                }
                const resizedImage = await redimensionarImagem(arquivo.url);
                const base64 = resizedImage?.match(/^data:image\/(?:jpeg|png);base64,([A-Za-z0-9+/=]+)$/)?.[1] || "";
                enviarFotoGeral({
                    equipamentoId: equipamentoStore.selecionado?.equipamentoId,
                    moradorId: moradorStore.selecionado?.id || "",
                    imageBase64Byte: base64,
                });
                if(!base64) {
                    toast.error("Envie uma imagem Válida")
                }
            } catch (error) {
                console.error("Erro ao redimensionar a imagem:", error);
            }
        }
    };

    
    if(statusColeta === 'success')
    {
        return(
            <div className='mt-5 ' style={{padding: 40, background: 'rgba(0,0,0,0.0400)', borderRadius: 4, height: '100%', margin: 50}}>
                <p style={{fontSize: 16, fontWeight: 600, textAlign: 'center'}}>Relatório do cadastrado ao lado</p>
                    <div className='d-flex justify-content-center'>
                        <MdFingerprint size={22} color={'#228B22'}/> 
                        <BsCardChecklist size={22} color={'#228B22'} className='ml-2'/>
                    </div>
                <div>
                </div>
                <div className='d-flex justify-content-center m-3'>
                    <button type={'button'} className='btn btn-danger' onClick={() => setCadastrar(false)} style={{ borderRadius: 60 }}>Voltar</button>
                </div>

                <Drawer
                    anchor={'right'}
                    open={openRelatorio}
                    onClose={() => setOpenRelatorio(false)}
                >
                    <Sidebar>
                        <Title style={{ background: 'transparent' }} className={'d-flex justify-content-between'}>
                            <span>RELATÓRIO DE CADASTRO</span>
                        </Title>

                        <div className='d-flex justify-content-center'>
                            <iframe scrolling={'no'} src={moradorStore.selecionado?.urlImg} className={'img-equipamento'} style={{borderRadius: 12, margin: 15, height: 400}} />
                        </div>
                        
                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com sucesso</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.acertos.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media ml-4">
                                    <Icon><BsBookmarkCheck /></Icon>
                                    <div className="media-body pl-2">
                                        {item.local}
                                        <div className="d-flex align-items-center align-content-center justify-content-between">
                                        <Notificacao>{item.notificacao}</Notificacao>
                                        </div>
                                    </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>

                        <TitleRelatorio>
                            <span>Relatório dos dispositivos com erros</span>
                        </TitleRelatorio>
                        <Content>
                            <ListGroup variant="flush">
                                {dispositivosStore?.relatorio?.erros.map((item, i) => (
                                <ListGroup.Item key={i} className="pl-0">
                                    <div className="media">
                                    <Icon><MdErrorOutline/></Icon>
                                    <div className="media-body pl-2">
                                        {item.local}
                                        <div className="d-flex align-items-center align-content-center justify-content-between">
                                        <Notificacao>Erro ao coletar</Notificacao>
                                        </div>
                                    </div>
                                    </div>
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Content>
                        <div className='d-flex justify-content-center m-3'>
                            <button type={'button'} className='btn btn-success' onClick={() => setOpenRelatorio(false)} style={{ borderRadius: 60 }}>Fechar</button>
                        </div>
                    </Sidebar>
                </Drawer>
            </div>
        )
    }

    if(statusColeta === 'error')
    {
        return(
            <div className='mt-5 animation' style={{padding: 40, background: 'rgba(0,0,0,0.0400)', borderRadius: 4, height: '100%', margin: 50}}>
                <p style={{fontSize: 16, fontWeight: 600, textAlign: 'center'}}>Erro ao cadastrar biometria!</p>
                <div className='d-flex justify-content-center'>
                <MdFingerprint size={22} color={'#FF0000'}/>
                <BsXCircle size={22} color={'#FF0000'} className='ml-2'/>
                </div>
                <div className='d-flex justify-content-between m-3'>
                <button type={'button'} className='btn btn-success' onClick={() => setStatusColeta('')} style={{ borderRadius: 60 }}>Tentar novamente</button>
                    <button type={'button'} className='btn btn-danger' onClick={() => setCadastrar(false)} style={{ borderRadius: 60 }}>Voltar</button>
                </div>
            </div>
        )
    }

    return(
        <>
        <div className='mt-3'>
            <p style={{fontSize: 17, fontWeight: 550, margin: 0}}>Cadastrar biometria facial no equipamento <b>{equipamentoStore.selecionado?.nome}</b></p>
            <p style={{width: '50%', fontSize: 13, margin: 0, marginTop: 5}}>
                Clique no botão abaixo para mandar um comando para realizar a captura da facial do morador ou enviar uma foto do morador para o equipamento
                <MdFaceUnlock size={23} style={{marginLeft: 10}} />
            </p>
            <div className='d-flex justify-content-between mt-4'>
                   <div>
                    {
                            equipamentoStore.selecionado?.tipoNome === "IntelbrasFacial" || "ControlIdFacial"
                            ?
                            <Button type={'button'} appearance={'primary'} color={'orange'} onClick={() => setOpenUpload(true)} style={{marginRight: 10}}>Upload foto do morador</Button>
                            :
                            ''
                        }
                   </div>
                    <div className='d-flex'>
                        <Button type={'submit'} appearance={'primary'} disabled={coletando} onClick={() => cadastrar()} style={{marginRight: 10}}>{coletando ? 'Coletando biometria...' : 'Coletar biometria'}</Button>
                        <Button type={'button'} disabled={coletando} onClick={() => setCadastrar(false)} style={{marginRight: 10}}>Voltar</Button>
                    </div>
            </div>
        </div>


        <Modal open={openUpload} onClose={() => setOpenUpload(false)} style={{height: 750}} size={'lg'}>
            <Modal.Header>
                <div>
                    <p className='modal-styled-title'>Importar imagem do morador</p>
                    <p className='modal-styled-subtitle'>
                        Importe uma foto do morador para enviar para o equipamento
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
            <Uploader autoUpload={false} action="/" draggable accept='image/jpeg, image/png' onChange={(files) => {
                    if (files.length > 0) {
                        setArquivo({
                            name: true,
                            url: files[0].blobFile as Blob
                    });
                    } else {
                        setArquivo(null);
                    }
                }}>
                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>Clique ou deslize a imagem do morador aqui para realizar o upload</span>
                    </div>
                </Uploader>
                <Drawer
                    anchor={'right'}
                    open={openRelatorio}
                    onClose={() => setOpenRelatorio(false)}
                >
                    <Sidebar>
                        <TitleResponse style={{ background: 'transparent' }} className={'d-flex justify-content-between'}>
                            <span>RELATÓRIO DE CADASTRO</span>
                        </TitleResponse>
                        <TitleResponse>
                            <span>Relatório dos dispositivos com sucesso</span>
                        </TitleResponse>
                        <Content>
                            <ListGroup variant="flush">
                                {
                                    dispositivosStore.relatorio?.acertos.length === 0
                                    ?
                                    <NotFound message={'Nenhum relatório de acertos encontrado'} />
                                    :
                                    <>
                                        {dispositivosStore?.relatorio?.acertos.map((item, i) => (
                                        <ListGroup.Item key={i} className="pl-0">
                                            <div className="media d-flex">
                                            <BsBookmarkCheck style={{alignSelf: 'center', fontSize: 21, marginRight: 5}} />
                                            <div className="media-body pl-2">
                                                {item.local}
                                                <div className="d-flex align-items-center align-content-center justify-content-between">
                                                <Notificacao>{item.notificacao}</Notificacao>
                                                </div>
                                            </div>
                                            </div>
                                        </ListGroup.Item>
                                        ))}
                                    </>
                                }
                            </ListGroup>
                        </Content>

                        <TitleResponse>
                            <span>Relatório dos dispositivos com erros</span>
                        </TitleResponse>
                        <Content>
                            <ListGroup variant="flush">
                                {
                                    dispositivosStore?.relatorio?.erros.length === 0
                                    ?
                                    <NotFound message={'Nenhum relatório de erros encontrado'} />
                                    :
                                    <>
                                        {dispositivosStore?.relatorio?.erros.map((item, i) => (
                                        <ListGroup.Item key={i} className="pl-0">
                                            <div className="media d-flex">
                                            <MdErrorOutline style={{alignSelf: 'center', fontSize: 21, marginRight: 5}} />
                                            <div className="media-body pl-2">
                                                {item.local}
                                                <div className="d-flex align-items-center align-content-center justify-content-between">
                                                <Notificacao>Erro ao coletar</Notificacao>
                                                <Notificacao>{item.notificacao}</Notificacao>
                                                </div>
                                            </div>
                                            </div>
                                        </ListGroup.Item>
                                        ))}
                                    </>
                                }
                            </ListGroup>
                        </Content>
                        <div className='d-flex justify-content-center m-3'>
                            <button type={'button'} className='btn btn-success' onClick={() => setOpenRelatorio(false)} style={{ borderRadius: 60 }}>Fechar</button>
                        </div>
                    </Sidebar>
                </Drawer>
            </Modal.Body>

            <Modal.Footer className='d-flex justify-content-end'>
                <div>
                    <Button appearance='primary' disabled={enviando || !arquivo} onClick={enviarFotoMorador}>
                        {
                            enviando ? 'Enviando...' : 'Enviar foto'
                        }
                    </Button>
                    <Button onClick={() => {
                        setOpenUpload(false);
                        setCadastrar(false);
                    }}>Fechar</Button>
                </div>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default observer(CadastrarFacial);