import { observer } from 'mobx-react-lite'
import React, { useState, useEffect, useContext, FC } from 'react'
import { Table } from 'react-bootstrap'
import { Button, Dropdown, IconButton, Input, InputPicker, Loader, Nav, SelectPicker, Toggle } from 'rsuite'
import { StoreContext } from '../../../stores'
import NotFound from '../../utils/NotFound'
import ExportIcon from '@rsuite/icons/Export';
import useGuarita from '../../../hooks/Guarita.hook'
import ProjectIcon from '@rsuite/icons/Project';
import { MdOutlineRouter } from 'react-icons/md'
import { ContainerLista, HeaderItem } from '../styles/Guarita.style'
import { VscLayersActive } from 'react-icons/vsc'
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PlusIcon from '@rsuite/icons/Plus';
import CadastrarGuarita from '../CadastrarGuarita'
import AtualizarGuarita from '../AtualizarGuarita'
import CadastrarModulo from '../CadastrarModulo'
import AtualizarModulo from '../AtualizarModulo'
import ModalConfirmacao from '../../utils/ModalConfirmacao'
import useModulo from '../../../hooks/Modulo.hook'
import SkeletonList from '../../utils/SkeletonList'
import { BravasInfoacessoCadastrado, BravasInfoacessoCadastradoequest } from '../../../interfaces/Bravas.interface'
import useBravas from '../../../hooks/Bravas.hook'
import CadastrarBravas from './CadastrarBravas'
import AtualizarBravas from './AtualizarBravas'

interface Props
{
  setIndex: React.Dispatch<React.SetStateAction<number>>
}

const BravasListagem: FC<Props> = ({ setIndex }) => {

  const { bravasStore, condominioStore, themeStore } = useContext(StoreContext);
  const _useBravasHook = useBravas();

  const [modalCadastrarBravas, setModalCadastrarBravas] = useState(false);
  const [modalEditarBravas, setModalEditarBravas] = useState(false);
  const [settingAcesso, setSettingAcesso] = useState<BravasInfoacessoCadastradoequest>({ })
  const [routeBRAVAS, setRouteBRAVAS] = useState('listabravas');
  const [acessoPN, setAcessoPN] = useState('');
  const [modalDeletarBravas, setModalDeletarBravas] = useState(false);

  const toggleCadastrarBravas = () => setModalCadastrarBravas(!modalCadastrarBravas);
  const toggleAtualizarBravas = () => setModalEditarBravas(!modalEditarBravas);
  const toggleModalDeletarBravas = () => setModalDeletarBravas(!modalDeletarBravas);


  
  useEffect(() =>
  {
      bravasStore.selecionado = null;
      bravasStore.infoAcesso = null;
      bravasStore.grupos = null;
  }, [condominioStore.selecionado]);

  useEffect(() =>
  {
      if(bravasStore.infoAcesso)
          setSettingAcesso(bravasStore.infoAcesso)
  }, [bravasStore.infoAcesso]);

//   useEffect(() =>
//   {
//       if(bravasStore.selecionado)
//       {
//           bravasStore.listarAcessosCadastrado(bravasStore.selecionado.id, null);
//           bravasStore.listarGruposCadastrado(bravasStore.selecionado.id);
//       }
//   }, [bravasStore.selecionado]);


  const deletarMBravasSelecionado = () =>
  {
      if(bravasStore.selecionado) {
          _useBravasHook.deletar(bravasStore.selecionado);
      }
  }


  const renderAcessos = () =>
  {

      const renderLista = () =>
      {
          if(bravasStore.loading)
              return <SkeletonList/>

          const renderInfoAcesso = () =>
          {

                if(bravasStore.loadingAcesso)
                    return <SkeletonList/>

                if(bravasStore?.acessos?.config === null)
                {
                    return <NotFound message='Erro ao coletar informação do equipamento' />
                }

                return(
                    <div className='mt-2 p-3'>
                        <div className='d-flex'>
                            <p style={{width: '16.1vw'}}>Tipo de acesso: </p>
                            <p style={{margin: 0, fontWeight: 550, fontSize: 19}}>{settingAcesso.config?.type}</p>
                        </div>
                        <div className='d-flex mt-3'>
                            <p style={{width: '20vw'}}>Nome: </p>
                            <Input placeholder='Selecione' value={settingAcesso.config?.name || ''} onChange={(e) => 
                            {
                                setSettingAcesso({ ...settingAcesso, config: { ...settingAcesso.config, name : e } as any  });
                            }} />
                        </div>
                        <div className='d-flex mt-3'>
                            <p style={{width: '16vw'}}>Posição do relé: </p>
                            <SelectPicker
                                placeholder={'Selecione o relê'}
                                cleanable={false}
                                searchable={false}
                                value={settingAcesso.config?.target?.position || ''}
                                data={[{label: 'Relê 1', value: '1'}, {label: 'Relê 2', value: '2'}, {label: 'Relê 3', value: '3'}, {label: 'Relê 4', value: '4'}]}
                                onChange={(e) => setSettingAcesso({ ...settingAcesso, config: { ...settingAcesso.config, target: { ...settingAcesso.config?.target, position: e as any } as any } as any } as any )} 
                            />
                        </div>

                        <div className='d-flex mt-3'>
                            <p style={{width: '16vw'}}>Habilitado: </p>
                            <Toggle checked={settingAcesso.config?.enabled} onChange={(e) => setSettingAcesso({ ...settingAcesso, config: { ...settingAcesso.config, enabled : e } as any  })} />
                        </div>

                        <div className='d-flex mt-3'>
                            <p style={{width: '16vw'}}>Pulso: </p>
                            <SelectPicker
                                placeholder={'Selecione o modo'}
                                cleanable={false}
                                searchable={false}
                                value={settingAcesso.config?.mode}
                                data={[{label: 'Toggle', value: 'toggle'}, {label: 'Pulso', value: 'pulse'}]}
                                onChange={(e) => setSettingAcesso({ ...settingAcesso, config: { ...settingAcesso.config, mode : e } as any  })} 
                            />
                        </div>

                        <div className='d-flex mt-3'>
                            <p style={{width: '20vw'}}>Tempo (ms): </p>
                            <Input placeholder='Selecione' value={settingAcesso.config?.pulse_time} onChange={(e) => 
                            {
                                setSettingAcesso({ ...settingAcesso, config: { ...settingAcesso.config, pulse_time : e } as any  });
                            }} />
                        </div>

                        <div className='d-flex mt-3'>
                            <p style={{width: '20vw'}}>Quantidade: </p>
                            <Input placeholder='Selecione' value={settingAcesso.config?.pulse_count} onChange={(e) => 
                            {
                                setSettingAcesso({ ...settingAcesso, config: { ...settingAcesso.config, pulse_count : e } as any  });
                            }} />
                        </div>

                        <Button appearance='primary' disabled={_useBravasHook.salvando || !settingAcesso.config?.target?.position} className={'mt-2'} color={'violet'} onClick={() => _useBravasHook.atualizarAcesso(settingAcesso as BravasInfoacessoCadastrado)}>
                            {
                                _useBravasHook.salvando ? <Loader content={'Salvando... Aguarde'} /> : 'Salvar alterações'
                            }
                        </Button>
                    </div>
                )
          }

          return(
              <div className='mt-2'>
                  <Nav appearance="tabs" activeKey={acessoPN} onSelect={(e) =>
                  {
                      setAcessoPN(e);
                      if(bravasStore.selecionado)
                      {
                          // bravasStore.obterInformaçãoAcesso(bravasStore.selecionado.id, e)
                          if(bravasStore.acessos && bravasStore.selecionado) bravasStore.obterInformaçãoAcesso(bravasStore.selecionado.id, e)
                      }
                  }} style={{ marginBottom: 10 }}>
                      {
                          bravasStore.acessos?.config?.accesses?.map((a, i) =>
                          (
                              <Nav.Item key={i} eventKey={a}>{a}</Nav.Item>
                          ))
                      }
                  </Nav>
                  {renderInfoAcesso()}
              </div>
          )
      }

      return(
          <div>
              <div className='mt-2'>
                  <p>Selecione o bravas para listar os acessos cadastrado</p>
                  <SelectPicker
                      placeholder={'Selecione'}
                      data={bravasStore.bravas.map((b) => ({ label: b.nome, value: b.id }))}
                      value={bravasStore.selecionado?.id}
                      style={{width: 300}}
                      onChange={(e) => {
                        if(e) bravasStore.definirSelecionado(e);
                          if(e) bravasStore.listarAcessosCadastrado(e, setAcessoPN);
                      }}
                      cleanable={false}
                  />
              </div>

              {bravasStore.selecionado === null ? <div/> : renderLista()}
          </div>
      )
  }

  const renderListagemBravas = () =>
  {

      if(bravasStore.loading)
          return <SkeletonList/>

      if(bravasStore.bravas.length === 0)
          return <NotFound message='Nenhum bravas cadastrado' />

      return(
            <div className='box-table'>
              <div className='mt-2'>
                    <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                      <thead>
                          <tr>
                              <th scope="col">Nome guarita</th>
                              <th scope="col">Ip</th>
                              <th scope="col">Porta API</th>
                              <th scope="col">Modelo</th>
                              <th scope="col">webadmin</th>
                              <th scope="col">Ação</th>
                          </tr>
                      </thead>
                      <tbody>
                          {
                              bravasStore.bravas.map((guarita, i) => (
                              <tr key={i} onClick={() => bravasStore.definirSelecionado(guarita.id)}> 
                                  <td>{guarita.nome}</td>
                                  <td>{guarita.ip}</td>
                                  <td>{guarita.porta}</td>
                                  <td>{guarita.modelo}</td>
                                  <td>
                                      <IconButton appearance="primary" color="violet" size={'xs'} icon={<ExportIcon />} href={`https://${guarita.ip}:8887`} target='blank'>
                                          Acessar
                                      </IconButton>
                                  </td>
                                  <td>
                                  <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                      <Dropdown.Item onClick={() => {
                                          toggleAtualizarBravas();
                                      }}>Atualizar</Dropdown.Item>
                                      <Dropdown.Item onClick={() => {
                                          toggleModalDeletarBravas();
                                      }}>Deletar</Dropdown.Item>
                                  </Dropdown>
                                  </td>
                              </tr>
                              ))
                          }
                      </tbody>
                  </Table>
              </div>
          </div>
      )
  }

  const renderGrupos = () =>
  {

      const renderLista = () =>
      {

            if(bravasStore.loading)
                return <SkeletonList/>

            if(bravasStore.grupos === null)
                return <div/>
                
            if(bravasStore.grupos?.config === null)
            {
                return <NotFound message='Erro ao coletar informação do equipamento' />
            }
            return(
                <div>
                    <p style={{fontSize: 18, margin: 5}}>Lista dos grupos</p>
                    <div className='mt-2'>
                        {
                            bravasStore.grupos?.config?.groups?.map((g) => 
                            (
                                <div className='content-grupos'>
                                    <p style={{fontSize: '9pt', width: 250}}>Abaixo está o nome do grupo cadastrado direto no módulo de acesso. Para visualizar as configurações acesse o equipamento</p>
                                    <p style={{fontSize: '14pt'}}><b>Grupo: </b> {g}</p>
                                        
                                    <IconButton appearance="primary" color="violet" size={'xs'} icon={<ExportIcon />} href={`https://${bravasStore.selecionado?.ip}:8887`} target='blank'>
                                        Acessar
                                    </IconButton>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
      }

      return(
          <div>
             <div className='mt-2'>
                  <p>Selecione o bravas para listar os acessos cadastrado</p>
                  <SelectPicker
                      placeholder={'Selecione'}
                      data={bravasStore.bravas.map((b) => ({ label: b.nome, value: b.id }))}
                      style={{width: 300}}
                      onChange={(e) => {
                        if(e) bravasStore.definirSelecionado(e);
                          if(e) bravasStore.listarGruposCadastrado(e);
                      }}
                      cleanable={false}
                  />
              </div>

              {
                  renderLista()
              }
          </div>
      )
  }

  const renderRoutesBravas = () =>
  {
      switch(routeBRAVAS)
      {
          case 'listabravas':
              return renderListagemBravas();
          case 'acessos':
              return renderAcessos();
          case 'grupos':
              return renderGrupos();
      }
      return(
          <div>ERRO</div>
      )
  }

  return(
    <ContainerLista className=''>
        <HeaderItem>
          <div className='d-flex box'>
                <div className='content d-flex'>
                    <VscLayersActive className='content-icon' />
                    <div>
                        <p className='subtitle'>Campo de cadastro e configurações do sistema BRAVAS</p>
                    </div>
                </div>

                <div style={{marginLeft: 30}} className='align-self-center'>
                    <p className='info-title'>Voltar para a lista</p>
                    <IconButton appearance="primary" color="violet" icon={<PagePreviousIcon />} onClick={() => setIndex(0)}>
                        Voltar
                    </IconButton>
                </div>

                <div style={{marginLeft: 20}} className='align-self-center'>
                    <div className='align-self-center'>
                        <p style={{margin: 0, width: 150}}>Menu de configurações do bravas</p>
                        <InputPicker cleanable={false} placeholder={'Aba de navegação'} value={routeBRAVAS} data={[{ label: 'Guaritas cadastrado', value: 'listabravas' }, { label: 'Acessos - Porta Nativa/Receptor RF', value: 'acessos' }, { label: 'Grupos de acessos', value: 'grupos' }]} onChange={(e) => setRouteBRAVAS(e)} style={{ width: 300 }} />
                    </div>
                </div>

                {
                  routeBRAVAS === 'listabravas'
                  ?
                  <div style={{marginLeft: 20}} className='align-self-center'>
                      <p style={{margin: 0}}>Cadastrar nova bravas</p>
                      <div className='align-self-center mt-2'>
                          <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarBravas} style={{marginBottom: 10}}>
                              Cadastrar
                          </IconButton>
                      </div>
                  </div>
                  :
                  <div/>
              }
            </div>

            
        </HeaderItem>

        {renderRoutesBravas()}

        <CadastrarBravas show={modalCadastrarBravas} hide={toggleCadastrarBravas} />
        <AtualizarBravas show={modalEditarBravas} hide={toggleAtualizarBravas} />

        <ModalConfirmacao show={modalDeletarBravas} hide={toggleModalDeletarBravas} action={deletarMBravasSelecionado} content={'Você tem certeza que deseja deletar esse Bravas?'} loading={_useBravasHook.deletando} />
        
    </ContainerLista>
  )
}

export default observer(BravasListagem);