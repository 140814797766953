import React, { FC, useContext, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Timeline } from 'rsuite';
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';
import { useReactToPrint } from "react-to-print";
import { StoreContext } from '../../stores';
import NotFound from './NotFound';

interface Props
{
    show: boolean;
    hide: () => void;
    hideAdd?: () => void;
    tipo: string;
}

const ModalRelatorioTag: FC<Props> = ({ show, hide, hideAdd, tipo }) => {

    const { dispositivosStore, condominioStore, moradorStore, authStore, ramalStore } = useContext(StoreContext);
    let componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef?.current
    });

    const getDateTime = () =>
    {
        var currentdate = new Date(); 
        var datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " - "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
                return datetime;
    }

    const fechar = () =>
    {
        hide();
        dispositivosStore.relatorio = null;
        if(hideAdd) hideAdd();
    }

    const renderSucesso = () =>
    {

        if(dispositivosStore?.relatorio?.acertos.length === 0)
        {
            return <NotFound message='Vazio' />
        }

        return(
            <>
                {
                    dispositivosStore?.relatorio?.acertos.map((item, i) =>
                    (
                        <Timeline align={'left'}>
                            <Timeline.Item style={{marginBottom: 10}} dot={<CheckIcon color={'green'} style={{fontSize: 18}} />}>
                                <p style={{margin: 0, fontSize: 14, fontWeight: 520}}>{item.local}</p>
                                <p style={{margin: 0, fontSize: 11}}>{item.notificacao}</p>
                            </Timeline.Item>
                        </Timeline> 
                    ))
                }
            </>
        )
    }

    const renderErros = () =>
    {

        if(dispositivosStore?.relatorio?.erros.length === 0)
        {
            return <NotFound message='Vazio' />
        }

        return(
            <>
                {
                    dispositivosStore?.relatorio?.erros.map((item, i) =>
                    (
                        <Timeline align={'left'}>
                            <Timeline.Item style={{marginBottom: 10}} dot={<CloseIcon color={'red'} style={{fontSize: 18}} />}>
                                <p style={{margin: 0, fontSize: 14, fontWeight: 520}}>{item.local}</p>
                                <p style={{margin: 0, fontSize: 11}}>{item.notificacao}</p>
                            </Timeline.Item>
                        </Timeline>
                    ))
                }
            </>
        )
    }

    const componentExport = () =>
    {
        return(
            <div ref={componentRef} className={'p-4'}>
                <div className='p-2'>
                    <p style={{fontSize: 24, margin: 0, color: 'rgba(0,0,0,0.7)'}}>Relatório - {tipo}</p>
                    <p style={{fontSize: 15, margin: 0, color: 'rgba(0,0,0,0.7)'}}>Abaixo está o relatório</p>

                    <div className='mt-3'>
                        <p className='m-0' style={{color: 'rgba(0,0,0,0.7)'}}><b>Condomínio:</b> {condominioStore.selecionado?.nome}</p>
                        <p className='m-0' style={{color: 'rgba(0,0,0,0.7)'}}><b>Morador:</b> {moradorStore.selecionado?.nome}</p>
                        <p className='m-0' style={{color: 'rgba(0,0,0,0.7)'}}><b>Usuário:</b> {authStore.usuario?.nome}</p>
                        <p className='m-0' style={{color: 'rgba(0,0,0,0.7)'}}><b>Local:</b> Administrativo</p>
                        <p className='m-0' style={{color: 'rgba(0,0,0,0.7)'}}><b>Data Hora:</b> {getDateTime()}</p>
                    </div>
                </div>
                <div className='d-flex justify-content-between p-2 mt-2'>
                    <div style={{width: '100%'}}>
                        <p style={{fontSize: 19, margin: 0, padding: 5, marginBottom: 10, color: 'green', fontWeight: 550}}> <CheckIcon color={'green'} style={{fontSize: 18}} /> Sucesso</p>
                        {
                            dispositivosStore?.relatorio?.acertos.length === 0
                            ?
                            <p style={{margin: 0, fontSize: 14, color: 'rgba(0,0,0,0.7)'}}>Vazio</p>
                            :
                            dispositivosStore?.relatorio?.acertos.map((item, i) =>
                            (
                                <Timeline align={'left'}>
                                    <Timeline.Item style={{marginBottom: 10}} dot={<CheckIcon color={'green'} style={{fontSize: 18}} />}>
                                        <p style={{margin: 0, fontSize: 19, fontWeight: 520, color: 'rgba(0,0,0,0.7)'}}>{item.local}</p>
                                        <p style={{margin: 0, fontSize: 14, color: 'rgba(0,0,0,0.7)'}}>{item.notificacao}</p>
                                    </Timeline.Item>
                                </Timeline> 
                            ))
                        }
                    </div>
                    <div  style={{width: '100%'}}>
                        <p style={{fontSize: 19, margin: 0, padding: 5, marginBottom: 10, color: 'red', fontWeight: 550}}> <CloseIcon color={'red'} style={{fontSize: 18}} /> Erros</p>
                        {
                            dispositivosStore?.relatorio?.erros.length === 0
                            ?
                            <p style={{margin: 0, fontSize: 14, color: 'rgba(0,0,0,0.7)'}}>Vazio</p>
                            :
                            dispositivosStore?.relatorio?.erros.map((item, i) =>
                            (
                                <Timeline align={'left'}>
                                    <Timeline.Item style={{marginBottom: 10}} dot={<CloseIcon color={'red'} style={{fontSize: 18}} />}>
                                        <p style={{margin: 0, fontSize: 19, fontWeight: 520, color: 'rgba(0,0,0,0.7)'}}>{item.local}</p>
                                        <p style={{margin: 0, fontSize: 14, color: 'rgba(0,0,0,0.7)'}}>{item.notificacao}</p>
                                    </Timeline.Item>
                                </Timeline> 
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Modal open={show} backdropStyle={{background: 'rgba(0,0,0,0.0100)'}}>
            <Modal.Header closeButton={false}>
                <Modal.Title>
                    <p style={{fontSize: 19, margin: 0}}>Relatório - {tipo}</p>
                    <p style={{fontSize: 15, margin: 0}}>Abaixo está o relatório</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex '>
                    <div className='m-2' style={{width: '100%'}}>
                        <p style={{fontSize: 15, margin: 0, padding: 5, marginBottom: 10, color: 'green', fontWeight: 550}}> <CheckIcon color={'green'} style={{fontSize: 18}} /> Sucesso</p>
                        {renderSucesso()}
                    </div>
                    <div className='m-2' style={{width: '100%'}}>
                        <p style={{fontSize: 15, margin: 0, padding: 5, marginBottom: 10, color: 'red', fontWeight: 550}}><CloseIcon color={'red'} style={{fontSize: 18}} /> Erros</p>
                        {renderErros()}
                    </div>
                </div>
            </Modal.Body>
            <div style={{opacity: 0, display: 'none'}}>
                {componentExport()}
            </div>
            <Modal.Footer>
                <Button appearance='primary' color='violet' onClick={handlePrint}> Exportar em PDF </Button>            
                <Button onClick={fechar}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(ModalRelatorioTag);
