import styled from 'styled-components';

export const Box = styled.div`
  color: ${(props) => props.theme.colors.text};
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

export const Icon = styled.div`
  svg {
    width: 40px;
    height: 40px;
  }
`;

export const Content = styled.div`
  padding-left: 10px;
`;

export const Message = styled.div`
  font-weight: 700;
  font-size: 15px;
  line-height: 1.2;
`;
