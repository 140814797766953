import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Header, ListRamais } from './styles/Ramal.style';
import { StoreContext } from '../../stores';
import { BiEdit, BiX } from 'react-icons/bi';
import CadastrarRamal from './CadastrarRamal';
import AtualizarRele from '../rele/AtualizarRele';
import AtualizarRamal from './AtualizarRamal';
import { useRamal } from '../../hooks/Ramal.hook';
import { Backdrop, CircularProgress, MenuItem } from '@mui/material';
import { BsArrowsExpand, BsTelephoneInbound, BsThreeDots } from 'react-icons/bs';
import { Ramal } from '../../interfaces/Ramal.interface';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import { Button, Dropdown, IconButton } from 'rsuite';
import AtualizarOrdem from './AtualizarOrdem';
import PlusIcon from '@rsuite/icons/Plus';
import DragableIcon from '@rsuite/icons/Dragable';
import SkeletonList from '../utils/SkeletonList';
import NotFound from '../utils/NotFound';
import { Table } from 'react-bootstrap';

interface Props {
    value: Ramal;
    position: number;
    atualizar: () => void;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }

interface ListProps {
    items: Ramal[];
    onClick?: React.MouseEventHandler<HTMLInputElement> | undefined;
    onChange?: React.FormEventHandler<HTMLUListElement> | undefined
    alterOrdem: boolean;
    setAlterOrdem: React.Dispatch<React.SetStateAction<boolean>>
    atualizar: () => void;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }

const SortableItem = SortableElement(({value, position, handleClick} : Props) => 
{
  const {
    ramalStore
  } = useContext(StoreContext);
  const {
    deletar,
    deletando
  } = useRamal();

  if(deletando) {
    return(
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return(
    <tbody>
        <tr onClick={() => ramalStore.definirSelecionado(value.id)}> 
            <td>{value.nome}</td>
            <td>{value.ramalNumero}</td>
            <td>{value.tipo}</td>
            <td>
                <IconButton style={{ height: 30, width: 30 }} onClick={(e) => handleClick(e as any)}>
                    <BsThreeDots className='icon'/>
                </IconButton>
            </td>
        </tr>
    </tbody>
  )
}
);

const SortableList = SortableContainer(({ items, alterOrdem, atualizar, setAlterOrdem, handleClick }: ListProps) => {
  
  const { ramalStore, themeStore } = useContext(StoreContext);
  const { atualizarOrdem } = useRamal();

  const atualizarOrdemRamal = () => {
    ramalStore.ramais.map((_, i) => _.ordemExibicao = i);
    atualizarOrdem(ramalStore.ramais);
    setAlterOrdem(!alterOrdem);
  }


    return (
      <ul className='m-0 p-0'>
        <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
          <thead>
              <tr>
              <th scope="col">Nome</th>
              <th scope="col">Ordem</th>
              <th scope="col">Tipo</th>
              <th scope="col">Ação</th>
              </tr>
          </thead>
              {items.sort((a, b) => a.ramalNumero > b.ramalNumero ? 1 : -1).map((ramal, index) => {
            return(
              <SortableItem disabled={!alterOrdem} key={`${index}`} index={index} atualizar={atualizar} value={ramal} position={index} handleClick={handleClick} />
            )
          })}
        </Table>
        <div className='d-flex justify-content-end'>
          <div>
            <Button onClick={() => setAlterOrdem(!alterOrdem)}>
              {
                  alterOrdem
                  ?
                  <span>Atualizar ordem: ATIVADO <BsArrowsExpand/></span>
                  :
                  <span>Atualizar ordem <BsArrowsExpand/></span>
              }
            </Button>

            <Button appearance='primary' style={{marginLeft: 10, marginRight: 10}} disabled={!alterOrdem} onClick={() => atualizarOrdemRamal()}>
              Salvar
            </Button>
          </div>
        </div>
        
       </ul>
    );
  });

const RamalListagem = () => {

    const { ramalStore, condominioStore } = useContext(StoreContext);
    const { deletar, deletando } = useRamal();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); 
    const [alterOrdem, setAlterOrdem] = useState(false);
    const [modalCadastrarRamal, setModalCadastrarRamal] = useState(false);
    const [modalEditarRamal, setModalEditarRamal] = useState(false);
    const [busca, setBusca] = useState('');
    const open = Boolean(anchorEl);

    const [modalDeletar, setModalDeletar] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const onSortEnd = (oldIndex: number, newIndex: number) => {
        ramalStore.ramais = arrayMoveImmutable(ramalStore.ramais, oldIndex, newIndex);
    };

    const filtrar = ramalStore.ramais.filter((ramal) =>
      ramal.nome?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) ||
      ramal.ramalNumero?.toString().toLocaleLowerCase().includes(busca.toLocaleLowerCase()) ||
      !busca
    );

    const toggleAlterOrdem = () => setAlterOrdem(!alterOrdem);
    const toggleCadastrarRamal = () => setModalCadastrarRamal(!modalCadastrarRamal);
    const toggleEditarRamal = () => setModalEditarRamal(!modalEditarRamal);
    const toggleModalDeletar = () => setModalDeletar(!modalDeletar);

    const deletarRamal = () =>
    {
      if(ramalStore.selecionado) {
        deletar(ramalStore.selecionado);
      }
    }

    useEffect(() =>
    {
      ramalStore.listar();
    }, []);

    const renderRamal = () => {

        if(deletando) return <SkeletonList/>
        if(ramalStore.loading) return <SkeletonList/>
        if(ramalStore.ramais.length === 0) return <NotFound message='Nenhum ramal cadastrado' />


        return(
            <div>
              <Table>
                <thead>
                      <tr>
                          <th>Nome</th>
                          <th>Número</th>
                          <th>Tipo</th>
                          <th>Ação</th>
                      </tr>
                </thead>
                <tbody>
                  {
                      filtrar.map((ramal, i) => (
                      <tr key={i}> 
                          <td>{ramal.nome}</td>
                          <td>{ramal.ramalNumero?.replace(/[^0-9]/g, '')}</td>
                          <td>{ramal.tipo}</td>
                          <td>
                              <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                <Dropdown.Item onClick={() => {
                                  ramalStore.definirSelecionado(ramal.id);
                                  toggleEditarRamal();
                                }}>Atualizar</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                  ramalStore.definirSelecionado(ramal.id);
                                  toggleModalDeletar();
                                }}>Deletar</Dropdown.Item>
                              </Dropdown>
                          </td>
                      </tr>
                      ))
                  }
              </tbody>
              </Table>
            </div>
        )
    }

    return(
        <Container>
            <Header>
              <div className='d-flex justify-content-between'>
                <div>
                    <p className='title'>Campo de cadastros de ramal</p>
                    <p className='subtitle'>Cadastre o ramal para associar a um dispositivo SIP</p>
                </div>
                <div>
                    <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarRamal}>
                        Adicionar novo ramal
                    </IconButton>
                </div>
              </div>

            <div className='d-flex box'>
                <div className='content d-flex'>
                    <BsTelephoneInbound className='content-icon' />
                    <div>
                        <p className='content-title'>Ramais cadastrados </p>
                        <p className='content-subtitle'>{ramalStore.ramais.length} cadastrado</p>
                    </div>
                </div>

                <div style={{marginLeft: 30}} className='align-self-center'>
                    <p className='info-title'>Atualizar ordem dos ramais</p>
                    <IconButton appearance="primary" color="violet" icon={<DragableIcon />} onClick={toggleAlterOrdem}>
                        Atualizar
                    </IconButton>
                    </div>
                </div>
          </Header>

            <ListRamais>
              {renderRamal()}
            </ListRamais>

            <CadastrarRamal show={modalCadastrarRamal} hide={toggleCadastrarRamal} condominioId={condominioStore.selecionado?.id} />
            <AtualizarRamal show={modalEditarRamal} hide={toggleEditarRamal} ramalSelecionado={ramalStore.selecionado} />
            <AtualizarOrdem show={alterOrdem} hide={toggleAlterOrdem} />
            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    toggleEditarRamal();
                }}>Atualizar</MenuItem>
                <MenuItem onClick={() => {
                  handleClose();
                  toggleModalDeletar();
                }}>Deletar</MenuItem>
            </Menu> */}

            <ModalConfirmacao show={modalDeletar} hide={toggleModalDeletar} action={deletarRamal} content={'Você tem certeza que deseja deletar esse ramal?'} loading={deletando} />
        </Container>
    )
}

export default observer(RamalListagem);