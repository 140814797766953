import React, { FC } from 'react';
import { BsFolder2Open } from 'react-icons/bs';

interface EmptyProps {
    message: string;
}

const Empty: FC<EmptyProps> = ({ message }) => {
    return(
        <div className='d-flex justify-content-center' style={{background: 'transparent'}}>
            <p style={{textAlign: 'center', fontSize: 15, margin: 0}}>{message}</p>
            <BsFolder2Open style={{marginLeft: 10, alignSelf: 'center'}} />
        </div>
    )
}

export default Empty;