import { Avatar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite'
import { useCondominio } from '../../hooks/Condominio.hook'
import { Condominio, CondominioAuxiliar, CondominioAuxiliarRequest, CondominioRequest } from '../../interfaces/Condominio.interface'
import { StoreContext } from '../../stores'


interface Props
{
    show: boolean;
    hide: () => void;
}


const AtualizarEndereco: FC<Props> = ({ show, hide }) => {

    const { condominioStore } = useContext(StoreContext);
    const { atualizarCondominioAuxiliar, atualizando } = useCondominio();
    const [form, setForm] = useState<CondominioAuxiliarRequest>({});

    const atualizarCondominio = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizarCondominioAuxiliar(form as CondominioAuxiliar, hide);
    }

    useEffect(() =>
    {
        if(show && condominioStore.condominiosAuxiliar)
        {
            setForm(condominioStore.condominiosAuxiliar);
        }
    }, [show, condominioStore.condominiosAuxiliar]);

    const renderForm = () =>
    {
        return(
            <Form onSubmit={(e) => atualizarCondominio(e)}>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label><span className='text-danger'>*</span>Endereço</Form.Label>
                    <Input required type="text" className='shadow-none' placeholder="Av. João Gomes" value={form.endereco || ''} onChange={(e) => setForm({ ...form, endereco: e })} />
                </Form.Group>

                <div>
                    <p>Geolocalização do condomínio</p>
                </div>

                <div className='d-flex'>
                    <Form.Group className="p-1 col-md-6" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Latitude</Form.Label>
                        <Input  type="text" className='shadow-none' placeholder="-26.1965843" value={form.latitude || ''} onChange={(e) => setForm({ ...form, latitude: e })} />
                    </Form.Group>
                    <Form.Group className="p-1 col-md-6" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Longitude</Form.Label>
                        <Input  type="text" className='shadow-none' placeholder="-52.6890572" value={form.longitude || ''} onChange={(e) => setForm({ ...form, longitude: e })} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-3'>
                    <div className='d-flex'>
                    <Button appearance="primary" type={'submit'}>
                        {
                            atualizando
                            ?
                            <Loader title={'Salvando...'} />
                            :
                            'Salvar'
                        }
                    </Button>
                    <Button style={{marginLeft: 5}} type={'button'} onClick={hide}>Voltar</Button>
                </div>
                </div>
            </Form>
        )
    }

    return (
        <Modal open={show} onClose={hide} size={'sm'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar o endereço do condomínio</p>
                    <p className='m-0'>Condomínio { condominioStore.selecionado ? condominioStore.selecionado.nome : '' }</p>
                </div>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarEndereco);