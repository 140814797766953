import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, List, Loader, Modal } from 'rsuite';
import { useMorador } from '../../hooks/Morador.hook';
import { useUnidade } from '../../hooks/Unidade.hook';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { StoreContext } from '../../stores';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarOrdem: FC<ModalProps> = ({ show, hide }) => {

    const { unidadeStore, moradorStore } = useContext(StoreContext);
    const [form, setForm] = useState<UnidadeRequest>({});
    const { AtualizarOrdem, atualizando } = useMorador();

    const handleSortEnd = ({ oldIndex, newIndex } : any) =>
    {
        moradorStore.moradores = arrayMoveImmutable(moradorStore.moradores, oldIndex, newIndex);
        moradorStore.moradores.map((_, i) => _.ordem = i);
        var ordem = 
        {
            novaOrdem: moradorStore.moradores.map(i => ({moradorId: i.id, ordem: i.ordem, nome: i.nome}))
        } as any;
        // 
        AtualizarOrdem(ordem);
    }

    const Listagem = () => {

        if(atualizando)
        {
          return(
            <div className={'d-flex justify-content-center mt-5'}>
              <Loader content={'Salvando nova ordem dos moradores... aguarde!'} />
            </div>
          )
        }

        return(
            <List sortable onSort={handleSortEnd}>
                {
                    moradorStore.moradores.map((morador, i) =>
                    <List.Item className='p-3' key={i} index={i} style={{borderRadius: 8}}>{morador.nome?.toUpperCase()}</List.Item>)
                }
            </List>
        )
    }

    return(
        <Modal open={show} onClose={() => atualizando ? {} : hide()}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar ordem dos moradores da unidade { unidadeStore.selecionado?.apartamento } - { unidadeStore.selecionado?.blocoNome }</p>
                    <p className='modal-styled-subtitle'>
                        Arraste para cima ou para baixo para alterar ordem dos moradores
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{Listagem()}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => atualizando ? {} : hide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarOrdem);