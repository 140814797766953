import styled from "styled-components";
import PerfctScrollBar from "react-perfect-scrollbar";

interface Props {
    tipo?: number;
}


export const Container = styled(PerfctScrollBar)`
    padding: 10px;
    height: 100vh;
`;

export const Header = styled.div`
    margin: 10px;
    margin-top: -1px;
    padding-left: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    .title
    {
    margin: 0;
    font-size: 12pt;
    font-weight: 550;
    align-self: center;
    }
    .subtitle
    {
    margin: 0;
    font-size: 12pt;
    }
`;

export const Acionamentos = styled.div`
    // position: fixed;
    // right: 20px;
    // top: 0;
    // background: #fff;
    // height: 100vh;
    // width: 20vw;
    // margin-top: 70px;
    // border-top-left-radius: 8px;
    // border-botom-left-radius: 8px;
`;

export const List = styled.div`

    // margin: 10px;
    border-radius: 12px;
    .content
    {
        background: ${(props) => props.theme.colors.bgBox};
        padding: 10px;
        border-radius: 8px;
        margin: 10px;
        box-shadow: 1px 3px 5px ${(props) => props.theme.colors.bgColor};
    }
`;

export const Tipo = styled.p<Props>`
    margin: 0;
    font-size: 8pt;
    padding: 8px;
    background: ${(props) => props.tipo  === 0 ? 'green' : 'red'};
    color: #fff;
    border-radius: 60px;
    // width: 50%;
    text-align: center;
`;
