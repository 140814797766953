import { AssociarMorador, Morador, NovaOrdem } from "../interfaces/Morador.interface";
import MoradorAPI from "../api/MoradorAPI";
import { useContext, useState } from "react";
import { StoreContext } from "../stores";
import { toast } from "react-toastify";
import UsuarioAPI from "../api/UsuarioAPI";
import axios, { AxiosError } from "axios";


export const useMorador = () => {

    const { moradorStore, unidadeStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);

    const cadastrar = async (morador: Morador, hide: () => void, clear: () => void) => {
        setSalvando(true);
        if (morador.cpf) {
            morador.cpf = morador.cpf.replace(/[^0-9]/g, '');
        }

        if (morador.celular) {
            morador.celular = morador.celular.replace(/[^0-9]/g, '');
        }

        if (morador.telefone) {
            morador.telefone = morador.telefone.replace(/[^0-9]/g, '');
        }

        // if (morador.nascimento) {
        //     morador.nascimento = morador.nascimento.concat('T00:00:00.000Z');
        // }


        try {
            const { data, status } = await MoradorAPI.cadastrar(morador);
            moradorStore.moradores.push(data);
            hide();
            toast.success('Morador cadastrado com sucesso!');
            clear();
            setSalvando(false);
        } catch (e) {
            let error = e as AxiosError;
            if (error) {
                setSalvando(false);
                toast.error(error.response!.data[0].mensagem);
            }
        }

    }

    const atualizar = async (morador: Morador, hide: () => void) => {
        setAtualizando(true);

        if (morador.cpf) {
            morador.cpf = morador.cpf.replace(/[^0-9]/g, '');
        }

        if (morador.celular) {
            morador.celular = morador.celular.replace(/[^0-9]/g, '');
        }

        if (morador.telefone) {
            morador.telefone = morador.telefone.replace(/[^0-9]/g, '');
        }

        try {
            const { status } = await MoradorAPI.atualizar(morador);
            if (status === 200) {
                moradorStore.moradores = moradorStore.moradores.map(mor => mor.id === morador.id ? morador : mor);
                unidadeStore.listar(morador.unidadeId);
                setAtualizando(false);
                hide();
                toast.success('Morador atualizado com sucesso!');
            }
        } catch (e) {
            let error = e as AxiosError;
            if (error) {
                setAtualizando(false);
                toast.error(error.response!.data[0].mensagem);
            }
        }
    }

    const AtualizarOrdem = async (novaOrdem: NovaOrdem) => {
        setAtualizando(true);
        try {
            await MoradorAPI.atualizarOrdem(novaOrdem);
            toast.success("Ordem atualizado com sucesso!");
            setAtualizando(false);
        }
        catch (e) {
            toast.error("Erro ao atualizar ordem");
            setAtualizando(false);

        }
    }

    const Restaurar = async (morador: Morador) => {
        setAtualizando(true);
        try {
            await MoradorAPI.restaurar(morador.id);
            moradorStore.listarPorUnidadeId(morador.unidadeId);
            moradorStore.listarExcluidoPorUnidadeId(morador.unidadeId);
            toast.success("Restaurado com sucesso!");
            setAtualizando(false);
        }
        catch (e) {
            toast.error("Erro ao restaurar");
            setAtualizando(false);
        }
    }


    const associarUsuarioNovoCondominio = async (novoMorador: AssociarMorador, hide: () => void) => {
        setSalvando(true);
        try {
            const { status } = await MoradorAPI.associarMoradorNovoCondominio(novoMorador);
            if (status === 200) {
                setSalvando(false);
                toast.success('Morador adicionado com sucesso!');
                hide();
            }
        } catch (e) {
            let error = e as AxiosError;
            if (error) {
                setSalvando(false);
                toast.error(error.response!.data[0].mensagem);
            }
        }
    }

    const cadastrarUsuario = async (moradorId: string, unidadeId: string, hide: () => void) => {
        setSalvando(true);
        try {
            const { status } = await MoradorAPI.cadastrarUsuario(moradorId);
            if (status === 200) {
                setSalvando(false);
                moradorStore.listarPorUnidadeId(unidadeId);
                toast.success('Criado um novo usuário para esse morador!');
                hide();
            }
        } catch (e) {
            //
            if (axios.isAxiosError(e)) {
                setSalvando(false);
                toast.error(e.response?.data[0].mensagem);
            }

        }
    }

    const deletar = async (morador: Morador) => {
        setDeletando(true);
        try {
            const { status } = await MoradorAPI.deletar(morador.id);
            if (status === 200) {
                moradorStore.moradores = moradorStore.moradores.filter(mor => mor.id !== morador.id);
                setDeletando(false);
                toast.success('Morador deletado com sucesso!')
            }
        } catch (e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar morador')
        }
    }

    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        cadastrarUsuario,
        associarUsuarioNovoCondominio,
        deletando,
        Restaurar,
        AtualizarOrdem
    }

}