import { makeAutoObservable, runInAction } from 'mobx';
import MoradorAPI from '../api/MoradorAPI';
import { converterData, converterHora, cpfMask, maskCelular, maskTelefoneFixo } from '../components/utils/mask';
import { Morador, MoradorDispositivos } from '../interfaces/Morador.interface';

class MoradorStore {
  selecionado: Morador | null = null;
  selecionadoExcluido: Morador | null = null;
  moradores: Morador[] = [];
  moradoresDispositivos: MoradorDispositivos[] = [];
  moradoresCondominio: Morador[] = [];
  moradoresNome: Morador[] = [];
  moradoresExcluido: Morador[] = [];
  loading: boolean = false;
  loadingDispositivo: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async listarPorCondominioId(condominioId: string) {
    this.loading = true;
    try {
        if(condominioId === '') {
          this.moradoresCondominio = [];
        } else {
          const { data } = await MoradorAPI.listarMoradorPorCondominio(condominioId);
          runInAction(() => {
              if(data)
              {
                this.moradoresCondominio = data;
                this.moradoresCondominio.forEach((morador) =>
                  {
                    morador.cpf = morador.cpf !== null ? cpfMask(morador.cpf) : '-';
                    morador.celular = morador.celular !== null ? maskCelular(morador.celular) : '-';
                    morador.telefone = morador.telefone !== null ? maskTelefoneFixo(morador.telefone) : '-';
                    morador.dataCadastro = morador.dataCadastro !== null ? `${converterData(new Date(morador.dataCadastro))} ${converterHora(new Date(morador.dataCadastro))}` : '-';
                    morador.unidadeIdentificacao = `${morador.unidadeIdentificacao} - ${morador.blocoIdentificacao}`;
                    morador.usuarioId = morador.usuarioId !== null ? 'SIM' : 'NÃO';
                    morador.nascimento = morador.nascimento !== null ? converterData(new Date(morador.nascimento)) : '-'
                  }
                )
              }
              else
                this.moradoresCondominio = [];
              setTimeout(() =>
              {
                this.loading = false;
              }, 1000);
          })
        }
    } catch(e) {
      this.loading = false;
      this.moradoresCondominio = [];
      //
    }
  }

  async listarPorUnidadeId(unidadeId: string) {
    this.loading = true;
    try {
        if(unidadeId === '') {
          this.moradores = [];
        } else {
          const { data } = await MoradorAPI.listarMoradorPorUnidade(unidadeId);
          runInAction(() => {
              if(data)
                this.moradores = data;
              else
                this.moradores = [];
              setTimeout(() =>
              {
                this.loading = false;
              }, 1000);
          })
        }
    } catch(e) {
      this.loading = false;
      this.moradores = [];
      //
    }
  }

  async listarRelatorioDispositivoPorUnidadeId(unidadeId: string) {
    this.loadingDispositivo = true;
    try {
      const { data } = await MoradorAPI.listarRelatorioDispositivoPorUnidade(unidadeId);
      runInAction(() => {
          if(data)
            this.moradoresDispositivos = data;
          else
            this.moradoresDispositivos = [];
          this.loadingDispositivo = false;
      })
      this.loading = false;
    } catch(e) {
      this.loadingDispositivo = false;
      this.moradores = [];
      //
    }
  }

  async listarPorNomeMorador(condominioId: string, nome: string) {
    this.loading = true;
    try {
        if(nome === '') {
          
        } else {
          const { data } = await MoradorAPI.obterPorNome(condominioId, nome);
          runInAction(() => {
              if(data)
                this.moradoresNome = data;
              else
                this.moradoresNome = [];
              this.loading = false;
          })
        }
    } catch(e) {
      this.loading = false;
      //
    }
  }
  
  async listarExcluidoPorUnidadeId(unidadeId: string) {
    this.loading = true;
    try {
        if(unidadeId === '') {
          this.moradoresExcluido = [];
        } else {
          const { data } = await MoradorAPI.listarMoradorExcluidoPorUnidade(unidadeId);
          runInAction(() => {
              if(!data)
              {
                this.moradoresExcluido = [];
                this.loading = false;
              }
              else
              {
                this.moradoresExcluido = data;
                this.loading = false;
              }
          })
        }
    } catch(e) {
      this.loading = false;
      this.moradoresExcluido = [];
      //
    }
  }

  async definirMoradorExcluidoSelecionado(moradorId: string | null) {
    const selecionado = this.moradoresExcluido.find((mor) => mor.id === moradorId);

    if(selecionado) {
      this.selecionadoExcluido = selecionado;
    }
  }

  async definirSelecionado(moradorId: string | null) {
    const selecionado = this.moradores.find((mor) => mor.id === moradorId);

    if(selecionado) {
      this.selecionado = selecionado;
    }
  }


}

export default MoradorStore;