import { Condominio, CondominioAuxiliar } from '../interfaces/Condominio.interface';
import api from '../services/api/api';

class CondominioAPI {
    listar = async () => await api.get<Condominio[]>(`/Condominios`);
    cadastrar = async (request: Condominio) => await api.post<Condominio>(`/condominios`, request); 
    atualizar = async (request: Condominio) => await api.put<Condominio>(`/condominios`, request);
    centrais = async () => await api.get<Condominio[]>('/CentraisAtendimento');

    // CONDOMINIO AUXILIAR
    listarCondominioAuxiliar = async (condominioId: string) => await api.get<CondominioAuxiliar>(`/CondominioAuxiliar/condominio`, { params: { condominioId } });
    cadastrarCondominioAuxiliar = async (request: CondominioAuxiliar) => await api.post<CondominioAuxiliar>(`/CondominioAuxiliar`, request); 
    atualizarCondominioAuxiliar = async (request: CondominioAuxiliar) => await api.put(`/CondominioAuxiliar`, request); 
}

export default new CondominioAPI();