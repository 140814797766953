import { useContext, useState } from "react";
import { toast } from "react-toastify";
import LayoutAPI from "../api/LayoutAPI";
import { StoreContext } from "../stores";
import { Layout } from "../interfaces/Layout.interface";
import FuncionarioAPI from "../api/FuncionarioAPI";
import { Funcionario } from "../interfaces/Funcionario.interface";
import axios from "axios";


export const useFuncionario = () => {

    const { funcionarioStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    
    const cadastrar = async (funcionario: Funcionario, hide: () => void, clear: () => void) => {
        setSalvando(true);

        try {
            const { data, status } = await FuncionarioAPI.adicionar(funcionario);
            if(status === 200) {
                funcionarioStore.listar();
                setSalvando(false);
                hide();
                clear();
                toast.success('Funcionario cadastrado com sucesso!');
            }
        } catch(e) {
            //
            if(axios.isAxiosError(e))
            {
                toast.error(e.response?.data);
            }
            setSalvando(false);
        }
    }

    const atualizar = async (funcionario: Funcionario, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await FuncionarioAPI.atualizar(funcionario);
            if(status === 200) {
                funcionarioStore.listar();
                // funcionarioStore.funcionarios = funcionarioStore.funcionarios.map(f => f.id === funcionario.id ? funcionario : f);
                setAtualizando(false);
                hide();
                toast.success('Funcionario atualizado com sucesso!');
            }
        } catch(e) {
            //
            setAtualizando(false);
            if(axios.isAxiosError(e))
            {
                toast.error(e.response?.data);
            }
        }
    }

    const deletar = async (funcionario: Funcionario) => {
        setDeletando(true);
        try {
            const { status } = await FuncionarioAPI.deletar(funcionario.id);
            if(status === 200) {
                funcionarioStore.funcionarios = funcionarioStore.funcionarios.filter(f => f.id !== funcionario.id);
                setDeletando(false);
                toast.success('Funcionario deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            if(axios.isAxiosError(e))
            {
                toast.error(e.response?.data);
            }
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando
    }

}