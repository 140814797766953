import styled from 'styled-components';

export const Box = styled.div`
  cursor: pointer;
  transition: all .4s;
  padding: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Unidade = styled.div`
  border-radius: 5px;
  width: 80px;
  text-align: center;

  span {
    display: block;
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    line-height: 1;
  }
  b {
    display: block;
    font-size: 16px;
    font-weight: 800;
    font-size: 1;
    color: ${(props) => props.theme.colors.primary};
  }
`;
