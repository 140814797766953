import { Table as TabelReact } from 'react-bootstrap';
import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Container = styled(PerfectScrollBar)`
  padding: 10px;
  height: 90vh;
  // background: ${(props) => props.theme.colors.bgBox};
  border-radius: 8px;
  // margin: 20px;
  .dashboard
  {
    background: ${(props) => props.theme.colors.bgBox};
    padding: 10px;
    border-radius: 8px;
    box-shadow: 1px 3px 5px ${(props) => props.theme.colors.bgColor};
  }
  .dashboard-content
  {
    border-right: 1px solid ${(props) => props.theme.colors.borderColor};
    width: 350px;
    margin-right: 15px;
  }
  .dashboard-icon
  {
    font-size: 20pt;
    align-self: center;
    margin-right: 20px;
  }
  .dashboard-title
  {
    margin: 1px;
    font-size: 12pt;
    font-weight: 550;
  }
  .dashboard-subtitle
  {
    margin: 2px;
    font-size: 10pt;
  }
  .dashboard-info
  {
    margin: 2px;

  }
`

export const Header = styled.div`
  margin-top: 5px;
  background: ${(props) => props.theme.colors.bgBox};
  padding: 10px;
  border-radius: 4px;
  .title
  {
    margin: 0;
    font-size: 12pt;
    font-weight: 550;
    align-self: center;
  }
  .subtitle
  {
    margin: 0;
    font-size: 12pt;
  }
`

export const List = styled.div` 
  border-radius: 12px;
  margin-top: 8px;
  // height: 20vh;
  background: ${(props) => props.theme.colors.bgColor};
  // padding-top: 10px;
  // padding-left: 10px;
  // padding-right: 10px;
  // border: 1px solid ${(props) => props.theme.colors.borderColor};
  thead th {
    font-weight: 0;
    // background: ${(props) => props.theme.colors.bgBox};
    font-size: 13px;
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.colors.bgNav};
    // margin-bottom: 20px;
  } 
  tbody td {
    font-size: 12px;
    padding: 12px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`

export const CardItem = styled.div`
  background: ${(props) => props.theme.colors.bgColor};
  margin-top: 5px;
  border-radius: 6px;
  padding: 4px;
  .title-item
  {
    margin: 0;
  }
  .subtitle-item
  {
    margin: 0;
    margin-top:-13px;
  }

  .item-icon
  {
    font-size: 20pt;
  }
`;