import { Backdrop, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import { borderBottom, borderRadius } from '@mui/system';
import { AxiosError } from 'axios';
import { observer } from 'mobx-react-lite';
import react, { FC, useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FaArrowDown } from 'react-icons/fa';
import MoradorAPI from '../../api/MoradorAPI';
import UsuarioAPI from '../../api/UsuarioAPI';
import { StoreContext } from '../../stores';
import { useMorador } from '../../hooks/Morador.hook';
import { Morador, MoradorRequest } from '../../interfaces/Morador.interface';
import { Unidade } from '../../interfaces/Unidade.interface';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import { CardAPP, CardValidation, ContainerFormMorador, InforPessoal, Scroll } from './styles/Moradores.style';
import { Button, ButtonGroup, Input, Loader, Modal, SelectPicker, Steps, Toggle } from 'rsuite';
import app from '../../assets/img/app.png';
import validacao from '../../assets/img/validacao-morador.png';

interface ITopoMorador {
    descricao: string
    id: string 
  }

interface PropsMorador { 
    show: boolean;
    hide: () => void;
}

const CadastrarMorador: FC<PropsMorador> = ({ show, hide }) => {

    let condominioId = localStorage.getItem('condominio');
    const { unidadeStore, moradorStore, acionamentoPerfilStore, condominioStore, cameraStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useMorador();
    const [form, setForm] = useState<MoradorRequest>({});
    const [tipoMorador, setTipoMorador] = useState<ITopoMorador[]>([]);
    const [disabled, setDisabled] = useState(false);
    const [step, setStep] = useState(0);
    const onChange = nextStep => {
      setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
    };
  
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    useEffect(() => {
        MoradorAPI
        .tipoMorador()
        .then((res) => setTipoMorador(res.data));
    }, []);

    useEffect(() => {
        if(condominioStore.selecionado && unidadeStore.selecionado && show){
            setStep(0)
            setForm({
                ...form,
                condominioId: condominioStore.selecionado.id,
                unidadeId: unidadeStore.selecionado.id,
                criarUsuario: true,
                ordem: 0,
                status: ''
            })
            cameraStore.listarCamerasPerfisIdentificacaoPorCondominioId(condominioStore.selecionado.id);
            acionamentoPerfilStore.listar()
        }
    }, [unidadeStore.selecionado, show]);

    const clear = () =>
    {
        setForm({ });
    }

    const salvar = () => {
        cadastrar(form as Morador, hide, clear);
    }

    const renderAcessoApp = () =>
    {
        return(
            <CardAPP>
                <div className='d-flex justify-content-center'>
                    <img src={app} className='app' />
                    <div className='align-self-center'>
                        <p className='title-app'>Acesso ao APP</p>
                        <p className='subtitle-app'>Você deseja que esse novo morador tenha acesso ao app?</p>
                        <Toggle size="lg" checkedChildren="Sim" unCheckedChildren="Não" checked={form.criarUsuario} onChange={ (e) => setForm({ ...form, criarUsuario: e }) } />
                    </div><br/>
                </div>
                <p><b>OBS:</b> Caso essa opção esteja desabilitada, alguns campos deixarão de ser obrigatórios!!</p>
            </CardAPP>
        )
    }

    const renderDadosMorador = () =>
    {
        return(
           <>
                <div className='card-form-morador'>
                    <div className='mt-2 col-md-12'>
                        <p className='title-form'><small className='text-danger'>*</small>Nome completo</p>
                        <Input required className='form-control shadow-none input-form' placeholder='Nome completo...' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })}  />
                    </div>
                    <div className='mt-2'>
                        <p className='title-form'>{ form.criarUsuario ? <small className='text-danger'>*</small> : '' }CPF</p>
                        <Input required={ form.criarUsuario ? true : false } className='form-control shadow-none input-form' placeholder='000.000.000-00' value={form.cpf || ''} onChange={(e) => setForm({ ...form, cpf: cpfMask(e) })}  />
                    </div>
                    <div className='mt-2'>
                        <p className='title-form'>RG</p>
                        <Input className='form-control shadow-none input-form' type='number' placeholder='' value={form.rg || ''} onChange={(e) => setForm({ ...form, rg: e })}  />
                    </div>
                    <div className='d-flex'>
                        <div className='col-md-6 p-1'>
                            <p className='title-form'>Nascimento</p>
                            <Input type={'date'} className='form-control shadow-none input-form' placeholder='00/00/0000'  value={form.nascimento || ''} onChange={(e) => setForm({ ...form, nascimento: e })}  />
                        </div>
                            <div className='col-md-6 p-1'>
                            <p className='title-form'>{ form.criarUsuario ? <small className='text-danger'>*</small> : '' }E-mail</p>
                            <Input required={ form.criarUsuario ? true : false } type={'email'} className='form-control shadow-none input-form' placeholder='seuemail@gmail.com'  value={form.email || ''} onChange={(e) => setForm({ ...form, email: e })}  />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='col-md-6 p-1'>
                            <p className='title-form'>{ form.criarUsuario ? <small className='text-danger'>*</small> : '' }Celular</p>
                            <Input required={ form.criarUsuario ? true : false } className='form-control shadow-none input-form' maxLength={14} placeholder='(99) 9 9999-9999 ' value={maskCelular(form?.celular || '')} onChange={(e) => setForm({ ...form, celular: maskCelular(e) })}  />
                        </div>
                        <div className='col-md-6 p-1'>
                            <p className='title-input-nascimento'>Telefone</p>
                            <Input className='form-control shadow-none input-form'  placeholder='(99) 99999-9999'  value={maskCelular(form.telefone || '')} onChange={(e) => setForm({ ...form, telefone: maskCelular(e) })}  />
                        </div>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-md-5 p-1'>
                        <p className='title-form'><small className='text-muted m-2'>Opcional</small>Palavra de segurança</p>
                        <Input type={'text'} className='form-control shadow-none input-form' placeholder='Ex: 8246'  value={form.palavra || ''} onChange={(e) => setForm({ ...form, palavra: e })}  />
                    </div>
                    <div className='col-md-6 p-1'>
                        <p className='title-form'><small className='text-muted m-2'>Opcional</small>Observação</p>
                        <input type={'text'} className='form-control shadow-none input-form' placeholder='Ex: Só ligar depois das 18hrs'  value={form.obs || ''} onChange={(e) => setForm({ ...form, obs: e.target.value })}  />
                    </div>
                </div>
           </>
        )
    }

    const renderPerfis = () =>
    {
        return(
            <div className='p-2'>
                <div className='mt-2'>
                    <div>
                        <p className='title-form'><small className='text-danger'>*</small>Status</p>
                        <SelectPicker
                            cleanable={false}
                            block
                            placeholder={'Selecione'}
                            value={form.status}
                            onChange={(e) => {
                                if(e) setForm({ ...form, status: e });
                            }}
                            data={[{ label: 'Liberado', value: 'Liberado' }, { label: 'Bloqueado', value: 'Bloqueado' }, { label: 'Aviso', value: 'Aviso' }]}
                        />
                    </div>
                    <div className=''>
                        <p className='title-form'><small className='text-danger'>*</small>Tipo morador</p>
                        <SelectPicker
                            cleanable={false}
                            block
                            placeholder={'Selecione'}
                            value={form.tipoId || ''}
                            onChange={(e) => {
                                if(e) setForm({ ...form, tipoId: e })
                            }}
                            data={tipoMorador.slice().sort((a, b) => a.descricao > b.descricao ? 1 : -1).map((m) => ({ label:m.descricao, value: m.id }))}
                        />
                    </div>
                    <div className=''>
                        <p className='title-form'>{ form.criarUsuario ? <small className='text-danger'>*</small> : '' }Nível de acesso</p>
                        <SelectPicker
                            cleanable={false}
                            block
                            placeholder={'Selecione'}
                            value={form.privilegio}
                            onChange={(e) => {
                                if(e) setForm({ ...form, privilegio: e })
                            }}
                            data={[{ label: 'Leitura', value: 0 }, { label: 'Editar', value: 1 }, { label: 'Administrador(a)', value: 2 }]}
                        />
                    </div>
                    
                    <div className=''>
                        <p className='title-form'><small className='text-danger'>*</small>Perfil de acionamento</p>
                        <SelectPicker
                            cleanable={false}
                            block
                            placeholder={'Selecione'}
                            value={form.acionamentoPerfilId || ''}
                            onChange={(e) => {
                                if(e) setForm({ ...form, acionamentoPerfilId: e });
                            }}
                            data={acionamentoPerfilStore.perfis.map((m) => ({ label: m.nome, value: m.id }))}
                        />
                    </div>
                    <div className=''>
                        <p className='title-form'>Câmêra perfil</p>
                        <SelectPicker
                            cleanable={false}
                            block
                            placeholder={'Selecione'}
                            value={form.cameraPerfilIdentificacaoId || ''}
                            onChange={(e) => {
                                if(e) setForm({ ...form, cameraPerfilIdentificacaoId: e });
                            }}
                            data={cameraStore.camerasPerfisIdentificacao.map((c) => ({ label: c.nome, value: c.id }))}
                        />
                    </div>
                </div>  
            </div>
        )
    }

    const renderStatus = () =>
    {

        if(form.criarUsuario)
        {
            if(
                !form.nome ||
                !form.cpf ||
                !form.email ||
                !form.celular ||
                !form.tipoId ||
                !form.acionamentoPerfilId ||
                !form.status ||
                !form.privilegio
            )
            {
                return(
                    <CardValidation className='d-flex justify-content-center'>
                        <div className='d-flex justify-content-center'>
                            <img src={validacao} className='app' />
                            <div className='align-self-center'>
                                <p className='title-app'>Ops!!!</p>
                                <p className='subtitle-app'>
                                    Para criar um morador que tenha acesso ao você tem que verificar se os campos, como: <br/>
                                    {!form.nome ? <span className='text-error'> <b>- NOME</b> <br/> </span> : ''}
                                    {!form.cpf ? <span className='text-error'> <b>- CPF</b> <br/> </span> : ''}
                                    {!form.email ? <span className='text-error'> <b>- EMAIL</b> <br/> </span> : ''}
                                    {!form.celular ? <span className='text-error'> <b>- CELULAR</b> <br/> </span> : ''}
                                    {!form.acionamentoPerfilId ? <span className='text-error'> <b>- ACIONAMENTO PERFIL</b> <br/> </span> : ''}
                                    {!form.tipoId ? <span className='text-error'> <b>- TIPO MORADOR</b> <br/> </span> : ''}
                                    {!form.privilegio ? <span className='text-error'> <b>- NÍVEL DE ACESSO</b> <br/> </span> : ''}
                                    {!form.status ? <span className='text-error'> <b>- STATUS</b> <br/> </span> : ''}
                                    Estão preenchidos corretamente
                                </p>
                            </div>
                        </div>
                    </CardValidation>
                )
            }
        }

        if(!form.criarUsuario)
        {
            if(
                !form.nome ||
                !form.tipoId ||
                !form.acionamentoPerfilId ||
                !form.status
            )
            {
                return(
                    <CardValidation className='d-flex justify-content-center'>
                        <div className='d-flex justify-content-center'>
                            <img src={validacao} className='app' />
                            <div className='align-self-center'>
                                <p className='title-app'>Ops!!!</p>
                                <p className='subtitle-app'>
                                    Para criar um morador que tenha acesso ao você tem que verificar se os campos, como: <br/>
                                    {!form.nome ? <span className='text-error'> <b>- NOME</b> <br/> </span> : ''}
                                    {!form.acionamentoPerfilId ? <span className='text-error'> <b>- ACIONAMENTO PERFIL</b> <br/> </span> : ''}
                                    {!form.tipoId ? <span className='text-error'> <b>- TIPO MORADOR</b> <br/> </span> : ''}
                                    {!form.status ? <span className='text-error'> <b>- STATUS</b> <br/> </span> : ''}
                                    Estão preenchidos corretamente
                                </p>
                            </div>
                        </div>
                    </CardValidation>
                )
            }
        }

        return(
            <CardValidation className='d-flex justify-content-center'>
                <div className='d-flex justify-content-center'>
                    <img src={validacao} className='app' />
                    <div className='align-self-center'>
                        <p className='title-app'>Finalizar cadastro</p>
                        <p className='subtitle-app'>Clique no botão abaixo para cadastrar esse novo morador(a) na unidade <b>{unidadeStore.selecionado?.andar} - {unidadeStore.selecionado?.blocoNome.toUpperCase()}</b></p>
                        <Button appearance="primary" disabled={!form.tipoId || !form.status || !form.acionamentoPerfilId} loading={salvando} onClick={salvar}>
                            Salvar
                        </Button>
                    </div>
                </div>
            </CardValidation>
        )
    }

    const renderStep = () =>
    {
        switch(step)
        {
            case 0:
                return renderAcessoApp();
            case 1:
                return renderDadosMorador();
            case 2:
                return renderPerfis();
            case 3:
                return renderStatus();
        }
    }

    const renderForm = () =>
    {
      return(
        <ContainerFormMorador>
            <div>
                <Steps current={step}>
                    <Steps.Item title="APP" description="Acesso ao app" onClick={() => setStep(0)} />
                    <Steps.Item title="Morador(a)" description="Dados do morador(a)" onClick={() => setStep(1)} />
                    <Steps.Item title="Perfil" description="Perfis de acessos" onClick={() => setStep(2)} />
                    <Steps.Item title="Status" description="Validação de dados" onClick={() => setStep(3)} />
                </Steps>
                {renderStep()}
                <div className='d-flex justify-content-center'>
                    <div>
                        <ButtonGroup>
                            <Button onClick={onPrevious} disabled={step === 0 || salvando}>
                            Voltar
                            </Button>
                            <Button onClick={onNext} disabled={step === 3 || salvando}>
                            Próximo
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            {/* <form onSubmit={(e) => salvar(e)}>
                <div>
                    <div className='header mb-3'>
                        <p className='title'>Novo morador</p>
                        <p className='subtitle'>Preencha todos os campos com o caractere <b>(*)</b> para cadastrar o novo morador</p>
                    </div>
                </div>

                
                <div className='d-flex justify-content-end'>
                    <div className='d-flex'>
                        <Button appearance="primary" type={'submit'} disabled={!form.tipoId || !form.status || !form.acionamentoPerfilId }>
                            {
                                salvando
                                ?
                                <Loader title={'Salvando...'} />
                                :
                                'Salvar'
                            }
                        </Button>
                        <Button style={{marginLeft: 5}} type={'button'} onClick={hide}>Voltar</Button>
                    </div>
                </div>
            </form> */}
        </ContainerFormMorador>
      )
    }

    return(
        <Modal open={show} onClose={() => salvando ? {} : hide()}>
            <Modal.Header/>
            <Modal.Body>
                {/* <Scroll> */}
                    {renderForm()}
                {/* </Scroll> */}
            </Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarMorador);


{/*  */}