import { Menu, MenuItem, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import { FiMonitor } from 'react-icons/fi';
import { Dropdown, IconButton, Input, InputGroup, Tag } from 'rsuite';
import { useMaquina } from '../../hooks/Maquina.hook';
import { StoreContext } from '../../stores';
import Button from '../utils/Button';
import Loading from '../utils/Loading';
import AtualizarMaquina from './AtualizarMaquina';
import CadastrarMaquina from './CadastrarMaquina';
import { Container, Header, List } from './styles/MaquinaListagem.style';
import PlusIcon from '@rsuite/icons/Plus';
import { Table } from 'react-bootstrap';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';

const MquinaListagem = () => {

  const { deletar, deletando } = useMaquina();
  const { usuarioStore, ramalStore, themeStore } = useContext(StoreContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [cadastrar, setCadastrar] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [search, setSearch] = useState('');

  const toggleCadastrar = () => setCadastrar(!cadastrar);
  const toggleAtualizar = () => setAtualizar(!atualizar);
  
  const filtrar = usuarioStore.maquinaLogada.filter(
    (i) => 
    i.nome.toLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(search.toLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
    i.ip?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    i.usuario?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    i.ramalNumero?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    !search)

  useEffect(() =>
  {
    usuarioStore.listarUsuáriosLogados();
  }, []);

  if(deletando)
  {
    return <Loading message='Deletando maquina...' />
  }

  return (
    <Container>
      <Header> 
        <div className='d-flex justify-content-between'>
            <div className='d-flex'>
              <div className='mt-2'>
                  <p className='title'>Campo de cadastros de máquinas</p>
                  <p className='subtitle'>Selecione a unidade para visualizar os veiculos</p>
              </div>
              <div style={{marginLeft: 10}} className='align-self-center'>
                  <p className='info-title-filtro' style={{width: 100}}>Campo de busca</p>
                  <InputGroup inside>
                      <Input placeholder='Buscar' style={{width: 350}} value={search} onChange={(e) => setSearch(e)} />
                      <InputGroup.Button onClick={() => setSearch('')}>
                          <CloseOutlineIcon />
                      </InputGroup.Button>
                  </InputGroup>
              </div>
            </div>
            <div className='mt-2'>
                <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrar}>
                    Adicionar nova máquina
                </IconButton>
            </div>
        </div>
      </Header>
      <List>

      <div className='p-1'>
        <Table striped={themeStore.theme === 'dark' ? false : true}>
            <thead>
                <tr>
                  <th>Id</th>
                  <th>Ponto De Atendimento</th>
                  <th>Usuario</th>
                  <th>Monitor Dguard Operação</th>
                  <th>Ip</th>
                  <th>Ramal</th>
                  <th>Ação</th>
                </tr>
            </thead>
            <tbody>
                {
                    filtrar.slice().sort((a, b) => a.ramalNumero > b.ramalNumero ? 1 : -1).map((m, i) =>
                    (
                        <tr key={i} className='' onClick={() => ramalStore.definirMaquinaSelecionado(m)}>
                            <td className='p-2'>{m.id.split('-')[4]}</td>
                            <td className='p-2'>{m.nome}</td>
                            <td className='p-2'>{m.usuario}</td>
                            <td className='p-2'>{m.monitorDGuard}</td>
                            <td className='p-2'>{m.ip}</td>
                            <td className='p-2'>{m.ramalNumero}</td>
                            <td>
                              <Dropdown appearance='primary' color={'violet'} title="Ação" size={'xs'}>
                                <Dropdown.Item onClick={toggleAtualizar}>Atualizar</Dropdown.Item>
                                <Dropdown.Item onClick={() => deletar(m)}>Deletar</Dropdown.Item>
                              </Dropdown>
                            </td>
                        </tr>
                    ))
                
                }
            </tbody>
        </Table>
    </div>
{/* 
        <div>
          {
            maquinasHiseg.slice().sort((a, b) => a.ramalNumero > b.ramalNumero ? 1 : -1).map((m, i) =>
            (
              <Tag key={i} className={'content'} color={m.usuario.toLowerCase().includes('pa livre') ? 'green' : 'violet'} onClick={() => ramalStore.definirMaquinaSelecionado(m)}>
                <div className='d-flex justify-content-between'>
                  <div style={{width: '100%'}}>
                    <p style={{margin: 0, width: '100%'}}>Usuário: <span style={{fontWeight: '550'}}>{m.usuario.split(' ')[0]} {m.usuario.split(' ')[1]}</span></p>
                    <p style={{margin: 0}}>Máquina: <span style={{fontWeight: '550'}}>{m.nome}</span></p>
                    <p style={{margin: 0}}>Ip: <span style={{fontWeight: '550'}}>{m.ip}</span></p>
                  </div>
                  <div className='align-self-center'>
                    <Dropdown title="Ação" appearance='primary' color={!m.usuario.toLowerCase().includes('pa livre') ? 'green' : 'violet'} size={'xs'}>
                      <Dropdown.Item onClick={toggleAtualizar}>Atualizar</Dropdown.Item>
                      <Dropdown.Item onClick={() => deletar(m)}>Deletar</Dropdown.Item>
                    </Dropdown>
                  </div>
                </div>
              </Tag>
            ))
          }
        </div> */}
      </List>

      <CadastrarMaquina show={cadastrar} hide={toggleCadastrar} />
      <AtualizarMaquina show={atualizar} hide={toggleAtualizar} maquinaSelecionado={ramalStore.maquinaSelecionado} />

    </Container>
  ) 
}

export default observer(MquinaListagem);
