import styled from 'styled-components';

interface Props
{
  selecionado: string | undefined;
  idLista: string;
}

export const Container = styled.div`
  .icon {
    margin: 0;
    align-self: center;
    color: ${(props) => props.theme.colors.text};
  }
`

export const Header = styled.div`
  // background: #fff;
  // padding: 5px;
  border-radius: 10px;
  .title {
    margin: 0;
    font-size: 15pt;
    font-weight: 550;
    align-self: center;
  }
  .subtitle {
    margin: 0;
    font-size: 10pt;
    align-self: center;
    color: rgba(0,0,0,0.7);
}
`

export const TransitionDiv = styled.div`
  .fade-enter .animation {
    opacity: 0;
    // transform: translateX(-50%);
  }
  .fade-enter-active .animation {
    opacity: 1;
    // transform: translateX(0%);
  }
  .fade-exit .animation {
    opacity: 1;
    // transform: translateX(0%);
  }
  .fade-exit-active .animation {
    opacity: 0;
    // transform: translateX(50%);
  }
  .fade-enter-active .animation,
  .fade-exit-active .animation {
    transition: opacity 100ms, transform 100ms;
  }
`

export const ListModulos = styled.div`
  // padding: 20px;
  flex-wrap: wrap;
  display: flex;
  .container-modulo {
    width: 30%;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.0500);
    transition: 0.1s;
  }
  .container-modulo:hover {
    cursor: pointer;
    transition: 0.1s;
  }
  .title {
    font-size: 12pt;
    margin: 0;
    font-weight: 500;
    width: 60%;
  }
  .info {
    font-size: 9pt;
    margin: 0;
    width: 50%;
    color: rgba(0,0,0,0.8);
  }
  .subtitle {
    font-size: 11pt;
    margin: 0;
    padding: 3px;
    background: #004C95;
    border-radius: 10px;
    width: 25%;
    text-align: center;
    color: #fff;
    margin-top: 10px;
  }
`;

export const ListReles = styled.div`
  // width: 100%;
  display: flex;
  justify-content: space-between;
  .container-rele {
    padding: 5px;
    // padding-left: 25px;
    transition: 0.2s;
    margin: 5px;
    border-radius: 10px;
    flex-wrap: wrap;
    // width: 50%;
    align-self: center;
  }
  .container-rele:hover {
    cursor: pointer;
    transition: 0.2s;
    background: ${(props) => props.theme.colors.bgColor};
  }
  .title {
    font-size: 9pt;
    margin: 0;
    width: 100%;
  }
  .subtitle {
    font-size: 10pt;
    padding: 5px;
    border-radius: 10px;
    background: #004C95;
    color: #fff;
    text-align: center;
  }
  .icon {
    color: ${(props) => props.theme.colors.text};
  }
`;

export const ListComandos = styled.div`
  background: ${(props) => props.theme.colors.bgColor};
  padding: 10px;
  border-radius: 10px;

  width: 100%;
  .comando {
    border-radius: 62px;
  }
  .container-modulo {
  border-radius: 62px;
    transition: 0.2s;
  }
  .container-comando {
    border-bottom: 1px solid rgba(0,0,0,0.0500);
    margin: 5px;
    padding-left: 10px;
    transition: 0.2s;
    // background: ${(props) => props.theme.colors.bgBox};
    border-radius: 10px;
    padding: 10px;
  }
  .container-comando:hover {
    cursor: pointer;
    transition: 0.2s;
    background: ${(props) => props.theme.colors.bgBox};
  }
  .acionamento {
    margin: 0;
    font-size: 10pt; 
    color: ${(props) => props.theme.colors.text};
    font-weight: 600;
  }
  .ordem {
    margin: 0;
    font-size: 10pt;
    margin-top: -10px;
  }
  .tipo {
    margin: 0;
    font-size: 10pt;
  }
  .icon {
    color: ${(props) => props.theme.colors.text};
  }

`;

export const ListActive = styled.div<Props>`
  background: ${(props) => props.idLista === props.selecionado ? props.theme.colors.bgColor : ''};
`;
