import React from 'react';
import { Input, InputGroup, InputNumber, InputNumber as RSInputNumber } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { StyledInputGroup } from './style';

interface InputNumberProps {
  value: number;
  handleMinus: () => void; 
  handlePlus: () => void; 
  handleChange: (newValue: string | number | null) => void;
  min: number;
  max?: number; 
  onChange?: (e: any) => void;
  title?: string
}

const CustomInputNumber: React.FC<InputNumberProps> = ({
  value,
  handleMinus,
  handlePlus,
  handleChange,
  min,
  max, 
  onChange,
  title = "Selecione Tentativas:"
}) => {

  const isDisabled = max !== undefined && value >= max;

  const handleInternalChange = (newValue: string | number | null) => {
    handleChange(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  
  return (
    <div className="m-1">
      <p style={{margin: 0}}>{title}</p>
      <div style={{ width: '8rem' }}>
        <StyledInputGroup>
          <InputGroup>
            <InputGroup.Button
              onClick={handleMinus}
              disabled={value <= min}
              className={value <= min ? 'disabled-button' : ''}
            >
              -
            </InputGroup.Button>
            <Input
              style={{ textAlign: 'center' }}
              className="custom-input-number"
              value={value}
              onChange={handleInternalChange}
            />
            <InputGroup.Button
              onClick={handlePlus}
              disabled={isDisabled}
              className={isDisabled ? 'disabled-button' : ''}
            >
              +
            </InputGroup.Button>
          </InputGroup>
        </StyledInputGroup>
      </div>
    </div>
  );
};

export default CustomInputNumber;