import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Header, ListLayout } from './styles/Layout.style';
import { StoreContext } from '../../stores';
import { BiEdit, BiX } from 'react-icons/bi';
import CadastrarLayout from './CadastrarLayout';
import AtualizarLayout from './AtualizarLayout';
import { useLayout } from '../../hooks/Layout.hook';
import { BsCameraVideo, BsThreeDots } from 'react-icons/bs';
import { Button, Input, IconButton, Dropdown } from 'rsuite';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import PlusIcon from '@rsuite/icons/Plus';
import PlayOutlineIcon from '@rsuite/icons/PlayOutline';
import SkeletonList from '../utils/SkeletonList';
import NotFound from '../utils/NotFound';
import { Table } from 'react-bootstrap';

const LayoutListagem = () => {

    const { layoutStore, condominioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando } = useLayout();
    const [search, setSearch] = useState(''); 
    const [modalDeletar, setModalDeletar] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [modalCadastrarLayout, setModalCadastrarLayout] = useState(false);
    const [modalEditarLayout, setModalEditarLayout] = useState(false);

    const toggleCadastrarLayout = () => setModalCadastrarLayout(!modalCadastrarLayout);
    const toggleEditarLayout = () => setModalEditarLayout(!modalEditarLayout);
    const toggleDeletar = () => setModalDeletar(!modalDeletar);

    const deletarLayout = () =>
    {
        if(layoutStore.selecionado) {
            deletar(layoutStore.selecionado);
        }
    }

    const visualizarCameras = () =>
    {
        if(layoutStore.selecionado)
        {
            window.open(`/camerasdguard/visualizacao`, 'sharer', 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1');
            localStorage.setItem('idlayout', layoutStore.selecionado.id);
        }
    }

    const filtrarLayouts = layoutStore.layouts.filter((layout) => 
        layout.nomeDGuard.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        !search
    );

    useEffect(() =>
    {
        layoutStore.layouts = [];
        layoutStore.listar()
    }, [])

    const renderLayout = () => {

        if(deletando) {
            return <SkeletonList/>
        }

        if(layoutStore.loading)
        {
            return <SkeletonList/>
        }

        if(layoutStore.layouts.length === 0)
        {
            return <NotFound message={'Nenhum layout cadastrado'} />
        }

        return(
            <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                <thead>
                        <tr>
                        <th scope="col">Nome DGuard</th> 
                        <th scope="col">Id DGuard</th>
                        <th scope="col">Default DGuard</th>
                        <th scope="col">Host remoto</th>
                        <th scope="col">porta remoto</th>
                        <th scope="col">Usuário DGuard</th>
                        <th scope="col">Senha DGuard</th>
                        <th scope="col">Visualizar</th>
                        <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        filtrarLayouts.map((layout, i) => (
                        <tr key={i} onClick={() => layoutStore.definirSelecionado(layout.id)}>
                            <td>{layout.nomeDGuard}</td>
                            <td>{layout.idDGuard}</td>
                            <td>{layout.defaultDGuard ? 'principal' : 'secundário'}</td>
                            <td>{layout.hostRemoto}</td>
                            <td>{layout.portaRemoto}</td>
                            <td>{layout.usuarioDGuard}</td>
                            <td>{layout.senhaDGuard}</td>
                            <td>
                                <IconButton size={'xs'} appearance={'primary'} color={'violet'} icon={<PlayOutlineIcon/>} onClick={visualizarCameras}>
                                    Visualizar
                                </IconButton>
                            </td>
                            <td>
                                <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                    <Dropdown.Item onClick={toggleEditarLayout}>Atualizar</Dropdown.Item>
                                    <Dropdown.Item onClick={toggleDeletar}>Deletar</Dropdown.Item>
                                </Dropdown>
                            </td>
                        </tr>
                        ))
                    }
                </tbody>
            </Table>
        )
    }

    return(
        <Container>
             <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de cadastro de layout</p>
                        <p className='m-0'>Preencha os campos para realizar os cadastros</p>
                    </div>
                    <div style={{marginLeft: 20, alignSelf: 'center'}}>
                        <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={() => toggleCadastrarLayout()}>
                            Adicionar novo layout
                        </IconButton>
                    </div>
                </div>
            <Header>
                <div>
                    <p className='title'>Use o campo ao lado para filtrar o layout de sua escolha </p>
                    <p className='m-0'>Abaixo está todas as informações do layout</p>
                </div>
            </Header>

            <ListLayout>
                {renderLayout()}
            </ListLayout>

            <CadastrarLayout show={modalCadastrarLayout} hide={toggleCadastrarLayout} condominioId={condominioStore.selecionado?.id} />
            <AtualizarLayout show={modalEditarLayout} hide={toggleEditarLayout} layoutSelecionado={layoutStore.selecionado} />

            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    toggleEditarLayout();
                }}>Atualizar</MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    toggleDeletar();
                }}>Deletar</MenuItem>
            </Menu> */}
            <ModalConfirmacao show={modalDeletar} hide={toggleDeletar} action={deletarLayout} content={'Você tem certeza que deseja deletar esse layout?'} loading={deletando} />
        </Container>
    )
}

export default observer(LayoutListagem);