import { makeAutoObservable, runInAction } from "mobx";
import NotaAPI from "../api/NotaAPI";
import { Nota } from "../interfaces/Nota.interface";

class NotaStore {

    notas: Nota[] = [];
    selecionado: Nota | null = null;
    loading: boolean = false;
    
    constructor() {
        makeAutoObservable(this);
    }

    async listarNotasPorCondominio() {
        this.loading = true;
        var condominioId = localStorage.getItem('condominio') as string;
        try {
            const { data } = await NotaAPI.listarPorCondominioId(condominioId);
            runInAction(() => {
                if(data)
                    this.notas = data;
                else
                    this.notas = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(notaId: string | null) {
        if(notaId !== null) {
            let selecionado = this.notas.find((no) => no.id === notaId);
            if(selecionado) {
                this.selecionado = selecionado;
            }
        } else {
            this.selecionado = null;
        }
    }

}

export default NotaStore;