import api from "../services/api/api";
import { AutomacaoAcionamento, NovaOrdem } from "../interfaces/AutomacaoAcionamento.interface";

class AutomacaoAcionamentoAPI {
    listarPorAutomacaoAcionamentoIdentificacaoId = async (automacaoAcionamentoIdentificacaoId: string) => await api.get<AutomacaoAcionamento[]>(`/AutomacaoAcionamento/ObterPorAutomacaoAcionamentoIdentificacaoId`, { params: { automacaoAcionamentoIdentificacaoId : automacaoAcionamentoIdentificacaoId } }); 
    cadastrar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamento) => await api.post(`/AutomacaoAcionamento`, automacaoAcionamentoIdentificacao); 
    atualizar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamento) => await api.put(`/AutomacaoAcionamento`, automacaoAcionamentoIdentificacao); 
    deletar = async (id: string) => await api.delete(`/AutomacaoAcionamento`, { params: { id } }); 
    atualizarOrdem = async (novaOrdem: NovaOrdem) => await api.put(`/AutomacaoAcionamento/NovaOrdem`, novaOrdem); 
}

export default new AutomacaoAcionamentoAPI();