import { observer } from 'mobx-react-lite';
import { useState, useEffect, useContext, FC } from 'react'
import { Button, Input, Modal, SelectPicker } from 'rsuite'
import { Funcionario, FuncionarioRequest } from '../../interfaces/Funcionario.interface';
import { Container } from './styles/Funcionario.style';
import { StoreContext } from '../../stores';
import { cpfMask, maskCelular } from '../utils/mask';
import { useFuncionario } from '../../hooks/Funcionario.hook';

interface Props
{
    show: boolean;
    hide: () => void;
}

const ModalAtualizar: FC<Props> = ({ show, hide }) => {

    const { funcionarioStore } = useContext(StoreContext);
    const { atualizar, atualizando } = useFuncionario();
    const [request, setRequest] = useState<FuncionarioRequest>({});

    useEffect(() =>
    {
        funcionarioStore.listarTiposFucionarios();
        if(funcionarioStore.selecionado)
            setRequest(funcionarioStore.selecionado)
    }, [show, funcionarioStore.selecionado]);
    

    const clear = () =>
    {
        setRequest({  });
    }

    const salvar = () =>
    {
        if(request.cpf)
            request.cpf = request.cpf.replace(/[^0-9]/g, '');
        if(request.celular)
            request.celular = request.celular.replace(/[^0-9]/g, '');
        atualizar(request as Funcionario, hide)
    }
    
    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p style={{fontSize: 16, fontWeight: 550}}>Cadastrar funcionario</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div className='p-1'>
                        <p className='title'>Nome<span className='text-danger'>*</span></p>
                        <Input placeholder='' value={request.nome} onChange={(e) => setRequest({ ...request, nome: e })}/>
                    </div>
                    <div className='d-flex mt-1'>
                        <div className='col-md-6 p-1'>
                            <p className='title'>Matricula<span className='text-danger'>*</span></p>
                            <Input placeholder='' value={request.matricula} onChange={(e) => setRequest({ ...request, matricula: e })}/>
                        </div>
                        <div className='col-md-6 p-1'>
                            <p className='title'>Descrição<span className='text-danger'>*</span></p>
                            <SelectPicker
                                block
                                value={request.tipoFuncionarioId}
                                cleanable={false}
                                data={funcionarioStore.tiposFuncionarios.map(f => ({ label: f.descricao, value: f.id }))}
                                onChange={(e) => {if(e) setRequest({ ...request, tipoFuncionarioId: e })}}
                            />
                        </div>
                    </div>
                    <div className='d-flex mt-1'>
                        <div className='col-md-6 p-1'>
                            <p className='title'>CPF<span className='text-danger'>*</span></p>
                            <Input placeholder='' maxLength={14} value={cpfMask(request.cpf ?? "")} onChange={(e) => setRequest({ ...request, cpf: e })}/>
                        </div>
                        <div className='col-md-6 p-1'>
                            <p className='title'>Celular<span className='text-danger'>*</span></p>
                            <Input placeholder='' maxLength={11} value={maskCelular(request.celular ?? "")} onChange={(e) => setRequest({ ...request, celular: e })}/>
                        </div>
                    </div>
                    <div className='p-1'>
                        <p className='title'>Email<span className='text-danger'>*</span></p>
                        <Input type='email' placeholder='' value={request.email} onChange={(e) => setRequest({ ...request, email: e })}/>
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{paddingRight: 20}}>
                <Button onClick={hide}>
                    Fechar
                </Button>
                <Button appearance='primary' color={'violet'} 
                disabled={
                    !request.tipoFuncionarioId ||
                    !request.nome ||
                    !request.cpf ||
                    !request.celular || request.celular.length <= 10 || request.celular.length === 14 ||
                    !request.email ||
                    atualizando
                } onClick={salvar} loading={atualizando}>
                    Salvar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(ModalAtualizar);