import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { StoreContext } from "../stores";
import AutomacaoAcionamentoIdentificacaoAPI from "../api/AutomacaoAcionamentoIdentificacaoAPI";
import { AutomacaoAcionamentoIdentificacao } from "../interfaces/AutomacaoAcionamentoIdentificacao.interface";


const useAutomacaoAcionamentoIdentificacao = () => {

    const { automacaoAcionamentoIdentificacaoStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);

    const cadastrar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamentoIdentificacao, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await AutomacaoAcionamentoIdentificacaoAPI.cadastrar(automacaoAcionamentoIdentificacao);
            if(status === 200) {
                automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao.push(data);
                toast.success('Automacao cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar automacao');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamentoIdentificacao, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await AutomacaoAcionamentoIdentificacaoAPI.atualizar(automacaoAcionamentoIdentificacao);
            if(status === 200) {
                automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao = automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao.map( a => a.id === automacaoAcionamentoIdentificacao.id ? automacaoAcionamentoIdentificacao : a );
                toast.success('Automacao atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar automacao');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const deletar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamentoIdentificacao, hide: () => void) => {
        setDeletando(true);
        try {
            const { status } = await AutomacaoAcionamentoIdentificacaoAPI.deletar(automacaoAcionamentoIdentificacao.id);
            if(status === 200) {
                automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao = automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao.filter( a => a.id !== automacaoAcionamentoIdentificacao.id );
                toast.success('Automacao deletado com sucesso');
                setDeletando(false);
                hide();
            } else {
                toast.error('Erro ao deletar automacao');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
        }
    }


    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando
    }

}


export default useAutomacaoAcionamentoIdentificacao;