import React from 'react'
import { Box } from './styles/index.style';
import { Placeholder } from 'rsuite';

const Loading = () => {
  return (
    <Box style={{padding: 5}}>
        <div>
            <Placeholder.Graph height={12} width={200} style={{borderRadius: 4}} active />
        </div>
        <div className='d-flex justify-conent-start mt-2' style={{marginLeft: 7, borderRadius: 4}}>
            <div>
                <Placeholder.Graph height={12} width={200} style={{borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={35} width={300} style={{marginTop: '-2px', borderRadius: 4}} active />
            </div>
            <div style={{marginLeft: 10}}>
                <Placeholder.Graph height={12} width={40} style={{borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={35} width={120} style={{marginTop: '-2px', borderRadius: 4}} active />
            </div>
            <div style={{marginLeft: 10}}>
                <Placeholder.Graph height={12} width={40} active /> <br/>
                <Placeholder.Graph height={35} width={160} style={{marginTop: '-2px', borderRadius: 4}} active />
            </div>
            <div style={{marginLeft: 10}}>
                <Placeholder.Graph height={12} width={40} active /> <br/>
                <Placeholder.Graph height={35} width={160} style={{marginTop: '-2px', borderRadius: 4}} active />
            </div>
            <div style={{marginLeft: 10}}>
                <Placeholder.Graph height={12} width={40} active /> <br/>
                <Placeholder.Graph height={35} width={160} style={{marginTop: '-2px', borderRadius: 4}} active />
            </div>
        </div>

        <div className='mt-4'>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={200} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={400} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={200} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={400} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={200} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={400} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={200} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
            <div className='d-flex mt-4'>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={40} style={{marginLeft: 10, borderRadius: 4}} active /> <br/>
                <Placeholder.Graph height={15} width={400} style={{marginLeft: 10, borderRadius: 4}} active />
            </div>
        </div>
    </Box>
  )
}

export default Loading;
