import { Avatar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useContext, useEffect, useState } from 'react';
import { FaWpforms } from 'react-icons/fa';
import { Button, InputPicker, Loader, Modal } from 'rsuite';
import { useComando } from '../../hooks/Comando.hook';
import { Comando, ComandoRequest } from '../../interfaces/Comando.interface';
import { Equipamento } from '../../interfaces/Equipamento.interface';
import { StoreContext } from '../../stores';
import CustomInputNumber from '../inputNumber';

interface PropsModal {
    show: boolean;
    hide: () => void;
}

const AtualizarComando: FC<PropsModal> = ({ show, hide }) => {
    
    const { comandoStore, acionamentoStore, zktecoStore, equipamentoStore, guaritaStore, releStore } = useContext(StoreContext);
    const { atualizar, atualizando, salvando } = useComando();
    const [comandoGuarita, setComandoGuarita] = useState<ComandoRequest>({});
    const [comandoEquipamento, setComandoEquipamento] = useState<ComandoRequest>({});
    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState<Equipamento>();
    const [mode, setMode] = useState('');
    const [mode2, setMode2] = useState('');
    const [tentativas, setTentativas] = useState(3);



    const handleMinus = () => {
        if (tentativas > 1) {
            setTentativas(tentativas - 1);
        }
    };

    const handlePlus = () => {
        if (tentativas < 3) {
            setTentativas(tentativas + 1);
        }
    };

    const handleChange = (newValue: string | number | null) => {
        if (typeof newValue === 'number') {
            setTentativas(newValue);
        } else if (typeof newValue === 'string') {
            const parsedValue = parseFloat(newValue);
            if (!isNaN(parsedValue)) {
                setTentativas(parsedValue);
            }
        }
    };

    const salvar = () => {
        if (comandoStore.selecionado) {
            const payload = {
                ...comandoStore.selecionado,
                ...(mode === 'equipamento' ? comandoEquipamento : comandoGuarita),
                tentativas 
            };
    
            if (mode2 === 'equipamento' && equipamentoSelecionado) {
                payload.tipo = equipamentoSelecionado.tipo;
            }
    
            switch (mode2) {
                case 'nice':
                case 'guarita':
                case 'equipamento':
                    // comandoEquipamento.tipo = equipamentoSelecionado?.tipo
                    atualizar(comandoEquipamento as Comando, acionamentoStore.selecionado!.id, hide);
                    break;
                case 'zktecoControladora':
                    atualizar(comandoEquipamento as Comando, acionamentoStore.selecionado!.id, hide);
                    break;
                case 'zktecoStandAlone':
                    atualizar(comandoEquipamento as Comando, acionamentoStore.selecionado!.id, hide);
                    break;
            }
        }
    }

    useEffect(() => {
        if (comandoStore.selecionado && show) {
            equipamentoStore.listarEquipamentosBiometriaPorCondominio(comandoStore.selecionado.condominioId);
    
            const temEquipamento = equipamentoStore.equipamentosBiometria.find(
                (eq) => eq.equipamentoId === comandoStore.selecionado?.caminhoId
            );
    
            switch (comandoStore.selecionado.tipo) {
                case 16:
                    setMode('zktecoControladora');
                    setComandoEquipamento(comandoStore.selecionado);
                    break;
                case 17:
                    setMode('zktecoStandAlone');
                    setComandoEquipamento(comandoStore.selecionado);
                    break;
                default:
                    if (temEquipamento) {
                        setMode('equipamento');
                        setComandoEquipamento(comandoStore.selecionado);
                    } else {
                        setMode('guarita');
                        setComandoGuarita(comandoStore.selecionado);
                    }
                    break;
            }
        }
    }, [comandoStore.selecionado, show, equipamentoStore.equipamentosBiometria]);

    if (atualizando) {
        return (
            <div>
                ATUALIZANDO...
            </div>
        );
    }

    const renderModulosGuaritas = () => {
        return (
            <div>
                <p></p>
                <div className='d-flesx justify-content-center mt-3'>
                    <div>
                        <div className={'m-2'}>
                            <p>Tipo do comando</p>
                            <InputPicker value={comandoGuarita.tipo} data={
                                [
                                    {
                                        label: 'GUARITA DIRETA',
                                        value: 0
                                    },
                                    {
                                        label: 'GUARITA SERVIDOR',
                                        value: 1
                                    }
                                ]
                            } block onChange={(e) => {
                                setComandoGuarita({ ...comandoGuarita, tipo: e });
                            }} />
                            <p style={{ margin: 2 }}>Como você deseja atualizar comando?</p>
                            <InputPicker placeholder={'Selecione o modo'} value={mode2} data={[{ label: 'Nice', value: 'nice' }, { label: 'Siam', value: 'siam' }, { label: 'Bravas', value: 'bravas' }, { label: 'ControlId/Anviz/Hikvision/Intelbras', value: 'equipamento' }, { label: 'Mip1000', value: 'mip1000' }, { label: 'Zkteco', value: 'zkteco' }, { label: 'Automação', value: 'automacao' }]} onChange={(e) => setMode2(e)} style={{ width: "100%" }} />
                            <CustomInputNumber
                                value={tentativas}
                                handleMinus={handleMinus}
                                handlePlus={handlePlus}
                                handleChange={handleChange}
                                min={1}
                                max={3}
                            />
                        </div>

                        <div className='d-flex justify-content-end'>
                            <Button
                                onClick={() => salvar()}
                            >
                                {false ? <Loader content="Salvando..." /> : 'Salvar'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderEquipamentosCadastrados = () => {
        return (
            <div className=''>
                <div className={'m-2'}>
                    <p>Selecione o equipamento</p>
                    <InputPicker value={comandoEquipamento.caminhoId || ""} data={equipamentoStore.equipamentosBiometria.map((eq) => ({ label: `${eq.nome} - ${eq.tipoNome}`, value: eq.equipamentoId }))} block onChange={(e) => {
                        var selecionado = equipamentoStore.equipamentosBiometria.find((equipamento) => equipamento.equipamentoId === e);
                        if (selecionado) setEquipamentoSelecionado(selecionado);
                        setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
                    }} />

                </div>

                <div className='d-flex justify-content-end'>
                    <Button
                        onClick={() => salvar()}
                    >
                        {false ? <Loader content="Salvando..." /> : 'Salvar'}
                    </Button>
                </div>
            </div>
        )
    }

    const renderZktecoControladora = () => {
        return (
            <div className=''>
                <div className={'m-2'}>
                    <p>Selecione o equipamento ZKteco</p>
                    <InputPicker placeholder={"Selecione o Equipamento"} value={comandoEquipamento.caminhoId || ""} data={equipamentoStore.zktecoInbio.map((b) => ({ label: `${b.nome} - ${b.ip}`, value: b.id }))} block onChange={(e) => {
                        zktecoStore.listarRelePorZkteco(e); 
                        setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
                    }} />
                </div>
                <div className={'m-2'}>
                    <p>Selecione o relê</p>
                    <InputPicker disabled={zktecoStore.releZkteco.length === 0} placeholder={'Selecione o relê'} data={zktecoStore.releZkteco.map((r) => ({ label: `${r.nome}`, value: r.id }))} block onChange={(e) => {
                        setComandoEquipamento({ ...comandoEquipamento, rele: e });
                    }} />
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='d-flex'>
                        <Button
                            appearance='primary'
                            color={'violet'}
                            disabled={salvando || !comandoEquipamento.caminhoId || !comandoEquipamento.rele }
                            onClick={() => salvar()}
                        >
                            {salvando ? <Loader content="Salvando..." /> : 'Salvar'}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const renderZktecoStandAlone = () => {
        return (
            <div className=''>
                <div className={'m-2'}>
                    <p>Selecione o equipamento ZKteco</p>
                    <InputPicker value={comandoEquipamento.caminhoId || ""} data={equipamentoStore.zktecoStandAlone.map((b) => ({ label: `${b.nome} - ${b.ip}`, value: b.id }))} block onChange={(e) => {
                        setComandoEquipamento({ ...comandoEquipamento, caminhoId: e }); 
                    }} />
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='d-flex'>
                        <Button
                            appearance='primary'
                            color={'violet'}
                            disabled={salvando || !comandoEquipamento.caminhoId }
                            onClick={() => salvar()}
                        >
                            {salvando ? <Loader content="Salvando..." /> : 'Salvar'}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const renderRoutes = () => {
        switch (mode) {
            case 'guarita':
                return renderModulosGuaritas();
            case 'equipamento':
                return renderEquipamentosCadastrados();
            case 'zktecoControladora':
                return renderZktecoControladora();
            case 'zktecoStandAlone':
                return renderZktecoStandAlone();
        }
        return(
            <div>
                CARREGANDO...
            </div>
        )
    }

    return (
        <Modal open={show} onClose={hide} backdropStyle={{ background: 'rgba(0,0,0,0.9)' }} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title className='d-flex justify-content-center'>
                    <Avatar variant="rounded" style={{ background: '#663b5f', marginRight: 10 }}>
                        <FaWpforms />
                    </Avatar>
                    <p>Atualizar comando</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderRoutes()}</Modal.Body>
        </Modal>
    );
};

export default observer(AtualizarComando);