import api from "../services/api/api";
import apiZkControladora from "../services/zktecoInbio/api";
import { Zkteco, ZktecoRele } from "../interfaces/Zkteco.interface";
import apiZkStandAlone from "../services/zktecoStandAlone/api";

class ZktecoAPI {

    listarPorCondominio = async (condominioId: string) => await api.get<Zkteco[]>(`/Zkteco/condominio/${condominioId}`);

    adicionar = async (zkteco: Zkteco) => await api.post<Zkteco>(`/Zkteco`, zkteco);

    atualizar = async (request: Zkteco) => await api.put<boolean>(`/Zkteco`, request);

    deletar = async (id: string) => await api.delete<boolean>(`/Zkteco/${id}`);

    listarRelePorZktecoId = async (condominioId: string) => await api.get<ZktecoRele[]>(`/ZktecoRele/zktecoId?zktecoId=${condominioId}`);

    obterRelePorId = async (condominioId: string) => await api.get<ZktecoRele>(`/ZktecoReles/${condominioId}`);
    
    adicionarRele = async (request: ZktecoRele) => await api.post<ZktecoRele>(`/ZktecoRele`, request);

    atualizarRele = async (request: ZktecoRele) => await api.put<boolean>(`/ZktecoRele`, request);

    deletarRele = async (id: string) => await api.delete<boolean>(`/ZktecoRele/${id}`);

    capturarSerial = async (equipamentoId: string) => await apiZkControladora.get(`/zktecopull/ler-dispositivo?equipamentoId=${equipamentoId}`);

    reiniciarEquipamentoInbio = async (equipamentoId: string) => await apiZkControladora.get(`/zktecopull/reiniciar/${equipamentoId}`)

    reiniciarEquipamentoStandAlone = async (equipamentoId: string) => await apiZkStandAlone.get(`/zktecopull/reiniciar/${equipamentoId}`)

}

export default new ZktecoAPI();