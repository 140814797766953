import { useState } from "react";
import DispositivoAPI from "../api/DispositivoAPI";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface UploadStatus {
  [id: string]: "success" | "error" | "loading" | null;
}

const useBiometricUpload = () => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [id: string]: string | null }>({});

  const resizeImage = (
    file: File,
    width: number,
    height: number
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          if (!ctx) {
            reject("Erro ao obter o contexto do canvas.");
            return;
          }

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // Retorna a imagem redimensionada como base64
        };

        img.onerror = (err) => reject(err);

        if (reader.result) {
          img.src = reader.result.toString();
        }
      };

      reader.onerror = (err) => reject(err);

      reader.readAsDataURL(file);
    });
  };

  const uploadBiometric = async (
    moradorId: string,
    file: File
  ): Promise<void> => {
    setLoading(true);
    setUploadStatus((prev) => ({ ...prev, [moradorId]: "loading" }));
    setErrors((prev) => ({ ...prev, [moradorId]: null }));
    try {
      const base64String = await resizeImage(file, 500, 600); // Redimensiona a imagem para 500x600
      const foto = base64String.split(",")[1];

      await DispositivoAPI.sendBiometric({
        moradorId,
        foto: foto || "",
      });

      setUploadStatus((prev) => ({ ...prev, [moradorId]: "success" }));
      toast.success('Biometria facial cadastrada com sucesso');
      
    } catch (err: AxiosError | any) {
      const errorResponse = err.response?.data;
      const notifications =
        errorResponse?.erros?.map((erro: any) => erro.notificacao) || [];

      setUploadStatus((prev) => ({ ...prev, [moradorId]: "error" }));
      setErrors((prev) => ({
        ...prev,
        [moradorId]:
          notifications.length > 0 ? notifications : ["Erro desconhecido"],
      }));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const resetUploadStatus = (moradorId: string) => {
    setUploadStatus((prev) => ({ ...prev, [moradorId]: null }));
    setErrors((prev) => ({ ...prev, [moradorId]: null }));
  };

  return { uploadBiometric, uploadStatus, resetUploadStatus, errors, loading };
};

export default useBiometricUpload;
