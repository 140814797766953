import React, { useContext, useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import Navegacao from "../../components/navegacao/Navegacao";
import Header from "../../components/layouts/Header";
import Body from "../../components/body/Body";
import { observer } from "mobx-react-lite";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { StoreContext } from "../../stores";
import {
  TransitionDiv,
  Row,
  Box,
  Footer,
  Scroll,
  ContainerLoading,
} from "./styles/Dashboard.style";
import logo from "../../assets/img/logo-negative.png";
import { CircularProgress, Skeleton } from "@mui/material";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { FaUser } from "react-icons/fa";
import ViewsAuthorizeIcon from "@rsuite/icons/ViewsAuthorize";
import DashboardIcon from "@rsuite/icons/Dashboard";
import PushMessageIcon from "@rsuite/icons/PushMessage";
import PeoplesIcon from "@rsuite/icons/Peoples";
import PageEndIcon from "@rsuite/icons/PageEnd";
import PageTopIcon from "@rsuite/icons/PageTop";
import UserBadgeIcon from "@rsuite/icons/UserBadge";
import BarLineChartIcon from "@rsuite/icons/BarLineChart";
import SpeakerIcon from "@rsuite/icons/Speaker";
import StorageIcon from "@rsuite/icons/Storage";
import AbTestIcon from "@rsuite/icons/AbTest";
import OffIcon from "@rsuite/icons/Off";
import Loading from "../../components/utils/Loading";
import GearIcon from "@rsuite/icons/Gear";
import PageIcon from "@rsuite/icons/Page";
import { motion } from "framer-motion";
import {
  BsCameraVideo,
  BsCollectionPlay,
  BsDoorOpen,
  BsFiles,
  BsMenuDown,
  BsNewspaper,
  BsPeople,
  BsPhoneVibrate,
} from "react-icons/bs";
import { AiOutlineCamera, AiOutlineCar } from "react-icons/ai";
import { FaRegAddressCard } from "react-icons/fa";
import { TbArrowAutofitContent, TbLockOpen } from "react-icons/tb";
import { VscGithubAction } from "react-icons/vsc";
import { HiPhoneOutgoing } from "react-icons/hi";
import { IoMdWifi } from "react-icons/io";
import { RxReload } from "react-icons/rx";
import { BiUserPin } from "react-icons/bi";
import { BiCar } from "react-icons/bi";

interface Page {
  name: string;
  icon: any;
}

interface NavbarProps {
  navigation: {
    iniciotitle: string;
    icondashboard: any;
    dashboard: string;
    iconcondominios: any;
    condominios: string;
    unidadeinfotitle: string;
    blocounidadeicon: any;
    blocounidadetitle: string;
    perfilicon: any;
    perfiltitle: string;
    unidadeicon: any;
    unidadetitle: string;
    notasicon: any;
    notastitle: string;
    relatorioicon: any;
    relatoriotitle: string;
    configuracaotititle: string;
    operacaotiicon: any;
    operacaotititle: string;
    editarcondominioicon: any;
    editarcondominiotitle: string;
    sistemainfotitle: string;
    sistemaicon: any;
    sistematitle: string;
    avisotitle: string;
    avisoicon: any;
  };
}

const Dashboard: React.FC<NavbarProps> = ({ navigation }) => {
  const {
    blocoStore,
    unidadeStore,
    guaritaStore,
    acionamentoStore,
    sipStore,
    ramalStore,
    layoutStore,
    veiculoStore,
    condominioStore,
    equipamentoStore,
    usuarioStore,
    authStore,
    redeStore,
    notaStore,
    bravasStore,
    siamStore,

    themeStore,
    moradorStore,
    acionamentoPerfilStore,
  } = useContext(StoreContext);

  const { loggout } = authStore;

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const [activePage, setActivePage] = useState(0);
  const [route, setRoute] = useState("dashboard");
  const [subRoute, setSubRoute] = useState("");

  let pagesUnidades = [
    {
      icon: <BsPeople />,
      page: "Morador",
    },
    // {
    //   icon: <BsPeople />,
    //   page: "Moradores excluídos",
    // },
    {
      icon: <AiOutlineCar />,
      page: "Veiculos",
    },
    {
      icon: <FaRegAddressCard />,
      page: "Autorizações",
    },
    {
      icon: <BsFiles />,
      page: "Relatórios Dispositivos",
    },
  ];

  let pagesRelatorios = [
    {
      label: "Moradores/Veículos/Visitantes",
      icon: <BsPeople />,
      page: "Relatório de moradores",
    },
    {
      icon: <BiUserPin />,
      page: "Relatório de visitantes",
    },
    {
      icon: <BiCar />,
      page: "Relatório de veículos",
    },
    {
      icon: <BsNewspaper />,
      page: "Relatório de eventos",
    },
  ];

  let pagesTI = [
    {
      label: "CONFIGURAÇÕES DE IMAGENS",
      icon: <BsCameraVideo />,
      page: "Layout",
    },
    {
      icon: <AiOutlineCamera />,
      page: "Câmeras",
    },

    {
      icon: <BsCollectionPlay />,
      page: "Perfis de câmeras",
    },

    {
      icon: <BsCameraVideo />,
      page: "Configurações octos",
    },
    {
      label: "CONFIGURAÇÕES DE ACESSOS",
      icon: <BsMenuDown />,
      page: "Guaritas",
    },
    {
      icon: <VscGithubAction />,
      page: "Equipamentos",
    },
    {
      icon: <BsDoorOpen />,
      page: "Acionamentos",
    },
    {
      icon: <TbArrowAutofitContent />,
      page: "Automações",
    },
    {
      label: "CONFIGURAÇÕES DE CHAMADAS",
      icon: <BsPhoneVibrate />,
      page: "Dispositivo SIP",
    },
    {
      icon: <HiPhoneOutgoing />,
      page: "Ramal",
    },
    {
      label: "CONFIGURAÇÕES DE REDE",
      icon: <IoMdWifi />,
      page: "Rede",
    },
    {
      label: "CONFIGURAÇÕES DE SINCRONIZAÇÃO",
      icon: <RxReload />,
      page: "Sincronização",
    },
  ];

  let condominio = localStorage.getItem("condominio");
  const history = useNavigate();

  const sair = () => {
    themeStore.theme = "light";
    history("/");
    loggout();
  };

  useEffect(() => {
    if (localStorage.getItem("@lastedroute"))
      setRoute(localStorage.getItem("@lastedroute") ?? "");
  }, [localStorage.getItem("@lastedroute")]);

  useEffect(() => {
    if (
      localStorage.getItem("@lastedsubroute") === null &&
      localStorage.getItem("@lastedroute") === "unidades"
    ) {
      setSubRoute("morador");
    } else if (
      localStorage.getItem("@lastedsubroute") === null &&
      localStorage.getItem("@lastedroute") === "operacao ti"
    ) {
      setSubRoute("layout");
    } else if (
      localStorage.getItem("@lastedsubroute") === null &&
      localStorage.getItem("@lastedroute") === "relatorio"
    ) {
      setSubRoute("relatório de moradores");
    } else {
      setSubRoute(localStorage.getItem("@lastedsubroute") ?? "");
    }
  }, [route]);

  const handlePageClick = (pageIndex: number, page: string) => {
    setActivePage(pageIndex);
    setRoute(page);
    setSubRoute(page);
    localStorage.setItem("@lastedroute", page);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      condominio,
      unidadeStore.listarPorCondominioId(),
      guaritaStore.listar(),
      guaritaStore.listarModuloPorCondominio(),
      siamStore.listar(),
      acionamentoStore.listar(),
      sipStore.listar(),
      layoutStore.listar(),
      acionamentoPerfilStore.listar(),
      equipamentoStore.listarEquipamentos(),
    ]).then(() => {
      setLoading(false);
      setState((state) => !state);
    });
  }, []);

  useEffect(() => {
    if (condominioStore.selecionado) {
      unidadeStore.selecionado = null;
    }
  }, [condominioStore.selecionado]);

  if (condominio === null) {
    history("/condominios");
  }

  const load = () => {
    if (loading) {
      return (
        <ContainerLoading className="d-flex justify-content-center">
          <Loading message="Carregando informações do condomínio" />
        </ContainerLoading>
      );
    }
  };

  const renderNavBar = () => {
    switch (route.toLocaleLowerCase().replace(/[^\w\s]/gi, "")) {
      case "unidades":
        return (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.3 }}
            className="second-screen"
          >
            <ul className="m-2">
              {pagesUnidades.map((page, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setSubRoute(page.page);
                    localStorage.setItem("@lastedsubroute", page.page);
                  }}
                  className={
                    subRoute.toLocaleLowerCase() ===
                    page.page.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }
                >
                  <Link
                    to={page.page
                      .toLocaleLowerCase()
                      .replace(" ", "")
                      .replace(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .replace("moradores", "morador")
                      .replace("veiculos", "veiculo")
                      .replace("autorizacoes", "autorizacao")
                      .replace("relatoriosdispositivos", "unidaderelatorio")}
                  >
                    <span className="page-icon">{page.icon}</span>
                    <span className="page-title">{page.page}</span>
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  to={"/condominios/dashboard/"}
                  onClick={() => {
                    setRoute("/");
                    localStorage.setItem("@lastedroute", "dashboard");
                    localStorage.removeItem("@lastedsubroute");
                  }}
                >
                  <span className="page-icon"></span>
                  <span className="page-title">Voltar</span>
                </Link>
              </li>
            </ul>
          </motion.div>
        );

      case "operacao ti":
        return (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.3 }}
            className="second-screen"
          >
            <ul className="m-2">
              {pagesTI.map((page, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setSubRoute(page.page);
                    // handlePageClick(0, 'perfil')
                    localStorage.setItem("@lastedsubroute", page.page);
                  }}
                  className={
                    subRoute.toLocaleLowerCase() ===
                    page.page.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }
                >
                  <p
                    style={{
                      fontWeight: 550,
                      fontSize: "8pt",
                      marginBottom: page.label ? "5px" : "",
                      marginTop: page.label ? "5px" : "",
                    }}
                  >
                    {page.label}
                  </p>
                  <Link
                    to={page.page
                      .toLocaleLowerCase()
                      .replace(" ", "")
                      .replace(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .replace(
                        "perfisdecameras",
                        "/condominios/dashboard/cameras/perfis"
                      )
                      .replace("acionamentos", "acionamento")
                      .replace("Dispositivo SIP", "dispositivoSIP")
                      .replace("configuracoesoctos", "octos")}
                  >
                    <span className="page-icon">{page.icon}</span>
                    <span className="page-title">{page.page}</span>
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  to={"/condominios/dashboard/"}
                  onClick={() => {
                    setRoute("/");
                    localStorage.setItem("@lastedroute", "dashboard");
                    localStorage.removeItem("@lastedsubroute");
                  }}
                  style={{ marginBottom: 70 }}
                >
                  <span className="page-icon"></span>
                  <span className="page-title">Voltar</span>
                </Link>
              </li>
            </ul>
          </motion.div>
        );

      case "relatorio":
        return (
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ duration: 0.3 }}
            className="second-screen"
          >
            <ul className="m-2">
              {pagesRelatorios.map((page, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setSubRoute(page.page);
                    localStorage.setItem("@lastedsubroute", page.page);
                  }}
                  className={
                    subRoute?.toLocaleLowerCase() ===
                    page.page.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }
                >
                  <p
                    style={{
                      fontWeight: 550,
                      fontSize: "8pt",
                      marginBottom: page.label ? "5px" : "",
                      marginTop: page.label ? "5px" : "",
                    }}
                  >
                    {page.label}
                  </p>
                  <Link
                    onClick={() => {
                      // if(subRoute?.includes('eventos'))
                      // {
                      //     return window.open('http://localhost:3000/#/relatorios/eventos', '_blank');
                      // }
                    }}
                    to={page.page
                      .toLocaleLowerCase()
                      .replace(" ", "")
                      .replace(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .replace(
                        "relatoriodemoradores",
                        "/condominios/dashboard/relatorio/moradoresgerais"
                      )
                      .replace(
                        "relatoriodevisitantes",
                        "/condominios/dashboard/relatorio/visitantesgerais"
                      )
                      .replace(
                        "relatoriodeveiculos",
                        "/condominios/dashboard/relatorio/veiculosgerais"
                      )
                      .replace(
                        "relatoriodeeventos",
                        "/condominios/dashboard/relatorio/eventos"
                      )}
                  >
                    <span className="page-icon">{page.icon}</span>
                    <span className="page-title">{page.page}</span>
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  to={"/condominios/dashboard/"}
                  onClick={() => {
                    setRoute("/");
                    localStorage.setItem("@lastedroute", "dashboard");
                    localStorage.removeItem("@lastedsubroute");
                  }}
                  style={{ marginBottom: 70 }}
                >
                  <span className="page-icon"></span>
                  <span className="page-title">Voltar</span>
                </Link>
              </li>
            </ul>
          </motion.div>
        );
    }

    return (
      <motion.ul
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        exit={{ x: "0%" }}
        transition={{ duration: 0.3 }}
        className="p-2"
      >
        <p className="title-info">VOLTAR PARA LISTA DE CONDOMÍNIOS</p>
        <li
          className={route === "condominios" ? "active" : ""}
          onClick={() => {
            handlePageClick(0, "condominios");
            localStorage.removeItem("@lastedroute");
          }}
        >
          <Link to={"/condominios/"}>
            <span className="page-icon">{navigation.iconcondominios}</span>
            <span className="page-title">{navigation.condominios}</span>
          </Link>
        </li>
        <p className="title-info">{navigation.iniciotitle}</p>
        <li
          className={route === "dashboard" ? "active" : ""}
          onClick={() => handlePageClick(0, "dashboard")}
        >
          <Link
            to={navigation.dashboard
              .toLocaleLowerCase()
              .replace("dashboard", "")}
          >
            <span className="page-icon">{navigation.icondashboard}</span>
            <span className="page-title">{navigation.dashboard}</span>
          </Link>
        </li>
        <p className="title-info">{navigation.unidadeinfotitle}</p>
        <li
          className={route === "bloco" ? "active" : ""}
          onClick={() => handlePageClick(0, "bloco")}
        >
          <Link
            to={navigation.blocounidadetitle
              .toLocaleLowerCase()
              .replace(" ", "")
              .replace(" ", "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}
          >
            <span className="page-icon">{navigation.blocounidadeicon}</span>
            <span className="page-title">{navigation.blocounidadetitle}</span>
          </Link>
        </li>
        <li
          className={route === "perfil" ? "active" : ""}
          onClick={() => handlePageClick(0, "perfil")}
        >
          <Link
            to={navigation.perfiltitle
              .toLocaleLowerCase()
              .replace(" ", "")
              .replace(" ", "")
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}
          >
            <span className="page-icon">{navigation.perfilicon}</span>
            <span className="page-title">{navigation.perfiltitle}</span>
          </Link>
        </li>
        <li
          className={route === "unidades" ? "active" : ""}
          onClick={() => handlePageClick(0, "unidades")}
        >
          <Link to={`/condominios/dashboard/morador`}>
            <span className="page-icon">{navigation.unidadeicon}</span>
            <span className="page-title">{navigation.unidadetitle}</span>
          </Link>
        </li>
        <li
          className={route === "notas" ? "active" : ""}
          onClick={() => handlePageClick(0, "notas")}
        >
          <Link to={`/condominios/dashboard/notas`}>
            <span className="page-icon">{navigation.notasicon}</span>
            <span className="page-title">{navigation.notastitle}</span>
          </Link>
        </li>
        <li
          className={route === "relatoriodetodos os moradores" ? "active" : ""}
          onClick={() => handlePageClick(0, "relatorio")}
        >
          <Link to={`/condominios/dashboard/relatorio/moradoresgerais`}>
            <span className="page-icon">{navigation.relatorioicon}</span>
            <span className="page-title">{navigation.relatoriotitle}</span>
          </Link>
        </li>

        <p className="title-info">{navigation.configuracaotititle}</p>
        <li
          className={route === "layout" ? "active" : ""}
          onClick={() => handlePageClick(0, "operacao ti")}
        >
          <Link to={`/condominios/dashboard/layout`}>
            <span className="page-icon">{navigation.operacaotiicon}</span>
            <span className="page-title">{navigation.operacaotititle}</span>
          </Link>
        </li>
        <li
          className={route === "editar condominio" ? "active" : ""}
          onClick={() => handlePageClick(0, "editar condominio")}
        >
          <Link to={`/condominios/dashboard/configuracao`}>
            <span className="page-icon">{navigation.editarcondominioicon}</span>
            <span className="page-title">
              {navigation.editarcondominiotitle}
            </span>
          </Link>
        </li>
        <li
          className={route === "aviso" ? "active" : ""}
          onClick={() => handlePageClick(0, "Avisos")}
        >
          <Link to={`/condominios/dashboard/aviso`}>
            <span className="page-icon">{navigation.avisoicon}</span>
            <span className="page-title">{navigation.avisotitle}</span>
          </Link>
        </li>

        <p className="title-info">{navigation.sistemainfotitle}</p>

        <li
          className={route === "layout" ? "active" : ""}
          onClick={() => sair()}
        >
          <Link to={"/login"}>
            <span className="page-icon">{navigation.sistemaicon}</span>
            <span className="page-title">{navigation.sistematitle}</span>
          </Link>
        </li>
      </motion.ul>
    );
  };

  return (
    <Box>
      <Container
        fluid
        className="animation-dashboard p-0"
        style={{ height: "100vh" }}
      >
        {load()}
        <div className={"d-flex"}>
          <Scroll active={expanded}>
            <div className="d-flexs p-3">
              <div className="d-flex">
                <p className="title">{condominioStore.selecionado?.nome}</p>
              </div>
              <div>
                <p className="subtitle mt-1">
                  {condominioStore.selecionado?.endereco}
                </p>
                <p className="subtitle">Seja bem vindo!</p>
              </div>
            </div>
            {renderNavBar()}
          </Scroll>
          <Body />
        </div>
      </Container>
    </Box>
  );
};

export default observer(Dashboard);
