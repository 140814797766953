import { runInAction, makeAutoObservable } from 'mobx';
import CondominioAPI from "../api/CondominioAPI";
import { AppIdentificacao, Condominio, CondominioAuxiliar } from "../interfaces/Condominio.interface";
import AppIdentificacaoAPI from '../api/AppIdentificacaoAPI';

class CondominioStore {

    condominios: Condominio[] = [];
    appIdentificacao: AppIdentificacao[] = [];
    condominiosAuxiliar: CondominioAuxiliar | null = null;
    selecionado: Condominio | null = null;
    centrais: Condominio[] = [];
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
        this.definirSelecionado = this.definirSelecionado.bind(this)
    }

    async listar() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            const request = await CondominioAPI.listar();
            runInAction(() => {
                this.condominios = request.data;
                if(condominioAtual !== '') {
                    let selecionado = this.condominios.find((con) => con.id === condominioAtual);
                    if(selecionado) {
                        this.selecionado = selecionado;
                        setTimeout(() =>
                        {
                            this.loading = false;
                        }, 1000);
                    }
                } else {
                    this.selecionado = null;
                    this.loading = false;
                }
            });
        } catch(e) {
            this.loading = false;
            //    
        }
    }

    async listarCondominioAuxiliar() {
        this.loading = true;
        try {
            let condominioAtual = localStorage.getItem('condominio');
            if(condominioAtual)
            {
                const request = await CondominioAPI.listarCondominioAuxiliar(condominioAtual);
                runInAction(() => {
                    this.condominiosAuxiliar = request.data;
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
            //    
        }
    }

    async listarAppIdentificacao() {
        this.loading = true;
        try {
            const request = await AppIdentificacaoAPI.obterTodos();
                runInAction(() => {
                    this.appIdentificacao = request.data;
                    setTimeout(() =>
                    {
                        this.loading = false;
                    }, 1000);
                });
        } catch(e) {
            this.loading = false;
            //    
        }
    }

    async definirSelecionado(condominioId: string) {
        let selecionado = this.condominios.find((con) => con.id === condominioId);
        if(selecionado) {
            this.selecionado = selecionado;
            localStorage.setItem('condominio', condominioId);
        } else
        {
            this.selecionado = null
        }
    }

    async listarCentrais() {
        const { data } = await CondominioAPI.centrais();
        runInAction(() => {
            this.centrais = data
        })
    }

}

export default CondominioStore;