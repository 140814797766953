import { readFile } from "fs";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import BlocoAPI from "../api/BlocoAPI";
import ReleAPI from "../api/ReleAPI";
import { StoreContext } from "../stores";
import { Bloco } from "../interfaces/Bloco.interface";
import { Rele } from "../interfaces/Rele.interface";


export const useRele = () => {

    const { releStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const cadastrar = async (rele: Rele, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await ReleAPI.cadastrarRele(rele);
            if(status === 200) {
                releStore.reles.push(data);
                setSalvando(false);
                hide();
                setSuccess(true);
                toast.success('Rele cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar rele')
        }
    }

    const atualizar = async (rele: Rele, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await ReleAPI.atualizarRele(rele);
            if(status === 200) {
                releStore.reles = releStore.reles.map(rel => rel.id === rele.id ? rele : rel);
                setAtualizando(false);
                hide();
                setSuccess(true);
                toast.success('Rele atualizado com sucesso!')
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar rele')
        }
    }

    const deletar = async (rele: Rele) => {
        setDeletando(true);
        try {
            const { status } = await ReleAPI.deletarRele(rele.id);
            if(status === 200) {
                releStore.reles = releStore.reles.filter(rel => rel.id !== rele.id);
                setDeletando(false);
                toast.success('Rele deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar rele')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        success,
    }

}