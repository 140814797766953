import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';
import { useBloco } from '../../hooks/Bloco.hook';
import { useSiam } from '../../hooks/Siam.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { Controladora, ControladoraRequest, Siam, SiamRequest } from '../../interfaces/Siam.interface';
import { StoreContext } from '../../stores';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarSiam: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<ControladoraRequest>({});
    const { atualizarControladora, atualizando } = useSiam();
    const { condominioStore, siamStore } = useContext(StoreContext);

    useEffect(() =>
    {
        if(siamStore.controladoraSelecionado) 
        {
            setForm(siamStore.controladoraSelecionado);
            siamStore.listarDispositivoPorSiamId(siamStore.controladoraSelecionado.siamId);
        };
    }, [siamStore.controladoraSelecionado])

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizarControladora(form as Controladora, hide);
    }

    
    const renderMenu = (dispositivos : any) => {
        if (siamStore.loading) {
        return (
            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
            <Loader content={'Buscando dispositivos... Aguarde!'} />
            </p>
        );
        }
        return dispositivos;
    };

    const FormSiam = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Equipamento</Form.Label>
                        <SelectPicker
                            block
                            data={siamStore.siam.map((s) => ({ label: s.nome, value: s.id }))}
                            placeholder={'Selecione o equipamento siam'}
                            value={form.siamId}
                            onChange={(e) =>
                            {
                                if(e) siamStore.definirSelecionado(e);
                            }}
                        />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Dispositivo</Form.Label>
                        <SelectPicker
                            block
                            data={siamStore.dispositivos.map((d) => ({ label: d.nome, value: d.id}))}
                            placeholder={'Selecione o dispositivo'}
                            value={form.dispositivoId}
                            onOpen={() =>
                                {
                                  if(siamStore.selecionado)
                                  {
                                    siamStore.listarDispositivoPorSiamId(siamStore.selecionado.id);
                                  }
                                }}
                            renderMenu={renderMenu}
                            onChange={(e) =>
                            {
                                var selecionado = siamStore.dispositivos.find(x => x.id == e);
                                if(selecionado)
                                {
                                    setForm({ ...form, nome: selecionado.nome, dispositivoId: selecionado.id});
                                }
                                else
                                {
                                    alert('erro')
                                }
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" disabled className='shadow-none' value={form.nome || ''} placeholder="Nome do siam" onChange={(e) => setForm({...form, nome: e})}  />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button appearance="primary" disabled={atualizando} type="submit">
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={atualizando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {atualizando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar controladora</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormSiam()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarSiam);