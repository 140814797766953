import styled from "styled-components";
import PerfctScrollBar from "react-perfect-scrollbar";

export const Container = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    // width: 28vw;
    height: 100vh;
    overflow: auto;
    .header {
        margin-top: 10px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .icon {
        align-self: center;
    }
    .title {
        margin: 0;
        font-size: 13pt;
        font-weight: 550;
        color: ${(props) => props.theme.colors.text};
        align-self: center;
    }
    .subtitle {
        margin: 0;
        font-size: 9pt;
        color: ${(props) => props.theme.colors.text};
    }
    .icon-close {
        font-weight: 550;
        font-size: 25pt;
        align-self: center;
        transition: 0.2s;
        color: ${(props) => props.theme.colors.text};
    }
    .icon-close:hover {
        cursor: pointer;
        transition: 0.2s;
        transform: scale(1.0250);
    }
    .button-add {
        padding: 30px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        transition: 0.2s;
    }
    .button-add:hover {
        background: rgba(0,0,0,0.1);
        transition: 0.2s;
        cursor: pointer;
    }
    .icon-add {
        font-size: 15pt;
        margin-right: 10px;
        color: ${(props) => props.theme.colors.text};
        margin-top: 6px;
    }
    .title-add {
        margin: 0;
        font-size: 11pt;
        font-weight: 550;
        color: ${(props) => props.theme.colors.text};
        align-self: center;
    }
    .subtitle-add {
        margin: 0;
        font-size: 9pt;
        color: ${(props) => props.theme.colors.text};
    }
`;

export const ListControle = styled(PerfctScrollBar)`
    height: 18vh;
    padding: 20px;
        // background: #004C95;
        margin: 10px;
        transition: 0.2s;
        // border-radius: 12px;
        // box-shadow: 1px 3px 5px rgba(0,0,0,0.3);
    .content-serial:hover {
        transition: 0.2s;
        cursor: pointer;
    }
    .icon-serial {
        align-self: center;
    }
    .serial {
        font-size: 11pt;
        padding: 10px;
        margin: 0;
        align-self: center;
    }
    .icon-option {
        font-size: 12pt;
    }
    .content
    {
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    }
`;

export const TransitionDiv = styled.div`    
    color: ${(props) => props.theme.colors.text};
    width: 100%;
    .fade-enter .animation {
        opacity: 0;
        transform: translateX(0%);
    }
    .fade-enter-active .animation {
        opacity: 1;
        transform: translateX(0%);
    }
    .fade-exit .animation {
        opacity: 1;
        transform: translateX(0%);
    }
    .fade-exit-active .animation {
        opacity: 0;
        transform: translateX(30%);
    }
    .fade-enter-active .animation,
    .fade-exit-active .animation {
        transition: opacity 500ms, transform 500ms;
    }
    
    .input-serial
    {
        padding: 10px;
        background: ${(props) => props.theme.colors.bgColor};
        border-radius: 10px;
        text-align: center;
        font-size: 14pt;
    }
`
