import { Alert, Avatar, FormControlLabel, LinearProgress, Snackbar, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { BsPhone } from 'react-icons/bs';
import { FaWpforms } from 'react-icons/fa'
import MoradorAPI from '../../api/MoradorAPI';
import { StoreContext } from '../../stores';
import { useBloco } from '../../hooks/Bloco.hook';
import { useMorador } from '../../hooks/Morador.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { AssociarMorador, AssociarMoradorRequest, Morador, MoradorRequest } from '../../interfaces/Morador.interface';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import { Container } from './styles/AssociarMoradorCard.style';
import { SelectPicker, Modal, Button, Loader } from 'rsuite';
import { BiParagraph } from 'react-icons/bi';
import { IPerfil } from '../../interfaces/AcionamentoPerfil.interface';

interface ModalProps {
    show: boolean;
    hide: () => void;
    moradorSelecionado?: Morador | null;
}

interface ITopoMorador {
    descricao: string
    id: string 
  }


const CadastrarUsuarioCard: FC<ModalProps> = ({ show, hide }) => {

    const { moradorStore } = useContext(StoreContext);
    const { cadastrarUsuario, salvando } = useMorador();
    const [cadastrar, setCadastrar] = useState('');

    useEffect(() =>
    {
        if(show)
        {
            setCadastrar('');
        }
    }, [show]);

    const salvar = () => {
        if(moradorStore.selecionado) cadastrarUsuario(moradorStore.selecionado?.id, moradorStore.selecionado?.unidadeId, hide);
        // else alert('Selecione o morador');
    }

    return(
        <Modal backdrop={'static'} open={show} onClose={hide} size={'xs'}>
        <Modal.Header>
          <Modal.Title>Criai um novo usuário</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                <p>
                    Clique no botão "Sim" para criar um novo usuário para esse morador!
                </p>
        </Modal.Body>
        <Modal.Footer>
            <Button
                appearance='primary'
                color={'blue'}
                onClick={() => salvar()}
            >
                { salvando ? <Loader content="Criando um novo usuário..." />: 'Sim' }
            </Button>
            <Button style={{marginLeft: 10}} onClick={hide}>Não</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default observer(CadastrarUsuarioCard);