import styled from 'styled-components';
import PerfectScroll from 'react-perfect-scrollbar';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.bgColor};
  // padding: 20px;
`
export const Header = styled.div`
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  .title
  {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
  }
  .subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .box
  {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
  }

  .content
  {
    padding: 20px;
    margin-top: 10px;
    width: 250px;
  }
  .content-icon
  {
    align-self: center;
    font-size: 25pt;
    margin-right: 20px;
  }
  .content-title
  {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
  }
  .content-subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .info-title
  {
    font-size: 8pt;
    width: 80%;
  }
  .info-title-filtro
  {
    font-size: 8pt;
    width: 50%;
  }
`

export const CardValidation = styled.div`
  padding 40px;
  .app
  {
    height: 200px;
    margin-right: 20px;
  }
  .title-app
  {
    margin: 0;
    font-weight: 550;
    font-size: 13pt;
  }
  .subtitle-app
  {
    margin: 0;
    font-size: 9pt;
    width: 70%;
    margin-bottom: 10px;
  }
`;

export const CardMorador = styled(PerfectScroll)`
  .content {
    padding: 5px;
    background: transparent;
    // margin-top: 10px;
    // border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .header {
    // padding: 10px;
  }
  .search {
    width: 100%;
    font-size: 10pt;
    background: transparent;
    color: ${(props) => props.theme.colors.text};
    border: 0;
  }
  .icon-filter {
    margin: 0;
    align-self: center;
    margin-right: 5px;
    font-size: 15pt;
  }
  .title {
    margin: 0;
    font-size: 9pt;
    font-weight: 550;
    width: 100%;
  }
  .subtitle {
    margin: 0;
    font-size: 8pt;
    font-weight: 550;
    width: 11vw;
  }
  .btn-add {
    border: 0;
    padding: 15px;
    background: #004C95;
    font-size: 11pt;
    color: #fff;
    border-radius: 10px;
    // box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  }
  .icon {
    align-self: center;
    margin: 0;
  }
  .info {
    font-size: 9pt;
    margin-left: 20px;
    font-weight: 550;
    color: rgba(0,0,0,0.7);
    // width: 20%;
  }
`;


export const ListMorador = styled(PerfectScroll)`
  margin-top: 5px;
  align-self: center;
  border-radius: 10px;
  height: 70vh;
  // border-radius: 12px;
  margin-top: 8px;
  thead th {
    font-weight: 0;
  // background: ${(props) => props.theme.colors.bgBox};
  font-size: 13px;
    padding: 10px;
    // border-bottom: 1px solid ${(props) => props.theme.colors.bgNav};
  } 
  tbody td {
    font-size: 12px;
  border: 0;
  padding: 15px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`

export const TransitionDiv = styled.div`
  .fade-enter .animation {
    opacity: 0;
    // transform: translateX(-50%);
  }
  .fade-enter-active .animation {
    opacity: 1;
    // transform: translateX(0%);
  }
  .fade-exit .animation {
    opacity: 1;
    // transform: translateX(0%);
  }
  .fade-exit-active .animation {
    opacity: 0;
    // transform: translateX(50%);
  }
  .fade-enter-active .animation,
  .fade-exit-active .animation {
    transition: opacity 300ms, transform 300ms;
  }
`


export const ContainerFormMorador = styled.form`
&::-webkit-scrollbar {
  width: 10px;
  border: 1px solid black;
}


  margin: 10px;
  padding: 10px;
  .title
  {
      font-size: 12pt;
      font-weight: 600;
      margin: 0;
  }
  .subtitle
  {
      font-size: 9pt;
      margin: 0;
  }

  .header
  {
      background: ${(props) => props.theme.colors.bgBox};
      font-size: 10pt;
      font-weight: 600;
      border-radius:5px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
  }
`;

export const ContainerFormVisitante = styled.div`
  width: 100%;
`;

export const InforPessoal = styled.form`
  .title {
    font-size: 15pt;
    color: ${(props) => props.theme.colors.text};
    margin: 0;
    font-weight: 500;
  }
  .subtitle {
    font-size: 10pt;
    margin: 0;
    border-bottom: 1px solid rgba(0,0,0,0.0500);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .title-input {
    font-size: 10pt;
    font-weight: 500;
    margin: 0;
  }
  .input {
    border: 0;
    font-size: 11pt;
    background: rgba(255,255,255,0.0900);
    border-bottom: 1px solid rgba(0,0,0,0.0900);
    color: ${(props) => props.theme.colors.text};
  }

  .title-input-cpf {
    font-size: 10pt;
    color: ${(props) => props.theme.colors.text};
    font-weight: 500;
    margin: 0;
  }
  .title-input-nascimento {
    font-size: 10pt;
    font-weight: 500;
    margin: 0;
    color: ${(props) => props.theme.colors.text};
    margin-left: 10px;
  }
  .input-cpf {
    border: 0;
    font-size: 11pt;
    color: ${(props) => props.theme.colors.text};
    background: rgba(255,255,255,0.0900);
    border-bottom: 1px solid rgba(0,0,0,0.0900);
  }
  .input-nascimento {
    border: 0;
    font-size: 11pt;
    background: ${(props) => props.theme.colors.bgBox};
    color: ${(props) => props.theme.colors.text};
    border-bottom: 1px solid rgba(0,0,0,0.0900);
    margin-left: 10px;
  }
  .select-input
  {
    background: ${(props) => props.theme.colors.bgBox};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid ${(props) => props.theme.colors.borderColor};
  }
  .acess-app {
    font-size: 11pt;
    padding: 5px; 
    border-radius: 12px;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
    background: ${(props) => props.theme.colors.bgColor};
    color: #fff;
    width: 35%;
    text-align: center;
  }
  .title-app
  {
    color: ${(props) => props.theme.colors.text};
  }
`;

export const InforPessoalVisitante = styled.form`
  padding: 20px;
  margin-top: -40px;
  .title {
    font-size: 15pt;
    margin: 0;
    font-weight: 500;
  }
  .subtitle {
    font-size: 10pt;
    margin: 0;
    border-bottom: 1px solid rgba(0,0,0,0.0500);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .title-input {
    font-size: 10pt;
    font-weight: 500;
    margin: 0;
  }
  .input {
    border: 0;
    font-size: 11pt;
    background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid rgba(0,0,0,0.0900);
    width: 100%;
  }

  .title-input-cpf {
    font-size: 10pt;
    font-weight: 500;
    margin: 0;
  }
  .title-input-nascimento {
    font-size: 10pt;
    font-weight: 500;
    background: ${(props) => props.theme.colors.bgBox};
    margin: 0;
    margin-left: 10px;
  }
  .input-cpf {
    border: 0;
    font-size: 11pt;
    border-bottom: 1px solid rgba(0,0,0,0.0900);
  }
  .input-nascimento {
    border: 0;
    font-size: 11pt;
    border-bottom: 1px solid rgba(0,0,0,0.0900);
    margin-left: 10px;
  }
`;