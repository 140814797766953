import { makeAutoObservable, runInAction } from "mobx";
import CameraAPI from "../api/CameraAPI";
import LayoutAPI from "../api/LayoutAPI";
import RamalAPI from "../api/RamalAPI";
import { CameraDGuard } from "../interfaces/Cameras.interface";
import { Images, Layout, LayoutDGuard, ServidorDGuard } from "../interfaces/Layout.interface";
import { Ramal } from "../interfaces/Ramal.interface";

class LayoutStore {

    layouts: Layout[] = [];
    images: Images[] = [];
    layoutGeral: Layout | null = null;
    servidorGeral: ServidorDGuard | null = null;
    cameraGeral: CameraDGuard[] = [];
    selecionado: Layout | null = null;
    layoutsDGuard: LayoutDGuard[] = [];
    servidoresDGuard: ServidorDGuard[] = [];
    servidorSelecionado: ServidorDGuard | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
        this.definirSelecionado = this.definirSelecionado.bind(this);
        this.listar = this.listar.bind(this);
    }

    async listar() {
        this.loading = true;
        let condominioId = localStorage.getItem('condominio');
        try {
            if(condominioId) {
                const { data } = await LayoutAPI.listarLayoutPorCondominioId(condominioId);
                runInAction(() => {
                    if(data) this.layouts = data;
                    else this.layouts = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    async listarPorId(layoutId: string) {
        this.loading = true;
        try {
            if(layoutId) {
                const { data } = await LayoutAPI.listarPorId(layoutId);
                runInAction( async () => {
                    if(data !== null)
                    {
                        this.layoutGeral = data;
                        const servidorDguard = (await LayoutAPI.ObterServidorDguard(data.servidorDGuardId)).data;
                        if(servidorDguard !== null)
                        {
                                this.servidorGeral = servidorDguard;
                                const camerasDGuard = await CameraAPI.listarCamerasDoServidorDGuardPorLayoutId(data.id);
                                if(camerasDGuard.data !== null)
                                {
                                        this.cameraGeral = camerasDGuard.data;

                                        camerasDGuard.data.forEach(async (camera) =>
                                        {
                                            var resposta = await LayoutAPI.autenticarDGuard(`http://${servidorDguard.ip}:${servidorDguard.portaCgi}/camera.cgi?camera=${camera.id}&qualidade=70&stream=1`, servidorDguard.usuarioDGuard, servidorDguard.senhaDGuard);
                                            var reader = new window.FileReader();
                                            reader.readAsDataURL(resposta.data); 
                                            reader.onload = function() {
                                                return reader.result;
                                            }
                                            var objectURL = URL.createObjectURL(resposta.data);
                                            this.images.push({ url: objectURL })
                                            this.loading = false;
                                        })
                                }
                        }
                    }
                });
            }
        } catch(e) {
            //
            this.loading = false;
        }
    }

    async listarServidoresDGuard() {
        try {
            const { data } = await LayoutAPI.listarServidoresDGuard();
            runInAction(() => {
                if(!data) this.servidoresDGuard = [];
                else this.servidoresDGuard = data;
            });
        } catch(e) {
            //
        }
    }

    async listarLayoutDGuard() {
        this.loading = true;
        try {
            const { data } = await LayoutAPI.listarLayoutDGuard();
            runInAction(() => {
                if(data)
                {
                    this.layoutsDGuard = data;
                } 
                else this.layoutsDGuard = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
            
        }
    }

    definirSelecionado(layoutId: string | null) {
        let selecionado = this.layouts.find((lay) => lay.id === layoutId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }

    definirServidorSelecionado(servidorDGuardId: string | null) {
        let selecionado = this.servidoresDGuard.find((ser) => ser.id === servidorDGuardId);
        if(selecionado) {
            this.servidorSelecionado = selecionado;
        }
    }

}

export default LayoutStore;