import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  TextArea,
  DatePicker,
} from './styles/NotaCadastrar.style';
import { Nota, NotaRequest } from '../../interfaces/Nota.interface';
import { StoreContext } from '../../stores';
import { useNotas } from '../../hooks/Nota.hook';
import { Button, Modal, SelectPicker } from 'rsuite';

interface NotasCadastrarProps {
  show: boolean;
  hide: () => void;
}


var procedimentos = 
[
    {
        label: 'Procedimento Eclusa',
        value: 1
    },
    {
        label: 'Procedimento Visitante',
        value: 2
    },
    {
        label: 'Procedimento Morador',
        value: 3
    },
    {
        label: 'Procedimento Entrega',
        value: 4
    },
    {
        label: 'Procedimento Prestador',
        value: 5
    }
]

const NotasCadastrar: React.FC<NotasCadastrarProps> = ({ show, hide }) => {
    
    const [form, setForm] = useState<NotaRequest>({});
    const { cadastrar, salvando } = useNotas();
    
    const { notaStore, condominioStore } = useContext(StoreContext);

    useEffect(() =>
    {
        if(show)
        {
            setForm({
                ...form,
                condominioId: condominioStore.selecionado?.id,
                tipo: 0,
                unidadeId: '00000000-0000-0000-0000-000000000000', 
                validade: new Date(),
                ordem: 0
            })
        }
    }, [show]);

    const SalvarNota = () =>
    {
        cadastrar(form as Nota, hide);
    }

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar Procedimento/Nota</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Box>
                    <p style={{fontWeight: '550'}}>Tipo de procedimento</p>
                    <SelectPicker
                        placeholder={'Selecione'}
                        data={procedimentos}
                        block
                        onChange={(e) => setForm({ ...form, tipo: e as any })}
                        onClean={() => setForm({ ...form, tipo: 0 })}
                    />
                    
                    <p style={{fontWeight: '550'}} className='title'>Descrição</p>
                    <TextArea
                        value={form.descricao}
                        placeholder="Escreva uma anotação aqui..."
                        onChange={(e) => setForm({ ...form, descricao: e.target.value })}
                        rows={9}
                        maxLength={3000}
                    />

                    <div className="d-flex justify-content-end">
                        <Button
                        appearance='primary'
                        color={'violet'}
                        style={{marginRight: 10}}
                        onClick={SalvarNota}
                        disabled={form.descricao === '' || salvando ? true : false || form.tipo === 0 ? true : false}
                        >
                            {
                                salvando ? 'Salvando...' : 'Salvar'
                            }
                        </Button>
                        <Button onClick={hide}>Fechar</Button>
                    </div>
                </Box>
                <p style={{margin: 0}}>{form.descricao?.length}</p>
                {
                    form.descricao && form.descricao?.length >= 3000
                    ?
                    <p style={{margin: 0}}>Máximo de caracteres: 3000</p>
                    :
                    ''
                }
                
            </Modal.Body>
        </Modal>
    );
};

export default observer(NotasCadastrar);