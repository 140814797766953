import { Button, Menu, MenuItem, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import react, { FC, useContext, useEffect, useState } from 'react';
import { BiBlock, BiCog, BiLogOut } from 'react-icons/bi';
import { observer } from 'mobx-react-lite';
import { BsCameraVideo, BsCollection, BsFolder, BsFullscreen, BsHddNetwork, BsSearch, BsShieldLock, BsUiChecksGrid } from 'react-icons/bs';
import { MdDarkMode, MdInput, MdLight, MdLightMode, MdOutlineDarkMode, MdOutlineDialerSip, MdOutlineDirectionsCarFilled, MdOutlineExitToApp, MdOutlineLightMode, MdOutlineLogout, MdOutlineNightlight, MdOutlineRouter, MdSupervisedUserCircle, MdWifiCalling3 } from 'react-icons/md';
import { StoreContext } from '../../stores';
import {  Condominio, Container } from './styles/Header.style';
import React from 'react';
import { styled } from '@mui/material/styles';
import { VscCallOutgoing, VscEditorLayout, VscGithubAction, VscVersions } from 'react-icons/vsc';
import { AiOutlineAlert, AiOutlineLogin, AiOutlineNotification, AiOutlineReload, AiOutlineSearch, AiOutlineSetting, AiOutlineUser } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { FaCogs, FaHome, FaNetworkWired, FaNewspaper, FaSearch, FaUser } from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';
import avatar from '../../assets/img/avatar.png';
import logo_dark from '../../assets/img/logo-negative.png';
import { SiNamebase } from 'react-icons/si';
import AtualizarCondominio from '../condominios/AtualizarCondominio';
import { Nav, Navbar, Popover, Whisper, IconButton, Avatar, Dropdown, Input } from 'rsuite';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { GiReloadGunBarrel } from 'react-icons/gi';
import { CgPlayPauseR } from 'react-icons/cg';
import NotFound from '../utils/NotFound';
import EditIcon from '@rsuite/icons/Edit';

interface HeaderProps {
    mode?: string;
}

const Header: FC<HeaderProps> = ({ mode }) => {

    const { condominioStore, authStore, themeStore } = useContext(StoreContext);
    const history = useNavigate();
    const { selecionado } = condominioStore;
    const { loggout } = authStore;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorCondominioEl, setAnchorCondominioEl] = useState<null | HTMLElement>(null);
    const [openEditarCondominio, setOpenEditarCondominio] = useState(false);
    const open = Boolean(anchorEl);
    const openCondominio = Boolean(anchorCondominioEl);
    const [auth, setAuth] = useState(false);

    const sair = () => {
        handleClose();
        themeStore.theme = 'light';
        history('/');
        loggout();
    }


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickCondominio = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorCondominioEl(event.currentTarget);
    };
    const handleCloseCondominio = () => {
        setAnchorCondominioEl(null);
    };

    const toggleEditarCondominio = () => setOpenEditarCondominio(!openEditarCondominio);

    const renderAcess = () =>
    {

    }

    const renderMenu = ({ onClose, left, top, className } : any, ref : any) => {
        const handleSelect = (eventKey: any) => {
          onClose();
        };
        return (
          <Popover ref={ref} className={className} style={{ left, top }} full>
            <Dropdown.Menu onSelect={handleSelect}>
              <Dropdown.Item eventKey={3} icon={themeStore.theme === 'dark' ? <MdLightMode size={22} style={{marginRight: 5}} /> : <MdDarkMode size={22} />} onClick={() => 
            {
                themeStore.toggle();
                onClose();
            }}>Trocar tema</Dropdown.Item>
              <Dropdown.Item eventKey={7} icon={<MdOutlineExitToApp size={22} style={{marginRight: 5}} />} onClick={() =>
            {
                sair();
                onClose();
            }}>Sair </Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        );
      };
  

    return(
        <Container className='d-flsex justify-content-center'>
            <div className='header d-flex justify-content-between align-self-center'>
                <div className='d-flex'>
                    <img className='align-self-center mt-1' style={{height: 35, marginRight: 10}} src={'https://www.deway.com.br/wp-content/uploads/2016/05/OutKey-01.png'} />
                    <div className='mt-'>
                        <p className='title'>Seja bem vindo</p>
                        <p className='subtitle'>Seja bem vindo ao painel administrativo OUTKEY</p>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <Whisper
                        placement="bottomEnd"
                        trigger="click"
                        speaker={<Popover arrow={false}>
                            {/* <NotFound message={'Nenhuma notificação!'} /> */}
                            <div className='p-2'>
                                Nenhuma notificação
                            </div>
                        </Popover>}
                    >
                        <IconButton style={{ background: 'transparent' }} size="md" icon={<AiOutlineNotification size={20} />} circle />
                    </Whisper>
                    <div className='bar' />
                    <Whisper
                        placement="bottomEnd"
                        trigger="click"
                        speaker={renderMenu}
                    >
                        <div className='d-flex justify-content-center' role={'button'}>
                            <Avatar circle src={avatar} alt="@theJian" />
                            <div>
                                <p className='user'>{authStore.usuario?.nome?.split(' ')[0]} {authStore.usuario?.nome?.split(' ')[1]}</p>
                                <p className='email'>{authStore.usuario?.email ?? 'Sem email'}</p>
                            </div>
                        </div>
                    </Whisper>
                </div>
            </div>

            <AtualizarCondominio show={openEditarCondominio} hide={toggleEditarCondominio} />
            
        </Container>
    );
}

export default observer(Header);