import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { StoreContext } from "../stores";
import ZktecoAPI from "../api/ZktecoAPI";
import { Zkteco, ZktecoRele } from "../interfaces/Zkteco.interface";


export const useZkteco = () => {

    const { zktecoStore, equipamentoStore} = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    
    const cadastrar = async (requets: Zkteco, hide: () => void, clear: () => void) => {
        setSalvando(true);

        try {
            const { status } = await ZktecoAPI.adicionar(requets as Zkteco);
            if(status === 200) {
                setSalvando(false);
                equipamentoStore.getZkteco()
                zktecoStore.listar()
                hide();
                clear();
                toast.success('Cadastrado com sucesso!');
            }
        } catch(e) {
            console.error(e)
            toast.error('Erro ao cadastrar');
        } finally {
            setSalvando(false)
        }
    }

    const atualizar = async (requets: Zkteco, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await ZktecoAPI.atualizar(requets as any);
            if(status === 200) {
                equipamentoStore.getZkteco()
                setAtualizando(false);
                hide();
                toast.success('Atualizado com sucesso!');
            }
        } catch(e) {
            console.error(e)
            toast.error('Erro ao cadastrar');
        } finally {
            setAtualizando(false)
        }
    }

    const deletar = async (request: Zkteco) => {
        setDeletando(true);
        try {
            const { status } = await ZktecoAPI.deletar(request.id);
            if(status === 200) {
                setDeletando(false);
                equipamentoStore.getZkteco();
                toast.success('Deletado com sucesso!');
            }
        } catch(e) {
            console.error(e)
            toast.error('Erro ao deletar');
        } finally {
            setDeletando(false);
        }
    }

    const cadastrarRele = async (requets: ZktecoRele, hide: () => void, clear: () => void) => {
        setSalvando(true);

        try {
            const { data, status } = await ZktecoAPI.adicionarRele(requets as any);
            if(status === 200) {
                setSalvando(false);
                zktecoStore.releZkteco.push(data);
                zktecoStore.listar()
                hide();
                clear();
                toast.success('Cadastrado com sucesso!');
            }
        } catch(e) {
            console.error(e)
            toast.error('Erro ao cadastrar');
        } finally {
            setSalvando(false);
        }
    }

    const atualizarRele = async (requets: ZktecoRele, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await ZktecoAPI.atualizarRele(requets as any);
            if(status === 200) {
                zktecoStore.releZkteco = zktecoStore.releZkteco.map( gua => gua.id === requets.id ? requets : gua );
                setAtualizando(false);
                hide();
                toast.success('Atualizado com sucesso!');
            }
        } catch(e) {
            console.error(e)
            toast.error('Erro ao atualizar');
        } finally {
            setAtualizando(false);
        }
    }

    const deletarRele = async (request: ZktecoRele) => {
        setDeletando(true);
        try {
            const { status } = await ZktecoAPI.deletarRele(request.id);
            if(status === 200) {
                setDeletando(false);
                zktecoStore.releZkteco = zktecoStore.releZkteco.filter( gua => gua.id !== request.id );
                toast.success('Deletado com sucesso!');
            }
        } catch(e) {
            console.error(e)
            toast.error('Erro ao deletar');
        } finally {
            setDeletando(false);
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        cadastrarRele,
        atualizarRele,
        deletarRele,
        salvando,
        atualizando,
        deletando,
    }

}