import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Header, Content } from './styles/UnidadeRelatorio';
import { BsChevronDoubleRight, BsPeople } from 'react-icons/bs';
import { IconButton, Input, SelectPicker } from 'rsuite';
import { StoreContext } from '../../stores';
import DragableIcon from '@rsuite/icons/Dragable';
import SearchIcon from '@rsuite/icons/Search';
import SkeletonList from '../utils/SkeletonList';
import { Table } from 'react-bootstrap';
import { converterData } from '../utils/mask';
import NotFound from '../utils/NotFound';
import PageIcon from '@rsuite/icons/Page';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const UnidadeRelatorio = () => {

    const { unidadeStore, moradorStore, themeStore, veiculoStore } = useContext(StoreContext);
    const [mode, setMode] = useState('');


    useEffect(() =>
    {
        if(unidadeStore.selecionado && mode === 'tag')
            moradorStore.listarRelatorioDispositivoPorUnidadeId(unidadeStore.selecionado.id)
        else if(unidadeStore.selecionado && mode === 'controle')
            veiculoStore.listarRelatorioControle(unidadeStore.selecionado.id);
    }, [unidadeStore.selecionado, mode])

    const RenderListTag = () =>
    {

        if(moradorStore.loadingDispositivo)
            return <SkeletonList/>

        if(moradorStore.moradoresDispositivos.length === 0)
            return <NotFound message='Nenhum registro encontrado!' />

        return(
            <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="md">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Email</th>
                        <th>Celular</th>
                        <th>Nascimento</th>
                        <th>Serial</th>
                        <th>Tipo</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        moradorStore.moradoresDispositivos.map((d, i) =>
                        (
                            <tr key={i} >
                                <td>{d.nome}</td>
                                <td>{d.cpf}</td>
                                <td>{d.email}</td>
                                <td>{d.celular}</td>
                                <td>{converterData(new Date(d.nascimento))}</td>
                                <td>{d.serial}</td>
                                <td>TAG</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        )
    }

    const RenderListControle = () =>
    {

        if(veiculoStore.loading)
            return <SkeletonList/>

        if(veiculoStore.controles.length === 0)
            return <NotFound message='Nenhum registro encontrado!' />

        return(
        <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
            <thead>
                    <tr>
                        <th>Unidade</th>
                        <th>Modelo</th>
                        <th>Placa</th>
                        <th>Cor</th>
                        <th>Marca</th>
                        <th>Qtd - Controles</th>
                        <th>Controles</th>
                        <th>Qtd - TagPassiva</th>
                        <th>Tags Passivas</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        veiculoStore.controles.filter(d => d.controle !== null || d.tagPassiva !== null).map((d, i) =>
                        (
                            <tr key={i} >
                                <td>{d.unidadeIdentificacao}</td>
                                <td>{d.modelo}</td>
                                <td>{d.placa}</td>
                                <td>{d.corDescricao}</td>
                                <td>{d.marcaDescricao}</td>
                                <td>{!d.controle ? ' - ' : d.controle.split(',').length}</td>
                                <td>{!d.controle ? ' - ' : d.controle}</td>
                                <td>{!d.tagPassiva ? ' - ' : d.tagPassiva.split(',').length}</td>
                                <td>{!d.tagPassiva ? ' - ' : d.tagPassiva}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        )
    }

    const RenderRoute = () =>
    {
        switch(mode)
        {
            case 'tag':
                return RenderListTag()
            case 'controle':
                return RenderListControle()
        }

        return(
            <div>
                Selecione
            </div>
        )
    }


    return (
        <Container>
        <Header>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='title'>Campo de cadastros de moradores</p>
                    <p className='subtitle'>Selecione a unidade para visualizar os moradores</p>
                </div>
            </div>

            <div className='d-flex box'>
                <div className='content d-flex'>
                    <PageIcon className='content-icon' />
                    <div>
                        <p className='content-title'>Relatório de dispositivos por unidade</p>
                        <p className='content-subtitle'>Selecione a unidade para ver todas as informações de dispositivos cadastrados</p>
                    </div>
                </div>
                <div className='align-self-center'>
                    <div style={{marginLeft: 20}}>
                        <p style={{width: 200}}>Informe o tipo de dispositivo que você deseja obter o relatório. <br/> <small><span className='text-danger'>*</span>Compo obrigatório</small></p>
                        <SelectPicker
                            block
                            cleanable={false}
                            style={{width: 200}}
                            value={mode}
                            data={[{ label: 'Controles', value: 'controle' }, {  label: 'Tags', value: 'tag'}]}                            
                            onChange={(e) =>
                            {
                                if(e) setMode(e);
                            }}
                        />
                    </div>
                </div>

                <div className='align-self-center' style={{marginLeft: 30, marginRight: 20}}>
                    <BsChevronDoubleRight style={{alignSelf: 'center', fontSize: 30}} />
                </div>

                <div className='align-self-center'>
                    <div style={{marginLeft: 20}}>
                        <p style={{width: 200}}>Selecione a unidade que você deseja visualizar as informações de dispositivos</p>
                        <SelectPicker
                            block
                            disabled={!mode}
                            cleanable={false}
                            style={{width: 200}}
                            value={unidadeStore.selecionado?.id}
                            placeholder={'Selecione'}
                            data={unidadeStore.unidadesPorCondomino.map((unidade) => ({ label: `${unidade.apartamento} - ${unidade.blocoNome}`, value: unidade.id }))}                            
                            onChange={(e) =>
                            {
                                if(e) unidadeStore.definirSelecionado(e);
                            }}
                        />
                    </div>
                </div>
            </div>
            </Header>

            <Content>
                {RenderRoute()}
            </Content>
        </Container>
  )
}

export default observer(UnidadeRelatorio);
