import { arrayMoveImmutable } from "array-move";
import { observer } from "mobx-react-lite";
import { FC, useContext } from "react";
import { Button, List, Loader, Modal } from "rsuite";
import useAcionamento from "../../hooks/Acionamento.hook";
import { StoreContext } from "../../stores";

interface ModalProps {
  show: boolean;
  hide: () => void;
}

const AtualizarOrdem: FC<ModalProps> = ({ show, hide }) => {
  const { acionamentoStore, condominioStore } = useContext(StoreContext);
  const { atualizarOrdemAcionamento, atualizando } = useAcionamento();

  const handleSortEnd = (payload?: { oldIndex: number; newIndex: number }) => {
    if (!payload) return;
    const { oldIndex, newIndex } = payload;

    acionamentoStore.acionamentos = arrayMoveImmutable(
      acionamentoStore.acionamentos,
      oldIndex,
      newIndex
    );
    acionamentoStore.acionamentos.forEach((_, i) => {
      _.ordem = i;
    });
  };

  const submit = () => {
    acionamentoStore.acionamentosOld = JSON.parse(JSON.stringify(acionamentoStore.acionamentos)); 
    atualizarOrdemAcionamento(acionamentoStore.acionamentos);
    hide();
  };

  const Listagem = () => {
    if (atualizando) {
      return (
        <div className={"d-flex justify-content-center mt-5"}>
          <Loader content={"Salvando nova ordem dos acionamentos... aguarde!"} />
        </div>
      );
    }

    return (
      <List sortable onSort={handleSortEnd}>
        {acionamentoStore.acionamentos.map((acionamento, i) => (
          <List.Item
            style={{
              background: "transparent",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
              margin: 4,
            }}
            className="p-3"
            key={i}
            index={i}
          >
            {acionamento.nome?.toUpperCase()}
          </List.Item>
        ))}
      </List>
    );
  };

  return (
    <Modal open={show} onClose={() => (atualizando ? {} : hide())}>
      <Modal.Header closeButton>
        <div>
          <p className="modal-styled-title">
            Atualizar ordem dos acionamentos do condomínio{" "}
            {condominioStore.selecionado?.nome}
          </p>
          <p className="modal-styled-subtitle">
            Arraste para cima ou para baixo para alterar ordem dos acionamentos
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>{Listagem()}</Modal.Body>
      <Modal.Footer>
        <Button onClick={submit} color="violet" appearance="primary">
          Salvar
        </Button>
        <Button onClick={() => (atualizando ? {} : hide())}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(AtualizarOrdem);
