import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Modal, Toggle } from 'rsuite';
import { IntelbrasConfigEvento,  IntelbrasConfigEventoRequest } from '../../../interfaces/Equipamento.interface';
import { useDispositivo } from '../../../hooks/Dispositivo.hook';
import { StoreContext } from '../../../stores';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const ModalConfigEvento: FC<ModalProps> = ({ show, hide }) => { 

    const { equipamentoStore } = useContext(StoreContext);
    const { loading, configurarServidorEventosIntelbras } = useDispositivo();
    const [form, setForm] = useState<IntelbrasConfigEventoRequest>({});

    useEffect(() => {
        if(equipamentoStore.intelbrasSelecionado && show) {
            setForm({
                ...form,
                equipamentoId: equipamentoStore.intelbrasSelecionado.id
            });
        }
    }, [equipamentoStore.intelbrasSelecionado && show]);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        configurarServidorEventosIntelbras(form as IntelbrasConfigEvento, hide)
    }

    const FormEquipamento = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="192.168.XXX.XXX" value={form.address || ''} onChange={(e) => setForm({...form, address: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="80" value={form.port || ''} onChange={(e) => setForm({...form, port: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Endereço</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Usuário" value={form.uploadpath || ''} onChange={(e) => setForm({...form, uploadpath: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Habilitar configuração de enviar eventos</Form.Label><br/>
                        <Toggle checkedChildren="Sim" unCheckedChildren="Não" checked={form.enable} onChange={(e) => setForm({...form, enable: e})}  />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance='primary' disabled={loading} type="submit">
                        { loading ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={loading} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {loading ? <LinearProgress color="primary" style={{height: 2, marginTop: 15, position: 'absolute',width: '100%', bottom: 0, left: 0}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size={'sm'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Configuração de envio de eventos</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormEquipamento()}</Modal.Body>
        </Modal>
    )
}

export default observer(ModalConfigEvento);