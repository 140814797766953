import styled from 'styled-components';

interface Styles {
    mode?: string;
}

export const Container = styled.div<Styles>`
    margin: 10px;
    border-radius: 12px;
    .header
    {
        padding: 5px;
        // margin-left: 20px;
        // margin-right: 20px;
        // padding: 10px;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    }

    .header .title
    {
        margin: 0;
        font-size: 11pt;
        font-weight: 550;
    }
    .header .subtitle
    {
        margin: 0;
        font-size: 9pt;
        margin-top: -2px;
    }
    .user
    {
        margin: 0;
        font-size: 9pt;
        font-weight: 600;
        margin-left: 10px;
    }
    .email
    {
        margin: 0;
        font-size: 9pt;
        margin-top: -2px;
        margin-left: 10px;
    }
    .bar
    {
        border-right: 1px solid ${(props) => props.theme.colors.borderColor};
        margin-left: 10px;
        margin-right: 18px;
        align-self: center;
        height: 50%;
    }
    .input-global
    {
        border: 0;
        background: transparent;
        // padding: 10px;
        border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        color: ${(props) => props.theme.colors.text};
        border-radius: 0;
        box-shadow: 0;
        width: 100%;
    }
    `
export const Condominio = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    position: fixed;
    // margin-left: auto;
    // margin-right: 0;
    // left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    width: 20%;
    z-index: 2;
    padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .condominio {
        align-self: center;
        margin: 0;
        font-weight: 600;
        font-size: 10pt;
        // color: ${(props) => props.theme.colors.primary};
    }
`;
