import React, { useContext, useEffect, useState } from 'react';
import { BarLeft, Container, Content } from './styles/Dashboard.style';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { StoreContext } from '../../stores'
import { observer } from 'mobx-react-lite';
import { ListGroup } from 'react-bootstrap';
import AlertaIcone from '../utils/AlertaIcone';
import { Button, IconButton, Progress, RadioTile, RadioTileGroup } from 'rsuite';
import { BsCameraVideo, BsColumnsGap, BsDoorOpen } from 'react-icons/bs';
import { MdOutlineLightMode, MdOutlineNightlight, MdOutlineRouter } from 'react-icons/md';
import EditIcon from '@rsuite/icons/Edit';
import AdicionarEndereco from './AdicionarEndereco';
import AtualizarEndereco from './AtualizarEndereco';
import { Icon } from '@rsuite/icons';
import ExportIcon from '@rsuite/icons/Export';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import image from '../../assets/img/tech.png';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
const Dashboard = () => {

    const { blocoStore, unidadeStore, guaritaStore, veiculoStore, themeStore, layoutStore, condominioStore, moradorStore, siamStore, equipamentoStore } = useContext(StoreContext);

    const [cadastrar, setCadastrar] = useState(false);
    const [atualizar, setAtualizar] = useState(false);

    const toggleCadastrar = () => setCadastrar(!cadastrar);
    const toggleAtualizar = () => setAtualizar(!atualizar);

    useEffect(() => {
        if (condominioStore.selecionado) {
            // eventoStore.listarUltimosPorCondominioIdEquantidade(condominioStore.selecionado.id, 20);
            blocoStore.listar();
            moradorStore.listarPorCondominioId(condominioStore.selecionado.id);
            siamStore.listar();
            guaritaStore.listar();
            condominioStore.listarCondominioAuxiliar();
            veiculoStore.listarPorCondominio(condominioStore.selecionado.id);

        }
    }, [condominioStore.selecionado]);


    const data1 = {
        labels: ['Blocos', 'Unidades', 'Moradores', 'Veículos'],
        datasets: [
            {
                label: 'Qtd',
                data:
                    [
                        blocoStore.blocos.length,
                        unidadeStore.unidadesPorCondomino.length,
                        moradorStore.moradoresCondominio.length,
                        veiculoStore.veiculosCondominio.length,
                    ],
                backgroundColor: [
                    '#96CDCD',
                    '#A020F0',
                    '#7FFF00',
                    '#1E90FF',
                ],
                borderColor: [
                    '#96CDCD',
                    '#A020F0',
                    '#7FFF00',
                    '#1E90FF',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Container>
            <div className='header'>
                {/* <p className='title'>Seja bem vindo ao condomínio <b>{condominioStore.selecionado?.nome} - {condominioStore.condominiosAuxiliar?.administradoraNome.toLocaleUpperCase()}</b></p> */}
                <p className='title'>
                    Olá, seja bem vindo ao condomínio &nbsp;
                    <b>{condominioStore.selecionado?.nome} - {condominioStore.selecionado?.condominioAuxiliar?.administradoraNome}</b>
                </p>
                <p className='subtitle'>
                    Use a aba de navegação ao lado para ver todas as informações do condomínio
                </p>
            </div>


            <Content className='d-flex justify-content-center p-4'>
                <div className='d-flex'>
                    <div className='d-flex align-self-center'>
                        <div style={{ height: 350, width: '100%' }} className='d-flex align-self-center'>
                            <Doughnut data={data1} style={{ width: '100%' }} />
                        </div>
                        <div className='align-self-center'>
                            <p className='title-chart'>Informações gerais dos dados</p>

                            <div className='d-flex mt-2'>
                                <div className='align-self-center'>
                                    <div style={{ background: '#96CDCD' }} className='icon-chart' />
                                </div>
                                <p className='title-chart'>Blocos - {blocoStore.blocos.length} cadastrado</p>
                            </div>

                            <div className='d-flex mt-2'>
                                <div className='align-self-center'>
                                    <div style={{ background: '#A020F0' }} className='icon-chart' />
                                </div>
                                <p className='title-chart'>Unidades - {unidadeStore.unidadesPorCondomino.length} cadastrado</p>
                            </div>

                            <div className='d-flex mt-2'>
                                <div className='align-self-center'>
                                    <div style={{ background: '#7FFF00' }} className='icon-chart' />
                                </div>
                                <p className='title-chart'>Moradores - {moradorStore.moradoresCondominio.length} cadastrado</p>
                            </div>

                            <div className='d-flex mt-2'>
                                <div className='align-self-center'>
                                    <div style={{ background: '#1E90FF' }} className='icon-chart' />
                                </div>
                                <p className='title-chart'>Veículos - {veiculoStore.veiculosCondominio.length} cadastrado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>

            <AdicionarEndereco show={cadastrar} hide={toggleCadastrar} />
            <AtualizarEndereco show={atualizar} hide={toggleAtualizar} />

        </Container>
    );
}

export default observer(Dashboard);