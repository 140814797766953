import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import react, { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Loader, Modal } from 'rsuite';
import { useBloco } from '../../hooks/Bloco.hook';
import { Bloco, BlocoRequest } from '../../interfaces/Bloco.interface';
import { CardItem } from './styles/Bloco.style';
import { BsX } from 'react-icons/bs';

interface ModalProps {
    show: boolean;
    hide: () => void;
    condominioId?: string;
}

const CadastrarBloco: FC<ModalProps> = ({ show, hide, condominioId }) => {

    const token = process.env.REACT_APP_BASE_TOKEN_BLOCOUNIDADE;
    const [list, setList] = useState<BlocoRequest[]>([]);
    const [form, setForm] = useState<BlocoRequest>({});
    const [status, setStatus] = useState('');
    const { cadastrar, cadastrarMultiplos, salvando, erro, success } = useBloco();

    useEffect(() => {
        setList([])
        list.push();
    }, [condominioId, show]);

    const addMais = () => {
        if(localStorage.getItem('condominio')) form.condominioId = localStorage.getItem('condominio') ?? "";
        list.push(form);
        setForm({});
    }

    const salvar = () => {
        if(localStorage.getItem('condominio')) form.condominioId = localStorage.getItem('condominio') ?? "";
        setForm({});
        if(list.length === 0)
        {
            list.push(form)
            cadastrarMultiplos(list as Bloco[], hide)
            list.push()
        }
        else
        {
            cadastrarMultiplos(list as Bloco[], hide)
        }
    }

    const handleRemoveItem = (index) => {
        const newList = list.filter((_, i) => i !== index);
        setList(newList);
    };

    if(success) {
        return(
            <Snackbar open={success} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Bloco cadastrado com sucesso!
                </Alert>
            </Snackbar>
        )
    }

    if(erro) {
        return(
            <Snackbar open={erro} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Erro ao cadastrar bloco
                </Alert>
            </Snackbar>
        )
    }

    const FormBloco = () => {
        return(
            <>
                <Form>
                    <div className='d-flex'>
                        <Form.Group className="mb-3 m-1" controlId="formBasicEmail">
                            <Form.Label><span className='text-danger'>*</span>Nome do bloco</Form.Label>
                            <Input required type="text" className='shadow-none' placeholder="Nome" value={form.nome ?? ""} onChange={(e) => setForm({ ...form, nome: e})} />
                            <Form.Text className="text-muted">
                                O campo acima informa a identificação do bloco no outkey
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3 m-1" controlId="formBasicPassword">
                            <Form.Label><span className='text-danger'>*</span>Identifcado NICE</Form.Label>
                            <Input required type="text" maxLength={4} className='shadow-none' placeholder="Identificador Nice" value={form.identificadorNice ?? ""} onChange={(e) => setForm({ ...form, identificadorNice: e })}  />
                            <Form.Text className="text-muted">
                            Informe o identificador nice para ser salvo no sistema
                            </Form.Text>
                        </Form.Group>
                    </div>

                    {
                        list.map((m, i) =>
                        (
                            <CardItem className='d-flex justify-content-between'>
                                <div>
                                    <p className='title-item'><b>Bloco:</b> &nbsp;{m.nome?.toLocaleUpperCase()}</p><br/>
                                    <p className='subtitle-item'><b>Identificador Nice:</b> &nbsp;{m.identificadorNice?.toLocaleUpperCase()}</p>
                                </div>
                                <BsX className='item-icon align-self-center' role={'button'} onClick={() => handleRemoveItem(i)} />
                            </CardItem>
                        ))
                    }

                    <Button disabled={!form.nome || !form.identificadorNice} appearance='primary' color='violet' size={'md'} className='mt-1' onClick={addMais}>Adicionar outro bloco</Button>
                </Form>
            </>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                {
                    status !== 'error'
                    ?
                    <div>
                        <p className='modal-styled-title'>Cadastre o novo bloco</p>
                        <p className='modal-styled-subtitle'>
                            Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                        </p>
                    </div>
                    :
                    <p className='modal-styled-title'>Token inválido</p>
                }
            </Modal.Header>
            <Modal.Body> { status !== 'error' ? FormBloco() : <p className=''>Você digitou as credenciais incorretamente.</p>}</Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" disabled={salvando || form.nome?.length === 0 || status === 'error' || list.length === 0 || list.length === 0 ?  !form.nome || !form.identificadorNice : false } onClick={salvar}>{ salvando ? <Loader title='Salvando...' /> : 'Salvar' }</Button>
                <Button appearance="default" disabled={salvando} onClick={hide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CadastrarBloco;