import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1;
  padding: 30px;

  .title {
    font-size: 13pt;
    font-weight: 500;
    margin: 0;
    margin-top: -15px;
  }
`;

export const Box = styled.div`
  padding: 15px;
  border-radius: 10px;
  height: 100%;
  .card-input
  {
    width: 300px;
    background: ${(props) => props.theme.colors.bgColor};
    border-radius: 7px;
  }
  .icon
  {
    align-self: center;
    margin-left: 20px;
    font-size: 14 pt;
  }
  .input
  {
    border: 0;
    background: transparent;
  }
  .button-add
  {
    border-radius: 7px;
    width: 150px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .tr-title
  {
    align-self: center;
    margin: 0;
    font-size: 9pt;
    font-weight: 550;
  }
  .tr-subtitle
  {
    align-self: center;
    margin: 0;
    font-size: 7pt;
  }
`;

