import { Avatar, Drawer, IconButton, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import { RiWirelessChargingLine } from 'react-icons/ri';
import { StoreContext } from '../../stores';
import { useRele } from '../../hooks/Rele.hook';
import CadastrarRele from './CadastrarRele';
import AtualizarRele from './AtualizarRele';
import { ListReles, ListActive, Container } from './styles/Rele.style';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import { Button, Dropdown, Modal } from 'rsuite';
import { Table } from 'react-bootstrap';
import NotFound from '../utils/NotFound';
import SkeletonList from '../utils/SkeletonList';

interface Props {
    moduloId: string | undefined;
    show: boolean;
    hide: () => void;
} 

const _Rele: FC<Props> = ({ moduloId, show, hide }) => {

    const { guaritaStore, releStore, comandoStore, condominioStore } = useContext(StoreContext);
    const { deletar, deletando } = useRele();

    const [modalCadastrarRele, setModalCadastrarRele] = useState(false);
    const [modalEditarRele, setModalEditarRele] = useState(false);
    const [anchorElRele, setAnchorElRele] = useState<null | HTMLElement>(null);
    const [anchorElComando, setAnchorElComando] = useState<null | HTMLElement>(null);
    const openRele = Boolean(anchorElRele);
    const openComando = Boolean(anchorElComando);
    const [modalDeletar, setModalDeletar] = useState(false);

    const deletarRele = () =>
    {
        if(releStore.selecionado) {
            deletar(releStore.selecionado);
        }
    }

    const handleClickRele = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorElRele(event.currentTarget);
    const handleCloseRele = () => setAnchorElRele(null);
    const toggleModalDeletar = () => setModalDeletar(!modalDeletar);
    const toggleCadastrarRele = () => setModalCadastrarRele(!modalCadastrarRele);
    const toggleEditarRele = () => setModalEditarRele(!modalEditarRele);

    useEffect(() => {
        if(moduloId) {
            releStore.listar(moduloId);
        }
    }, [moduloId]);

    useEffect(() => {
        guaritaStore.listarModuloPorCondominio();
        releStore.selecionado = null;
    }, [guaritaStore]);

    useEffect(() => {
        let primeiroRele = releStore.reles[releStore.reles.length - 1];
        if(primeiroRele) {
            comandoStore.listar(primeiroRele.id);
            releStore.definirSelecionado(primeiroRele.id);
        }
    }, []);

    const renderBody = () =>
    {

        if(releStore.loading)
            return <SkeletonList/>

        if(releStore.reles.length === 0) return <NotFound message='Nenhum relé cadastrado' />

        return(
            <tbody>
                {
                    releStore.reles.map((rele, i) => (
                    <tr key={i} onClick={() => releStore.definirSelecionado(rele.id)}> 
                        <td>{rele.nome}</td>
                        <td>{rele.numeroRele}</td>
                        <td>
                            <Dropdown title={'Ação'} size={'xs'}>
                            <Dropdown.Item onClick={() => {
                                toggleEditarRele();
                            }}>Atualizar</Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                toggleModalDeletar();
                            }}>Deletar</Dropdown.Item>
                            </Dropdown>
                        </td>
                    </tr>
                    ))
                }
            </tbody>
        )
    }

    const renderReles = () => {

        if(releStore.loading)
            return <SkeletonList/>

        return(
            <Container>
                <Table>
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Número relé</th>
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    {renderBody()}
                </Table>
            </Container>
        )
    }


    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Relés cadastrado nesso módulo</p>
                    <p className='modal-styled-subtitle'>
                        Abaixo está a lista de todos os relés cadastrados
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body style={{height: '60vh'}}>{renderReles()}</Modal.Body>

            <Modal.Footer>
                <Button 
                    appearance='primary' 
                    style={{fontSize: 13}}
                    onClick={() => {
                        toggleCadastrarRele();
                    }}>
                        Cadastrar novo relé no módulo { guaritaStore.moduloSelecionado?.tipoModulo }
                </Button>
                <Button onClick={hide} style={{marginLeft: 10}}>Fechar</Button>
            </Modal.Footer>

            <CadastrarRele show={modalCadastrarRele} hide={toggleCadastrarRele} moduloId={moduloId} />
            <AtualizarRele show={modalEditarRele} hide={toggleEditarRele} releSelecionado={releStore.selecionado} />

            {/* MENU RELE */}
            <Menu
                id="basic-menu"
                anchorEl={anchorElRele}
                open={openRele}
                onClose={handleCloseRele}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleCloseRele();
                    toggleEditarRele();
                }}>Atualizar</MenuItem>
                <MenuItem onClick={() => {
                    handleCloseRele();
                    toggleModalDeletar();
                }}>Deletar</MenuItem>
            </Menu>

           
           <ModalConfirmacao show={modalDeletar} hide={toggleModalDeletar} action={deletarRele} content={'Você tem certeza que deseja deletar esse relé?'} loading={deletando} />
        </Modal>
    )
}

export default observer(_Rele);