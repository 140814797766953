import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';
import { Zkteco, ZktecoRequest } from '../../../interfaces/Zkteco.interface';
import { StoreContext } from '../../../stores';
import { useZkteco } from '../../../hooks/Zkteco.hook';
import CondominioStore from '../../../stores/CondominioStore';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarZkteco: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<ZktecoRequest>({});
    const { atualizar, atualizando } = useZkteco();
    const { zktecoStore, condominioStore } = useContext(StoreContext);


    const salvar = (e: FormEvent<HTMLFormElement>) => {
        setForm({...form, condominioId: condominioStore.selecionado?.id})
        if(condominioStore.selecionado) {
            form.condominioId = condominioStore.selecionado.id;
        }
        e.preventDefault();
        atualizar(form as Zkteco, hide);
    }

    useEffect(() =>
    {
        if(zktecoStore.selecionado)
            setForm(zktecoStore.selecionado);
    }, [zktecoStore.selecionado]);

    const FormCard = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>IP</Form.Label>
                        <Input required type="text" value={form.ip || ''} className='shadow-none' placeholder="192.168.0.0" onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta API</Form.Label>
                        <Input required type="number" value={form.porta} className='shadow-none' placeholder="Ex: 80" onChange={(e) => setForm({...form, porta: parseInt(e)})} />
                    </Form.Group>
                </div>
                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' value={form.nome || ''} placeholder="Nome" onChange={(e) => setForm({...form, nome: e})}  />
                    </Form.Group>
                    <Form.Group className=" col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input disabled value={form.modelo?.toUpperCase()} />
                    </Form.Group>
                </div>


                <div className='d-flex justify-content-end'>
                    <Button appearance="primary" disabled={atualizando} type="submit">
                        { atualizando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={atualizando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar equipamento ZKteco</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormCard()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarZkteco);