import { makeAutoObservable, runInAction } from "mobx";
import { Captura, Mip1000, Mip1000Rele } from "../interfaces/Mip1000.interface";
import Mip1000API from "../api/Mip1000API";
import { toast } from "react-toastify";

class Mip1000Store {
  mip1000: Mip1000[] = [];
  releMip1000: Mip1000Rele[] = [];
  selecionado: Mip1000 | null = null;
  selecionadoRele: Mip1000Rele | null = null;
  infoRele: Mip1000Rele | null = null;
  captura: Captura | null = null;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async listarPorCondominio(condominioId: string) {
    this.loading = true;
    try {
      if (condominioId) {
        const { data } = await Mip1000API.listarPorCondominio(condominioId);
        runInAction(() => {
          if (data) this.mip1000 = data;
          else this.mip1000 = [];
          this.loading = false;
        });
      }
    } catch (e) {
      this.loading = false;
    }
  }

  async capturarSerial(equipamentoId: string) {
    this.loading = true;
    try {
      if (equipamentoId) {
        const { data } = await Mip1000API.capturarSerial(equipamentoId);
        runInAction(() => {
          if (data.serial !== 0) {
            this.captura = data;
          } else {
            toast.warning("Serial não identificado");
            this.captura = null;
          }

          this.loading = false;
        });
      }
    } catch (e) {
      this.loading = false;
    }
  }

  async obterRelePorId(id: string) {
    this.loading = true;
    try {
      if (id) {
        const { data } = await Mip1000API.obterRelePorId(id);
        runInAction(() => {
          if (data) this.infoRele = data;
          else this.infoRele = null;
          this.loading = false;
        });
      }
    } catch (e) {
      this.loading = false;
    }
  }

  async obterMuId(id: string) {
    this.loading = true;
    try {
      if (id) {
        const { data } = await Mip1000API.obterMuId(id);
        runInAction(() => {
          if (data) toast.success("MuId habilitado com sucesso");
          else toast.error("MuErro ao habilitar MuId");
          this.loading = false;
        });
      }
    } catch (e) {
      this.loading = false;
    }
  }

  async listarRelePorMip1000(mip1000Id: string) {
    this.loading = true;
    try {
      if (mip1000Id) {
        const { data } = await Mip1000API.listarRelePorMip1000Id(mip1000Id);
        runInAction(() => {
          if (data) this.releMip1000 = data;
          else this.releMip1000 = [];
          this.loading = false;
        });
      }
    } catch (e) {
      this.loading = false;
    }
  }

  definirSelecionadoRele(releId: string) {
    let selecionado = this.releMip1000.find((b) => b.id === releId);
    if (selecionado) {
      this.selecionadoRele = selecionado;
    } else this.selecionadoRele = null;
  }

  definirSelecionado(bravasId: string) {
    let selecionado = this.mip1000.find((b) => b.id === bravasId);
    if (selecionado) {
      this.selecionado = selecionado;
    } else this.selecionado = null;
  }
}

export default Mip1000Store;
