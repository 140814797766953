import { Veiculo, VeiculoRelatorio } from "../interfaces/Veiculo.interface";
import api from "../services/api/api";
import { Marcas } from '../interfaces/Marca.interface';
import { Cores } from '../interfaces/Cor.interface';

class VeiculoAPI {

    listarVeiculoPorCondominio = async (condominioId: string) => await api.get<Veiculo[]>(`/Veiculo/veiculos/${condominioId}`);

    listarVeiculoPorUnidade = async (unidadeId: string) => await api.get<Veiculo[]>(`/Veiculo/unidade/${unidadeId}`);

    listarRelatorioControleVeiculoPorUnidade = async (unidadeId: string) => await api.get<VeiculoRelatorio[]>(`Veiculo/controle/veiculo/${unidadeId}`);

    listarMarcas = async () => await api.get<Marcas[]>(`/Veiculo/marcas/0`);

    listarCores = async () => await api.get<Cores[]>(`/Veiculo/cores/0`);

    cadastrarVeiculo = async (request: Veiculo) => await api.post<Veiculo>(`/Veiculo`, request);

    atualizarVeiculo = async (request: Veiculo) => await api.put<Veiculo>(`/Veiculo`, request);

    deletarVeiculo = async (veiculoId: string) => await api.delete(`/Veiculo/${veiculoId}`);
}

export default new VeiculoAPI();