import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
  height: 100vh;
  width: 100%;

  .content {
    margin: 10px;
  }
`;
