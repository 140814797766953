import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Modal, SelectPicker } from 'rsuite';
import { useUnidade } from '../../hooks/Unidade.hook';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { StoreContext } from '../../stores';
import InputNumber from '../inputNumber';

interface ModalProps {
    show: boolean;
    hide: () => void;
    unidadeSelecionado?: Unidade | null;
}

const AtualizarUnidade: FC<ModalProps> = ({ show, hide, unidadeSelecionado }) => {

    const token = process.env.REACT_APP_BASE_TOKEN_BLOCOUNIDADE;
    const { blocoStore } = useContext(StoreContext);
    const [form, setForm] = useState<UnidadeRequest>({});
    const [status, setStatus] = useState('');
    const { atualizar, atualizando, erro, success } = useUnidade();
    const [value, setValue] = useState<number>(1);
    const min = 0;

    const handleCapacidadeChange = (type: 'plus' | 'minus' | 'set', value?: number) => {
        setForm(prev => {
            const current = prev.capacidadeLocacao || 0;
            let newValue = current;

            switch (type) {
                case 'plus':
                    newValue = current + 1;
                    break;
                case 'minus':
                    newValue = Math.max(0, current - 1); 
                    break;
                case 'set':
                    if (value !== undefined && value >= 0) {
                        newValue = value;
                    }
                    break;
            }

            return { ...prev, capacidadeLocacao: newValue };
        });
    };

    useEffect(() => {
        if (unidadeSelecionado) {
            setForm({
                ...unidadeSelecionado,
                capacidadeLocacao: unidadeSelecionado.capacidadeLocacao || 0
            });
        }
    }, [unidadeSelecionado]);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Unidade, hide);
    }

    if (success) {
        return (
            <Snackbar open={success} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Unidade cadastrado com sucesso!
                </Alert>
            </Snackbar>
        )
    }

    if (erro) {
        return (
            <Snackbar open={erro} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Erro ao cadastrar unidade
                </Alert>
            </Snackbar>
        )
    }

    const FormUnidade = () => {
        return (
            <Form onSubmit={(e) => salvar(e)}>

                <div className='d-flex'>
                    <Form.Group className=" p-1 col-6" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Apartamento</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Apartamento" value={form.apartamento?.toString().replace(/[^0-9]/g, '') || ''} onChange={(e) => setForm({ ...form, apartamento: parseInt(e.replace(/[^0-9]/g, '')) })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Identificação</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Identificação" value={form.identificacao || ''} onChange={(e) => setForm({ ...form, identificacao: e })} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className=" p-1 col-6" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Andar</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Andar" value={form.andar?.toString().replace(/[^0-9]/g, '' || '')} onChange={(e) => setForm({ ...form, andar: parseInt(e.replace(/[^0-9]/g, '')) })} />
                    </Form.Group>
                    <Form.Group className="mb-3 col-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Identificador NICE</Form.Label>
                        <Input required maxLength={4} type="text" className='shadow-none' placeholder="Identificador NICE" value={form.identificadorNice || ''} onChange={(e) => setForm({ ...form, identificadorNice: e })} />
                    </Form.Group>
                </div>

                <SelectPicker
                    block
                    placeholder={'Alterar unidade para outro bloco'}
                    data={blocoStore.blocos.map((bloco) => ({ label: bloco.nome, value: bloco.id }))}
                    disabledItemValues={[form.blocoId || '']}
                    value={form.blocoId}
                    onChange={(e) => setForm({ ...form, blocoId: e as any })}
                />

                <div>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ramal da unidade</Form.Label>
                        <Input placeholder='Selecione a unidade' value={form.ramalInterno} onChange={(e) => setForm({ ...form, ramalInterno: e })} />
                    </Form.Group>
                </div>

                <div>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Capacidade Locação</Form.Label>
                        <InputNumber
                            value={form.capacidadeLocacao || 0}
                            handleMinus={() => handleCapacidadeChange('minus')}
                            handlePlus={() => handleCapacidadeChange('plus')}
                            handleChange={(val) => handleCapacidadeChange('set', Number(val))}
                            min={min}
                            onChange={(value) => setForm({ ...form, capacidadeLocacao: value })}
                            title=''
                        />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-2'>
                    <Button appearance='primary' className='align-self-end shadow-none' type="submit">
                        {atualizando ? 'Salvando...' : 'Salvar'}
                    </Button>
                </div>
                {atualizando ? <LinearProgress color="primary" style={{ height: 2, marginTop: 15 }} /> : ''}
            </Form>
        )
    }

    return (
        <Modal open={show} onClose={hide} size='lg'>
            <Modal.Header closeButton>
                {
                    status !== 'error'
                        ?
                        <div>
                            <p className='modal-styled-title'>Atualização de unidade</p>
                            <p className='modal-styled-subtitle'>
                                Pree  cha todos os campos para fazer a atualização
                            </p>
                        </div>
                        :
                        <p className='modal-styled-title'>Token inválido</p>
                }
            </Modal.Header>
            <Modal.Body> {status !== 'error' ? FormUnidade() : <p className=''>Você digitou as credenciais incorretamente.</p>}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarUnidade);