import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import { Carousel} from "react-bootstrap";
import { StoreContext } from "../../stores";
import { Morador } from "../../interfaces/Morador.interface";
import {
  SelectPicker,
  Modal,
  Button,
  Loader,
  FlexboxGrid,
  InputPicker,
  InputNumber,
  Input,
  IconButton,
  List as ListArrow
} from "rsuite";
import {
  Container,
  ContentForm,
  HeaderComando,
} from "./styles/ComandoListagem.style";
import RenderComandos from "../utils/RenderComandos";
import { Comando, ComandoRequest } from "../../interfaces/Comando.interface";
import { useComando } from "../../hooks/Comando.hook";
import { Equipamento } from "../../interfaces/Equipamento.interface";
import AtualizarComando from "./AtualizarComando";
import { arrayMoveImmutable } from "array-move";
import InfoComando from "../acionamento/InfoComando";
import ModalConfirmacao from "../utils/ModalConfirmacao";
import DragableIcon from "@rsuite/icons/Dragable";
import { VscLayersActive } from "react-icons/vsc";
import SkeletonList from "../utils/SkeletonList";
import PlusIcon from "@rsuite/icons/Plus";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import NotFound from "../utils/NotFound";
import { ETipoModulo } from "../../enums/ETipoModulo";
import CustomInputNumber from "../inputNumber";

interface ModalProps {
  show: boolean;
  hide: () => void;
  moradorSelecionado?: Morador | null;
}

const ComandoListagem: FC<ModalProps> = ({ show, hide }) => {
  const {
    comandoStore,
    acionamentoStore,
    automacaoAcionamentoIdentificacaoStore,
    equipamentoStore,
    guaritaStore,
    releStore,
    siamStore,
    bravasStore,
    mip1000Store,
    zktecoStore,
    condominioStore
  } = useContext(StoreContext);
  const { cadastrar, salvando, _deletar, deletando, atualizarNovaOrdem } =
    useComando();
  const [guaritaSelecionado, setGuaritaSelecionado] = useState("");
  const [moduloSelecioando, setModuloSelecionado] = useState("");
  const [releSelecionado, setReleSelecionado] = useState("");
  const [mode, setMode] = useState("");
  const [ tempoRele, setTempoRele] = useState(0)
  const [comandoGuarita, setComandoGuarita] = useState<ComandoRequest>({});
  const [comandoEquipamento, setComandoEquipamento] = useState<ComandoRequest>(
    {}
  );
  const [equipamentoSelecionado, setEquipamentoSelecionado] =
    useState<Equipamento>();
  const [atualizar, setAtualizar] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [modalDeletar, setModalDeletar] = useState(false);
  const [index, setIndex] = useState(0);
  const [tipoControlId, setTipoControlId] = useState(2);
  const [tentativas, setTentativas] = useState(3);
  const handleMinus = () => {
    if (tentativas > 1) {
      setTentativas(tentativas - 1);
    }
  };
  const handlePlus = () => {
    if (tentativas < 3) {
      setTentativas(tentativas + 1);
    }
  };

  const handleChange = (newValue: string | number | null) => {
    if (typeof newValue === "number") {
      setTentativas(newValue);
    } else if (typeof newValue === "string") {
      const parsedValue = parseFloat(newValue);
      if (!isNaN(parsedValue)) {
        setTentativas(parsedValue);
      }
    }
  };

  const toggleAtualizar = () => setAtualizar(!atualizar);
  const toggleOpenInfo = () => setOpenInfo(!openInfo);
  const toggleModalDeletar = () => setModalDeletar(!modalDeletar);

  const deletarComando = () => {
    if (comandoStore.selecionado) {
      _deletar(comandoStore.selecionado);
    } else {
      alert("Nenhum comando selecionado");
    }
  };

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    comandoStore.comandosPorAcionamento = arrayMoveImmutable(
      comandoStore.comandosPorAcionamento,
      oldIndex,
      newIndex
    );
    comandoStore.comandosPorAcionamento.map(
      (comando, i) => (comando.ordem = i)
    );

    atualizarNovaOrdem(comandoStore.comandosPorAcionamento);
  };

  const guaritas = guaritaStore.guaritas.map((guarita) => ({
    label: `${guarita.nome} - ${guarita.porta}`,
    value: guarita.id,
  }));

  const modulos = guaritaStore.modulos.map((modulo) => ({
    label: `${ETipoModulo[modulo.tipo]} - ${modulo.enderecoCAN}`,
    value: modulo.id,
  }));

  const reles = releStore.reles.map((rele) => ({
    label: `${rele.numeroRele} - ${rele.nome}`,
    value: rele.id,
  }));

  const tiposRele = [
    {
        label: "Manter Aberto",
        value: 255
    },
    {
        label: "Fechar",
        value: 0
    },
    {
        label: "Abrir por tempo",
        value: 1
    }
]

  useEffect(() => {
    if (acionamentoStore.selecionado && show && condominioStore.selecionado) {
      comandoStore.listarPorAcionamento(acionamentoStore.selecionado.id);
      equipamentoStore.listarEquipamentosBiometriaPorCondominio(
        condominioStore.selecionado.id
      );
      bravasStore.listar();
      automacaoAcionamentoIdentificacaoStore.listar();
      mip1000Store.listarPorCondominio(
        condominioStore.selecionado.id
      );
      zktecoStore.listarPorCondominio(condominioStore.selecionado.id)
    }
  }, [acionamentoStore.selecionado, show]);

  useEffect(() => {
    if (show) {
      setGuaritaSelecionado("");
      setModuloSelecionado("");
      setReleSelecionado("");
      setMode("");
      setIndex(0);
    }
  }, [show]);

  const clear = () => {
    setComandoGuarita({});
    setComandoEquipamento({});
    setMode("");
  };

  const salvar = () => {
    if (acionamentoStore.selecionado && condominioStore.selecionado) {
      const condominioId = condominioStore.selecionado.id
      const acionamentoId = acionamentoStore.selecionado.id;

      switch (mode) {
        case "nice":
          comandoGuarita.condominioId = condominioId;
          comandoGuarita.acionamentoId = acionamentoId;
          comandoGuarita.tentativas = tentativas; // Corrigido
          cadastrar(comandoGuarita as Comando, clear);
          setIndex(0);
          break;

        case "equipamento":
          if (equipamentoSelecionado) {
            comandoEquipamento.condominioId = condominioId;
            comandoEquipamento.acionamentoId = acionamentoId;
            comandoEquipamento.tentativas = tentativas; // Corrigido
            comandoEquipamento.tipo = equipamentoSelecionado.tipoNome
              .toLowerCase()
              .includes("controlid")
              ? tipoControlId
              : equipamentoSelecionado.tipo;
            cadastrar(comandoEquipamento as Comando, clear);
            setIndex(0);
          }
          break;

        case "siam":
          comandoEquipamento.condominioId = condominioId;
          comandoEquipamento.acionamentoId = acionamentoId;
          comandoEquipamento.tipo = 9;
          comandoEquipamento.tentativas = tentativas; // Corrigido
          cadastrar(comandoEquipamento as Comando, clear);
          setIndex(0);
          break;

        case "automacao":
          comandoEquipamento.condominioId = condominioId;
          comandoEquipamento.acionamentoId = acionamentoId;
          comandoEquipamento.tipo = 10;
          comandoEquipamento.tentativas = tentativas; // Corrigido
          cadastrar(comandoEquipamento as Comando, clear);
          setIndex(0);
          break;

        case "bravas":
          comandoEquipamento.condominioId = condominioId;
          comandoEquipamento.acionamentoId = acionamentoId;
          comandoEquipamento.tipo = 11;
          comandoEquipamento.tentativas = tentativas; // Corrigido
          cadastrar(comandoEquipamento as Comando, clear);
          setIndex(0);
          break;

        case "mip1000":
          comandoEquipamento.condominioId = condominioId;
          comandoEquipamento.acionamentoId = acionamentoId;
          comandoEquipamento.tipo = 12;
          comandoEquipamento.tentativas = tentativas; // Corrigido
          cadastrar(comandoEquipamento as Comando, clear);
          setIndex(0);
          break;
        case 'zktecoInbio':
          comandoEquipamento.condominioId = condominioStore.selecionado.id
          comandoEquipamento.acionamentoId = acionamentoStore.selecionado.id;
          comandoEquipamento.tipo = 16// zkteco;
          comandoEquipamento.tentativas = tentativas;
          cadastrar(comandoEquipamento as Comando, clear);
          setIndex(0);
          break;
        case 'zktecoStandAlone':
            comandoEquipamento.condominioId = condominioStore.selecionado.id
            comandoEquipamento.acionamentoId = acionamentoStore.selecionado.id;
            comandoEquipamento.tipo = 17// zkteco;
            comandoEquipamento.tentativas = tentativas;
            cadastrar(comandoEquipamento as Comando, clear);
            setIndex(0);
            break;

        default:
          alert("Erro ao cadastrar comando");
      }
    }
  };

  const renderComandosCadastrado = () => {
    if (comandoStore.loading) {
      return (
        <div>
          <SkeletonList />
        </div>
      );
    }

    if (comandoStore.comandosPorAcionamento.length === 0)
      return <NotFound message={"Nenhum comando cadastrado"} />;

    const styleCenter = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "60px",
      // background: 'red'
    };

    return (
      <div className="mt-3">
        <ListArrow sortable onSort={handleSortEnd}>
          {comandoStore.comandosPorAcionamento.map((comando, index) => (
            <ListArrow.Item
              key={index}
              index={index}
              style={{
                background: "transparent",
                borderTop: "1px solid rgba(0,0,0,0.200)",
              }}
            >
              <FlexboxGrid
                className="d-flex justify-content-center"
                style={{ border: "none" }}
              >
                {/* <FlexboxGrid.Item colspan={7} style={styleCenter}>
                                    <div>
                                        <div className='d-flex'>
                                            <div style={{fontWeight: 550, fontSize: 12}}>Nome do acionamento</div>
                                        </div>
                                        <p style={{margin: 0, fontSize: 11}}>{comando.acionamentoNome}</p>
                                    </div>
                                </FlexboxGrid.Item> */}

                <FlexboxGrid.Item
                  colspan={5}
                  style={{
                    ...styleCenter,
                    flexDirection: "column",
                    alignItems: "flex-start",
                    overflow: "hidden",
                  }}
                >
                  <div>
                    <div className="d-flex">
                      <div style={{ fontWeight: 550, fontSize: 12 }}>
                        Tipo do comando
                      </div>
                    </div>
                    <p style={{ margin: 0, alignSelf: "center" }}>
                      {RenderComandos(comando.tipo)}
                    </p>
                  </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={7} style={styleCenter}>
                  <div>
                    <div className="d-flex">
                      <div style={{ fontWeight: 550, fontSize: 12 }}>Ordem</div>
                    </div>
                    <p
                      style={{ margin: 0, marginLeft: 10, alignSelf: "center" }}
                    >
                      {comando.ordem}
                    </p>
                  </div>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={4} style={styleCenter}>
                  <div style={{ textAlign: "center" }}>
                    <div className="d-flex">
                      <div style={{ fontWeight: 550, fontSize: 12 }}>
                        Tentativas
                      </div>
                    </div>
                    <p style={{ margin: 0, alignSelf: "center" }}>
                      {comando.tentativas || "-"}
                    </p>
                  </div>
                </FlexboxGrid.Item>

                {comando.tipo === 7 ? (
                  ""
                ) : (
                  <FlexboxGrid.Item
                    colspan={4}
                    style={{
                      ...styleCenter,
                    }}
                    onClick={() => comandoStore.definirSelecionado(comando.id)}
                  >
                    <div>
                      <div className="d-flex">
                        <div style={{ fontWeight: 550, fontSize: 12 }}>
                          Informações
                        </div>
                      </div>
                      <Button
                        color="blue"
                        disabled={
                          comando.tipo !== 0 &&
                          comando.tipo !== parseInt("1") &&
                          comando.tipo !== parseInt("10") &&
                          comando.tipo !== parseInt("11") &&
                          comando.tipo !== parseInt("12")
                        }
                        onClick={toggleOpenInfo}
                        appearance="primary"
                      >
                        Abrir
                      </Button>
                    </div>
                  </FlexboxGrid.Item>
                )}

                <FlexboxGrid.Item
                  colspan={4}
                  style={{
                    ...styleCenter,
                  }}
                >
                  {comando.tipo === 7 ||
                  comando.tipo === 11 ||
                  comando.tipo === 10 ? (
                    ""
                  ) : (
                    <Button
                      onClick={() => {
                        comandoStore.definirSelecionado(comando.id);
                        toggleAtualizar();
                      }}
                    >
                      Atualizar
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      comandoStore.definirSelecionado(comando.id);
                      toggleModalDeletar();
                    }}
                    disabled={deletando}
                    color="red"
                    appearance="primary"
                    style={{ marginLeft: 5 }}
                  >
                    {deletando ? "Deletando..." : "Deletar"}
                  </Button>
                </FlexboxGrid.Item>
              </FlexboxGrid>
              {/* <Dropdown title="Ajuste">
                                    <Dropdown.Item onClick={() => toggleAtualizar()}>Atualizar</Dropdown.Item>
                                    <Dropdown.Item onClick={() => _deletar(comando)}>{ () => _deletando ? "Deletando..." : "Deletar" }</Dropdown.Item>
                                </Dropdown> */}
            </ListArrow.Item>
          ))}
        </ListArrow>
      </div>
    );
  };

  const renderModulosGuaritasNice = () => {
    return (
        <ContentForm className='d-flesx justify-content-center mt-3'>
            <div>
                <div className='box d-flex justify-content-center'>
                    <div className={'w-100 m-1'}>
                        <p>Selecione o módulo guarita</p>
                        <InputPicker data={guaritas} placeholder={'Selecione o módulo guarita'} block onChange={(e) => {
                            setGuaritaSelecionado(e);
                            guaritaStore.listarModulo(e);
                        }} />
                    </div>

                    <div className={'m-1 w-100'}>
                        <p>Selecione o módulo</p>
                        <InputPicker disabled={!guaritaSelecionado} placeholder={'Selecione o módulo do guarita'} data={modulos} block onChange={(e) => {
                            setModuloSelecionado(e);
                            releStore.listar(e);
                        }} />
                    </div>
                </div>

                <div className='box d-flex justify-content-center mt-2'>
                    <div className={'w-100 m-1'}>
                        <p>Selecione o relé</p>
                        <InputPicker placeholder={'Selecione o rele'} disabled={!moduloSelecioando} data={reles} block onChange={(e) => {
                            setReleSelecionado(e)
                            setComandoGuarita({ ...comandoGuarita, caminhoId: e });
                        }} />
                    </div>
                    <div className={'w-100 m-1'}>
                        <p>Tipo do comando</p>
                        <InputPicker placeholder={'Selecione o tipo de comando'} disabled={!releSelecionado} data={
                            [
                                {
                                    label: 'GUARITA DIRETA',
                                    value: 0
                                },
                                {
                                    label: 'GUARITA SERVIDOR',
                                    value: 1
                                },
                                {
                                    label: 'GUARITA DIRETA MG3000',
                                    value: 7
                                },
                                {
                                    label: 'GUARITA SERVIDOR MG3000',
                                    value: 8
                                },
                            ]
                        } block onChange={(e) => {
                            setComandoGuarita({ ...comandoGuarita, tipo: e });
                        }} />
                    </div>

                </div>
                {comandoGuarita.tipo === 7 || comandoGuarita.tipo === 8 ? 
                <div className='box d-flex justify-content-center mt-2'>
                    <div className={'w-100 m-1'}>
                        <p>Tipo de rele</p>
                        <InputPicker placeholder={'Selecione o tipo do rele'} data={tiposRele} block onChange={(e) => {
                          setComandoGuarita({ ...comandoGuarita, tempoReleAtivo: e });
                          setTempoRele(e)
                        }} />
                    </div>
                    {tempoRele > 0 && tempoRele < 255 ?
                    <div className={'w-100 m-1'}>
                            <p>Tempo do Rele <span style={{fontSize: 10, opacity: 0.8, marginLeft: 3}}> max 254</span></p>
                        <Input placeholder='Tempo de abertura do rele' type='number' max={254} value={comandoGuarita.tempoReleAtivo}
                        onChange={(e) => {
                          let valor = Number(e);
                          if (!valor) 
                              valor = 1;
                          valor = Math.min(254, Math.max(1, valor));
                          setComandoGuarita({ ...comandoGuarita, tempoReleAtivo: valor });
                      }} 
                        />
                    </div>
                   : ""}
                </div>
                :""}

                <div className={'m-2'}>
                    <p>Ordem do comando</p>
                    <InputNumber style={{ width: '100%' }} onChange={(e) => {
                        setComandoGuarita({ ...comandoGuarita, ordem: Number(e) });
                    }} />
                </div>

                <div className='d-flex justify-content-end'>
                    <div className='d-flex'>
                        <Button
                            appearance='primary'
                            color={'violet'}
                            disabled={!releSelecionado || salvando}
                            onClick={salvar}
                        >
                            {salvando ? <Loader content="Salvando..." /> : 'Salvar'}
                        </Button>
                        <Button
                            style={{ marginLeft: 10 }}
                            onClick={hide}
                        >
                            Cancelar
                        </Button>
                    </div>
                </div>
            </div>
        </ContentForm>
    )
}

  const renderMenu = (dispositivos: any) => {
    if (siamStore.loading) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          <Loader content={"Buscando dispositivos... Aguarde!"} />
        </p>
      );
    }
    return dispositivos;
  };

  const renderModulosGuaritasSiam = () => {
    return (
      <div className="d-flesx justify-content-center mt-3">
        <div>
          <div className={"m-2"}>
            <p>Selecione o módulo SIAM</p>
            <SelectPicker
              placeholder={"Selecione"}
              data={siamStore.controladora.map((s) => ({
                label: s.nome,
                value: s.id,
              }))}
              renderMenu={renderMenu}
              onOpen={() => {
                if (condominioStore.selecionado) {
                  siamStore.listarControladoraPorCondominioId(
                    condominioStore.selecionado.id
                  );
                }
              }}
              block
              onChange={(e) => {
                if (e)
                  setComandoEquipamento({
                    ...comandoEquipamento,
                    caminhoId: e,
                  });
              }}
            />
          </div>
          <CustomInputNumber
            value={tentativas}
            handleMinus={handleMinus}
            handlePlus={handlePlus}
            handleChange={handleChange}
            min={1}
            max={3}
          />
          <div className={"m-2"}>
            <p>Selecione a ordem</p>
            <Input
              disabled={!comandoEquipamento.caminhoId}
              placeholder={"Informe a ordem do comando"}
              onChange={(e) => {
                setComandoEquipamento({
                  ...comandoEquipamento,
                  ordem: Number(e),
                });
              }}
            />
          </div>

          <div className="d-flex justify-content-end">
            <div className="d-flex">
              <Button
                appearance="primary"
                color={"violet"}
                disabled={salvando || !comandoEquipamento.caminhoId}
                onClick={salvar}
              >
                {salvando ? <Loader content="Salvando..." /> : "Salvar"}
              </Button>
              <Button style={{ marginLeft: 20 }} onClick={hide}>
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEquipamentosCadastrados = () => {
    return (
      <div className="">
        <div className={"m-2"}>
          <p>Selecione o equipamento</p>
          <InputPicker
            value={comandoEquipamento.caminhoId || ""}
            placeholder={"informe o equipamento cadastrado"}
            data={equipamentoStore.equipamentosBiometria.map((eq) => ({
              label: `${eq.nome} - ${eq.tipoNome}`,
              value: eq.equipamentoId,
            }))}
            block
            onChange={(e) => {
              var selecionado = equipamentoStore.equipamentosBiometria.find(
                (equipamento) => equipamento.equipamentoId === e
              );
              if (selecionado) setEquipamentoSelecionado(selecionado);
              setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
            }}
          />
        </div>
        {equipamentoSelecionado &&
        equipamentoSelecionado.tipoNome
          .toLocaleLowerCase()
          .includes("controlid") ? (
          <>
            <div className={"m-2"}>
              <p>Modelo do controlId</p>
              <InputPicker
                value={tipoControlId}
                placeholder={"Selecione"}
                data={[
                  { label: "Tag/Digital", value: 2 },
                  { label: "Tag/Facial", value: 13 },
                  { label: "Antena Veicular", value: 14 },
                ]}
                block
                onChange={(e) => {
                  setComandoEquipamento({ ...comandoEquipamento, tipo: e });
                  setTipoControlId(e);
                }}
              />
            </div>
            <div className={"m-2"}>
              <p>Rele de Acionamento</p>
              <InputPicker
                style={{ width: "100%" }}
                placeholder={"Informe rele de abertura"}
                data={[
                  { label: "Secbox", value: 0 },
                  { label: "Rele 1", value: 1 },
                  { label: "Rele 2", value: 2 },
                  { label: "Rele 3", value: 3 },
                  { label: "Rele 4", value: 4 },
                ]}
                onChange={(e) =>
                  setComandoEquipamento({
                    ...comandoEquipamento,
                    controlIdPorta: Number(e),
                  })
                }
              />
            </div>
          </>
        ) : (
          ""
        )}
        <div className={"m-2"}>
          <p>Ordem do comando</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder={"Informe a ordem do comando"}
            onChange={(e) =>
              setComandoEquipamento({ ...comandoEquipamento, ordem: Number(e) })
            }
          />
        </div>
        <CustomInputNumber
          value={tentativas}
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          handleChange={handleChange}
          min={1}
          max={3}
        />
        <div className="d-flex justify-content-end">
          <div className="d-flex">
            {equipamentoSelecionado &&
            equipamentoSelecionado.tipoNome
              .toLocaleLowerCase()
              .includes("controlid") ? (
              <Button
                appearance="primary"
                color={"violet"}
                disabled={
                  salvando ||
                  !comandoEquipamento.caminhoId ||
                  !tipoControlId ||
                  !comandoEquipamento.ordem ||
                  comandoEquipamento.controlIdPorta === null ||
                  comandoEquipamento.controlIdPorta === undefined
                }
                onClick={salvar}
              >
                {salvando ? <Loader content="Salvando..." /> : "Salvar"}
              </Button>
            ) : (
              <Button
                appearance="primary"
                color={"violet"}
                disabled={salvando || !comandoEquipamento.caminhoId}
                onClick={salvar}
              >
                {salvando ? <Loader content="Salvando..." /> : "Salvar"}
              </Button>
            )}
            <Button style={{ marginLeft: 20 }} onClick={hide}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderAutomacao = () => {
    return (
      <div className="">
        <div className={"m-2"}>
          <p>Selecione a automação cadastrada</p>
          <InputPicker
            placeholder={"informe o equipamento cadastrado"}
            data={automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao.map(
              (a) => ({ label: `${a.nome}`, value: a.id })
            )}
            block
            onChange={(e) => {
              setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
            }}
          />
        </div>
        <div className={"m-2"}>
          <p>Ordem do comando</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder={"Informe a ordem do comando"}
            onChange={(e) =>
              setComandoEquipamento({ ...comandoEquipamento, ordem: Number(e) })
            }
          />
        </div>
        <CustomInputNumber
          value={tentativas}
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          handleChange={handleChange}
          min={1}
          max={3}
        />
        <div className="d-flex justify-content-end">
          <div className="d-flex">
            <Button
              appearance="primary"
              color={"violet"}
              disabled={salvando || !comandoEquipamento.caminhoId}
              onClick={salvar}
            >
              {salvando ? <Loader content="Salvando..." /> : "Salvar"}
            </Button>
            <Button style={{ marginLeft: 20 }} onClick={hide}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderBravas = () => {
    return (
      <div className="">
        <div className={"m-2"}>
          <p>Selecione o módulo de acesso BRAVAS</p>
          <InputPicker
            placeholder={"informe o equipamento cadastrado"}
            data={bravasStore.bravas.map((b) => ({
              label: `${b.nome} - ${b.ip}`,
              value: b.id,
            }))}
            block
            onChange={(e) => {
              bravasStore.listarRelesPorBravasId(e);
            }}
          />
        </div>
        <div className={"m-2"}>
          <p>Selecione o relê</p>
          <InputPicker disabled={bravasStore.reles.length === 0} placeholder={'Selecione o relê'} data={ bravasStore.reles.length > 0 ? bravasStore.reles?.map((r) => ({ label: `${r.nome}`, value: r.id })) : []} block onChange={(e) => {
                        setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
                    }} />
        </div>
        <CustomInputNumber
          value={tentativas}
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          handleChange={handleChange}
          min={1}
          max={3}
        />
        <div className="d-flex justify-content-end">
          <div className="d-flex">
            <Button
              appearance="primary"
              color={"violet"}
              disabled={salvando || !comandoEquipamento.caminhoId}
              onClick={salvar}
            >
              {salvando ? <Loader content="Salvando..." /> : "Salvar"}
            </Button>
            <Button style={{ marginLeft: 20 }} onClick={hide}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderMip1000 = () => {
    return (
      <div className="">
        <div className={"m-2"}>
          <p>Selecione o mip1000</p>
          <InputPicker
            placeholder={"informe que equipamento cadastrado"}
            data={mip1000Store.mip1000.map((m) => ({
              label: `${m.nome} - ${m.ip}`,
              value: m.id,
            }))}
            block
            onChange={(e) => {
              mip1000Store.listarRelePorMip1000(e);
            }}
          />
        </div>
        <div className={"m-2"}>
          <p>Selecione o relê</p>
          <InputPicker
            disabled={mip1000Store.releMip1000.length === 0}
            placeholder={"Selecione o relê"}
            data={mip1000Store.releMip1000.map((r) => ({
              label: `${r.nome}`,
              value: r.id,
            }))}
            block
            onChange={(e) => {
              setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
            }}
          />
        </div>
        <CustomInputNumber
          value={tentativas}
          handleMinus={handleMinus}
          handlePlus={handlePlus}
          handleChange={handleChange}
          min={1}
          max={3}
        />
        <div className="d-flex justify-content-end">
          <div className="d-flex">
            <Button
              appearance="primary"
              color={"violet"}
              disabled={salvando || !comandoEquipamento.caminhoId}
              onClick={salvar}
            >
              {salvando ? <Loader content="Salvando..." /> : "Salvar"}
            </Button>
            <Button style={{ marginLeft: 20 }} onClick={hide}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderZktecoControladora = () => {
    return (
        <div className=''>
            <div className={'m-2'}>
                <p>Selecione o equipamento ZKteco</p>
                <InputPicker placeholder={'informe o equipamento cadastrado'} data={equipamentoStore.zktecoInbio.map((b) => ({ label: `${b.nome} - ${b.ip}`, value: b.id }))} block onChange={(e) => {
                    zktecoStore.listarRelePorZkteco(e); 
                }} />
            </div>
            <div className={'m-2'}>
                <p>Selecione o relê</p>
                <InputPicker disabled={zktecoStore.releZkteco.length === 0} placeholder={'Selecione o relê'} data={zktecoStore.releZkteco.map((r) => ({ label: `${r.nome}`, value: r.id }))} block onChange={(e) => {
                    setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
                }} />
            </div>
            <CustomInputNumber
              value={tentativas}
              handleMinus={handleMinus}
              handlePlus={handlePlus}
              handleChange={handleChange}
              min={1}
              max={3}
            />
            <div className='d-flex justify-content-end'>
                <div className='d-flex'>
                    <Button
                        appearance='primary'
                        color={'violet'}
                        disabled={salvando || !comandoEquipamento.caminhoId }
                        onClick={salvar}
                    >
                        {salvando ? <Loader content="Salvando..." /> : 'Salvar'}
                    </Button>
                    <Button
                        style={{ marginLeft: 20 }}
                        onClick={hide}
                    >
                        Cancelar
                    </Button>
                </div>
            </div>
        </div>
    )
  }

  const renderZktecoStandAlone = () => {
    return (
        <div className=''>
            <div className={'m-2'}>
                <p>Selecione o equipamento ZKteco</p>
                <InputPicker placeholder={'informe que equipamento cadastrado'} data={equipamentoStore.zktecoStandAlone.map((b) => ({ label: `${b.nome} - ${b.ip}`, value: b.id }))} block onChange={(e) => {
                    zktecoStore.listarRelePorZkteco(e); 
                    setComandoEquipamento({ ...comandoEquipamento, caminhoId: e });
                }} />
            </div>
            <CustomInputNumber
              value={tentativas}
              handleMinus={handleMinus}
              handlePlus={handlePlus}
              handleChange={handleChange}
              min={1}
              max={3}
            />
            <div className='d-flex justify-content-end'>
                <div className='d-flex'>
                    <Button
                        appearance='primary'
                        color={'violet'}
                        disabled={salvando || !comandoEquipamento.caminhoId}
                        onClick={salvar}
                    >
                        {salvando ? <Loader content="Salvando..." /> : 'Salvar'}
                    </Button>
                    <Button
                        style={{ marginLeft: 20 }}
                        onClick={hide}
                    >
                        Cancelar
                    </Button>
                </div>
            </div>
        </div>
    )
  }


  const renderRoutes = () => {
    switch (mode) {
      case "nice":
        return renderModulosGuaritasNice();
        break;
      case "siam":
        return renderModulosGuaritasSiam();
        break;
      case "bravas":
        return renderBravas();
        break;
      case "equipamento":
        return renderEquipamentosCadastrados();
        break;
      case "mip1000":
        return renderMip1000();
        break;
      case "automacao":
        return renderAutomacao();
        break;
      case 'zktecoInbio':
        return renderZktecoControladora();
        break;
      case 'zktecoStandAlone':
        return renderZktecoStandAlone();
        break;
    }

    return <div></div>;
  };

  const renderForm = () => {
    return <div>{renderRoutes()}</div>;
  };

  return (
    <Modal
      backdrop={"static"}
      keyboard={false}
      size={"lg"}
      open={show}
      onClose={hide}
    >
      <Modal.Header closeButton>
        <div className="p-1">
          <p className="modal-styled-title">Cadastro de comandos</p>
          <p className="modal-styled-subtitle">
            Cadastre novos comandos no acionamento{" "}
            <b>{acionamentoStore.selecionado?.nome}</b>
          </p>
          {/* <p className='modal-styled-subtitle'>Prrencha todos os campos para cadastrar as configurações da nova automação do acionamento</p> */}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Carousel
            variant="dark"
            indicators={false}
            controls={false}
            activeIndex={index}
          >
            <Carousel.Item>
              <div>
                <HeaderComando>
                  <div className="d-flex box">
                    <div className="content d-flex">
                      <VscLayersActive className="content-icon" />
                      <div>
                        <p className="content-subtitle">
                          {comandoStore.comandosPorAcionamento.length}{" "}
                          cadastrado no acionamento{" "}
                          {acionamentoStore.selecionado?.nome}{" "}
                        </p>
                      </div>
                    </div>

                    <div
                      style={{ marginLeft: 30 }}
                      className="align-self-center"
                    >
                      <div className="d-flex">
                        <DragableIcon
                          style={{
                            fontSize: 20,
                            alignSelf: "center",
                            marginRight: 10,
                          }}
                        />
                        <p style={{ fontSize: 12, width: 200 }}>
                          Selecione o comando e arraste para cima ou para baixo
                        </p>
                      </div>
                    </div>

                    <div
                      style={{ marginLeft: 30 }}
                      className="align-self-center"
                    >
                      <p className="info-title" style={{ width: 100 }}>
                        Cadastrar novo comando
                      </p>
                      <IconButton
                        appearance="primary"
                        color="violet"
                        icon={<PlusIcon />}
                        onClick={() => setIndex(1)}
                      >
                        Cadastrar
                      </IconButton>
                    </div>
                  </div>
                </HeaderComando>
                {renderComandosCadastrado()}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div>
                <HeaderComando>
                  <div className="d-flex box">
                    <div className="content d-flex">
                      <VscLayersActive className="content-icon" />
                      <div>
                        <p className="content-subtitle">
                          Informe o tipo de comando para realizar o cadastro
                        </p>
                      </div>
                    </div>

                    <div
                      style={{ marginLeft: 30 }}
                      className="align-self-center"
                    >
                      <p className="info-title">Voltar para a lista</p>
                      <IconButton
                        appearance="primary"
                        color="violet"
                        icon={<PagePreviousIcon />}
                        onClick={() => setIndex(0)}
                      >
                        Voltar
                      </IconButton>
                    </div>

                    <div
                      style={{ marginLeft: 20 }}
                      className="align-self-center"
                    >
                      <div className="align-self-center">
                        <p style={{ margin: 0, width: 200 }}>
                          Como você deseja criar um novo comando?
                        </p>
                        <InputPicker
                          placeholder={"Selecione o modo de cadastro"}
                          value={mode}
                          data={[
                            { label: "Nice", value: "nice" },
                            { label: "Siam", value: "siam" },
                            { label: "Bravas", value: "bravas" },
                            {
                              label: "ControlId/Anviz/Hikvision/Intelbras",
                              value: "equipamento",
                            },
                            { label: "Mip1000", value: "mip1000" },
                            { label: "ZktecoInbio", value: "zktecoInbio" },
                            { label: "ZktecoStandAlone", value: "zktecoStandAlone" },
                            { label: "Automação", value: "automacao" },
                          ]}
                          onChange={(e) => setMode(e)}
                          style={{ width: 300 }}
                        />
                      </div>
                    </div>
                  </div>
                </HeaderComando>
                <div className="mt-4">{renderForm()}</div>
              </div>
            </Carousel.Item>
          </Carousel>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div>
          {/* {
                        index === 1
                        ?
                        <Button
                            style={{ marginRight: 40 }}
                            onClick={() => setIndex(0)}
                        >
                            voltar
                        </Button>
                        :
                        <Button
                            style={{ marginRight: 40 }}
                            onClick={() => setIndex(1)}
                        >
                            cadastrar
                        </Button>
                    } */}
          {index === 1 ? (
            <div />
          ) : (
            <Button style={{ marginRight: 40 }} onClick={() => hide()}>
              Fechar
            </Button>
          )}
        </div>
      </Modal.Footer>
      <AtualizarComando show={atualizar} hide={toggleAtualizar} />
      <InfoComando show={openInfo} hide={toggleOpenInfo} />
      <ModalConfirmacao
        show={modalDeletar}
        hide={toggleModalDeletar}
        action={deletarComando}
        content={"Você tem certeza que deseja deletar esse comando?"}
        loading={deletando}
      />
    </Modal>
  );
};

export default observer(ComandoListagem);
