import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { StoreContext } from '../../stores';
import { useAcionamentoPerfil } from '../../hooks/AcionamentoPerfil.hook';
import { Acionamento } from '../../interfaces/Acionamento.interface';
import { IPerfil, IPerfilRequest } from '../../interfaces/AcionamentoPerfil.interface';
import { Button, Input, Loader, Modal, Toggle } from 'rsuite';

interface PropsModal {
    show: boolean;
    hide: () => void;
    perfilSelecionado: IPerfil | null;
}

interface IAcionamento {
    id: string;
    nome: string;
}

const AtualizarPerfil: FC<PropsModal> = ({ show, hide, perfilSelecionado }) => {

    const { acionamentoStore, acionamentoPerfilStore, condominioStore } = useContext(StoreContext);
    const [form, setForm] = useState<IPerfilRequest>({});
    const [acionamentos, setAcionamentos] = useState<string[]>([]);
    const { atualizar, atualizando } = useAcionamentoPerfil();

    const AtualizarPerfil = async () => {
        const payload = {
            ...form,
            acionamentos: acionamentos.map((id) => {
                const acionamento = acionamentoStore.acionamentos.find((a) => a.id === id);
                return {
                    id: acionamento?.id,
                    nome: acionamento?.nome,
                    condominioId: acionamentoPerfilStore.listarPorCondominio,
                };
            }),
        };

        try {
            await atualizar(payload as IPerfil, hide);
            await acionamentoPerfilStore.listar();
        } catch (error) {
            console.error('Erro ao atualizar o perfil:', error);
        }
    };

    useEffect(() => {
        if (perfilSelecionado) {
            setAcionamentos(perfilSelecionado.acionamentos.map(x => x.id));
        }
    }, [perfilSelecionado])

    useEffect(() => {
        if (perfilSelecionado) {
            setForm({
                ...perfilSelecionado,
                acionamentosIds: acionamentos
            });
        }
    }, [perfilSelecionado, acionamentos]);

    const handleChange = (checked: boolean, acionamento: Acionamento) => {
        if (checked) { 
            setAcionamentos((prev) => [...prev, acionamento.id]);
        } else {
            setAcionamentos((prev) => prev.filter((id) => id !== acionamento.id));
        }
    };

    const FormPerfil = () => {
        return (
            <div>
                <div>
                    <p className={'title mt-2'}>Informe o nome do perfil</p>
                    <Input placeholder="Nome do perfil" value={form.nome} onChange={(value) => setForm({ ...form, nome: value })}/>
                </div>
                <div>
                    <p className={'title mt-2'}>Selecione os acionamentos para esse perfil</p>
                    <div className='d-flex flex-wrap'>
                        {
                            acionamentoStore.acionamentos.map((acionamento, i) => (
                                <div className='m-2' key={i}>
                                    <p>{acionamento.nome}</p>
                                    <Toggle checked={form.acionamentosIds?.includes(acionamento.id)} onChange={(checked) => handleChange(checked, acionamento)} />

                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastre o novo perfíl</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormPerfil()}</Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" disabled={atualizando || form.nome?.length === 0} onClick={AtualizarPerfil}>{atualizando ? <Loader title='Atualizando...' /> : 'Salvar'}</Button>
                <Button appearance="default" disabled={atualizando} onClick={hide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarPerfil);