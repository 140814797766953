import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Modal, SelectPicker } from 'rsuite';
import { Zkteco, ZktecoRequest } from '../../../interfaces/Zkteco.interface';
import { StoreContext } from '../../../stores';
import { useZkteco } from '../../../hooks/Zkteco.hook';
import { MODELOS_ZKTECO } from '../../../stores/EquipamentoStore';
interface ModalProps {
    show: boolean;
    hide: () => void;
}

const CadastrarZkteco: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<ZktecoRequest>({});
    const { cadastrar, salvando } = useZkteco();
    const { condominioStore } = useContext(StoreContext);
    const [error, setError] = useState("")
    const data = MODELOS_ZKTECO.INBIO.map(
        item => ({ label: item, value: item })
      );


    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(condominioStore.selecionado)
        {
            form.condominioId = condominioStore.selecionado.id;
            if(!form.modelo || !form.ip || !form.nome || !form.porta){
                setError("Preencha todos os campos para cadastrar")
            } else {
                cadastrar(form as Zkteco, hide, () => {});
                setError("")
            }
            
        }
    }

    const handleChange = (value: string | null) => {
        const modeloEquip = data.find((item) => item.value === value)
        setForm({...form, modelo: modeloEquip?.value})
    }

    const FormCard = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>IP</Form.Label>
                        <Input type="text" value={form.ip || ''} className='shadow-none' placeholder="192.168.0.0" onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input type="number" value={form.porta} className='shadow-none' placeholder="Ex: 80" onChange={(e) => setForm({...form, porta: parseInt(e)})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="mb-3 col-md-6 p-1" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input type="text" className='shadow-none' value={form.nome || ''} placeholder="Nome" onChange={(e) => setForm({...form, nome: e})}  />
                    </Form.Group>
                    <Form.Group className=" col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <br></br>
                        <SelectPicker cleanable={false} searchable={false} style={{width: "100%"}} placeholder="Modelo" data={data} className='shadow-none' value={form.modelo || 'aaaaaS'} onChange={handleChange} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-center mt-1 mb-1'>
                    <p className='text-danger'>{error}</p>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        { salvando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar novo equipamento ZKTECO</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormCard()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarZkteco);