import { Alert, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Loader, Modal } from 'rsuite';
import { useUnidade } from '../../hooks/Unidade.hook';
import { Bloco } from '../../interfaces/Bloco.interface';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { CardItem } from './styles/Bloco.style';
import { BsTrash } from 'react-icons/bs';
import InputNumber from '../inputNumber';

interface ModalProps {
    show: boolean;
    hide: () => void;
    blocoSelecionado?: Bloco | null;
}

const CadastrarUnidade: FC<ModalProps> = ({ show, hide, blocoSelecionado }) => {

    const token = process.env.REACT_APP_BASE_TOKEN_BLOCOUNIDADE;
    const [list, setList] = useState<UnidadeRequest[]>([]);
    const [form, setForm] = useState<UnidadeRequest>({});
    const [status, setStatus] = useState('');
    const { cadastrarMultiplos, salvando, erro, success } = useUnidade();
    const min = 0;

    const handleCapacidadeChange = (type: 'plus' | 'minus' | 'set', value?: number) => {
        setForm(prev => {
            const current = prev.capacidadeLocacao || 0;
            let newValue = current;
    
            switch(type) {
                case 'plus': 
                    newValue = current + 1;
                    break;
                case 'minus':
                    newValue = Math.max(0, current - 1); 
                    break;
                case 'set':
                    if (value !== undefined && value >= 0) {
                        newValue = value;
                    }
                    break;
            }
            
            return { ...prev, capacidadeLocacao: newValue };
        });
    };

    useEffect(() => {
        if(blocoSelecionado) {
            setForm({
                ...form,
                condominioId: blocoSelecionado.condominioId,
                blocoId: blocoSelecionado.id,
                blocoNome: blocoSelecionado.nome,
                capacidadeLocacao: form.capacidadeLocacao || 0 
            });
        }
    }, [blocoSelecionado]);

    useEffect(() => {
        setList([])
        list.push();
    }, [show]);

    const addMais = () => {
        if(blocoSelecionado)
        {
            form.condominioId = blocoSelecionado.condominioId;
            form.blocoId = blocoSelecionado.id;
            form.blocoNome = blocoSelecionado.nome;
            form.capacidadeLocacao = form.capacidadeLocacao || 1
            list.push(form);
            setForm({});
        }
        else
        {
            alert('Erro interno');
        }
    }

    const salvar = () => {
        setForm({});
        if(list.length === 0)
        {
            if(blocoSelecionado)
            {
                form.condominioId = blocoSelecionado.condominioId;
                form.blocoId = blocoSelecionado.id;
                form.blocoNome = blocoSelecionado.nome;
                
                list.push(form)
                cadastrarMultiplos(list as Unidade[], hide)
                list.push()
            }
            else
            {
                alert('Erro interno');
            }
            
        }
        else
        {
            if(blocoSelecionado)
            {
                form.condominioId = blocoSelecionado.condominioId;
                form.blocoId = blocoSelecionado.id;
                form.blocoNome = blocoSelecionado.nome;
                
                cadastrarMultiplos(list as Unidade[], hide)
            }
            else
            {
                alert('Erro interno');
            }
        }
    }

    const isFormValid = () => { return form.apartamento != null && form.identificadorNice && form.ramalInterno && form.andar && (form.capacidadeLocacao ?? 0) >= min; };

    const isButtonDisabled = () => { return ( salvando || !form || status === 'error' || !isFormValid() ); };

    const handleRemoveItem = (index) => {
        const newList = list.filter((_, i) => i !== index);
        setList(newList);
    };

    if(success) {
        return(
            <Snackbar open={success} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                Unidade cadastrado com sucesso!
                </Alert>
            </Snackbar>
        )
    }

    if(erro) {
        return(
            <Snackbar open={erro} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Erro ao cadastrar unidade
                </Alert>
            </Snackbar>
        )
    }

    const FormUnidade = () => {
        return(
            <Form>
                
                <div className='d-flex p-1'>
                    <Form.Group className=" p-1 col-6" controlId="formBasicEmail">
                        <Form.Label>Apartamento<span className='text-danger'>*</span></Form.Label>
                        <Input required type="number" className='shadow-none' placeholder="Apartamento" value={form?.apartamento?.toString().replace(/[^0-9]/g, '') ?? ''} onChange={(e) => setForm({ ...form, apartamento: parseInt(e.replace(/[^0-9]/g, '')) })} />
                    </Form.Group>
                    <Form.Group className="mb-1 col-6 p-1" controlId="formBasicEmail">
                        <Form.Label>Identificação<span className='text-danger'>*</span></Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Identificação" value={form.identificacao ?? ''} onChange={(e) => setForm({ ...form, identificacao: e })} />
                    </Form.Group>
                </div>

                <div className='d-flex p-1'>
                    <Form.Group className=" p-1 col-6" controlId="formBasicEmail">
                        <Form.Label>Andar<span className='text-danger'>*</span></Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Andar" value={form?.andar?.toString().replace(/[^0-9]/g, '') ?? ''} onChange={(e) => setForm({ ...form, andar: parseInt(e.replace(/[^0-9]/g, '') )})} />
                    </Form.Group>
                    <Form.Group className="mb-1 col-6 p-1" controlId="formBasicEmail">
                        <Form.Label>Identificador NICE<span className='text-danger'>*</span></Form.Label>
                        <Input required type="text" maxLength={4} className='shadow-none' placeholder="Identificador NICE" value={form.identificadorNice ?? ''} onChange={(e) => setForm({ ...form, identificadorNice: e })} />
                    </Form.Group>
                </div>

                <div>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label>Ramal da unidade<span className='text-danger'>*</span></Form.Label>
                        <Input placeholder='Selecione a unidade' value={form.ramalInterno ?? ''} onChange={(e) => setForm({ ...form, ramalInterno: e })} />
                    </Form.Group>
                </div>

                <div>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label>Capacidade Locação<span className='text-danger'>*</span></Form.Label>
                        <InputNumber
                            value={form.capacidadeLocacao || 0}
                            handleMinus={() => handleCapacidadeChange('minus')}
                            handlePlus={() => handleCapacidadeChange('plus')}
                            handleChange={(val) => handleCapacidadeChange('set', Number(val))}
                            min={min}
                            onChange={(e) => setForm({ ...form, capacidadeLocacao: e })}
                            title=''
                        />
                    </Form.Group>
                </div>

                {
                        list.map((m, i) =>
                        (
                            <CardItem className='d-flex justify-content-between p-3'>
                                <div className='d-flex'>
                                    <div className='mt-1'>
                                        <p className='title-item'><b>Apartamento:</b> &nbsp;{m.apartamento}</p><br/>
                                        <p className='subtitle-item'><b>Identificação:</b> &nbsp;{m.identificadorNice?.toLocaleUpperCase()}</p>
                                    </div>
                                    <div className='mt-1' style={{marginLeft: 30}}>
                                        <p className='title-item'><b>Andar:</b> &nbsp;{m.andar}</p><br/>
                                        <p className='subtitle-item'><b>Ramal da unidade:</b> &nbsp;{m.ramalInterno?.toLocaleUpperCase()}</p>
                                    </div>
                                </div>
                                <BsTrash className='item-icon align-self-center' size={20} color='red@H&s@2@23@' role={'button'} onClick={() => handleRemoveItem(i)} />
                            </CardItem>
                        ))
                    }

                    <Button disabled={!form.apartamento || !form.identificadorNice || !form.ramalInterno || !form.andar} appearance='primary' color='violet' size={'md'} className='mt-3' onClick={addMais}>Adicionar outro bloco</Button>
                </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size='md'>
            <Modal.Header closeButton>
                {
                    status !== 'error'
                    ?
                    <div>
                        <p className='modal-styled-title'>Cadastro de unidades</p>
                        <p className='modal-styled-subtitle'>
                            Cadastre novas unidades para efetuar outros tipos de cadastros
                        </p>
                    </div>
                    :
                    <p className='modal-styled-title'>Token inválido</p>
                }
            </Modal.Header>
            <Modal.Body> { status !== 'error' ? FormUnidade() : <p className=''>Você digitou as credenciais incorretamente.</p>}</Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" disabled={isButtonDisabled()} onClick={salvar}>{ salvando ? <Loader title='Salvando...' /> : 'Salvar' }</Button>
                <Button appearance="default" disabled={salvando} onClick={hide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(CadastrarUnidade);