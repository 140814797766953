import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import React, { FC, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { useRele } from '../../hooks/Rele.hook';
import { Rele, ReleRequest } from '../../interfaces/Rele.interface';
import { useRede } from '../../hooks/Rede.hook';
import { Rede, RedeRequest } from '../../interfaces/Rede.interface';
import { StoreContext } from '../../stores';
import { FormContainer } from './styles/ModalCadastrar.style';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'rsuite';

interface PropsModal {
    show: boolean;
    hide: () => void;
}

const ModalCadastrar: FC<PropsModal> = ({ show, hide }) => {
    
    let inputRef = useRef<HTMLInputElement>(null);
    const { condominioStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useRede();
    
    const [form, setForm] = useState<RedeRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrar(form as Rede, hide);
    }

    useEffect(() => {
        setForm({
            ...form,
            condominioId: condominioStore.selecionado?.id
        });
    }, []);

    useEffect(() => {
        if(inputRef.current && show)
        {
            inputRef.current.focus();
        }
    }, [show]);

    const renderForm = () => {
        return(
            <FormContainer onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-12 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Subrede</Form.Label>
                        <Form.Control ref={inputRef} required type="text" className='shadow-none input' placeholder="192.168.0.0/10" value={form.subRede || ''} onChange={(e) => setForm({...form, subRede: e.target.value})} />
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-3'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        { salvando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                </FormContainer>
        )
    }

    return(
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastre nova rede</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
    )
}

export default observer(ModalCadastrar);