import { LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { StoreContext } from '../../../stores';
import useEquipamento from '../../../hooks/Equipamento.hook';
import { Button, Input, Modal, SelectPicker } from 'rsuite';
import { Zkteco, ZktecoRequest } from '../../../interfaces/Zkteco.interface';

interface ModalProps {
    show: boolean;
    hide: () => void;
    condominioId: any;
}

const AtualizarZkteco: FC<ModalProps> = ({ show, hide, condominioId }) => {

    const { equipamentoStore } = useContext(StoreContext);
    const { atualizarZkteco, atualizandoZkteco } = useEquipamento();
    const [form, setForm] = useState<ZktecoRequest>({});
    const [valueSelectPicker, setValueSelectPicker] = useState('')

    const salvar = (e: FormEvent<HTMLFormElement>) => {

        if (valueSelectPicker === null || valueSelectPicker === undefined){
            alert("Erro")
        }
        else
        e.preventDefault();
        form.condominioId = condominioId
        atualizarZkteco(form as Zkteco, hide);
    }

    useEffect(() => {
        if(equipamentoStore.zktecoSelecionado && show) {
            setForm( equipamentoStore.zktecoSelecionado);
        } else {
            setForm({});
        }
    }, [equipamentoStore.zktecoSelecionado, show]);

    const FormEquipamento = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="192.168.XXX.XXX" value={form.ip || ''} onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input required className='shadow-none' placeholder="80" value={form.porta || ''} onChange={(e) => setForm({...form, porta: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Nome" value={form.nome || ''} onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input disabled value={form.modelo?.toUpperCase()}/>
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance='primary' disabled={atualizandoZkteco} type="submit">
                        { atualizandoZkteco ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={atualizandoZkteco} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                </div>
                {atualizandoZkteco ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size='lg'>
            <Modal.Header closeButton>
            <div>
                    <p className='modal-styled-title'>Atualizar Zkteco - {equipamentoStore.zktecoSelecionado ? equipamentoStore.zktecoSelecionado.nome : '-'}</p>
                    <p className='modal-styled-subtitle'>
                        Atualização de equipamentos
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormEquipamento()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarZkteco);