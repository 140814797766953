import styled from "styled-components";

export const Container = styled.form`
    .header
    {
        padding: 5px;
        background-color: ${(props) => props.theme.colors.bgBox};
        border-radius: 4px;
    }
    .title
    {
        margin: 0;
        font-size: 10pt;
        font-weight: 550;
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;

    }
    .tag
    {
        height: 100px;
    }

    .info-tag
    {
        // width: 500px;
        align-self: center;
    }
`;

export const ListTags = styled.div`
    background: ${(props) => props.theme.colors.bgColor};
    thead th {
        color: rgba(0,0,0,0.8);
        font-weight: 500;
        // border-bottom: 1px solid rgba(0,0,0,0.1);
        padding: 15px;
    }
    tbody td {
        color: rgba(0,0,0,0.7);
        font-weight: 500;
        font-size: 9pt;
        margin: 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding: 15px;
    }
    #serial {
        color: #fff;
        padding: 5px;
        background: #004C95;
        border-radius: 10px;
    }
    .btn-add {
        margin: 0;
        border: 0;
        padding: 10px;
        width: 100%;
        background: #004C95;
        color: #fff;
        box-shadow: 1px 2px 5px rgba(0,0,0,0.3);
        font-size: 10pt;
        font-weight: 500;
        border-radius: 12px;
    }
`;  

export const ContainerTable = styled.div`
    // background: ${(props) => props.theme.colors.bgColor};
    border: none;
    thead th {
        font-weight: 500;
        // border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
        border: none;
    }
    tbody td {
        font-weight: 500;
        font-size: 9pt;
        margin: 0;
        border-top: 1px solid ${(props) => props.theme.colors.borderColor};
        // padding: 15px;
    }
    #serial {
        padding: 5px;
        background: #004C95;
        border-radius: 10px;
    }
    .btn-add {
        margin: 0;
        border: 0;
        padding: 10px;
        width: 100%;
        background: #004C95;
        color: #fff;
        box-shadow: 1px 2px 5px rgba(0,0,0,0.3);
        font-size: 10pt;
        font-weight: 500;
        border-radius: 12px;
    }
`;  