import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState, useRef } from "react";
import { Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  InputPicker,
  SelectPicker,
  Pagination,
  Input,
  Button,
  Popover,
  Whisper,
  Modal,
  Loader,
  IconButton,
  InputGroup,
  DateRangePicker,
  DatePicker,
  TagGroup,
  Tag,
} from "rsuite";
import { useDispositivo } from "../../hooks/Dispositivo.hook";
import { StoreContext } from "../../stores";
import {
  converterData,
  converterDataHora,
  converterHora,
  cpfMask,
  maskCelular,
  maskTelefoneFixo,
} from "../utils/mask";
import NotFound from "../utils/NotFound";
import RenderTipoLog from "../utils/RenderTipoLog";
import { Container, Content, Header, Scroll } from "./styles/Relatorio.style";
import { Listagem } from "./styles/RelatorioListagem.style";
import { BsPeople } from "react-icons/bs";
import SearchIcon from "@rsuite/icons/Search";
import Loading from "./Loading";
import DetailIcon from "@rsuite/icons/Detail";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import { BiCar } from "react-icons/bi";
import moment from "moment";
import { IReports, IReportsRequest } from "../../interfaces/IReports";
import { toast } from "react-toastify";
import Workbook from "react-excel-workbook";

const RelatorioEventos: FC = () => {
  const {
    condominioStore,
    unidadeStore,
    reportStore,
    themeStore,
    moradorStore,
  } = useContext(StoreContext);

  const [search, setSearch] = useState("");
  const [inicio, setInicio] = useState<Date>();
  const [request, setRequest] = useState<IReportsRequest>({});
  const [containerHeight, setContainerHeight] = useState(0);
  const [fim, setFim] = useState<Date>();
  const [open, setOpen] = useState(false);

  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    const setHeight = () => {
      const windowHeight = window.innerHeight;
      setContainerHeight(windowHeight);
    };
    setSearch("");
    reportStore.reports = [];
    window.addEventListener("resize", setHeight);
    setHeight();

    return () => {
      window.removeEventListener("resize", setHeight);
    };
  }, []);

  useEffect(() => {
    if (condominioStore.selecionado && open) {
      moradorStore.listarPorCondominioId(condominioStore.selecionado.id);
      unidadeStore.listarPorCondominioSelecionado(
        condominioStore.selecionado.id
      );
    }
  }, [condominioStore.selecionado, open]);

  const filtro = reportStore.reports
    .slice()
    .reverse()
    .filter(
      (r) =>
        r.nome
          ?.toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[-\u0300-\u036f]/g, "")
          .includes(
            search
              .toLocaleLowerCase()
              .normalize("NFD")
              .replace(/[-\u0300-\u036f]/g, "")
          ) ||
        r.bloco?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        r.condominioNome
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        r.dataHoraEvento
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        r.modelo?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        r.marca?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        r.local?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        r.placa?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        r.serial?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        r.tipoPessoa
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        r.moradorNome
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        r.nomeEvento
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        r.usuarioMorador
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()) ||
        !search
    )
    .filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });

  const listar = () => {
    if (
      condominioStore.selecionado &&
      request.dataHoraInicio &&
      request.dataHoraFim
    ) {
      if (request.dataHoraFim > request.dataHoraInicio) {
        reportStore.reports = [];
        request.condominioId = condominioStore.selecionado.id;

        const object: any = {
          condominioId: condominioStore.selecionado.id,
            dataHoraInicio: moment(request.dataHoraInicio).add(-3, 'hours'),
            dataHoraFim: moment(request.dataHoraFim).add(-3, 'hours'),
            filtros: request.filtros ?? { }
        }
        reportStore.getReports(object);
        setPage(1);
      } else {
        toast.error("Data final não pode ser menor do que a data inicial");
      }
    }
  };

  const dataTipoEvento = [
    {
      label: "Acionamento por TAG",
      value: "9039a4f8-3078-42d9-b369-a3fd07054a21",
    },
    {
      label: "Acionamento por Biometria",
      value: "9039a4f8-3078-42d9-b369-a3fd07054a28",
    },
    {
      label: "Acionamento por APP",
      value: "9039a4f8-3078-42d9-b369-a3fd07054a34",
    },
    {
      label: "Acionamento por Controle TX",
      value: "9039a4f8-3078-42d9-b369-a3fd07054a19",
    },
    {
      label: "Acionamento PC",
      value: "9039a4f8-3078-42d9-b369-a3fd07054a07",
    },
    {
      label: "Acionamento por Facial",
      value: "9039a4f8-3078-42d9-b369-a3fd07054a31",
    },
    {
      label: "Acionamento por Chave Digital",
      value: "9039a4f8-3078-42d9-b369-a3fd07054a35",
    },
  ];

  const renderEventos = () => {
    if (reportStore.loading) {
      return <Loading />;
    }

    if (reportStore.reports.length === 0) {
      return <NotFound message={"Nenhum registro encontrado"} />;
    }

    return (
      <Content>
        <Table
          striped
          hover
          variant={themeStore.theme === "dark" ? "dark" : "none"}
        >
          <thead className="sticky-top">
            <tr>
              <th>Condominio</th>
              <th>Evento</th>
              <th>DataHora Do Evento</th>
              <th>Usuário/Morador</th>
              <th>Unidade</th>
              <th>Serial</th>
              <th>Local</th>
              <th>Marca</th>
              <th>Modelo</th>
              <th>Cor</th>
              <th>Placa</th>
            </tr>
          </thead>
          <tbody>
            {filtro.map((x, i) => (
              <tr key={i} className={"p-2"}>
                <td>{x.condominioNome ? x.condominioNome : "-"}</td>
                <td>{x.nomeEvento ? x.nomeEvento : "-"}</td>
                <td>{x.dataHoraEvento}</td>
                <td>{x.usuarioMorador}</td>
                <td>{x.unidadeIdentificacao}</td>
                <td>{x.serial ? x.serial : "-"}</td>
                <td>{x.local ? x.local : "-"}</td>
                <td>{x.marca ? x.marca : "-"}</td>
                <td>{x.modelo ? x.modelo : "-"}</td>
                <td>{x.cor ? x.cor : "-"}</td>
                <td>{x.placa ? x.placa : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Content>
    );
  };

  const ptBrPaginationLocale = {
    prev: "Anterior",
    next: "Próximo",
    first: "Primeira Página",
    last: "Última Página",
    limit: "Itens por Página:",
    total: "Resultado:",
  };

  return (
    <Container>
      <Header>
        <div className="d-flex box">
          <div className="content d-flex">
            <BsPeople className="content-icon" />
            <div>
              <p className="content-title">
                Relatório de eventos do condomínio
              </p>
              <p className="content-subtitle">
                Clique no botão abaixo para puxar todos os dados
              </p>
            </div>
          </div>

          <div className="d-flex">
            <div className="align-self-center">
              <div style={{ marginLeft: 10 }} className="align-self-center =">
                <p className="info-title align-self-center">Data Inicio:</p>
                <DatePicker
                  className="mt-1"
                  disabled={reportStore.loading}
                  format="dd-MM-yyyy HH:mm"
                  placeholder="Data de inicio"
                  style={{ width: 200 }}
                  onChange={(e) => {
                    if (e) setRequest({ ...request, dataHoraInicio: e });
                  }}
                />
              </div>

              <div
                style={{ marginLeft: 10 }}
                className="align-self-center mt-2"
              >
                <p className="info-title align-self-center">Data Final:</p>
                <DatePicker
                  className="mt-1"
                  disabled={!request.dataHoraInicio || reportStore.loading}
                  format="dd-MM-yyyy HH:mm"
                  placeholder="Data encerramento"
                  style={{ width: 200 }}
                  onChange={(e) => {
                    if (e) setRequest({ ...request, dataHoraFim: e });
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{ marginLeft: 20, marginRight: 20 }}
            className="align-self-center mt-3"
          >
            {/* <p className='info-title'>Clique no botão abaixo para realizar a busca:</p> */}
            <IconButton
              disabled={
                reportStore.loading ||
                !request.dataHoraInicio ||
                !request.dataHoraFim
              }
              appearance="primary"
              color="violet"
              icon={<SearchIcon />}
              onClick={listar}
            >
              Buscar
            </IconButton>

            <div
              style={{ marginLeft: "0px" }}
              className="align-self-center mt-3"
            >
              {/* <p className='info-title-filtro mt-2'>Campo de filtragem:</p> */}
              <InputGroup inside>
                <Input
                  disabled={
                    !reportStore.reports ||
                    reportStore.reports.length === 0 ||
                    reportStore.loading
                  }
                  placeholder="Buscar"
                  value={search}
                  onChange={(e) => {
                    setSearch(e);
                    setPage(1);
                  }}
                />
                <InputGroup.Button
                  disabled={
                    !reportStore.reports || reportStore.reports.length === 0
                  }
                  onClick={() => setSearch("")}
                >
                  <CloseOutlineIcon />
                </InputGroup.Button>
              </InputGroup>
            </div>
          </div>

          <div className="align-self-center" style={{ marginLeft: 20 }}>
            <p className="info-title">Exportar dados dos visitates(xlsx):</p>
            {!reportStore.reports ||
            reportStore.reports.length === 0 ||
            reportStore.loading ? (
              <IconButton
                disabled={
                  !reportStore.reports ||
                  reportStore.reports.length === 0 ||
                  reportStore.loading
                }
                appearance="primary"
                color="green"
                icon={<DetailIcon />}
              >
                Exportar
              </IconButton>
            ) : (
              <Workbook
                filename={`Relatório de eventos do condominio ${
                  condominioStore.selecionado?.nome
                } do dia ${moment(request.dataHoraInicio).format(
                  "DD-MM-YYYY HH.mm.ss"
                )} até ${moment(request.dataHoraFim).format(
                  "DD-MM-YYYY HH.mm.ss"
                )}.xlsx`}
                element={
                  <IconButton
                    disabled={!reportStore.reports}
                    appearance="primary"
                    color="green"
                    icon={<DetailIcon />}
                  >
                    Exportar
                  </IconButton>
                }
              >
                <Workbook.Sheet data={reportStore.reports} name="First Sheet">
                  <Workbook.Column label="Condominio" value="condominioNome" />
                  <Workbook.Column label="Evento" value="nomeEvento" />
                  <Workbook.Column
                    label="DataHoraEvento"
                    value="dataHoraEvento"
                  />
                  <Workbook.Column
                    label="Usuario/Morador"
                    value="usuarioMorador"
                  />
                  <Workbook.Column
                    label="Unidade"
                    value="unidadeIdentificacao"
                  />
                  <Workbook.Column label="Serial" value="serial" />
                  <Workbook.Column label="Local" value="local" />
                  <Workbook.Column label="Marca" value="marca" />
                  <Workbook.Column label="Modelo" value="modelo" />
                  <Workbook.Column label="Cor" value="cor" />
                  <Workbook.Column label="Placa" value="placa" />
                </Workbook.Sheet>
              </Workbook>
            )}
          </div>
        </div>
      </Header>

      <div className="d-flex p-2">
        <div className="p-1">
          <Button
            appearance="primary"
            color={"violet"}
            size={"xs"}
            onClick={toggleOpen}
          >
            Selecionar filtro
          </Button>
        </div>
        <div className="align-self-center d-flex ">
          <div className="p-1">
            {request.filtros?.moradorId ? (
              <Tag
                closable
                color={"violet"}
                onClose={() =>
                  setRequest({
                    ...request,
                    filtros: { ...request.filtros, moradorId: undefined },
                  })
                }
              >
                Morador:{" "}
                {
                  moradorStore.moradoresCondominio.find(
                    (x) => x.id === request.filtros?.moradorId
                  )?.nome
                }{" "}
                -{" "}
                {
                  moradorStore.moradoresCondominio.find(
                    (x) => x.id === request.filtros?.moradorId
                  )?.unidadeIdentificacao
                }
              </Tag>
            ) : (
              ""
            )}
          </div>
          <div className="p-1">
            {request.filtros?.tipoEventoId ? (
              <Tag
                closable
                color={"violet"}
                onClose={() =>
                  setRequest({
                    ...request,
                    filtros: { ...request.filtros, tipoEventoId: undefined },
                  })
                }
              >
                Evento:{" "}
                {
                  dataTipoEvento.find(
                    (x) => x.value === request.filtros?.tipoEventoId
                  )?.label
                }
              </Tag>
            ) : (
              ""
            )}
          </div>
          <div className="p-1">
            {request.filtros?.unidadeId ? (
              <Tag
                closable
                color={"violet"}
                onClose={() =>
                  setRequest({
                    ...request,
                    filtros: { ...request.filtros, unidadeId: undefined },
                  })
                }
              >
                Unidade:{" "}
                {`${
                  unidadeStore.unidadesPorCondomino.find(
                    (x) => x.id === request.filtros?.unidadeId
                  )?.apartamento
                } - ${
                  unidadeStore.unidadesPorCondomino.find(
                    (x) => x.id === request.filtros?.unidadeId
                  )?.blocoNome
                }`}
              </Tag>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {renderEventos()}

      {reportStore.reports.length > 0 ? (
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={10}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={reportStore.reports.length}
          limitOptions={[50, 100, 150]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
          // locale={ptBrPaginationLocale}
        />
      ) : (
        ""
      )}

      <Modal open={open} onClose={toggleOpen}>
        <Modal.Header>
          <div>
            <p className="modal-styled-title">Filtro</p>
            <p className="modal-styled-subtitle">
              Selecione os campos que você deseja filtrar
            </p>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div>
              <p>Morador</p>
              <SelectPicker
                block
                data={moradorStore.moradoresCondominio.map((x) => ({
                  label: `${x.nome} - ${x.unidadeIdentificacao}`,
                  value: x.id,
                }))}
                placeholder={"Selecione"}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    filtros: { ...request.filtros, moradorId: e ?? undefined },
                  })
                }
              />
            </div>
            <div className="mt-2">
              <p>Tipo Evento</p>
              <SelectPicker
                block
                data={dataTipoEvento}
                placeholder={"Selecione"}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    filtros: {
                      ...request.filtros,
                      tipoEventoId: e ?? undefined,
                    },
                  })
                }
              />
            </div>
            <div className="mt-2">
              <p>Unidade</p>
              <SelectPicker
                block
                placeholder={"Selecione"}
                data={unidadeStore.unidadesPorCondominoSelecionado.map((x) => ({
                  label: `${x.apartamento} - ${x.blocoNome}`,
                  value: x.id,
                }))}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    filtros: { ...request.filtros, unidadeId: e ?? undefined },
                  })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" color={"red"} onClick={toggleOpen}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default observer(RelatorioEventos);
