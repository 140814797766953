import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';


export const Content = styled.div`
   .title-rele
   {
    margin: 0;
    font-size: 12pt;
    font-weight: 550;
   }

   .subtitle-rele
   {
    margin: 0;
    font-size: 10pt;
    font-weight: 550;
    margin-top: 10px;
   }

   .content
   {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 3px 2px ${(props) => props.theme.colors.borderColor};
    width: 600px;
    padding: 5px;
    border-radius: 8px;
   }

   .icon-info
   {
    font-size: 25pt;
    align-self: center;
   }

   .info-rele
   {
    margin: 0;
    align-self: center;
    font-size: 10pt;
   }
`