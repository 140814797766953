import { observer } from 'mobx-react-lite'
import React, { useState, useEffect, useContext, FC } from 'react'
import { Table } from 'react-bootstrap'
import { Button, Dropdown, IconButton, InputPicker, Loader, SelectPicker } from 'rsuite'
import { StoreContext } from '../../../stores'
import NotFound from '../../utils/NotFound'
import ExportIcon from '@rsuite/icons/Export';
import useGuarita from '../../../hooks/Guarita.hook'
import ProjectIcon from '@rsuite/icons/Project';
import { MdOutlineRouter } from 'react-icons/md'
import { ContainerLista, HeaderItem } from '../styles/Guarita.style'
import { VscLayersActive } from 'react-icons/vsc'
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PlusIcon from '@rsuite/icons/Plus';
import CadastrarGuarita from '../CadastrarGuarita'
import AtualizarGuarita from '../AtualizarGuarita'
import CadastrarModulo from '../CadastrarModulo'
import AtualizarModulo from '../AtualizarModulo'
import ModalConfirmacao from '../../utils/ModalConfirmacao'
import useModulo from '../../../hooks/Modulo.hook'
import Rele from '../../rele/Rele'
import ModalVersaoModulos from '../ModalVersaoModulos'
import { ETipoModulo } from '../../../enums/ETipoModulo'

interface Props
{
  setIndex: React.Dispatch<React.SetStateAction<number>>
}

const NiceListagem: FC<Props> = ({ setIndex }) => {


  const { guaritaStore, releStore, condominioStore, themeStore } = useContext(StoreContext);
  const { deletar, deletando, reiniciar, reiniciando, resetar, atualizarReceptores, resetando, atualizando } = useGuarita();
  const { _deletar, _deletando } = useModulo();

  const [modalCadastrarGuarita, setModalCadastrarGuarita] = useState(false);
  const [modalEditarGuarita, setModalEditarGuarita] = useState(false);
  const [modalDeletarGuarita, setModalDeletarGuarita] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [modalEditarModulo, setModalEditarModulo] = useState(false);
  const [modalDeletarModulo, setModalDeletarModulo] = useState(false);
  const[openVersaoModulo, setOpenVersaoModulo] = useState(false);
  const [routeNICE, setRouteNICE] = useState('listaguarita');
  const [modalCadastrarModulo, setModalCadastrarModulo] = useState(false);

  const toggleCadastrarGuarita = () => setModalCadastrarGuarita(!modalCadastrarGuarita);
  const toggleEditarGuarita = () => setModalEditarGuarita(!modalEditarGuarita);
  const toggleEditarModulo = () => setModalEditarModulo(!modalEditarModulo);
  const toggleModalDeletarModulo = () => setModalDeletarModulo(!modalDeletarModulo);
  const toggleOpenVersaoModulo = () => setOpenVersaoModulo(!openVersaoModulo);
  const toggleCadastrarModulo = () => setModalCadastrarModulo(!modalCadastrarModulo);

  const toggleModalDeletarGuarita = () => setModalDeletarGuarita(!modalDeletarGuarita);

  const deletarGuaritaSelecionado = () =>
  {
      if(guaritaStore.selecionado)
      {
          deletar(guaritaStore.selecionado);
      }
      else
      {
          alert('Selecione o guarita para deletar');
      }
  }

  const deletarModuloSelecionado = () =>
  {
      if(guaritaStore.moduloSelecionado) {
          _deletar(guaritaStore.moduloSelecionado);
      }
  }

  const toggleCollapse = () => {
    setOpenCollapse(!openCollapse);
    releStore.definirSelecionado(null);
  }

  const renderListaGuarita = () =>
  {
      if(guaritaStore.guaritas.length === 0)
          return <NotFound message='Nenhum guarita cadastrado' />

      return(
          <div className='box-table'>
              <div className='mt-2'>
                    <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                      <thead>
                          <tr>
                              <th scope="col">Nome guarita</th>
                              <th scope="col">Ip</th>
                              <th scope="col">Porta</th>
                              <th scope="col">Modelo</th>
                              <th scope="col">MAC</th>
                              <th scope="col">SocketServer</th>
                              <th scope="col">webadmin</th>
                              <th scope="col">Código</th>
                              <th scope="col">Ação</th>
                          </tr>
                      </thead>
                      <tbody>
                          {
                              guaritaStore.guaritas.map((guarita, i) => (
                              <tr key={i} onClick={() => guaritaStore.definirSelecionado(guarita.id)}> 
                                  <td>{guarita.nome}</td>
                                  <td>{guarita.ip}</td>
                                  <td>{guarita.porta}</td>
                                  <td>{guarita.modelo}</td>
                                  <td>{guarita.mac}</td>
                                  <td>{guarita.socketServerIp}:{guarita.socketServerPorta}</td>
                                  <td>
                                      <IconButton style={{marginLeft: 10}} appearance="primary" color="violet" size={'xs'} icon={<ExportIcon />} href={`http://${guarita.ip}`} target='blank'>
                                          Acessar
                                      </IconButton>
                                  </td>
                                  <td>{guarita.codigoOutkey}</td>
                                  <td>
                                  <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                      <Dropdown.Item onClick={() => {
                                          toggleEditarGuarita();
                                      }}>Atualizar</Dropdown.Item>
                                      <Dropdown.Item onClick={() => {
                                          toggleModalDeletarGuarita();
                                      }}>Deletar</Dropdown.Item>
                                  </Dropdown>
                                  </td>
                              </tr>
                              ))
                          }
                      </tbody>
                  </Table>
              </div>
          </div>
          
      )
  }

  const renderListaModulo = () =>
  {

      if(guaritaStore.modulos.length === 0)
          return <NotFound message='Nenhum módulo cadastrado' />

      return(
          <div>

              <div>
                  <div className='d-flex justify-content-start'>
                      <div className='p-2'>
                          <p>Selecione o módulo guarita</p>
                          <div className='align-self-center'>
                              <SelectPicker
                                  cleanable={false}
                                  style={{width: 500}}
                                  placeholder={'Selecione o guarita'}
                                  value={guaritaStore.selecionado?.id}
                                  data={guaritaStore.guaritas.map((guarita) => ({ label: `${guarita.nome} - ${guarita.ip}:${guarita.porta} - ${guarita.mac}`, value: guarita.id}))}
                                  onChange={(e) => {
                                      guaritaStore.listarModulo(e as any);
                                      guaritaStore.definirSelecionado(e as any);
                                  }}
                              />
                          </div>
                      </div>
                  </div>

                  <div className='box-table'>
                      <div className=''>
                          <Table>
                              <thead>
                                  <tr>
                                      <th>Tipo do módulo</th>
                                      <th>Endereço CAN</th>
                                      <th>Relés</th>
                                      <th>Ação</th>
                                  </tr>
                              </thead>
                              <tbody>
                              {
                                      guaritaStore.modulos.map((modulo, i) => (
                                      <tr key={i} onClick={() => guaritaStore.definirModuloSelecionado(modulo)}> 
                                          <td>{ETipoModulo[modulo.tipo]}</td>
                                          <td>{modulo.enderecoCAN}</td>
                                          <td>
                                              <ProjectIcon role='button' style={{fontSize: 19}} onClick={toggleCollapse} />
                                              {/* <IconButton size={'xs'} appearance="ghost" color="violet" icon={<ProjectIcon />} onClick={toggleCollapse} disabled={!guaritaStore.selecionado} style={{marginBottom: 10}}/> */}
                                          </td>
                                          <td>
                                              <Dropdown appearance="primary" color="violet"  title={'Ação'} size={'xs'}>
                                              <Dropdown.Item onClick={() => {
                                                  toggleEditarModulo();
                                              }}>Atualizar</Dropdown.Item>
                                              <Dropdown.Item onClick={() => {
                                                  toggleModalDeletarModulo();
                                              }}>Deletar</Dropdown.Item>
                                              </Dropdown>
                                          </td>
                                      </tr>
                                      ))
                                  }
                              </tbody>
                          </Table>
                      </div>
                  </div>
              </div>
          </div>
      )
  }

  const renderListaConfig = () =>
  {
      if(!guaritaStore.selecionado)
      {
          return <NotFound message='Nenhum guarita encontrado' />
      }

      return(
          <div className=''>
              <div className='align-self-center mt-3'>
                  <SelectPicker
                      style={{width: 500}}
                      placeholder={'Selecione o guarita'}
                      cleanable={false}
                      value={guaritaStore.selecionado?.id}
                      data={guaritaStore.guaritas.map((guarita) => ({ label: `${guarita.nome} - ${guarita.ip}:${guarita.porta} - ${guarita.mac}`, value: guarita.id}))}
                      onChange={(e) => {
                          guaritaStore.listarModulo(e as any);
                          guaritaStore.definirSelecionado(e as any);
                      }}
                  />
              </div>
              <div className='d-flex flex-wrap'>
                  <div className='d-flex justify-content-betweesn box-card'>
                      <div>
                          <p className='title-config'>
                              <MdOutlineRouter className='icon'/> <b>Reiniciar módulo guarita</b> <br/>
                          </p>
                      
                          <div className='d-flex align-self-center card-button mt-2'>
                              <Button size={'md'} appearance="primary" color="violet" style={{width: 300}} disabled={reiniciando} onClick={() => 
                              {
                                  if(guaritaStore.selecionado) reiniciar(guaritaStore.selecionado, () => {});
                              }}>
                              {
                                  reiniciando
                                  ?
                                  <Loader content={'Reiniciando guarita...'} style={{ alignSelf: 'center' }} />
                                  :
                                  'Reiniciar'
                              }
                              </Button>
                              <p className='subtitle-config'>
                                  Ao clicar em "REINICIAR MÓDULO GUARITA" o sistema vai acessar o módulo e realizar o comando re reinício
                              </p>
                          </div>
                      </div>
                      
                  </div>

                  <div className='box-card'>
                      <div>
                          <p className='title-config'>
                              <MdOutlineRouter className='icon'/> <b>Dispositivos</b> <br/>
                          </p>
                          
                      </div>
                      <div className='d-flex align-self-center card-button mt-2'>
                          <Button size={'md'} appearance="primary" color="violet" style={{width: 300}} onClick={() => 
                          {
                              toggleOpenVersaoModulo();
                          }}>
                              Abrir
                          </Button>
                          <p className='subtitle-config'>
                              Lista todos os dispositvos do módulo guarita
                          </p>
                      </div>
                  </div>

                  <div className=' box-card'>
                      <div>
                          <p className='title-config'>
                              <MdOutlineRouter className='icon'/> <b>Atualizar receptores</b> <br/>
                          </p>
                      </div>
                      <div className='d-flex align-self-center card-button mt-2'>
                          <Button size={'md'} appearance="primary" color="violet" style={{width: 300}} disabled={atualizando} onClick={() => 
                          {
                              if(guaritaStore.selecionado) atualizarReceptores(guaritaStore.selecionado, () => {});
                          }}>
                          {
                              atualizando
                              ?
                              <Loader content={'Reiniciando guarita...'} style={{ alignSelf: 'center' }} />
                              :
                              'Atualizando receptores'
                          }
                          </Button>
                          <p className='subtitle-config'>
                              Atualiza os receptores do módulo guarita
                          </p>
                      </div>
                  </div>

                  <div className='box-card'>
                      <div>
                          <p className='title-config'>
                              <MdOutlineRouter size={22} className='icon'/> <b>Resetar o módulo guarita</b> <br/>
                          </p>
                      </div>
                      <div className='d-flex align-self-center mt-2'>
                          <Button size={'md'} appearance="primary" color="violet" style={{width: 300}} disabled={resetando} onClick={() => 
                          {
                              if(guaritaStore.selecionado) resetar(guaritaStore.selecionado, () => {});
                          }}>
                          {
                              resetando
                              ?
                              <Loader content={'Resentando guarita...'} style={{ alignSelf: 'center' }} />
                              :
                              'Resetar módulo'
                          }
                          </Button>
                          <p className='subtitle-config'>
                              Reseta o módulo guarita
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      )
  }

  const voltar = () =>
  {

  }

  useEffect(() =>
  {
      guaritaStore.definirSelecionado('')
      guaritaStore.modulos = [];
  }, [condominioStore.selecionado]);

  const renderRoutesNICE = () =>
  {
      switch(routeNICE)
      {
          case 'listaguarita':
              return renderListaGuarita();
          case 'listamodulo':
              return renderListaModulo();
          case 'listaconfig':
              return renderListaConfig();
      }
  }

  return(
      <ContainerLista>
          <HeaderItem>
              <div className='d-flex box'>
                  <div className='content d-flex'>
                      <VscLayersActive className='content-icon' />
                      <div>
                          <p className='content-subtitle'>Configurações gerais do sistema guarita NICE</p>
                      </div>
                  </div>

                  <div style={{marginLeft: 30}} className='align-self-center'>
                      <p className='info-title'>Voltar para a lista</p>
                      <IconButton appearance="primary" color="violet" icon={<PagePreviousIcon />} onClick={() => setIndex(0)}>
                          Voltar
                      </IconButton>
                  </div>

                  <div style={{marginLeft: 20}} className='align-self-center'>
                      <div className='align-self-center'>
                          <p style={{margin: 0, width: 200}}>Menu</p>
                          <InputPicker cleanable={false} placeholder={'Selecione o modo de cadastro'} value={routeNICE} data={[{ label: 'Guaritas cadastrado', value: 'listaguarita' }, { label: 'Módulos', value: 'listamodulo' }, { label: 'Configurações', value: 'listaconfig' }]} onChange={(e) => setRouteNICE(e)} style={{ width: 300 }} />
                      </div>
                  </div>


                  {
                      routeNICE === 'listaguarita'
                      ?
                      <div style={{marginLeft: 20}} className='align-self-center'>
                          <p style={{margin: 0, width: 200}}>Cadastrar nova guarita</p>
                          <div className='d-flex justify-content-end'>
                              <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={() => toggleCadastrarGuarita()}>
                                  Adicionar novo guarita NICE
                              </IconButton>
                          </div>
                      </div>
                      :
                      <div/>
                  }

                  {
                      routeNICE === 'listamodulo'
                      ?
                      <div style={{marginLeft: 20}} className='align-self-center'>
                          <p style={{margin: 0, width: 200}}>Cadastrar nova guarita</p>
                          <div className='align-self-center mt-2' style={{ marginLeft: 20 }}>
                              <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarModulo} disabled={!guaritaStore.selecionado} style={{marginBottom: 10}}>
                                  Adicionar novo módulo
                              </IconButton>
                          </div>
                      </div>
                      :
                      <div/>
                  }
                  
              </div>
          </HeaderItem>
          <div>{renderRoutesNICE()}</div>


            <Rele moduloId={guaritaStore.moduloSelecionado?.id} show={openCollapse} hide={toggleCollapse} />

            <ModalVersaoModulos show={openVersaoModulo} hide={toggleOpenVersaoModulo} />

            <CadastrarGuarita show={modalCadastrarGuarita} hide={toggleCadastrarGuarita} />
            <AtualizarGuarita show={modalEditarGuarita} hide={toggleEditarGuarita} guaritaSelecionado={guaritaStore.selecionado} />
            <CadastrarModulo show={modalCadastrarModulo} hide={toggleCadastrarModulo} guaritaSelecionado={guaritaStore.selecionado} />
            <AtualizarModulo show={modalEditarModulo} hide={toggleEditarModulo} moduloSelecionado={guaritaStore.moduloSelecionado} />
            <ModalConfirmacao show={modalDeletarGuarita} hide={toggleModalDeletarGuarita} action={deletarGuaritaSelecionado} content={'Você tem certeza que deseja deletar esse módulo guarita?'} loading={deletando} />
            <ModalConfirmacao show={modalDeletarModulo} hide={toggleModalDeletarModulo} action={deletarModuloSelecionado} content={'Você tem certeza que deseja deletar esse módulo?'} loading={_deletando} />
      </ContainerLista>
  )
}

export default observer(NiceListagem);