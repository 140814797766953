import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AcionamentoAPI from "../api/AcionamentoAPI";
import { StoreContext } from "../stores";
import { Acionamento } from "../interfaces/Acionamento.interface";

const useAcionamento = () => {
  const { acionamentoStore } = useContext(StoreContext);
  const [salvando, setSalvando] = useState(false);
  const [atualizando, setAtualizando] = useState(false);
  const [deletando, setDeletando] = useState(false);

  const cadastrar = async (acionamento: Acionamento, hide: () => void) => {
    setSalvando(true);
    try {
      const { data, status } = await AcionamentoAPI.cadastrarAcionamento(acionamento);
  
      if (status === 200) {
        acionamentoStore.acionamentos.push(data);
        toast.success("Acionamento cadastrado com sucesso");
        hide();
      } else {
        toast.error("Erro ao cadastrar Acionamento");
      }
    } catch (e) {
      console.error(e)
    } finally {
      setSalvando(false)
      acionamentoStore.listar()
    }
  };

  const atualizar = async (acionamento: Acionamento, hide: () => void) => {
    setAtualizando(true);
    try {
      const { status } = await AcionamentoAPI.atualizarAcionamento(acionamento);
      if (status === 200) {
        acionamentoStore.acionamentos = acionamentoStore.acionamentos.map(
          (aci) => (aci.id === acionamento.id ? acionamento : aci)
        );
        toast.success("Acionamento atualizado com sucesso");
        hide();
      } else {
        toast.error("Erro ao atualizar acionamento");
      }
    } catch (e) {
      console.error(e)
    } finally {
      setAtualizando(false);
      acionamentoStore.listar()
    }
  };

  const deletar = async (acionamento: Acionamento) => {
    setDeletando(true);
    try {
      const { status } = await AcionamentoAPI.deletarAcionamento(
        acionamento.id
      );
      if (status === 200) {
        acionamentoStore.acionamentos = acionamentoStore.acionamentos.filter(
          (aci) => aci.id !== acionamento.id
        );
        toast.success("Acionamento deletado com sucesso");
      } else {
        toast.error("Erro ao deletado acionamento");
      }
    } catch (e) {
      console.error(e)
    } finally {
      setDeletando(false)
      acionamentoStore.listar()
    }
  };

  const atualizarOrdemAcionamento = async (acionamento: Acionamento[]) => {
    setAtualizando(true);
    const novaOrdem = {
      novaOrdem: acionamento.map((i) => ({
        id: i.id,
        nome: i.nome,
        ordem: i.ordem,
      })),
    };

    try {
      const { data } = await AcionamentoAPI.atualizarOrdem(novaOrdem as any);
      if (data === true) {
        toast.success(`Ordem do acionamento alterado com sucesso`);
        setAtualizando(false);
      }
    } catch (e) {
      toast.error(`Erro ao alterar a ordem do acionamento`);
      console.error(e)
    } finally {
      setAtualizando(false);
    }
  };

  return {
    cadastrar,
    atualizar,
    deletar,
    salvando,
    atualizando,
    deletando,
    atualizarOrdemAcionamento,
  };
};

export default useAcionamento;
