import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Dropdown, Input, Modal, SelectPicker } from 'rsuite';
import { useUnidade } from '../../hooks/Unidade.hook';
import { Bloco } from '../../interfaces/Bloco.interface';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { StoreContext } from '../../stores';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import NotFound from '../utils/NotFound';
import AtualizarUnidade from './AtualizarUnidade';
import CadastrarUnidade from './CadastrarUnidade';

interface ModalProps {
    show: boolean;
    hide: () => void;
    blocoSelecionado: Bloco | null;
}

const ModalListagemUnidade: FC<ModalProps> = ({ show, hide, blocoSelecionado }) => {

    const token = process.env.REACT_APP_BASE_TOKEN_BLOCOUNIDADE;
    const { atualizar, atualizando, erro, success } = useUnidade();
    const { _deletar, _deletando } = useUnidade();
    const { unidadeStore, themeStore } = useContext(StoreContext);
    const [form, setForm] = useState<UnidadeRequest>({});
    const [cadastrarUnidadeModal, setCadastrarUnidadeModal] = useState(false);
    const [atualizarUnidadeModal, setAtualizarUnidadeModal] = useState(false);
    const [modalConfirmacaoUnidade, setModalConfirmacaoUnidade] = useState(false);

    useEffect(() => {
        if(blocoSelecionado) {
            unidadeStore.listar(blocoSelecionado.id);
        }
    }, [blocoSelecionado]);

    const toggleCadastrarUnidade = () => setCadastrarUnidadeModal(!cadastrarUnidadeModal);
    const toggleAtualizarUnidade = () => setAtualizarUnidadeModal(!atualizarUnidadeModal);
    const toggleConfirmacaoUnidade = () => 
    {
        setModalConfirmacaoUnidade(!modalConfirmacaoUnidade);
    }

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        atualizar(form as Unidade, hide);
    }


    const deletarUnidade = () =>
    {
        if(unidadeStore.selecionado) {
            _deletar(unidadeStore.selecionado);

        }
    }

    if(success) {
        return(
            <Snackbar open={success} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                Unidade cadastrado com sucesso!
                </Alert>
            </Snackbar>
        )
    }

    if(erro) {
        return(
            <Snackbar open={erro} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Erro ao cadastrar unidade
                </Alert>
            </Snackbar>
        )
    }

    const renderList = () => {

        if(unidadeStore.unidades.length === 0) return <NotFound message={`Nenhuma unidade cadastrada no bloco ${blocoSelecionado?.nome.toLocaleUpperCase()}`} />

        return(
            <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="md">
                <thead>
                    <tr>
                        <th>Bloco</th>
                        <th>Identificação</th>
                        <th>Apartamento</th>
                        <th>Identificador NICE</th>
                        <th>Capacidade Locação</th>
                        <th>Ramal Unidade</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                {
                    unidadeStore.unidades.slice().sort((a, b) => a.identificadorNice.toLocaleUpperCase() > b.identificadorNice.toLocaleUpperCase() ? 1 : -1).map((unidade, i) => (
                        <tr onClick={() => unidadeStore.definirSelecionado(unidade.id)}>
                            <td>{unidade.blocoNome}</td>
                            <td>{unidade.apartamento}</td>
                            <td>{unidade.identificacao}</td>
                            <td>{unidade.identificadorNice}</td>
                            <th>{unidade.capacidadeLocacao === 0 ? "Não definido" : unidade.capacidadeLocacao }</th>
                            <td>{unidade.ramalInterno}</td>
                            <td>
                                <Dropdown size='xs' title={'Ação'} appearance='primary' color={'violet'}>
                                    <Dropdown.Item onClick={() => {
                                        toggleAtualizarUnidade();
                                    }}>Atualizar</Dropdown.Item>
                                    <Dropdown.Item onClick={toggleConfirmacaoUnidade}>Deletar</Dropdown.Item>
                                </Dropdown>
                            </td>
                        </tr>
                    ))
                }
                    
                    
                </tbody>
            </Table>
        )
    }

    return(
        <Modal open={show} onClose={hide} size={'lg'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Listagem de todas as unidades do bloco {blocoSelecionado?.nome.toLocaleUpperCase()}</p>
                    <p className='modal-styled-subtitle'>
                        Abaixo tem todas as unidades cadastrada nesse bloco
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body style={{height: 500}}>
                {renderList()}
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" onClick={toggleCadastrarUnidade}>Cadastrar nova unidade</Button>
                <Button appearance="default" onClick={hide}>fechar</Button>
            </Modal.Footer>

            <CadastrarUnidade show={cadastrarUnidadeModal} hide={toggleCadastrarUnidade} blocoSelecionado={blocoSelecionado} />
            <AtualizarUnidade show={atualizarUnidadeModal} hide={toggleAtualizarUnidade} unidadeSelecionado={unidadeStore.selecionado} />

            <ModalConfirmacao show={modalConfirmacaoUnidade} hide={toggleConfirmacaoUnidade} action={deletarUnidade} content={'Você tem certeza que deseja deletar essa unidade?'} loading={_deletando} />
        </Modal>
    )
}

export default observer(ModalListagemUnidade);