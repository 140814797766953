import { Rele } from "../interfaces/Rele.interface";
import api from "../services/api/api";

class ReleAPI {

    listarRelePorModulo = async (moduloId: string) => await api.get(`/NiceRele/modulo/${moduloId}`);

    listarRelePorId = async (releId: string) => await api.get(`/NiceRele/${releId}`);

    listarRelePorCondominio = async (condominioId: string) => await api.get(`/NiceRele/condominio/${condominioId}`);

    cadastrarRele = async (request: Rele) => await api.post<Rele>(`/NiceRele`, request);

    atualizarRele = async (request: Rele) => await api.put(`/NiceRele`, request);

    deletarRele = async (releId: string) => await api.delete(`/NiceRele/${releId}`);
}

export default new ReleAPI();