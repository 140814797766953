import { Autocomplete, Backdrop, Box, CircularProgress, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { BsPeople, BsPhone, BsThreeDots } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import { useMorador } from '../../hooks/Morador.hook';
import { converterData, converterDataHora, converterHora, cpfMask, formatDateTimeString, maskCelular } from '../utils/mask';
import { CardMorador, Container, Header, ListMorador, TransitionDiv } from './styles/Moradores.style';
import { FiUserCheck } from 'react-icons/fi';
import NotFound from '../utils/NotFound';
import { Button, Dropdown, Input, Nav, Navbar, SelectPicker, IconButton, Tag, Whisper, Tooltip } from 'rsuite';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import Loading from './Loading';


const MoradoresExcluidosListagem = () => {

    const { unidadeStore, visitanteStore, moradorStore, dispositivosStore, condominioStore, themeStore } = useContext(StoreContext);
    const { Restaurar, atualizando } = useMorador();
    
    const [modalRestaurarMorador, setModalRestaurarrMorador] = useState(false);
    const [searchMorador, setSearchMorador] = useState('');
    const toggleRestaurarMorador = () => setModalRestaurarrMorador(!modalRestaurarMorador);

    const restaurarMorador = () =>
    {
        if(moradorStore.selecionadoExcluido) {
            Restaurar(moradorStore.selecionadoExcluido);
        }
    }
    useEffect(() => {
        let unidadeId = localStorage.getItem('@unidadeId');
        unidadeStore.listarPorCondominioId()
        .then(() =>
        {
            if(unidadeId) 
            {
                moradorStore.listarExcluidoPorUnidadeId(unidadeId);
            }
            else
            {
                let primeiraUnidade = unidadeStore.unidadesPorCondomino[0];
                if(primeiraUnidade) {
                    moradorStore.listarExcluidoPorUnidadeId(primeiraUnidade.id); 
                }
            }
        })
        .catch(() => alert('erro'))
    }, [condominioStore.selecionado]);

    const filtrarMorador = moradorStore.moradoresExcluido.filter(morador =>
        morador.nome?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(searchMorador.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
        morador.status?.toLocaleLowerCase().includes(searchMorador.toLocaleLowerCase()) ||
        !searchMorador
    );

    const renderMoradores = () =>
    {

        if(moradorStore.loading)
        {
            return <Loading />
        }

        if(!unidadeStore.selecionado) 
            {
                return <NotFound message='Selecione a unidade' />
            }

        if(moradorStore.moradoresExcluido.length === 0) 
        {
            return <NotFound message='Nenhum morador(a) excluído' />
        }

        return(
            <div>
                <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="md">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Email</th>
                            <th>Celular</th>
                            <th>Excluido Em</th>
                            <th>Excluido Por</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtrarMorador.map((morador, i) =>
                            (
                                <tr key={i} onClick={() => moradorStore.definirMoradorExcluidoSelecionado(morador.id)}>
                                    <td>{morador.nome}</td>
                                    <td>{morador.cpf ? cpfMask(morador.cpf) : '-'}</td>
                                    <td>{morador.email ? morador.email : '-'}</td>
                                    <td>{morador.celular ? maskCelular(morador.celular) : '-'}</td>
                                    <td>{morador.excluidoEm ? formatDateTimeString(morador.excluidoEm) : '-'}</td>
                                    <td>{morador.excluidoPor ? morador.excluidoPor : '-'}</td>
                                    <td>
                                        <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'} placement={'bottomEnd'}>
                                            <Dropdown.Item onClick={() =>
                                            {
                                                moradorStore.definirSelecionado(morador.id);
                                                toggleRestaurarMorador();
                                            }}>Restaurar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return(
        <Container>
            <Header>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de moradores excluídos</p>
                        <p className='subtitle'>Selecione a unidade para visualizar os moradores</p>
                    </div>
                </div>

                <div className='d-flex box'>
                    <div className='content d-flex'>
                        <BsPeople className='content-icon' />
                        <div>
                            <p className='content-title'>Moradores excluídos</p>
                            <p className='content-subtitle'>{moradorStore.moradores.length} cadastrado na unidade {unidadeStore.selecionado?.apartamento}</p>
                        </div>
                    </div>
                    <div className='align-self-center'>
                        <div style={{marginLeft: 20}}>
                            <p>Selecione a unidade</p>
                            <SelectPicker
                                block
                                cleanable={false}
                                style={{width: 100}}
                                size={'xs'}
                                // value={value.toString()}
                                value={unidadeStore.selecionado?.id}
                                placeholder={'Selecione'}
                                data={unidadeStore.unidadesPorCondomino.map((unidade) => ({ label: `${unidade.apartamento} - ${unidade.blocoNome}`, value: unidade.id }))}                            
                                onChange={(e) =>
                                {
                                    if(e) unidadeStore.definirSelecionado(e);
                                    if(e) moradorStore.listarExcluidoPorUnidadeId(e);

                                }}
                            />
                        </div>
                    </div>

                    <div style={{marginLeft: 20}} className='align-self-center'>
                        <p className='info-title-filtro'>Filtrar moradores da unidade <span style={{fontWeight: 550, fontSize: 15}}>{unidadeStore.selecionado?.apartamento}</span></p>
                        <Input placeholder='Buscar' onChange={(e) => setSearchMorador(e)} />
                    </div>
                </div>

                <ListMorador>
                    {renderMoradores()}
                </ListMorador>
            </Header>

            <ModalConfirmacao show={modalRestaurarMorador} hide={toggleRestaurarMorador} action={restaurarMorador} content={'Você tem certeza que deseja restaurar esse morador?'} loading={atualizando} />
        </Container>
    )
}

export default observer(MoradoresExcluidosListagem);