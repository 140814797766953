import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { StoreContext } from '../../../stores';
import useEquipamento from '../../../hooks/Equipamento.hook';
import { Hikvision, HikvisionRequest } from '../../../interfaces/Equipamento.interface';
import { Button, Input, Modal, SelectPicker } from 'rsuite';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarControlId: FC<ModalProps> = ({ show, hide }) => {

    const { equipamentoStore, cameraStore, layoutStore } = useContext(StoreContext);
    const { atualizarHikvision, atualizandoHikvision } = useEquipamento();
    const [selectLayout, setSelectLayout] = useState('');
    const [form, setForm] = useState<HikvisionRequest>({});

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        form.tipoTag = 1;
        atualizarHikvision(form as Hikvision, hide);
    }

    useEffect(() => {
        if(equipamentoStore.hikvisionSelecionado && show) {
            setForm(equipamentoStore.hikvisionSelecionado);
        } else {
            setForm({});
        }
    }, [equipamentoStore.hikvisionSelecionado, show]);

    useEffect(() =>
    {
        if(layoutStore.layouts.length === 1)
        {
            cameraStore.listarPorLayout(layoutStore.layouts[0].id);
        }
        else
        {
            
        }
    }, [show]);


    const FormEquipamento = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="192.168.XXX.XXX" value={form.ip || ''} onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="80" value={form.porta || ''} onChange={(e) => setForm({...form, porta: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Usuário</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Usuário" value={form.usuario || ''} onChange={(e) => setForm({...form, usuario: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Senha</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Ex: 2020" value={form.senha || ''} onChange={(e) => setForm({...form, senha: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Nome" value={form.nome || ''} onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Modelo" value={form.modelo || ''} onChange={(e) => setForm({...form, modelo: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Tipo</Form.Label>
                        <SelectPicker searchable={false} cleanable={false} block data={[ {label: 'Modelo antigo', value: 1}, { label: 'Modelo novo', value: 2} ]} className='shadow-none' placeholder="Tipo" value={Number(form.tipo)} onChange={(e) => {if(e) setForm({...form, tipo: e.toString()})}} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-12" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Serial</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Ex: 2020" value={form.serial || ''} onChange={(e) => setForm({...form, serial: e})} />
                    </Form.Group>
                </div>

                {/* <div>
                    <Form.Label><span className='text-danger'>*</span>Tipo de tag aceito</Form.Label>
                    <select
                        className='form-control shadow-none col-md-12'
                        value={form.tipoTag?.toString() || ''}
                        onChange={(e) => setForm({ ...form, tipoTag: e.target.value })}
                        required
                    >
                        <option value={''}>Selecione</option>
                        <option value={'0'}>Hikvision</option>
                        <option value={'1'}>Nice/ControlId/Anviz</option>
                    </select>
                </div> */}

                <Form.Group className="mb-3 col-md-12 p-1" controlId="formBasicPassword">
                        <Form.Label>Layout da câmera</Form.Label>
                        {
                            layoutStore.layouts.length > 1
                            ?
                            <div>
                                {
                                    layoutStore.layouts.map((layout, i) =>
                                        <Form.Check 
                                            key={i}
                                            type={'checkbox'}
                                            id={i.toString()}
                                            label={`${layout.nomeDGuard}`}
                                            onChange={(e) => {
                                                setSelectLayout(layout.id);
                                                cameraStore.listarPorLayout(layout.id);
                                            }}
                                            checked={selectLayout === layout.id ? true : false }
                                        />
                                    )
                                }
                                <>
                                    {
                                        cameraStore.loading
                                        ?
                                        'CAREGANDO CAMERAS...'
                                        :
                                        <SelectPicker
                                            block
                                            placeholder={'Selecione a câmera'}
                                            data={cameraStore.cameras.map((cam) =>({ label: cam.nomeDGuard, value: cam.id }))}
                                            value={form.cameraId || ''}
                                            onChange={(e) => {if(e) setForm({ ...form, cameraId: e })}}
                                        />
                                        // <select
                                        //     className='form-control shadow-none'
                                        //     onChange={(e) => setForm({ ...form, cameraId: e.target.value })}
                                        //     value={form.cameraId || ''}
                                        //     required
                                        // >
                                        //     <option value={"00000000-0000-0000-0000-000000000000"}>Selecione</option>
                                        //     {
                                        //         cameraStore.cameras.map((camera, i) =>
                                        //             <option key={i} value={camera.id}>{camera.nomeDGuard}</option>
                                        //         )
                                        //     }
                                        // </select>
                                    }
                                </>
                            </div>
                            :
                            <>
                                {
                                    cameraStore.loading
                                    ?
                                    <div>
                                        CARREGANDO CAMERAS...
                                    </div>
                                    :
                                    <SelectPicker
                                        block
                                        placeholder={'Selecione a câmera'}
                                        data={cameraStore.cameras.map((cam) =>({ label: cam.nomeDGuard, value: cam.id }))}
                                        value={form.cameraId || ''}
                                        onChange={(e) => {if(e) setForm({ ...form, cameraId: e })}}
                                    />
                                    // <select
                                    //     className='form-control shadow-none'
                                    //     onChange={(e) => setForm({ ...form, cameraId: e.target.value })}
                                    //     value={form.cameraId || ''}
                                    //     required
                                    // >
                                    //     <option value={"00000000-0000-0000-0000-000000000000"}>Selecione</option>
                                    //     {
                                    //         cameraStore.cameras.map((camera, i) =>
                                    //             <option key={i} value={camera.id}>{camera.nomeDGuard}</option>
                                    //         )
                                    //     }
                                    // </select>
                                }
                            </>
                        }
                </Form.Group>

                <div className='d-flex justify-content-end mt-4'>
                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance='primary' disabled={atualizandoHikvision} type="submit">
                        { atualizandoHikvision ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={atualizandoHikvision} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                </div>
                {atualizandoHikvision ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size='lg'>
            <Modal.Header closeButton>
            <div>
                    <p className='modal-styled-title'>Atualizar Hikvision - {equipamentoStore.hikvisionSelecionado ? equipamentoStore.hikvisionSelecionado.nome : '-'}</p>
                    <p className='modal-styled-subtitle'>
                        Atualização de equipamentos
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormEquipamento()}</Modal.Body>
        </Modal>
    )
}

export default observer(AtualizarControlId);