import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import RamalAPI from "../api/RamalAPI";
import UsuarioAPI from "../api/UsuarioAPI";
import { Maquina } from "../interfaces/Ramal.interface";
import { Usuario } from "../interfaces/Usuario.interface";

class UsuarioStore {

    usuarios: Usuario[] = [];
    maquinaLogada: Maquina[] = [];
    selecionado: Usuario | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async listar() {
        try {
            const { data } = await UsuarioAPI.listarUsuarios();
            runInAction(() => {
                this.usuarios = data;
            });
        } catch (e) {

        }
    }

    async listarUsuáriosLogados() {
        try {
            const { data } = await UsuarioAPI.listarUsuariosRamais();
            runInAction(() => {
                if (data) this.maquinaLogada = data;
                else this.maquinaLogada = []; 
            });
        } catch (e) {

        }
    }

    definirSelecionado(usuarioId: string) {
        let selecionado = this.usuarios.find((u) => u.id === usuarioId);
        if (selecionado) {
            this.selecionado = selecionado;
        }
    }

    async deslogarUsuario(ramalId: string, centralAtendimentoId: string) {
        try {
            const { data } = await RamalAPI.deslogarUsuario(ramalId, centralAtendimentoId);
            this.listarUsuáriosLogados();
            toast.success('Usuário deslogado com sucesso!');
        } catch (e) {
            toast.error('Erro ao deslogar usuário!');

        }
    }


}

export default UsuarioStore;