import styled from 'styled-components';

export const Header = styled.div`
    padding: 10px;
    border-radius: 10px;
    display: flex;
    background: ${(props) => props.theme.colors.bgBox};
    margin-bottom: 20px;
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
    .title
    {
        margin: 0;
        font-size: 12pt;
        font-weight: 550;
        align-self: center;
    }
`;


export const Container = styled.div`
    
`;


export const Success = styled.div`
    
    margin-top: 100px;
    .header
    {
        align-self: center;
        display: flex;
    }
    .icon
    {
        font-size: 25pt;
        color: ${(props) => props.theme.colors.success};
    }
    .title
    {
        align-self: center;
        font-size: 13pt;
        font-weight: 550;
        margin-left: 10px;
    }
    .btn-cadastro
    {
        alig-self: center;
        margin-top: 40px;
        border-radius: 60px;
    }

`;