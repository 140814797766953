import { Avatar, FormControlLabel, LinearProgress, Switch } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa';
import { StoreContext } from '../../stores';
import { useRamal } from '../../hooks/Ramal.hook';
import { Ramal, RamalRequest } from '../../interfaces/Ramal.interface';
import { Button, Input, InputPicker, Modal, SelectPicker } from 'rsuite';

interface PropsModal {
    show: boolean;
    hide: () => void;
    condominioId?: string;
}

const CadastrarRamal: FC<PropsModal> = ({ show, hide, condominioId }) => {
    
    const { sipStore, cameraStore, layoutStore, condominioStore } = useContext(StoreContext);
    const { cadastrar, salvando } = useRamal();
    const [form, setForm] = useState<RamalRequest>({});
    const [selectLayout, setSelectLayout] = useState('');

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(form.tipo === 'FXO' && form.complemento === "") form.ramalNumero?.toString().replace(/\.|-/gm,'');
        else if(form.tipo === 'FXO') form.ramalNumero = `${form.ramalNumero}.${form.complemento}`;
        cadastrar(form as Ramal, hide);
    }

    useEffect(() => {
        if(condominioId && show) {
            setForm({
                ...form,
                condominioId: condominioId,
                tipo: 'FXS',
                ramalNumero: '',
                complemento: '',
                nome: '',
                dispositivoSipId: '',
            })
            layoutStore.listar();
            cameraStore.listarPorCondominioId(condominioStore.selecionado?.id || "");
        }
    }, [condominioId, show]);

    const FormRamal = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    {
                        form.tipo === 'FXS' || form.tipo === 'FXSCentral'
                        ?
                        <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                            <Form.Label><span className='text-danger'>*</span>Ramal número</Form.Label>
                            <Input required type="text" className='shadow-none' placeholder="Ex: 2020" value={form.ramalNumero || ''} onChange={(e) => setForm({...form, ramalNumero: e})} />
                        </Form.Group>
                        :
                        <div className='d-flex' style={{ marginRight: 20 }}>
                            <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                                <Form.Label><span className='text-danger'>*</span>Ramal número</Form.Label>
                                <Input required type="text" className='shadow-none' placeholder="Ex: 2020" value={form.ramalNumero || ''} onChange={(e) => setForm({...form, ramalNumero: e})} />
                            </Form.Group>
                            <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                                <Form.Label><span className='text-danger'>*</span>Ramal complemento</Form.Label>
                                <Input type="text" className='shadow-none' placeholder="Ex: 2020" value={form.complemento || ''} onChange={(e) => setForm({...form, complemento: e})} />
                            </Form.Group>
                        </div>
                    }
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Ex: PED. EXTERNO" value={form.nome || ''} onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip dos dispositivo SIP</Form.Label>
                        <SelectPicker
                            block
                            placeholder={'Selecione o dispositivo SIP'}
                            data={sipStore.sips.map((sip) => ({ label: `${sip.ip}:${sip.porta} ${sip.modelo}`, value: sip.id }))}
                            value={form.dispositivoSipId || ''}
                            onChange={(e) => {if(e) setForm({ ...form, dispositivoSipId: e})}}
                        />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Tipo</Form.Label>
                        <SelectPicker
                            block
                            placeholder={'Selecione o tipo do equipamento'}
                            data={
                                [
                                    {
                                        label: 'FXS',
                                        value: 'FXS'
                                    },
                                    {
                                        label: 'FXO',
                                        value: 'FXO'
                                    },
                                    {
                                        label: 'FXSCentral',
                                        value: 'FXSCentral'
                                    },
                                    {
                                        label: 'FXOPrincipal',
                                        value: 'FXOPrincipal'
                                    }
                                        
                                ]
                            }      
                            value={form.tipo || ''}                  
                            onChange={(e) => {if(e) setForm({ ...form, tipo: e })}}
                        />
                        {/* <select
                            className='form-control shadow-none'
                            onChange={(e) => setForm({ ...form, tipo: e.target.value })}
                            value={form.tipo || ''}
                        >
                            <option value={'FXS'}>FXS</option>
                            <option value={'FXO'}>FXO</option>
                            <option value={'FXSCentral'}>FXSCentral</option>
                        </select> */}
                    </Form.Group>
                </div>

                <div className='m-1'>
                    <p><span className='text-danger'>*</span>Câmera</p>
                    {
                        layoutStore.layouts.length > 1
                        ?
                        <div>
                            {
                                layoutStore.layouts.map((layout, i) =>
                                    <Form.Check 
                                        key={i}
                                        type={'checkbox'}
                                        id={i.toString()}
                                        label={`${layout.nomeDGuard}`}
                                        onChange={(e) => {
                                            setSelectLayout(layout.id);
                                            cameraStore.listarPorLayout(layout.id);
                                        }}
                                        checked={selectLayout === layout.id ? true : false }
                                    />
                                )
                            }
                            <>
                                {
                                    cameraStore.loading
                                    ?
                                    'CAREGANDO CAMERAS...'
                                    :
                                    <InputPicker block aria-required data={cameraStore.cameras.map((camera => ({ label: camera.nomeDGuard, value: camera.id })))} placeholder={'Informe a câmera'} value={form.cameraId || ''} onChange={(e) => setForm({ ...form, cameraId: e })} />
                                }
                            </>
                        </div>
                        :
                        <>
                            {
                                cameraStore.loading
                                ?
                                <div>
                                    CARREGANDO CAMERAS...
                                </div>
                                :
                                <InputPicker block aria-required data={cameraStore.camerasCondominio.map((camera => ({ label: camera.nomeDGuard, value: camera.id })))} placeholder={'Informe a câmera'} value={form.cameraId || ''} onChange={(e) => setForm({ ...form, cameraId: e })} />
                            }
                        </>
                    }
                </div>

                <div className='d-flex justify-content-end mt-3'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        { salvando ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {salvando ? <LinearProgress color="primary" style={{height: 2, marginTop: 15}} /> : ''}
                </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size={'lg'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastre de ramal</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormRamal()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarRamal);