import { Autocomplete, Backdrop, Box, CircularProgress, Menu, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { AiOutlineCaretLeft, AiOutlineTags, AiOutlineUser } from 'react-icons/ai';
import { BiEdit, BiFingerprint, BiPlus, BiUser, BiX } from 'react-icons/bi';
import { BsPeople, BsPhone, BsThreeDots } from 'react-icons/bs';
import { RiAlignLeft } from 'react-icons/ri'
import { GrUserExpert } from 'react-icons/gr';
import { VscTable } from 'react-icons/vsc';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { StoreContext } from '../../stores';
import { useMorador } from '../../hooks/Morador.hook';
import { useVisitante } from '../../hooks/Visitante.hook';
import { Morador } from '../../interfaces/Morador.interface';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import CadastrarTag from '../dispositivo/tag/CadastrarTag';
import Empty from '../utils/Empty';
import Loading from '../utils/Loading';
import { converterData, converterHora, cpfMask, maskCelular } from '../utils/mask';
import AtualizarVisitante from './AtualizarVisitante';
import CadastrarVisitante from './CadastrarVisitante';
import ModalBiometria from '../dispositivo/biometria/ModalBiometria';
import { CardMorador, Container, Header, ListMorador, TransitionDiv } from './styles/Autorizacao';
import { FiUserCheck } from 'react-icons/fi';
import NotFound from '../utils/NotFound';
import PermissoesListagem from '../permissoes/PermissoesListagem';
import { Button, Dropdown, Input, Nav, Navbar, SelectPicker, IconButton, Tag, Whisper, Tooltip } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import { CgArrowsV } from 'react-icons/cg';
import DragableIcon from '@rsuite/icons/Dragable';
import SearchIcon from '@rsuite/icons/Search';
import SkeletonList from '../utils/SkeletonList';


const ListagemAutorizacao = () => {

    const { unidadeStore, visitanteStore, moradorStore, dispositivosStore, condominioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando } = useMorador();
    const { _deletar, _deletando } = useVisitante();
    
    const [modalEditarVisitante, setModalEditarVisitante] = useState(false);
    const [modalDeletarVisitante, setModalDeletarVisitante] = useState(false);
    const [searchMorador, setSearchMorador] = useState('');
    const [searchVisitante, setSearchVisitante] = useState('');
    const [cadastrarVisitante, setCadastrarVisitante] = useState(false);
    const [value, setValue] = useState<UnidadeRequest | string>('');

    const toggleCadastrarVisitante = () => setCadastrarVisitante(!cadastrarVisitante);
    const toggleEditarVisitante = () => setModalEditarVisitante(!modalEditarVisitante);
    const toggleModalDeletarVisitante = () => setModalDeletarVisitante(!modalDeletarVisitante );

    const deletarMorador = () =>
    {
        if(moradorStore.selecionado) {
            deletar(moradorStore.selecionado);
        }
    }

    const deletarVisitante = () =>
    {
        if(visitanteStore.selecionado) {
            _deletar(visitanteStore.selecionado);
        }
    }
 
    useEffect(() => {
        let unidadeId = localStorage.getItem('@unidadeId');
        unidadeStore.listarPorCondominioId()
        .then(() =>
        {
            if(unidadeId)
            {
                visitanteStore.listarPorUnidade(unidadeId);
                moradorStore.listarPorUnidadeId(unidadeId);
                unidadeStore.definirSelecionado(unidadeId);
                dispositivosStore.listarControlesPorUnidade(unidadeId);
            }
            else
            {
                let primeiraUnidade = unidadeStore.unidadesPorCondomino[0];
                if(primeiraUnidade) {
                    moradorStore.listarPorUnidadeId(primeiraUnidade.id);
                    visitanteStore.listarPorUnidade(primeiraUnidade.id);
                    unidadeStore.definirSelecionado(primeiraUnidade.id);
                    dispositivosStore.listarControlesPorUnidade(primeiraUnidade.id);
                }
            }
        })
        .catch(() => alert('erro'))
        
        // if(unidadeStore.selecionado)
        // {
            // moradorStore.listarPorUnidadeId(unidadeStore.selecionado.id);
            // visitanteStore.listarPorUnidade(unidadeStore.selecionado.id);
            // unidadeStore.definirSelecionado(unidadeStore.selecionado.id);
            // dispositivosStore.listarControlesPorUnidade(unidadeStore.selecionado.id);
        // }
        // else
        // {
            // let primeiraUnidade = unidadeStore.unidadesPorCondomino[0];
            // if(primeiraUnidade) {
            //     moradorStore.listarPorUnidadeId(primeiraUnidade.id);
            //     visitanteStore.listarPorUnidade(primeiraUnidade.id);
            //     unidadeStore.definirSelecionado(primeiraUnidade.id);
            //     dispositivosStore.listarControlesPorUnidade(primeiraUnidade.id);
            // }
        // }
    }, [condominioStore.selecionado]);

    const filtrarMorador = moradorStore.moradores.filter(morador =>
        morador.nome?.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(searchMorador.toLocaleLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '')) ||
        morador.status?.toLocaleLowerCase().includes(searchMorador.toLocaleLowerCase()) ||
        !searchMorador
    );

    const filtrarVisitante = visitanteStore.visitantes.filter(visitante =>
        visitante.nome?.toLocaleLowerCase().includes(searchVisitante.toLocaleLowerCase()) ||
        !searchVisitante
    );

    const renderAutorizacoes = () =>
    {
        if(visitanteStore.loading)
        {
            return <SkeletonList/>
        }

        if(deletando)
        {
            return <SkeletonList/>
        }

        if(visitanteStore.visitantes.length === 0)
        {
            return <NotFound message='Nenhum visitante autorizado cadastrado' />
        }

        return(
            <div>
                <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="md">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Tipo</th>
                            <th>Celular</th>
                            <th>Email</th>
                            <th>Entrada</th>
                            <th>Saida</th>
                            <th>Confirmado</th>
                            <th>Autorizado</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filtrarVisitante.filter((v) => v.autorizado).map((visitante, i) =>
                            (
                                <tr key={i} onClick={() => visitanteStore.definirSelecionado(visitante.id)}>
                                    <td>{visitante.nome}</td>
                                    <td>{visitante.cpf ? cpfMask(visitante.cpf) : '-'}</td>
                                    <td>{visitante.tipoDescricao}</td>
                                    <td>{visitante.celular ? maskCelular(visitante.celular) : '-'}</td>
                                    <td>{visitante.email ? visitante.email : '-'}</td>
                                    <td>{visitante.dataHoraEntrada ? converterData(new Date(visitante.dataHoraEntrada)) : '-'}</td>
                                    <td>{visitante.dataHoraSaida ? converterData(new Date(visitante.dataHoraSaida)) : '-'}</td>
                                    <td>{visitante.confirmado}</td>
                                    <td>{visitante.autorizado}</td>
                                    <td>
                                        <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                            <Dropdown.Item onClick={() =>
                                            {
                                                toggleEditarVisitante();
                                            }}>Atualizar</Dropdown.Item>
                                            <Dropdown.Item onClick={toggleModalDeletarVisitante}>Deletar</Dropdown.Item>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return(
        <Container>
            <Header>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de cadastros de visitantes autorizados</p>
                        <p className='subtitle'>Selecione a unidade para visualizar os visitantes autorizados</p>
                    </div>
                    <div>
                        <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarVisitante}>
                            Adicionar novo visitante autorizado na unidade {unidadeStore.selecionado?.apartamento}
                        </IconButton>
                    </div>
                </div>

                <div className='d-flex box'>
                    <div className='content d-flex'>
                        <BsPeople className='content-icon' />
                        <div>
                            <p className='content-title'>Visitantes autorizados cadastrados </p>
                            <p className='content-subtitle'>{visitanteStore.autorizados.length} cadastrado na unidade {unidadeStore.selecionado?.apartamento}</p>
                        </div>
                    </div>
                    <div className='align-self-center'>
                        <div style={{marginLeft: 20}}>
                            <p>Selecione a unidade</p>
                            <SelectPicker
                                block
                                cleanable={false}
                                style={{width: 100}}
                                value={unidadeStore.selecionado?.id}
                                placeholder={'Selecione'}
                                data={unidadeStore.unidadesPorCondomino.map((unidade) => ({ label: `${unidade.apartamento} - ${unidade.blocoNome}`, value: unidade.id }))}                            
                                onChange={(e) =>
                                {
                                    moradorStore.listarPorUnidadeId(e as any);
                                    unidadeStore.definirSelecionado(e as any);
                                    visitanteStore.listarPorUnidade(e as any);
                                    dispositivosStore.listarTagPorUnidade(e as any);
                                }}
                            />
                        </div>
                    </div>

                    <div style={{marginLeft: 40}} className='align-self-center'>
                        <p className='info-title-filtro'>Filtrar visitantes da unidade <span style={{fontWeight: 550, fontSize: 15}}>{unidadeStore.selecionado?.apartamento}</span></p>
                        <Input placeholder='Buscar' onChange={(e) => setSearchVisitante(e)} />
                    </div>
                </div>

                <ListMorador>
                    {renderAutorizacoes()}
                </ListMorador>
                {/* <div>
                    <div>
                        <p>Selecione a unidade</p>
                        <SelectPicker
                            block
                            style={{width: 100}}
                            value={value.toString() || ''}
                            data={unidadeStore.unidadesPorCondomino.map((unidade) => ({ label: `${unidade.apartamento} - ${unidade.blocoNome}`, value: unidade.id }))}                            
                            onChange={(e) =>
                            {
                                moradorStore.listarPorUnidadeId(e);
                                unidadeStore.definirSelecionado(e);
                                visitanteStore.listarPorUnidade(e);
                                dispositivosStore.listarTagPorUnidade(e);
                            }}
                        />
                    </div>
                </div> */}
            </Header>

            {/* {renderRoutes()} */}


            {/* MENU MORADOR */}
            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    toggleEditarMorador();
                }}
                style={{
                    fontSize: 15
                }}
                >Atualizar</MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    toggleModalDeletarMorador();
                }}
                style={{
                    fontSize: 15
                }}
                >Deletar</MenuItem>
                {
                    moradorStore.selecionado?.usuarioId !== null
                    ?
                    <MenuItem onClick={() => {
                        toggleOpenAssociarMorador();
                        handleClose();
                    }}
                    style={{
                        fontSize: 15
                    }}
                    >Associar morador no novo condomínio
                    </MenuItem>
                    :
                    ''
                }
            </Menu> */}

            {/* MENU VISITANTES */}
            {/* <Menu
                id="basic-menu"
                anchorEl={anchorElVisitantes}
                open={openVisitantes}
                onClose={handleCloseVisitantes}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleCloseVisitantes();
                    toggleEditarVisitante();
                }}
                style={{
                    fontSize: 15
                }}
                >Atualizar</MenuItem>
                <MenuItem onClick={() => {
                    handleCloseVisitantes();
                    toggleModalDeletarVisitante();
                }}
                style={{
                    fontSize: 15
                }}
                >Deletar</MenuItem>
                
            </Menu> */}

            {/* MENU */}
            {/* <Menu
                id="basic-menu"
                anchorEl={anchorOrdem}
                open={openOrdem}
                onClose={handleCloseOrdem}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleCloseOrdem();
                    setSearchMorador('');
                    setSelectSelecionado('')
                }}
                style={{
                    fontSize: 15
                }}
                >Limpar</MenuItem>
                <MenuItem onClick={() => {
                    handleCloseOrdem();
                    setSearchMorador('Liberado');
                    setSelectSelecionado('Liberado');
                }}
                style={{
                    fontSize: 15
                }}
                >Status: Liberado</MenuItem>
                <MenuItem onClick={() => {
                    handleCloseOrdem();
                    setSearchMorador('Bloqueado');
                    setSelectSelecionado('Bloqueado');
                }}
                style={{
                    fontSize: 15
                }}
                >Status: Bloqueado</MenuItem>
            </Menu> */}

            <CadastrarVisitante show={cadastrarVisitante} hide={toggleCadastrarVisitante} />
            <AtualizarVisitante show={modalEditarVisitante} hide={toggleEditarVisitante} />
            <ModalConfirmacao show={modalDeletarVisitante} hide={toggleModalDeletarVisitante} action={deletarVisitante} content={'Você tem certeza que deseja deletar esse visitante autorizado?'} loading={_deletando} />

        </Container>
    )
}

export default observer(ListagemAutorizacao);