import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_ZKTECO_CONTROLADORA;
const usuario = process.env.REACT_APP_BASE_USUARIO_ZK;
const senha = process.env.REACT_APP_BASE_SENHA_ZK;

const basicAuthHeader = () => {
  const credentials = `${usuario}:${senha}`;
  const encodedCredentials = btoa(credentials);

  return {
    Authorization: `Basic ${encodedCredentials}`,
  };
};

const api = axios.create({
  baseURL: baseURL,
  headers: {
    ...basicAuthHeader(),
    "Content-Type": "application/json",
  },
});

export default api;