import { makeAutoObservable, runInAction } from "mobx";
import { Funcionario, TipoFuncionario } from "../interfaces/Funcionario.interface";
import FuncionarioAPI from "../api/FuncionarioAPI";

class FuncionarioStore {

    funcionarios: Funcionario[] = [];
    tiposFuncionarios: TipoFuncionario[] = [];
    selecionado: Funcionario | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listar() {
        this.loading = true;
        try {
            const { data } = await FuncionarioAPI.obterTodos();
            runInAction(() => {
                if(data) this.funcionarios = data;
                else this.funcionarios = [];
                this.loading = false;
            });
            this.loading = false;
        } catch(e) {
            this.loading = false;
        }
    }

    async listarTiposFucionarios() {
        try {
            const { data } = await FuncionarioAPI.obterTipoFuncionario();
            runInAction(() => {
                if(data) this.tiposFuncionarios = data;
                else this.tiposFuncionarios = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(funcionarioId: string) {
        let selecionado = this.funcionarios.find((f) => f.id === funcionarioId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
        else this.selecionado = null;
    }
}

export default FuncionarioStore;