import { Log } from "../interfaces/Log.interface";
import api from "../services/api/api";

class LogAPI {
    // NOTAS
    listarNotaPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/notas/condominio/${condominioId}`);
    listarNotaPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/notas/unidade/${unidadeId}`);
    // DISPOSITIVOS
    listarDispositivoPorCondominio = async (unidadeId: string) => await api.get<Log[]>(`/Logs/Dispositivo/condominio/${unidadeId}`);
    listarDispositivoPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/Dispositivo/unidade/${unidadeId}`);
    // MORADORES
    listarMoradorPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/moradores/condominio/${condominioId}`);
    listarMoradorPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/moradores/unidade/${unidadeId}`);
    listarVeiculoPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/veiculo/condominio/${condominioId}`);
    // VEICULO
    listarVeiculoPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/veiculo/unidade/${unidadeId}`);
    listarUsuarioPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/usuario/condominio/${condominioId}`);
    // USUARIO
    listarUsuarioPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/usuario/unidade/${unidadeId}`);
    listarVisitanteAtivosPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/visitantes/ativos/condominio/${condominioId}`);
    // VISITAMTES
    listarVisitanteAtivosPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/visitantes/ativos/unidade/${unidadeId}`);
    listarVisitanteAutorizadoPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/visitantes/autorizado/condominio/${condominioId}`);
    listarVisitanteAutorizadoPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/visitantes/autorizado/unidade/${unidadeId}`);
    listarVisitanteEncerradosPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/visitantes/encerrado/condominio/${condominioId}`);
    listarVisitanteEncerradosPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/visitantes/ativo/encerrado/${unidadeId}`);
    // BIOMETRIA DIGITAL
    listarBiometriaDigitalPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/biometriaDigital/condominio/${condominioId}`);
    listarBiometriaDigitalPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/biometriaDigital/unidade/${unidadeId}`);
    // TAG
    listarTagPorCondominio = async (condominioId: string) => await api.get<Log[]>(`/Logs/tag/condominio/${condominioId}`);
    listarTagPorUnidade = async (unidadeId: string) => await api.get<Log[]>(`/Logs/tag/unidade/${unidadeId}`);
}

export default new LogAPI();