
import { observer } from 'mobx-react-lite';
import react, { FC, useContext, useEffect, useState } from 'react';
import MoradorAPI from '../../api/MoradorAPI';
import { StoreContext } from '../../stores';
import { useMorador } from '../../hooks/Morador.hook';
import { cpfMask, maskCelular, maskTelefoneFixo } from '../utils/mask';
import { Button, Input, Loader, Modal, SelectPicker, Toggle } from 'rsuite';
import { AutomacaoAcionamentoIdentificacao, AutomacaoAcionamentoIdentificacaoRequest } from '../../interfaces/AutomacaoAcionamentoIdentificacao.interface';
import useAutomacaoAcionamentoIdentificacao from '../../hooks/AutomacaoAcionamentoIdentificacao.hook';


interface PropsMorador {
    show: boolean;
    hide: () => void;
}

const CadastrarAutomacaoAcionamento: FC<PropsMorador> = ({ show, hide }) => {

    let condominioId = localStorage.getItem('condominio');
    const { unidadeStore, moradorStore, acionamentoPerfilStore, condominioStore, cameraStore } = useContext(StoreContext);
    const [form, setForm] = useState<AutomacaoAcionamentoIdentificacaoRequest>({});
    const { cadastrar, salvando } = useAutomacaoAcionamentoIdentificacao();


    const salvar = () => {
        cadastrar(form as AutomacaoAcionamentoIdentificacao, hide);
    }

    useEffect(() =>
    {
        if(condominioStore.selecionado)
            setForm({ ...form, condominioId: condominioStore.selecionado.id })
    }, [condominioStore.selecionado]);

    const renderForm = () =>
    {
      return(
        <div>
            <div className='card-form-morador'>
                <div className='mt-2'>
                    <p className='title-form'>Nome da automação do acionamento</p>
                    <Input className='' placeholder='Identificação' value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })}  />
                </div>
            </div>
        </div>
      )
    }

    return(
        <Modal open={show} onClose={hide} size={'xs'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastro de automação de acionamento</p>
                    <p className='modal-styled-subtitl'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                {renderForm()}
            </Modal.Body>

            <Modal.Footer>
                <Button appearance={'primary'} color={'violet'} disabled={salvando} onClick={salvar}>
                    {
                        salvando
                        ?
                        <Loader content={'Salvando...'} />
                        :
                        'Cadastrar'
                    }
                </Button>
                <Button onClick={hide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(CadastrarAutomacaoAcionamento);