import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { StoreContext } from '../../stores';
import { useCondominio } from '../../hooks/Condominio.hook';
import { Condominio, CondominioRequest } from '../../interfaces/Condominio.interface';
import { Container, Header, Success } from './styles/CadastrarCondominio.style';
import { BsJournalCheck } from 'react-icons/bs';
import { Button, Input, Loader, Modal, SelectPicker } from 'rsuite';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const CadastrarCondomominio: FC<ModalProps> = ({ show, hide }) => {

    const { condominioStore } = useContext(StoreContext);
    const { cadastrar, salvando, salvo, setSalvo } = useCondominio();
    const [form, setForm] = useState<CondominioRequest>({});

    const clear = () =>
    {
        setForm({});
    }

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrar(form as Condominio, hide, clear);
    }

    useEffect(() => {
        condominioStore.listarAppIdentificacao();
    }, []);

    const FormCondominio = () => {

        if (salvo) {
            return (
                <div className='d-flex justify-content-center'>
                    <Success>
                        <div className='header'>
                            <BsJournalCheck className='icon' />
                            <p className='title'>Condominio cadastrado com sucesso!</p>
                        </div>

                        <div className='d-flex justify-content-center' onClick={() => setSalvo(false)}>
                            <button className='btn btn-outline-success shadow-none btn-cadastro'>Cadastrar outro condomínio</button>
                        </div>
                    </Success>
                </div>
            )
        }

        return (
            <div>
                <Form onSubmit={(e) => salvar(e)}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label><span className='text-danger'>*</span>Central de atendimento</Form.Label>
                        <SelectPicker
                            block
                            placeholder={'Selecione'}
                            onChange={(e) => { if (e) setForm({ ...form, centralAtendimentoId: e }) }}
                            value={form.centralAtendimentoId || ''}
                            data={condominioStore.centrais.map((c) => ({ label: c.nome, value: c.id }))}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Identificação do APP</Form.Label>
                        <SelectPicker
                            block
                            placeholder={'Selecione'}
                            onChange={(e) => {
                                if (e) {
                                    setForm({
                                        ...form,
                                        condominioAuxiliar: {
                                            ...form.condominioAuxiliar,
                                            appId: e || ""
                                        }
                                    });
                                }
                            }}
                            value={form.condominioAuxiliar?.appId || ''}
                            data={condominioStore.appIdentificacao.map((c) => ({ label: c.nome, value: c.appId }))}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome do condomínio</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Nome" value={form.nome || ''} onChange={(e) => setForm({ ...form, nome: e })} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Endereco</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Endereço"
                            onChange={(e) => {
                                if (e) {
                                    setForm({
                                        ...form,
                                        condominioAuxiliar: {
                                            ...form.condominioAuxiliar,
                                            endereco: e || ""
                                        }
                                    });
                                }
                            }}
                            value={form.condominioAuxiliar?.endereco || ''}
                        />
                    </Form.Group>

                    <div className='d-flex justify-content-end'>
                        <Button type='submit' appearance="primary" disabled={salvando} style={{ marginRight: 10 }}>{salvando ? <Loader title='Salvando...' /> : 'Salvar'}</Button>
                        <Button type='button' appearance="default" disabled={salvando} onClick={hide}>Fechar</Button>
                    </div>
                </Form>
                {salvando ? <LinearProgress color="primary" style={{ height: 2, width: '100vw', position: 'fixed', top: 0, left: 0 }} /> : ''}
            </div>

        )
    }

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar novo condomínio</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para cadastrar o condomínio
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormCondominio()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarCondomominio);