import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState, useEffect } from 'react';
import { BiServer } from 'react-icons/bi';
import { BsArrowRightShort, BsHddNetwork } from 'react-icons/bs';
import { Button } from 'rsuite';
import { StoreContext } from '../../stores';
import ModalAtualizar from './ModalAtualizar';
import ModalCadastrar from './ModalCadastrar';
import { Container, Header } from './styles/Rede.style';

const RedeListagem = () => {

    const { redeStore } = useContext(StoreContext);
    const [cadastrar, setCadastrar] = useState(false);
    const [atualizar, setAtualizar] = useState(false);

    const toggleCadastrar = () => setCadastrar(!cadastrar);
    const toggleAtualizar = () => setAtualizar(!atualizar);

    useEffect(() =>
    {
        redeStore.listarPorCondominio();
    }, []);

    return (
        <Container>
            <Header>
                <div>
                    <p className='title'>Redes do condomínio</p>
                    <p className='subtitle'>Crie, atualize ou exclua as redes que estão sendo listado abaixo</p>
                </div>
            </Header>
            <div className='content'>
                <div>
                    <Timeline position="alternate">
                        {
                            redeStore.redes.map((rede, i) =>
                            (
                                <TimelineItem key={i} onClick={() => redeStore.definirSelecionado(rede.id)}>
                                    <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                    >
                                    <p className='net'>Clique no ícone para atualizar/deletar</p>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color={'primary'} style={{padding: 10, marginTop: 10}} role={'button'} onClick={toggleAtualizar}>
                                        <BiServer size={22} />
                                    </TimelineDot>
                                    <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        {rede.subRede}
                                    </Typography>
                                    <Typography style={{fontSize: 12}}>Ip da sub-rede</Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))
                        }
                        <Button appearance='primary' color={'violet'} className=' mt-2' onClick={toggleCadastrar}>Cadastrar nova rede</Button>
                    </Timeline>
                </div>
            </div>

            <ModalCadastrar show={cadastrar} hide={toggleCadastrar} />
            <ModalAtualizar show={atualizar} hide={toggleAtualizar} />
        </Container>
    )
}

export default observer(RedeListagem);
