import { Maquina, Ramal, RamalCentral, RamalGeral } from "../interfaces/Ramal.interface";
import api from "../services/api/api";

class RamalAPI {
    
    listarRamaisGeral = async () => await api.get<RamalGeral[]>(`/Ramal/geral`);

    listarRamaisCentral = async () => await api.get<RamalCentral[]>(`/Ramal/Centrais`);

    listarRamalPorCondominioId = async (condominioId: string) => await api.get<Ramal[]>(`/Ramal/ramaisporcondominioid`, { params: { condominioId } });

    listarRamalPorDispositivosipId = async (dispositivoSipId: string) => api.get<Ramal[]>(`/Ramal/dispositivoSipId/${dispositivoSipId}`);

    cadastrarRamal = async (request: Ramal) => await api.post<Ramal>(`/Ramal`, request);

    reiniciarRamal = async (dispositivoSipId: string) => await api.get(`/DispositivosSip/Reboot/${dispositivoSipId}`);

    atualizarRamal = async (request: Ramal) => await api.put<Ramal>(`/Ramal`, request);

    atualizarOrdem = async (request: Ramal[]) => await api.put(`/Ramal/novaOrdem`, request);

    deletarRamal = async (ramalId: string) => await api.delete(`/Ramal/${ramalId}`);

    deslogarUsuario = async (ramalId: string, centralAtendimentoId: string) => await api.get(`/Maquina/deslogar/${ramalId}/${centralAtendimentoId}`);

}

export default new RamalAPI();