import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import BlocoAPI from "../api/BlocoAPI";
import ComandoAPI from "../api/ComandoAPI";
import { Bloco } from "../interfaces/Bloco.interface";
import { Comando } from "../interfaces/Comando.interface";

class ComandoStore {

    loading: boolean = false;
    comandos: Comando[] = [];
    comandosPorAcionamento: Comando[] = [];
    selecionado: Comando | null = null;
    selecionadoPorAcionamento: Comando | null = null;

    constructor() {
        makeAutoObservable(this);
        this.definirSelecionado = this.definirSelecionado.bind(this);
        this.listar = this.listar.bind(this);
    }

    async listar(caminhoId: string) {
        try {
            if(caminhoId) {
                const { data } = await ComandoAPI.listarComandoPorCaminhoId(caminhoId);
                runInAction(() => {
                    if(data) this.comandos = data;
                    else this.comandos = [];
                });
            }
        } catch(e) {
            //
        }
    }

    async listarPorAcionamento(acionamentoId: string) {
        this.loading = true;
        try {
            if(acionamentoId) {
                const { data } = await ComandoAPI.listarComandoPorAcionamentoId(acionamentoId);
                runInAction(() => {
                    if(data) this.comandosPorAcionamento = data;
                    else this.comandosPorAcionamento = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(caminhoId: string) {
        let selecionado = this.comandosPorAcionamento.find((cmd) => cmd.id === caminhoId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
    }

    definirSelecionadoPorAcionamento(acionamentoId: string) {
        let selecionado = this.comandosPorAcionamento.find((ac) => ac.id === acionamentoId);
        if(selecionado) {
            this.selecionadoPorAcionamento = selecionado;
        }
    }

}

export default ComandoStore;