import { useContext, useState } from "react";
import { toast } from "react-toastify";
import GuaritaAPI from "../api/GuaritaAPI";
import ModuloAPI from "../api/ModuloAPI";
import { StoreContext } from "../stores";
import { Guarita } from "../interfaces/Guarita.interface";
import { Modulo } from "../interfaces/Modulo.interface";


const useModulo = () => {

    const { guaritaStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [_deletando, setDeletando] = useState(false);

    const cadastrar = async (modulo: Modulo, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await ModuloAPI.cadastrarModulo(modulo);
            if(status === 200) {
                guaritaStore.modulos.push(data);
                toast.success('Módulo cadastrado com sucesso');
                hide();
                setSalvando(false);
            } else {
                toast.error('Erro ao cadastrar módulo');
                setSalvando(false);
            }
        } catch(e) {
            setSalvando(false);
            //
        }
    }

    const atualizar = async (modulo: Modulo, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await ModuloAPI.atualizarModulo(modulo);
            if(status === 200) {
                guaritaStore.modulos = guaritaStore.modulos.map( mod => mod.id === modulo.id ? modulo : mod );
                toast.success('Módulo atualizado com sucesso');
                hide();
                setAtualizando(false);
            } else {
                toast.error('Erro ao atualizar módulo');
                setAtualizando(false);
            }
        } catch(e) {
            setAtualizando(false);
            //
        }
    }

    const _deletar = async (modulo: Modulo) => {
        setDeletando(true);
        try {
            const { status } = await ModuloAPI.deletarModulo(modulo.id);
            if(status === 200) {
                guaritaStore.modulos = guaritaStore.modulos.filter( mod => mod.id !== modulo.id );
                toast.success('Módulo deletado com sucesso');
                setDeletando(false);
            } else {
                toast.error('Erro ao deletado módulo');
                setDeletando(false);
            }
        } catch(e) {
            setDeletando(false);
            //
        }
    }


    return {
        cadastrar,
        atualizar,
        _deletar,
        salvando,
        atualizando,
        _deletando
    }

}


export default useModulo;