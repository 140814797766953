import { observer } from 'mobx-react-lite';
import React, { useContext, useState, useEffect } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { BiEdit, BiX } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { StoreContext } from '../../../stores';
import { useSip } from '../../../hooks/Sip.hook';
import AtualizarSip from './AtualizarSip';
import CadastrarSip from './CadastrarSip';
import { Container, Header, ListSip } from './styles/Sip.style';
import { Button, Dropdown, IconButton } from 'rsuite';
import ModalConfirmacao from '../../utils/ModalConfirmacao';
import PlusIcon from '@rsuite/icons/Plus';
import SkeletonList from '../../utils/SkeletonList';
import NotFound from '../../utils/NotFound';
import { Table } from 'react-bootstrap';

const Sip = () => {

    const { sipStore, condominioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando } = useSip();
    const [modalCadastrarSip, setModalCadastrarSip] = useState(false);
    const [modalAtualizarSip, setModalAtualizarSip] = useState(false);
    const [busca, setBusca] = useState('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [modalDeletar, setModalDeletar] = useState(false);

    const filtrarSip = sipStore.sips.filter((sip) => 
        sip.fabricante?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) ||
        sip.modelo?.toLocaleLowerCase().toString().includes(busca.toLocaleLowerCase()) ||
        sip.porta?.toString().includes(busca.toLocaleLowerCase()) ||
        sip.ip?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) ||
        !busca
    );

    const toggleCadastrarSip = () => setModalCadastrarSip(!modalCadastrarSip);
    const toggleEditarSip = () => setModalAtualizarSip(!modalAtualizarSip);
    const toggleModalDeletar= () => setModalDeletar(!modalDeletar);
    
    const deletarSIP = () =>
    {
        if(sipStore.selecionado) {
            deletar(sipStore.selecionado);
        }
    }

    useEffect(() =>
    {
        sipStore.listar();
    }, []);

    const renderSip = () => {

        if(deletando) return <SkeletonList/>
        if(sipStore.loading) return <SkeletonList/>

        if(sipStore.sips.length === 0) return <NotFound message='Nenhum dispositivo sip cadastrado' />

        return(
            <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                <thead>
                <tr className='tr'>
                  <th>Ip</th>
                  <th>Porta</th>
                  <th>Fabricante</th>
                  <th>Modelo</th>
                  <th>Tipo</th>
                  <th>Usuário</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {
                    filtrarSip.map((sip, i) => (
                    <tr key={i}>
                        <td>{sip.ip}</td>
                        <td>{sip.porta}</td>
                        <td>{sip.fabricante}</td>
                        <td>{sip.modelo}</td>
                        <td>{sip.usuario}</td>
                        <td>{sip.tipo}</td>
                        <td>
                            <Dropdown appearance='primary' color={'violet'} title={'Ação'} size={'xs'}>
                                <Dropdown.Item onClick={() =>
                                {
                                    sipStore.definirSelecionado(sip.id);
                                    toggleEditarSip();

                                }}>Atualizar</Dropdown.Item>
                                <Dropdown.Item onClick={() =>
                                {
                                    sipStore.definirSelecionado(sip.id);
                                    toggleModalDeletar();
                                }}>Deletar</Dropdown.Item>
                            </Dropdown>
                            {/* <IconButton style={{ height: 30, width: 30 }} onClick={(e) => handleClick(e)}>
                                <BsThreeDots className='icon'/>
                            </IconButton> */}
                        </td>
                    </tr>
                    ))
                }
            </tbody>
            </Table>
        )
    }

    return(
        <Container>
            <Header>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de cadastros de dipositivo SIP</p>
                        <p className='subtitle'>Clique no botão ao lado para cadastrar novos dispositivos</p>
                    </div>
                    <div>
                        <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarSip}>
                            Adicionar novo dispositivo SIP
                        </IconButton>
                    </div>
                </div>
            </Header> 

            <ListSip>
                {renderSip()}
            </ListSip>

            <CadastrarSip show={modalCadastrarSip} hide={toggleCadastrarSip} condominioId={condominioStore.selecionado?.id} />
            <AtualizarSip show={modalAtualizarSip} hide={toggleEditarSip} sipSelecionado={sipStore.selecionado} />

            {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    toggleEditarSip();
                }}>Atualizar</MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    toggleModalDeletar();    
                }}>Deletar</MenuItem>
            </Menu> */}

            <ModalConfirmacao show={modalDeletar} hide={toggleModalDeletar} action={deletarSIP} content={'Você tem certeza que deseja deletar esse SIP?'} loading={deletando} />
        </Container>
    )
}

export default observer(Sip);