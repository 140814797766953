import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, List, Loader, Modal } from 'rsuite';
import { useMorador } from '../../hooks/Morador.hook';
import { useRamal } from '../../hooks/Ramal.hook';
import { useUnidade } from '../../hooks/Unidade.hook';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { StoreContext } from '../../stores';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarOrdem: FC<ModalProps> = ({ show, hide }) => {

    const { ramalStore, condominioStore } = useContext(StoreContext);
    const [form, setForm] = useState<UnidadeRequest>({});
    const { atualizarOrdem, atualizando } = useRamal();

    const handleSortEnd = ({ oldIndex, newIndex } : any) =>
    {
        ramalStore.ramais = arrayMoveImmutable(ramalStore.ramais, oldIndex, newIndex);
        ramalStore.ramais.map((_, i) => _.ordemExibicao = i);
        atualizarOrdem(ramalStore.ramais);
    }

    const Listagem = () => {

        if(atualizando)
        {
          return(
            <div className={'d-flex justify-content-center mt-5'}>
              <Loader content={'Salvando nova ordem dos ramais... aguarde!'} />
            </div>
          )
        }

        return(
            <List sortable onSort={handleSortEnd}>
                {
                    ramalStore.ramais.map((ramal, i) =>
                    <List.Item style={{background: 'transparent', margin: 4}} className='p-3' key={i} index={i}>{ramal.nome?.toUpperCase()} - {ramal.ramalNumero}</List.Item>)
                }
            </List>
        )
    }

    return(
        <Modal open={show} onClose={() => atualizando ? {} : hide()}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar ordem dos ramais do condomínio { condominioStore.selecionado?.nome }</p>
                    <p className='modal-styled-subtitle'>
                        Arraste para cima ou para baixo para alterar ordem dos ramais
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{Listagem()}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => atualizando ? {} : hide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarOrdem);