import { makeAutoObservable, runInAction } from "mobx";
import AutomacaoAcionamentoIdentificacaoAPI from "../api/AutomacaoAcionamentoIdentificacaoAPI";
import { AutomacaoAcionamentoIdentificacao } from "../interfaces/AutomacaoAcionamentoIdentificacao.interface";

class AutomacaoAcionamentoIdentificacaoStore {

    automacaoAcionamentoIdentificacao: AutomacaoAcionamentoIdentificacao[] = [];
    selecionado: AutomacaoAcionamentoIdentificacao | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listar() {
        this.loading = true;
        let condominioId = localStorage.getItem('condominio')
        try {
            if(condominioId) {
                const { data } = await AutomacaoAcionamentoIdentificacaoAPI.listarPorCondominioId(condominioId);
                runInAction(() => {
                    if(data) this.automacaoAcionamentoIdentificacao = data;
                    else this.automacaoAcionamentoIdentificacao = [];
                    this.loading = false;
                });
            }
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(automacaoAcionamentoIdentificacaoavasId: string) {
        let selecionado = this.automacaoAcionamentoIdentificacao.find((a) => a.id === automacaoAcionamentoIdentificacaoavasId);
        if(selecionado) {
            this.selecionado = selecionado;
        }
        else this.selecionado = null;
    }

}

export default AutomacaoAcionamentoIdentificacaoStore;