import axios, { AxiosResponse } from "axios";

const baseUrl =  process.env.REACT_APP_BASE_MS_RELATORIOS;

const api = axios.create({
  baseURL: baseUrl
});

api.interceptors.request.use(
  async (config) => {   
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
