import axios from 'axios';

const baseURL = process.env.DOMAIN_ZKTECO_STANDALONE;

const apiZkStandAlone = axios.create({ baseURL });

apiZkStandAlone.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
} as any;

apiZkStandAlone.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config; 
});

export default apiZkStandAlone;