import styled from "styled-components";
import { InputGroup } from "rsuite"; // Importação do componente base do RSuite

export const StyledInputGroup = styled(InputGroup)`
  .disabled-button {
    opacity: 0.4;
    color: rgb(107, 107, 107);
    pointer-events: none;   
    cursor: not-allowed; 
  }
`;
