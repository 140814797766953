import React, { FC, useContext, useEffect, useState } from 'react';
import { Container, ListCondominio, ListView, Navigation, Content, HeaderNavigation, Dashboard, NavigationBar } from './styles/Condominios.style';
import Header from '../layouts/Header';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../stores';
import { useNavigate  } from 'react-router-dom';
import CadastrarCondomominio from './CadastrarCondominio';
import Maquina from '../maquina';
import UsuarioListagem from '../usuario';
import ServidorDGuard from '../servidorDGuard';
import { Button, Input, Nav, IconButton, Navbar, InputGroup } from 'rsuite';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PlusIcon from '@rsuite/icons/Plus';
import ColumnsIcon from '@rsuite/icons/Columns';
import PcIcon from '@rsuite/icons/Pc';
import StorageIcon from '@rsuite/icons/Storage';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import FuncionarioListagem from '../funcionario/index'
import VisibleIcon from '@rsuite/icons/Visible';
import { Table } from 'react-bootstrap';
import AtualizarSenha from '../usuario/AtualizarSenha';
import Loading from './Loading';
import { BsCameraVideo } from 'react-icons/bs';

ChartJS.register(ArcElement, Tooltip, Legend);

const Condominios: FC = () => {
  
    const { condominioStore, layoutStore, cameraStore, usuarioStore, themeStore, authStore } = useContext(StoreContext);
    const { condominios, definirSelecionado, loading } = condominioStore;
    const [search, setSearch] = useState('');
    const [modalCadastrarCondominio, setModalCadastrarCondominio] = useState(false);
    const [routes, setRoutes] = useState('condominios');
    const navigate = useNavigate();

    useEffect(() => {
        layoutStore.definirSelecionado(null);
        condominioStore.definirSelecionado('');
        cameraStore.cameras = [];
        localStorage.removeItem('condominio')
    }, []);

    useEffect(() =>
    {
      usuarioStore.listarUsuáriosLogados();
    }, []);

    const toggleCadastrarCondominio = () => setModalCadastrarCondominio(!modalCadastrarCondominio);

    const RenderAtualizarSenha = () =>
    {
        return <AtualizarSenha show={authStore.usuario?.renovar ?? false} hide={() => {}} />
    }
    
    const filtrarCondominio = condominios.filter((cond) => cond.nome.toLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, '').includes(
        search.toLowerCase().normalize('NFD').replace(/[-\u0300-\u036f]/g, ''),
      ))

    // const loading = () => {
    //     if(carregando) {
    //         return(
    //             <Backdrop
    //                 sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //                 open={true}
    //                 >
    //             <LinearProgress color='info' style={{height: 2, width: '100%', position: 'absolute', top: 0}} />
    //                 <CircularProgress color="inherit" />
    //             </Backdrop>
    //         )
    //     }
    // }

    const ListagemCondominios = () =>
    {

        if(loading)
        {
            return(
                <div style={{width: '97%'}}>
                    <Loading/>
                </div>
            )
        }

        return(
            <ListCondominio>
                <ListView>
                    <div className='d-flex justify-content-between content'>
                        <div className='d-flex align-self-center'>
                            <div className='p-1 mt-3'>
                                <p className='title'>Condomínios cadastrados</p>
                                <p className='subtitle'>Selecione o condomínio para ver os dados cadastrado</p>
                            </div>
                            <div style={{marginLeft: 10}} className='align-self-center'>
                                <p className='info-title-filtro' style={{width: 100}}>Filtrar condomínio por nome</p>
                                <InputGroup inside>
                                    <Input placeholder='Buscar' style={{width: 350}} value={search} onChange={(e) => setSearch(e)} />
                                    <InputGroup.Button onClick={() => setSearch('')}>
                                        <CloseOutlineIcon />
                                    </InputGroup.Button>
                                </InputGroup>
                            </div>
                        </div>
                        <div className='align-self-center mt-3'>
                            <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarCondominio}>
                                Cadastrar novo condomínio
                            </IconButton>
                        </div>
                    </div>
                    <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Administradora</th>
                                <th scope="col">Endereço</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filtrarCondominio.map((condominio, i) => (
                                    <tr key={i} role={'button'} onClick={() => 
                                    {
                                        condominioStore.definirSelecionado(condominio.id);
                                        navigate("/condominios/dashboard");
                                    }}> 
                                    <td>{condominio.nome}</td>
                                        <td>{condominio.condominioAuxiliar?.administradoraNome ?? " Sem APP configurado"}</td>
                                        <td>{!condominio.condominioAuxiliar?.endereco  ? 'Sem endereço cadastrado' : condominio.condominioAuxiliar?.endereco}</td>
                                    </tr>
                                    
                                
                                ))
                            }
                        </tbody>
                    </Table>
                </ListView>
            </ListCondominio>
            // <ListCondominio>
            //     <ListView>
                    // <div className='d-flex justify-content-between content'>
                    //     <div className='d-flex align-self-center'>
                    //         <div className='p-1 mt-3'>
                    //             <p className='title'>Condomínios cadastrados</p>
                    //             <p className='subtitle'>Selecione o condomínio para ver os dados cadastrado</p>
                    //         </div>
                    //         <div style={{marginLeft: 10}} className='align-self-center'>
                    //             <p className='info-title-filtro' style={{width: 100}}>Filtrar condomínio por nome</p>
                    //             <InputGroup inside>
                    //                 <Input placeholder='Buscar' style={{width: 350}} value={search} onChange={(e) => setSearch(e)} />
                    //                 <InputGroup.Button onClick={() => setSearch('')}>
                    //                     <CloseOutlineIcon />
                    //                 </InputGroup.Button>
                    //             </InputGroup>
                    //         </div>
                    //     </div>
                    //     <div className='align-self-center mt-3'>
                    //         <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarCondominio}>
                    //             Cadastrar novo condomínio
                    //         </IconButton>
                    //     </div>
                    // </div>
                    // {
                    //     filtrarCondominio.map((condominio, i) => (
                    //         <Link to={'/condominios/dashboard'} key={i} className='_link' onClick={() => definirSelecionado(condominio.id)}>
                    //            <BiBuilding className='icon' size={25} />
                    //             <p className='item'>{condominio.nome}</p>
                    //         </Link>
                    //     )) 
                    // }
            //     </ListView>
            // </ListCondominio>
        )
    }
    const Usuarios = () =>
    {
        return <UsuarioListagem/>
    }

    const renderRoutes = () =>
    {
        switch(routes)
        {
            case 'condominios':
                return ListagemCondominios();
            case 'maquina':
                return <Maquina/>;
            case 'usuarios':
                return Usuarios();
            case 'dguard':
                return <ServidorDGuard/>
            case 'funcionario':
                return <FuncionarioListagem/>
        }
        
    }

    const data1 = {
        labels: ['NAE 1', 'NAE 2', 'NAE 3', 'NAE 4', 'NAE 5'],
        datasets: [
          {
            label: 'Quantidade de condomínios por NAE',
            data:
            [
                condominioStore.condominios.filter(x => x?.nome.toLocaleLowerCase().includes('nae 1')).length,
                condominioStore.condominios.filter(x => x?.nome.toLocaleLowerCase().includes('nae 2')).length,
                condominioStore.condominios.filter(x => x?.nome.toLocaleLowerCase().includes('nae 3')).length,
                condominioStore.condominios.filter(x => x?.nome.toLocaleLowerCase().includes('nae 4')).length,
                condominioStore.condominios.filter(x => x?.nome.toLocaleLowerCase().includes('nae 5')).length,
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

      const data2 = {
        labels: ['Operadores ativos', 'P.A disponível'],
        datasets: [
          {
            label: 'Qtd',
            data:
            [
                usuarioStore.maquinaLogada.filter(x => x.usuarioIdLogado).length,
                usuarioStore.maquinaLogada.filter(x => !x.usuarioIdLogado).length,
            ],
            backgroundColor: [
                'rgba(200, 255, 100, 1)',
                'rgba(100, 240, 56, 1)',
            ],
            borderColor: [
                'rgba(200, 255, 100, 1)',
                'rgba(100, 240, 56, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };

    return(
        <Container>
            {authStore.usuario?.renovar ? RenderAtualizarSenha() : <div/>}
            <Header mode='condominio' />

            <div className='p-3'>
                <HeaderNavigation>
                    <div className='d-flex'>
                        {/* <div className='content d-flex'>
                            <BiBuildingHouse className='content-icon' />
                            <div>
                                <p className='content-title'>Condomínios cadastrados</p>
                                <p className='content-subtitle'>{condominioStore.condominios.length} cadastrado</p>
                            </div>
                        </div> */}

                        <div style={{marginLeft: 30}} className='align-self-center'>
                            <p className='info-title'>Atualizar ordem dos moradores</p>
                            <IconButton appearance="primary" color={routes === 'condominios' ? 'blue' : 'violet'} icon={<ColumnsIcon />} onClick={() => setRoutes('condominios')}>
                                Condomínios
                            </IconButton>
                        </div>

                        <div style={{marginLeft: 20}} className='align-self-center'>
                            <p className='info-title'>Buscar morador do condomínio pelo nome</p>
                            <IconButton appearance="primary" color={routes === 'maquina' ? 'blue' : 'violet'} icon={<PcIcon />} onClick={() => setRoutes('maquina')}>
                                Máquinas / Usuários
                            </IconButton>
                        </div>

                        <div style={{marginLeft: 20}} className='align-self-center'>
                            <p className='info-title'>Funcionários cadastrados no sistema</p>
                            <IconButton appearance="primary" color={routes === 'funcionario' ? 'blue' : 'violet'} icon={<CharacterAuthorizeIcon />} onClick={() => setRoutes('funcionario')}>
                                Funcionários
                            </IconButton>
                        </div>

                        <div style={{marginLeft: 20}} className='align-self-center'>
                            <p className='info-title'>Servidores DGUARD cadastrados</p>
                            <IconButton appearance="primary" color={routes === 'dguard' ? 'blue' : 'violet'} icon={<StorageIcon />} onClick={() => setRoutes('dguard')}>
                                Servidores
                            </IconButton>
                        </div>
                    </div>
                </HeaderNavigation>

                
                <Dashboard className='d-flex mt-2'>
                    {/* <div className='' style={{width: '50%'}}>
                        <div className='d-flex chat1'>
                            <div className='doughnut'>
                                <p style={{marginLeft: 29}}>NAEs cadastrado no sistema</p>
                                <Doughnut data={data1} />
                            </div>
                            <div className='p-3'>
                                <p>Legendas</p>

                                <div className='d-flex mt-2'>
                                    <div className='align-self-center'>
                                        <div style={{background: 'rgba(255, 99, 132, 0.5)', height: 10, width: 10, borderRadius: 60, alignSelf: 'center', marginRight: 10}} />
                                    </div>
                                    <p>Condomínios NAE 1 - {condominioStore.condominios.filter(x => x.nome?.toLocaleLowerCase()?.includes('nae 1')).length} cadastrado</p>
                                </div>

                                <div className='d-flex mt-2'>
                                    <div className='align-self-center'>
                                        <div style={{background: 'rgba(54, 162, 235, 0.5)', height: 10, width: 10, borderRadius: 60, alignSelf: 'center', marginRight: 10}} />
                                    </div>
                                    <p>Condomínios NAE 2 - {condominioStore.condominios.filter(x => x.nome?.toLocaleLowerCase().includes('nae 2')).length} cadastrado</p>
                                </div>

                                <div className='d-flex mt-2'>
                                    <div className='align-self-center'>
                                        <div style={{background: 'rgba(255, 206, 86, 0.5)', height: 10, width: 10, borderRadius: 60, alignSelf: 'center', marginRight: 10}} />
                                    </div>
                                    <p>Condomínios NAE 3 - {condominioStore.condominios.filter(x => x.nome?.toLocaleLowerCase().includes('nae 3')).length} cadastrado</p>
                                </div>

                                <div className='d-flex mt-2'>
                                    <div className='align-self-center'>
                                        <div style={{background: 'gba(75, 192, 192, 0.5)', height: 10, width: 10, borderRadius: 60, alignSelf: 'center', marginRight: 10}} />
                                    </div>
                                    <p>Condomínios NAE 4 - {condominioStore.condominios.filter(x => x.nome?.toLocaleLowerCase().includes('nae 4')).length} cadastrado</p>
                                </div>

                                <div className='d-flex mt-2'>
                                    <div className='align-self-center'>
                                        <div style={{background: 'rgba(153, 102, 255, 0.5', height: 10, width: 10, borderRadius: 60, alignSelf: 'center', marginRight: 10}} />
                                    </div>
                                    <p>Condomínios NAE 5 - {condominioStore.condominios.filter(x => x?.nome.toLocaleLowerCase().includes('nae 5')).length} cadastrado</p>
                                </div>
                            </div>
                        </div>
                        <div className='chat2 d-flex'>
                            <div className='doughnut'>
                                <p style={{marginLeft: 29}}>Usuários usando o sistema outkey operacional</p>
                                <Doughnut data={data2} />
                            </div>
                            <div className='p-3'>
                                <p>Legendas</p>

                                <div className='d-flex mt-2'>
                                    <div className='align-self-center'>
                                        <div style={{background: 'rgba(200, 255, 100, 1)', height: 10, width: 10, borderRadius: 60, alignSelf: 'center', marginRight: 10}} />
                                    </div>
                                    <p>Operadores ativo - {usuarioStore.maquinaLogada.filter(x => x.usuarioIdLogado).length} logado</p>
                                </div>

                                <div className='d-flex mt-2'>
                                    <div className='align-self-center'>
                                        <div style={{background: 'rgba(100, 240, 56, 1)', height: 10, width: 10, borderRadius: 60, alignSelf: 'center', marginRight: 10}} />
                                    </div>
                                    <p>P.A - {usuarioStore.maquinaLogada.filter(x => !x.usuarioIdLogado).length} livres</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <Content >
                        {renderRoutes()}
                    </Content>
                </Dashboard>
            </div>

            <CadastrarCondomominio show={modalCadastrarCondominio} hide={toggleCadastrarCondominio} />

        </Container>
    );
}

export default observer(Condominios);