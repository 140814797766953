import { useContext, useState } from "react";
import { toast } from "react-toastify";
import LayoutAPI from "../api/LayoutAPI";
import { StoreContext } from "../stores";
import { Layout, ServidorDGuard } from "../interfaces/Layout.interface";
import ServidorDGuardAPI from "../api/ServidorDGuardAPI";


export const useServidorDGuard = () => {

    const { layoutStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const cadastrar = async (servidorDGuard: ServidorDGuard, hide: () => void) => {
        setSalvando(true);

        try {
            const { data, status } = await ServidorDGuardAPI.cadastrar(servidorDGuard);
            if(status === 200) {
                layoutStore.servidoresDGuard.push(data);
                setSalvando(false);
                hide();
                toast.success('Servidor Dguard cadastrado com sucesso!');
            }
        } catch(e) {
            setSalvando(false);
            toast.error('Erro ao cadastrar Servidor Dguard');
        }
    }

    const atualizar = async (servidorDGuard: ServidorDGuard, hide: () => void) => {
        setAtualizando(true);
        try {
            const { status } = await ServidorDGuardAPI.atualizar(servidorDGuard);
            if(status === 200) {
                layoutStore.servidoresDGuard = layoutStore.servidoresDGuard.map(ser => ser.id === servidorDGuard.id ? servidorDGuard : ser);
                setAtualizando(false);
                hide();
                setSuccess(true);
                toast.success('Servidor Dguard atualizado com sucesso!');
            }
        } catch(e) {
            //
            setAtualizando(false);
            toast.error('Erro ao atualizar Servidor Dguard');
        }
    }

    const deletar = async (servidorDGuard: ServidorDGuard, hide: () => void) => {
        setDeletando(true);
        try {
            const { status } = await ServidorDGuardAPI.deletar(servidorDGuard.id);
            if(status === 200) {
                layoutStore.servidoresDGuard = layoutStore.servidoresDGuard.filter(ser => ser.id !== servidorDGuard.id);
                setDeletando(false);
                hide();
                toast.success('Servidor Dguard deletado com sucesso!')
            }
        } catch(e) {
            //
            setDeletando(false);
            toast.error('Erro ao deletar Servidor Dguard')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
    }

}