import api from "../services/api/api";
import { Aviso } from "../interfaces/Aviso.interface";

class AvisoAPI {
  cadastrarAviso = async (request: Aviso) =>
    await api.post<Aviso>(`/Aviso`, request);

  atualizarAviso = async (request: Aviso) =>
    await api.put<Aviso>(`/Aviso`, request);

  deletarAviso = async (id: string) =>
    await api.delete(`/Aviso`, { params:{ id} });

  listarAvisos = async (condominioId: string) =>
    await api.get<Aviso[]>(`/Aviso/condominio/${condominioId}`);

  atualizarStatusAviso = async (request: Aviso) =>
    await api.put(`/Aviso/atualizarstatus`, request);
}

export default new AvisoAPI();
