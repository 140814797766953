import styled from 'styled-components';

export const Container = styled.div`
    // padding: 10px;
    width: 97%;
    .content
    {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        // height: 80vh;
    }
    .net
    {
        margin: 0;
        color: ${(props) => props.theme.colors.text};
        font-size: 8pt;
    }
    .input
    {
        background: red;
    }
`

export const Header = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    // border-bottom: 1px solid rgba(0,0,0,0.0800);
    padding: 10px;
    .title
    {
        margin: 0;
        font-size: 10pt;
        font-weight: 550;
    }
    .subtitle
    {
        margin: 0;
        font-size: 9pt;
    }
`;
