import { useContext, useState } from "react";
import { toast } from "react-toastify";
import AcionamentoAPI from "../api/AcionamentoAPI";
import { StoreContext } from "../stores";

import { Aviso } from "../interfaces/Aviso.interface";
import AvisoAPI from "../api/AvisoAPI";


const useAviso = () => {
  const {  avisoStore } = useContext(StoreContext);
  const [salvando, setSalvando] = useState(false);
  const [atualizando, setAtualizando] = useState(false);
  const [deletando, setDeletando] = useState(false);

  const cadastrar = async (aviso: Aviso, hide: () => void, clear: () => void) => {
    setSalvando(true);
    try {
      const { data, status } = await AvisoAPI.cadastrarAviso(
        aviso
      );
      if (status === 200) {
        avisoStore.listar();
        hide();
        toast.success("Aviso cadastrado com sucesso");
        clear();
        setSalvando(false);
      } else {
        toast.error("Erro ao cadastrar Aviso");
        setSalvando(false);
      }
    } catch (e) {
      setSalvando(false);
      //
    }
  };

  const atualizar = async (aviso: Aviso, hide: () => void, clear: () => void) => {
    setAtualizando(true);
    try {
      const { status } = await AvisoAPI.atualizarAviso(aviso);
      if (status === 200) {
        avisoStore.avisos = avisoStore.avisos.map(
          (avi) => (avi.id === aviso.id ? aviso : avi)
        );
        toast.success("Aviso atualizado com sucesso");
        avisoStore.listar();
        hide();
        setAtualizando(false);
      } else {
        toast.error("Erro ao atualizar aviso");
        setAtualizando(false);
      }
    } catch (e) {
      setAtualizando(false);
      //
    }
  };

  const deletar = async (id: string) => {
    setDeletando(true);
    try {
      const { status } = await AvisoAPI.deletarAviso(
        id
      );
      if (status === 200) {
        avisoStore.avisos = avisoStore.avisos.filter(
          (aci) => aci.id !== id
        );
        toast.success("Aviso deletado com sucesso");
        setDeletando(false);
      } else {
        toast.error("Erro ao deletar aviso");
        setDeletando(false);
      }
    } catch (e) {
      setDeletando(false);
      
    }
  };


  return {
    cadastrar,
    atualizar,
    deletar,
    salvando,
    atualizando,
    deletando,
    // atualizarOrdemAcionamento,
  };
};

export default useAviso;
