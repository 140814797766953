import styled from 'styled-components';

export const Container = styled.div`
    .content
    {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        // height: 80vh;
    }
    .net
    {
        margin: 0;
        color: ${(props) => props.theme.colors.text};
        font-size: 8pt;
    }
    .input
    {
        background: red;
    }
`

export const Header = styled.div`
    background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid rgba(0,0,0,0.0800);
    padding: 10px;
    margin: 10px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    .title {
        font-size: 14pt;
        font-weight: 550;
        margin: 0;
    }
    .subtitle {
        font-size: 9pt;
        margin: 0;
    }
    .btn-add-ramal {
        background: #004C95;
        color: #fff;
        margin-right: 30px;
        border: 0;
        border-radius: 12px;
        font-size: 11pt;
    }
`;
