import { useContext, useEffect, useMemo, useState } from 'react';
import { StoreContext } from '../../stores';
import AtualizarAcionamento from './AtualizarAcionamento';
import CadastrarAcionamento from './CadastrarAcionamento';
import useAcionamento from '../../hooks/Acionamento.hook';
import { Container, Header, ListAcionamentos } from './styles/Acionamento.style';
import { BsDoorOpen} from 'react-icons/bs';
import { observer } from 'mobx-react-lite';
import {QRCodeSVG} from 'qrcode.react';
import { Dropdown, Modal, IconButton } from 'rsuite';
import ComandoListagem from '../comandos/ComandoListagem';
import RenderTipoAcionamento from '../utils/RenderTipoAcionamento';
import ModalConfirmacao from '../utils/ModalConfirmacao';
import AtualizarOrdem from './AtualizarOrdem';
import DragableIcon from '@rsuite/icons/Dragable';
import PlusIcon from '@rsuite/icons/Plus';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import QrcodeIcon from '@rsuite/icons/Qrcode';
import SkeletonList from '../utils/SkeletonList';
import { Table } from 'react-bootstrap';

const AcionamentoListagem = () => {

    const { acionamentoStore, condominioStore, themeStore } = useContext(StoreContext);
    const { deletar, deletando, atualizarOrdemAcionamento } = useAcionamento();
    const [modalCadastrarAcionamento, setModalCadastrarAcionamento] = useState(false);
    const [modalEditarAcionamento, setModalEditarAcionamento] = useState(false);
    const [alterOrdem, setAlterOrdem] = useState(false);
    const [openComando, setOpenComando] = useState(false);
    const [openQrCode, setOpenQrCode] = useState(false);
    const [modalDeletar, setModalDeletar] = useState(false);
    const [busca, setBusca] = useState('');

    const toggleAlterOrdem = () => setAlterOrdem(!alterOrdem);
    const toggleCadastrarAcionamento = () => setModalCadastrarAcionamento(!modalCadastrarAcionamento);
    const toggleEditarAcionamento = () => setModalEditarAcionamento(!modalEditarAcionamento);
    const toggleComando = () => setOpenComando(!openComando);
    const toggleOpenQrCode = () => setOpenQrCode(!openQrCode);
    const toggleModalDeletar = () => setModalDeletar(!modalDeletar);

    const deletarAcionamento = () =>
    {
      if(acionamentoStore.selecionado) {
        deletar(acionamentoStore.selecionado);
      }
    }

    useEffect(() =>
    {
      acionamentoStore.listar();
    }, []);

    const sortedAcionamentos = useMemo(() => {
       return acionamentoStore.acionamentosOld.filter((acionamento) => 
          acionamento.nome?.toLocaleLowerCase().includes(busca.toLocaleLowerCase()) || !busca ) .sort((a, b) => a.ordem - b.ordem); }, 
        [acionamentoStore.acionamentosOld, busca]);

    const renderListagem = () => 
    {

      if(deletando) {
          return <SkeletonList/>
      }

      if(acionamentoStore.loading) {
        return <SkeletonList/>
    }

      return(
          <div>
              <Table striped hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                  <thead>
                      <tr>
                          <th>Ordem</th>
                          <th>Nome</th>
                          <th>Visivel</th>
                          <th>Comandos</th>
                          <th>QR CODE</th>
                          <th>Ação</th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          sortedAcionamentos.map((acionamento, i) =>
                          (
                              <tr key={i} onClick={() => acionamentoStore.definirSelecionado(acionamento.id)}>
                                  <td>{acionamento.ordem + 1}</td>
                                  <td>{acionamento.nome.toUpperCase()}</td>
                                  <td>{RenderTipoAcionamento(acionamento.tipo)}</td>
                                  <td>
                                    <IconButton size={'xs'} appearance={'primary'} color={'violet'} icon={<SingleSourceIcon />} onClick={toggleComando}>
                                      Comandos
                                    </IconButton>
                                  </td>
                                  <td>
                                    <IconButton size={'xs'} appearance={'primary'} color={'violet'} icon={<QrcodeIcon />} onClick={toggleOpenQrCode}>
                                      QR CODE
                                    </IconButton>
                                  </td>
                                  <td>
                                      <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                                          <Dropdown.Item onClick={toggleEditarAcionamento}>Atualizar</Dropdown.Item>
                                          <Dropdown.Item onClick={toggleModalDeletar}>Deletar</Dropdown.Item>
                                      </Dropdown>
                                  </td>
                              </tr>
                          ))
                      }
                  </tbody>
              </Table>
          </div>
      )
  }
    return(
        <Container>


          <Header>
            <div className='d-flex justify-content-between'>
                      <div>
                          <p className='title'>Campo de cadastros de acionamentos</p>
                          <p className='subtitle'>Cadastre primeiro os acionamentos para adicionar os comandos</p>
                      </div>
                      <div>
                          <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarAcionamento}>
                              Adicionar novo acionamento
                          </IconButton>
                      </div>
                  </div>

                  <div className='d-flex box'>
                      <div className='content d-flex'>
                          <BsDoorOpen className='content-icon' />
                          <div>
                              <p className='content-title'>Acionamentos cadastrados </p>
                              <p className='content-subtitle'>{acionamentoStore.acionamentos.length} cadastrado</p>
                          </div>
                      </div>

                      <div style={{marginLeft: 30}} className='align-self-center'>
                          <p className='info-title'>Atualizar ordem dos acionamentos</p>
                          <IconButton appearance="primary" color="violet" icon={<DragableIcon />} onClick={toggleAlterOrdem}>
                              Atualizar
                          </IconButton>
                      </div>
                  </div>
            </Header>

            <ListAcionamentos>
              {renderListagem()}
            </ListAcionamentos>

          <CadastrarAcionamento show={modalCadastrarAcionamento} hide={toggleCadastrarAcionamento} />
          <AtualizarAcionamento show={modalEditarAcionamento} hide={toggleEditarAcionamento} acionamentoSelecionado={acionamentoStore.selecionado} />
          <ComandoListagem show={openComando} hide={toggleComando} />
          <AtualizarOrdem show={alterOrdem} hide={toggleAlterOrdem} />


          <Modal open={openQrCode} onClose={toggleOpenQrCode}>
            <Modal.Header closeButton />
            <div className={'d-flex justify-content-center'}>
              <p style={{margin: 10, fontWeight: 550, fontSize: 25}}>{condominioStore.selecionado?.nome}</p>
            </div>
            <div onClick={() => window.print()} className={'d-flex justify-content-center'}>
              <QRCodeSVG style={{height: 300, width: 300}} value={acionamentoStore.selecionado ? acionamentoStore.selecionado.id : ''} />
            </div>
              
            <div className={'d-flex justify-content-center'}>
              <p style={{margin: 10, fontWeight: 550, fontSize: 20}}>{acionamentoStore.selecionado?.nome}</p>
            </div>
            <div className={'d-flex justify-content-center'}>
            </div>
          </Modal>

          <ModalConfirmacao show={modalDeletar} hide={toggleModalDeletar} action={deletarAcionamento} content={'Você em certeza que deseja deletar esse acionamento?'} loading={deletando} />

        </Container>
    )
}

export default observer(AcionamentoListagem);