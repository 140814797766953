import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Header } from './styles/Autenticacao.style';
import logooutkey from '../../assets/img/logo-outkey.png';
import access from '../../assets/img/access.png';
import { Button, Input, InputGroup, Loader } from 'rsuite';
import SendIcon from '@rsuite/icons/Send';
import { cpfMask } from '../../components/utils/mask';
import { MdOutlineHelpOutline } from 'react-icons/md';
import useAutenticacaoDoisFatores from '../../hooks/AutenticacaoDoisFatores.hook';
import { ValidarToken } from '../../interfaces/AutenticacaoDoisFatores';
import CheckIcon from '@rsuite/icons/Check';

const AutenticacaoDoisFatores = () => {

  const { enviarCodigo, validarCodigo, loading, validated, sendCode, loadingValdiation } = useAutenticacaoDoisFatores();
  const [cpf, setCpf] = useState('');
  const [token, setToken] = useState('');
  
  const [show, setShow] = useState(false);

  const validarCpf = () =>
  {
    enviarCodigo(cpf)
  }

  const validarToken = () =>
  {
    var codigo: ValidarToken = 
    {
      cpf: cpf,
      codigo: token
    }
    validarCodigo(codigo);
  }

  const renderFormToken = () =>
  {
    if(loading)
    {
      return <Loader speed="normal" content="Validando seu cpf e enviando o token... Aguade!" style={{marginTop: '40px'}} />
    }

    if(sendCode)
    {
      return(
        <div>
          <p className='subtitle-auth'>Por favor, informe o token que lhe foi enviado via SMS e insira-o no campo abaixo.</p>
          <div className='d-flex content-button'>
            <Input placeholder='Insira o token aqui' className='input-1' maxLength={6} minLength={6} value={token} onChange={(e) => setToken(e)} />
          </div>
          <Button className='mt-2 w-100' appearance='primary' color={'violet'} onClick={() =>
          {
            if(token.length < 6)
              alert('Quantidade de dígitos inválida');
            else
              validarToken();
          }} loading={loadingValdiation}>Autenticar</Button>
        </div>
      )
    }

    return(
      <div/>
    )
  }

  return (
    <Container>
      <Header>
        <div className='container content d-flex'>
          <img src={logooutkey} className='logo' />
          <div>
            <p className='title'>
              Outkey
            </p><p className='subtitle'>
              Sistema de notificação
            </p>
          </div>
        </div>
      </Header>

      <Box className='container align-self-center'>
        <div className='content d-flex justify-content-center'>
          <div className='left'>
            <p className='title'>Olá! Esta é a plataforma de envio de notificações</p>
            <p className='subtitle'>Aqui você consegue enviar notificações para vários moradores de forma rápida e prática.</p>
            <div className='center'>
            <img src={access} className='access' />
          </div>
          </div>
          
          <div className='right'>
            <p className='title-auth'>Olá! Informe seu cpf para realizar a sua autenticação</p>
            <div className='d-flex content-button'>
              <Input disabled={sendCode} placeholder='Informe seu CPF aqui...' className='input-1' value={cpf} onChange={(e) => setCpf(cpfMask(e))} />
              {
                !sendCode
                ?
                <SendIcon className='icon-send' onClick={() =>
                {
                  if(!sendCode)
                    validarCpf()
                }} />
                :
                <CheckIcon className='icon-send' />
              }
              
            </div>

            {
              cpf.length < 14
              ?
              <div className='d-flex mt-1' style={{paddingLeft: 4}}>
                <MdOutlineHelpOutline className='align-self-center'/>
                <small>Infome seu cpf completo</small>
              </div>
              :
              ''
            }

            {renderFormToken()}
            
          </div>
        </div>
      </Box>
    </Container>
  )
}

export default observer(AutenticacaoDoisFatores);
