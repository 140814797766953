import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineCaretLeft, AiOutlineSearch } from 'react-icons/ai';
import { VscListFlat } from 'react-icons/vsc';
import { StoreContext } from '../../stores';
import { useCamera } from '../../hooks/Camera.hook';
import AtualizarCamera from './AtualizarCamera';
import CadastrarCamera from './CadastrarCamera';
import { Container, Header, ListCameras } from './styles/ListagemCameras.style'
import { SelectPicker, Button, Input, Dropdown, IconButton, Toggle } from 'rsuite';
import { Form, Table } from 'react-bootstrap';
import PlusIcon from '@rsuite/icons/Plus';
import DragableIcon from '@rsuite/icons/Dragable';
import SearchIcon from '@rsuite/icons/Search';
import { Camera } from '../../interfaces/Cameras.interface';
import PlayOutlineIcon from '@rsuite/icons/PlayOutline';
import AtualizarOrdem from './AtualizarOrdem';
import SkeletonList from '../utils/SkeletonList';

const ListagemCameras = () => {

  const { cameraStore, layoutStore, themeStore, condominioStore } = useContext(StoreContext);
  const { deletar, deletando, atualizar, atualizando } = useCamera();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = useState('');
  const [anchorElCamera, setAnchorElCamera] = React.useState(null);
  const [cadastrarCamera, setCadastrarCamera] = useState(false);
  const [atualizarCamera, setAtualizarCamera] = useState(false);
  const [atualizarOrdem, setAtualizarOrdem] = useState(false);

  const open = Boolean(anchorEl);
  const openCamera = Boolean(anchorElCamera);

  useEffect(() =>
  {
    layoutStore.listar();
  }, [])

  useEffect(() => {
    
    // let primeiroLayout = layoutStore.layouts[layoutStore.layouts.length - 1];
    // if(primeiroLayout) {
      if(condominioStore.selecionado)
        cameraStore.listarPorCondominioId(condominioStore.selecionado.id);
    //   layoutStore.definirSelecionado(primeiroLayout.id);
    // }
  }, [condominioStore.selecionado]);

  const filtrarCam = cameraStore.camerasCondominio.filter((cam) =>
    cam.nomeDGuard.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
    !search
  );

  const toggleCadastrarCamera = () => setCadastrarCamera(!cadastrarCamera);
  const toggleAtualizarCamera = () => setAtualizarCamera(!atualizarCamera);
  const toggleAtualizarOrdem = () => setAtualizarOrdem(!atualizarOrdem);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCamera = (event: any) => {
    setAnchorElCamera(event.currentTarget);
  };
  const handleCloseCamera = () => {
    setAnchorElCamera(null);
  };

  const renderCameras = () => {

    if(cameraStore.loading)
      return <SkeletonList/>

    return(
        <div className='content-camera'>
          <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
            <thead>
              <tr className='tr'>
                <th scope="col">Id DGuard</th>
                <th scope="col">Nome</th>
                {/* <th scope="col">Ordem</th> */}
                <th scope="col">Habilitar Câmera</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              {
                  filtrarCam.map((camera, i) => (
                    <tr key={i} onClick={() => cameraStore.definirSelecionado(camera.id)}>
                      <td>{camera.idDGuard} <AiOutlineCaretLeft/> id da câmera dguard </td>
                      <td>{camera.nomeDGuard}</td>
                      <td style={{width: 200}}>
                        {console.log(camera)}
                        <Toggle
                          checkedChildren="Sim" 
                          unCheckedChildren="Não" 
                          loading={cameraStore.selecionado?.id === camera.id ? atualizando : false}
                          checked={camera.cameraApp}
                          onChange={(e) =>
                          {
                            camera.cameraApp = e;
                            atualizar(camera, () => {});
                          }}
                        />
                      </td>
                      <td>
                        <Dropdown appearance='primary' color={'violet'} size={'xs'} title={'Ação'} style={{alignSelf: 'center'}}>
                          <Dropdown.Item onClick={() =>
                          {
                            toggleAtualizarCamera();
                          }}>Atualizar</Dropdown.Item>
                          <Dropdown.Item onClick={() =>
                          {
                            deletar(camera);
                          }}>Deletar</Dropdown.Item>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </Table>
        </div>
    )
  }

  return (
    <Container>
      <Header>
        <div className='d-flex justify-content-between'>
            <div>
                <p className='title'>Campo de cadastros de câmeras</p>
                <p className='subtitle'>Selecione o layout para visualizar as câmeras</p>
            </div>
            <div>
                <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarCamera}>
                    Adicionar nova câmera no layout {layoutStore.selecionado?.nomeDGuard}
                </IconButton>
            </div>
        </div>

        <div className='d-flex box'>
            <div className='content d-flex'>
                <PlayOutlineIcon className='content-icon' />
                <div>
                    <p className='content-title'>Câmeras cadastradas</p>
                    <p className='content-subtitle'>{cameraStore.camerasCondominio.length} cadastrado</p>
                </div>
            </div>

            <div style={{marginLeft: 30}} className='align-self-center'>
                <p className='info-title'>Atualizar ordem das câmeras</p>
                <IconButton disabled={cameraStore.camerasCondominio.length <= 1 } appearance="primary" color="violet" icon={<DragableIcon />} onClick={toggleAtualizarOrdem}>
                    Atualizar
                </IconButton>
            </div>

            {/* <div className='align-self-center'>
                <div style={{marginLeft: 20}}>
                    <p>Selecione o layout</p>
                    <SelectPicker
                        block
                        cleanable={false}
                        style={{width: 300}}
                        value={layoutStore.selecionado?.id}
                        placeholder={'Selecione'}
                        data={layoutStore.layouts.map((layout) => ({ label: `${layout.nomeDGuard}`, value: layout.id }))}                            
                        onChange={(e) =>
                        {
                          if(e) cameraStore.listarPorLayout(e);
                            layoutStore.definirSelecionado(e);
                        }}
                    />
                </div>
            </div> */}

            {/* <div style={{marginLeft: 20}} className='align-self-center'>
                <p className='info-title'>Buscar morador do condomínio pelo nome</p>
                <IconButton appearance="primary" color="violet" icon={<SearchIcon />} onClick={toggleOpenBuscaMorador}>
                    Buscar
                </IconButton>
            </div> */}

            <div style={{marginLeft: 40}} className='align-self-center'>
                <p className='info-title-filtro'>Filtrar câmeras do layout <span style={{fontWeight: 550, fontSize: 15}}>{layoutStore.selecionado?.nomeDGuard}</span></p>
                <Input placeholder='Buscar' onChange={(e) => setSearch(e)} />
            </div>
        </div>

        <ListCameras>
          {renderCameras()}
        </ListCameras> 
      </Header>


      <CadastrarCamera show={cadastrarCamera} hide={toggleCadastrarCamera} layout={layoutStore.selecionado} />
      <AtualizarCamera show={atualizarCamera} hide={toggleAtualizarCamera} cameraSelecionado={cameraStore.selecionado} layout={layoutStore.selecionado} />
      <AtualizarOrdem show={atualizarOrdem} hide={toggleAtualizarOrdem} />

    </Container>
  )
}

export default observer(ListagemCameras);
