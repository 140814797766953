import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled(PerfectScrollbar)`
  padding: 15px;
  background: ${(props) => props.theme.colors.bgColor};
  height: 100vh;
`

export const Header = styled.div`
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  .title
  {
    font-size: 12pt;
    margin: 0;
    font-weight: 550;
  }
  .subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .box
  {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
  }

  .content
  {
    padding: 20px;
    margin-top: 10px;
    width: 250px;
  }
  .content-icon
  {
    align-self: center;
    font-size: 25pt;
    margin-right: 20px;
  }
  .content-title
  {
    font-size: 10pt;
    margin: 0;
    font-weight: 550;
  }
  .content-subtitle
  {
    font-size: 9pt;
    margin: 0;
  }

  .info-title
  {
    font-size: 8pt;
    width: 80%;
  }
  .info-title-filtro
  {
    font-size: 8pt;
    width: 50%;
  }
`

export const Content = styled.div`
  background: ${(props) => props.theme.colors.bgBox};
  margin-top: 20px;
  // position: fixed;
  // height: 100px;
  border-radius: 8px;
  .item
  {
    background: ${(props) => props.theme.colors.bgBox};
    box-shadow: 1px 9px 6px ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    margin-top: 5px;
    padding: 10px;
    transition: 0.2s;
  }
  .item:hover
  {
    transition: 0.2s;
    cursor: pointer;
    padding-right: 5px;
    background: ${(props) => props.theme.colors.borderColor};
  }
  .box-content
  {
    background: ${(props) => props.theme.colors.bgColor};
    border-radius: 8px;
    padding: 10px;
  }
  .title
  {

  }
  .subtitle
  {

  }
  .icon
  {
    align-self: center;
    font-size: 17pt;
  }
`

export const ContentIdentificacao = styled.div`
  margin-top: 5px;
  align-self: center;
  border-radius: 10px;
  height: 65vh;
  // border-radius: 12px;
  margin-top: 8px;
  thead th {
    font-weight: 0;
  // background: ${(props) => props.theme.colors.bgBox};
  font-size: 13px;
    padding: 15px;
    // border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  } 
  tbody td {
    font-size: 12px;
  border: 0;
  padding: 16px;
    // background: ${(props) => props.theme.colors.bgBox};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`