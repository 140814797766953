import { observer } from 'mobx-react-lite'
import { useState, useEffect, useContext } from 'react'
import { BsArrowDownRight, BsDoorOpen } from 'react-icons/bs';
import { Button, Dropdown, IconButton, Loader, SelectPicker, Timeline } from 'rsuite';
import { Content, ContentIdentificacao, Header } from './styles/Automacao.style';
import PlusIcon from '@rsuite/icons/Plus';
import { StoreContext } from '../../stores';
import CadastrarAutomacaoAcionamentos from './CadastrarAutomacaoAcionamentos';
import AtualizarAutomacaoAcionamentos from './AtualizarAutomacaoAcionamentos';
import NotFound from '../utils/NotFound';
import { Carousel, Table } from 'react-bootstrap';
import { AiOutlineRight } from 'react-icons/ai';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import CadastrarAutomacaoProgramacao from './programacao/CadastrarAutomacaoProgramacao';
import AtualizarAutomacaoAProgramacao from './programacao/AtualizarAutomacaoAProgramacao';
import DragableIcon from '@rsuite/icons/Dragable';
import AtualizarOrdem from './programacao/AtualizarOrdem';
import useAutomacaoAcionamento from '../../hooks/AutomacaoAcionamento.hook';
import SkeletonList from '../utils/SkeletonList';
import useAutomacaoAcionamentoIdentificacao from '../../hooks/AutomacaoAcionamentoIdentificacao.hook';

const ListagemAutomacoes = () => {


    const { automacaoAcionamentoIdentificacaoStore, automacaoAcionamentoStore } = useContext(StoreContext);
    const [cadastrar, setCadastrar] = useState(false);
    const [atualizar, setAtualizar] = useState(false);
    const [atualizarOrdem, setAtualizarOrdem] = useState(false);
    const { deletar, deletando } = useAutomacaoAcionamento();
    const _automacaoAcionamentoIdentificacao = useAutomacaoAcionamentoIdentificacao();

    const [cadastrarProgramacao, setCadastrarProgramacao] = useState(false);
    const [atualizarProgramacao, setAtualizarProgramacao] = useState(false);
    const [index, setIndex] = useState(0);

    const toggleCadastrar = () => setCadastrar(!cadastrar);
    const toggleAtualizar = () => setAtualizar(!atualizar);

    const toggleCadastrarProgramacao = () => setCadastrarProgramacao(!cadastrarProgramacao);
    const toggleAtualizarProgramacao = () => setAtualizarProgramacao(!atualizarProgramacao);
    const toggleAtualizarOrdem = () => setAtualizarOrdem(!atualizarOrdem);


    useEffect(() =>
    {
        automacaoAcionamentoIdentificacaoStore.listar();
    }, []);

    const renderListaAutomacoesIdentificacoes = () =>
    {
        if(automacaoAcionamentoIdentificacaoStore.loading)
            return <SkeletonList/>

        if(automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao.length === 0)
            return <NotFound message='Nenhuma automação cadastrada' />

        return(
            <ContentIdentificacao className='content-camera'>
                <Table>
                    <thead>
                    <tr className='tr'>
                        <th scope="col">Nome</th>
                        <th scope="col">Info</th>
                        <th scope="col">Programação</th>
                        <th scope="col">Ação</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao.map((a, i) => (
                            <tr key={i} onClick={() => automacaoAcionamentoIdentificacaoStore.definirSelecionado(a.id)}>
                                <td>{a.nome}</td>
                                <td style={{width: '30%'}}>
                                    <span style={{fontSize: '9pt'}}>Clique no botão <b>"Visualizar programação"</b> para visualizar os acionamentos e o tempo de execução</span>
                                </td>
                                <td>
                                    <Button appearance='primary' color={'violet'} size={'xs'} onClick={() => {
                                        setIndex(1);
                                        automacaoAcionamentoStore.listar(a.id);
                                    }}>Visualizar programação</Button>
                                </td>
                                <td>
                                    <Dropdown loading={automacaoAcionamentoIdentificacaoStore.selecionado?.id === a.id && _automacaoAcionamentoIdentificacao.deletando} appearance='primary' color={'violet'} size={'xs'} title={'Ação'} style={{alignSelf: 'center'}}>
                                        <Dropdown.Item onClick={() =>
                                        {
                                            // _automacaoAcionamentoIdentificacao.atualizar(a);
                                            toggleAtualizar();
                                        }}>Atualizar</Dropdown.Item>
                                        <Dropdown.Item onClick={() =>
                                        {
                                            _automacaoAcionamentoIdentificacao.deletar(a, () => {});
                                        }}>Deletar</Dropdown.Item>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
                </ContentIdentificacao>
            // <ContentIdentificacao className='d-flex flex-wrap'>
            //     {
            //         automacaoAcionamentoIdentificacaoStore.automacaoAcionamentoIdentificacao.map((a, i) => (
            //             <div className='item d-flex justify-content-between' key={i} onClick={() => {
            //                 setIndex(1);
            //                 automacaoAcionamentoStore.listar(a.id);
            //                 automacaoAcionamentoIdentificacaoStore.definirSelecionado(a.id);
            //             }}>
            //                 <div>
            //                     <p className='title'>{a.nome}</p>
            //                     <p className='subtitle'>Selecione o automação que você deseja programar</p>
            //                 </div>
            //                 <AiOutlineRight className='icon' />
            //             </div>
            //         ))
            //     }
            // </ContentIdentificacao>
        )
    }

    const renderListaAutomacoes = () =>
    {

        if(automacaoAcionamentoStore.loading)
            return <SkeletonList/>

        if(automacaoAcionamentoStore.automacaoAcionamento.length === 0)
            return <NotFound message={`Nenhuma automação programada para ${automacaoAcionamentoIdentificacaoStore.selecionado?.nome}`} />

        return(
            <Content className='d-flex'>
                {
                    automacaoAcionamentoStore.automacaoAcionamento.map((a, i) => (
                        <div className='d-flex box-content m-1' key={i}
                            onClick={() => automacaoAcionamentoStore.definirSelecionado(a.id)}
                        >
                            <div>
                                <div className='d-flex justify-content-between' style={{width: '100%'}}>
                                    <p style={{margin: 0}}>{a.nome}</p>
                                    <Dropdown loading={deletando && automacaoAcionamentoStore.selecionado?.id === a.id} title="Ação" activeKey="a" appearance='primary' color={'violet'} size={`xs`}>
                                        <Dropdown.Item onClick={() =>
                                        {
                                            if(automacaoAcionamentoStore.selecionado)
                                                deletar(automacaoAcionamentoStore.selecionado)
                                        }}>Deletar</Dropdown.Item>
                                    </Dropdown>
                                </div>
                                <p style={{margin: 0}}>
                                    <span>Tempo de espera: <b>{a.delay}</b></span>
                                </p>

                                <p style={{margin: 0, fontSize: '8pt', width: '50%'}}>Os acionamentos vai ser executado de acordo com o tempo e a ordem que foi configurado</p>
                            </div>
                            <AiOutlineRight style={{alignSelf: 'center', marginLeft: 10, fontSize: 18}} />
                        </div>
                    ))
                }
            </Content>
        )
    }

    return (
        <div>
        <Header>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='title'>Campo de cadastros de automações para os acionamentos</p>
                        <p className='subtitle'>Cadastre primeiro o modelo automações e em seguida crie novas regras</p>
                    </div>
                </div>

                <div className='d-flex box'>
                    <div className='content d-flex'>
                        <BsDoorOpen className='content-icon' />
                        <div>
                            <p className='content-title'>Automações cadastrados</p>
                        </div>
                    </div>

                    
                    {
                        index === 1 && automacaoAcionamentoIdentificacaoStore.selecionado
                        ?
                        <div className='d-flex'>
                            <div style={{ alignSelf: 'center' }}>
                                <p className='info-title'>Voltar para lista</p>
                                <IconButton icon={<ArrowLeftLineIcon />}  onClick={() => setIndex(0)}>
                                    Voltar
                                </IconButton>
                            </div>
                            <div style={{marginLeft: 20}} className='align-self-center'>
                                <p className='info-title'>Atualizar ordem dos acionamentos</p>
                                <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarProgramacao}>
                                    Cadastrar programação
                                </IconButton>
                            </div>

                            <div style={{marginLeft: 30}} className='align-self-center'>
                                <p className='info-title'>Atualizar ordem</p>
                                <IconButton disabled={automacaoAcionamentoStore.automacaoAcionamento.length === 0} appearance="primary" color="violet" icon={<DragableIcon />} onClick={toggleAtualizarOrdem}>
                                    Atualizar
                                </IconButton>
                            </div>
                        </div>
                        :
                        <div style={{marginLeft: 0}} className='align-self-center'>
                            <p className='info-title'>Atualizar ordem dos acionamentos</p>
                            <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrar}>
                                Adicionar nova automação
                            </IconButton>
                        </div>
                    }
                </div>

                <div>
                    <Carousel variant="dark" indicators={false} controls={false} activeIndex={index} interval={1000}>
                        <Carousel.Item>
                            {renderListaAutomacoesIdentificacoes()}
                        </Carousel.Item>

                        <Carousel.Item>
                            {renderListaAutomacoes()}
                        </Carousel.Item>
                    </Carousel>
                </div>
                
        </Header>


        <CadastrarAutomacaoAcionamentos show={cadastrar} hide={toggleCadastrar} />
        <AtualizarAutomacaoAcionamentos show={atualizar} hide={toggleAtualizar} />

        <CadastrarAutomacaoProgramacao show={cadastrarProgramacao} hide={toggleCadastrarProgramacao} />
        <AtualizarAutomacaoAProgramacao show={atualizarProgramacao} hide={toggleAtualizarProgramacao} />
        <AtualizarOrdem show={atualizarOrdem} hide={toggleAtualizarOrdem} />
        </div>
  )
}

export default observer(ListagemAutomacoes);