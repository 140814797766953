import { observer } from 'mobx-react-lite'
import React, { FC, useState, useEffect, useContext } from 'react'
import { ContainerLista, HeaderItem } from '../styles/Guarita.style'
import { VscLayersActive } from 'react-icons/vsc'
import { Button, Dropdown, IconButton } from 'rsuite'
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PlusIcon from '@rsuite/icons/Plus';
import { StoreContext } from '../../../stores'
import { Content } from '../styles/Mip1000.style'
import CadastrarZkteco from './CadastrarZkteco'
import AtualizarZkteco from './AtualizarZkteco'
import { Table } from 'react-bootstrap'
import { useZkteco } from '../../../hooks/Zkteco.hook'
import ModalConfirmacao from '../../utils/ModalConfirmacao'
import CadastrarReleZK from '../zkteco/rele/CadastraReleZK'

interface Props {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>
}


const ZktecoListagem: FC<Props> = ({ index, setIndex }) => {

  const { zktecoStore, condominioStore, themeStore } = useContext(StoreContext);
  const { deletar, deletando, salvando } = useZkteco();
  const [cadastrarZk, setCadastrarZk] = useState(false);
  const [atualizarZk, setAtualizarZk] = useState(false);
  const [cadastrarRele, setCadastrarRele] = useState(false);
  const [reiniciarZK, setReiniciarZK] = useState(false);
  const [atualizarRele, setAtualizarRele] = useState(false);
  const [modalDeletarZkteco, setModalDeletarZkteco] = useState(false);
  const { equipamentoStore } = useContext(StoreContext)
  const [route, setRoute] = useState('zkteco');

  const toggleCadastrarZk = () => setCadastrarZk(!cadastrarZk);
  const toggleAtualizarZk = () => setAtualizarZk(!atualizarZk);

  const toggleCadastrarRele = () => setCadastrarRele(!cadastrarRele);
  const toggleReiniciarZK = () => setReiniciarZK(!reiniciarZK)
  const toggleAtualizarRele = () => setAtualizarRele(!atualizarRele);
  const toggleModalDeletarZkteco = () => setModalDeletarZkteco(!modalDeletarZkteco);

  const deletarZktecoSelecionado = () => {
    if (zktecoStore.selecionado) {
      deletar(zktecoStore.selecionado);
    }
  }

  const reiniciarZkSelecionado = () => {
    if (zktecoStore.selecionado) {
      zktecoStore.reiniciarZkInbio(zktecoStore.selecionado.id)
    }
  }
  useEffect(() => {
    if (route === 'zkteco' && index === 5) {
      if (condominioStore.selecionado)
        zktecoStore.listarPorCondominio(condominioStore.selecionado.id);
        equipamentoStore.getZkteco();
    }
  }, [index]);

  const renderList = () => {
    return (
      <div className='box-table'>
        <div className='mt-2'>
          <Table striped hover variant={themeStore.theme === 'dark' ? 'dark' : 'none'} size="lg">
            <thead>
              <tr>
                <th scope="col">Nome Controladora</th>
                <th scope="col">Ip</th>
                <th scope="col">Porta API</th>
                <th scope="col">Modelo</th>
                <th scope="col">Relé</th>
                <th scope="col">Reiniciar</th>
                <th scope="col">Ação</th>
              </tr>
            </thead>
            <tbody>
              {
                equipamentoStore.zktecoInbio.map((guarita, i) => (
                  <tr key={i} onClick={() => zktecoStore.definirSelecionado(guarita.id)}>
                    <td>{guarita.nome}</td>
                    <td>{guarita.ip}</td>
                    <td>{guarita.porta}</td>
                    <td>{guarita.modelo}</td>
                    <td>
                      <Button size='xs' appearance='primary' color='violet' onClick={() => {
                        zktecoStore.definirSelecionado(guarita.id)
                        toggleCadastrarRele();
                      }}>Abrir</Button>
                    </td>
                    <td><Button size='xs' appearance='primary' color='violet' onClick={() => {
                      if(zktecoStore.selecionado?.id)
                        toggleReiniciarZK();
                    }}>
                      Reiniciar</Button></td>
                    <td>
                      <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'}>
                        <Dropdown.Item onClick={() => {
                          toggleAtualizarZk();
                        }}>Atualizar</Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                          toggleModalDeletarZkteco();
                        }}>Deletar</Dropdown.Item>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
      </div>
    )
  }

  const renderButtonCadastro = () => {

    return (
      <div className='align-self-center mt-2' style={{ marginLeft: 20 }}>
        <p style={{ margin: 0, width: 150 }}>Cadastre nova controladora no condomínio</p>
        <IconButton appearance="primary" color="violet" icon={<PlusIcon />} style={{ marginBottom: 10 }} onClick={toggleCadastrarZk}>
          Cadastrar novo ZKTECO
        </IconButton>
      </div>
    )
  }

  return (
    <div>
      <ContainerLista className=''>
        <HeaderItem>
          <div className='d-flex box'>
            <div className='content d-flex align-self-center'>
              <VscLayersActive className='content-icon' />
              <div>
                <p className='subtitle'>Campo de cadastro e configurações do sistema ZKTECO</p>
              </div>
            </div>

            <div style={{ marginLeft: 30 }} className='align-self-center'>
              <p className='info-title'>Voltar para a lista</p>
              <IconButton appearance="primary" color="violet" icon={<PagePreviousIcon />} onClick={() => setIndex(0)}>
                Voltar
              </IconButton>
            </div>

            {renderButtonCadastro()}

          </div>
        </HeaderItem>


        <Content>
          {renderList()}
        </Content>
      </ContainerLista>


      <CadastrarZkteco show={cadastrarZk} hide={toggleCadastrarZk} />
      <AtualizarZkteco show={atualizarZk} hide={toggleAtualizarZk} />

      <CadastrarReleZK show={cadastrarRele} hide={toggleCadastrarRele} />
      <ModalConfirmacao show={modalDeletarZkteco} hide={toggleModalDeletarZkteco} action={deletarZktecoSelecionado} content={'Você tem certeza que deseja deletar esse ZKTECO?'} loading={deletando} />
      <ModalConfirmacao show={reiniciarZK} hide={toggleReiniciarZK} action={reiniciarZkSelecionado} content={'Você tem certeza que deseja reiniciar esse ZKTECO?'} />

    </div>
  )
}

export default observer(ZktecoListagem);