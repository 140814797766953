import React, { FC, useContext, useEffect, useState } from "react"
import {
  Button,
  Input,
  Modal,
  Uploader,
  InputNumber,
  Stack,
  toaster,
  Message,
  Loader,
} from "rsuite"
import { ContainerImageUpload, TextAlert } from "./styles/Aviso.style"
import useAviso from "../../hooks/Aviso.hook"
import { Aviso, AvisoRequest } from "../../interfaces/Aviso.interface"
import { StoreContext } from "../../stores"

interface ModalProps {
  show: boolean
  hide: () => void
  condominioId?: string
}

const AtualizarAviso: FC<ModalProps> = ({ show, hide }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null)
  const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null)
  const [isImageInvalid, setIsImageInvalid] = useState(false)
  const [form, setForm] = useState<AvisoRequest>({})
  const [remainingChars, setRemainingChars] = useState(190)
  const [loading, setLoading] = useState(false)
  const { condominioStore, avisoStore } = useContext(StoreContext)
  const { cadastrar, atualizar } = useAviso()

  useEffect(() => {
    if (avisoStore.selecionado) {
      const avisoSelecionado = avisoStore.selecionado
      setForm({ ...avisoSelecionado })
      setUploadedImage(avisoSelecionado.imagem)
      setRemainingChars(190 - (avisoSelecionado.descricao || "").length)
    }
  }, [avisoStore.selecionado, show])

  const handleUploadChange = (fileList: any) => {
    const file = fileList[0]
    const reader = new FileReader()

    reader.onload = () => {
      const image = new Image()
      image.src = reader.result as string
      image.onload = () => {
        const { width, height } = image
        if (width === 720 && height === 1280) {
          setUploadedImage(reader.result as string)
          setUploadedImageFile(file.blobFile)
          setIsImageInvalid(false)

          const base64Image = reader.result?.toString().split(",")[1]
          let formato: "jpg" | "jpeg" = "jpg"

          if (file.name.toLowerCase().endsWith(".jpeg")) {
            formato = "jpeg"
          }

          setForm((prevForm) => ({
            ...prevForm,
            formato: formato,
            content: base64Image,
          }))
        } else {
          setUploadedImage(null)
          setUploadedImageFile(null)
          setIsImageInvalid(true)
          toaster.push(
            <Message showIcon type="error" closable>
              O formato da tela deverá ser: 720x1280
            </Message>,
            { placement: "topEnd", duration: 5000 }
          )
        }
      }
    }
    reader.readAsDataURL(file.blobFile)
  }

  const clear = () => {
    setForm({})
    setUploadedImage(null)
    setUploadedImageFile(null)
    setIsImageInvalid(false)
    setRemainingChars(190)
    setLoading(false)
  }

  const atualizarAviso = async () => {
    if (avisoStore.selecionado?.status !== 0) {
      toaster.push(
        <Message showIcon type="error" closable>
          Só é possível atualizar avisos com status "Pendente".
        </Message>,
        { placement: "topEnd" }
      )
      return
    }

    if (!form.validade) {
      toaster.push(
        <Message showIcon type="error" closable>
          O campo validade é obrigatório.
        </Message>,
        { placement: "topEnd" }
      )
      return
    }

    const descricao = form?.descricao || ""

    if (!uploadedImage && (descricao.length < 3 || descricao.length > 190)) {
      toaster.push(
        <Message showIcon type="error" closable>
          A descrição deve ter entre 3 e 190 caracteres se não houver imagem.
        </Message>,
        { placement: "topEnd" }
      )
      return
    }

    if (uploadedImage && descricao.length > 0 && descricao.length < 3) {
      toaster.push(
        <Message showIcon type="error" closable>
          A descrição deve ter pelo menos 3 caracteres.
        </Message>,
        { placement: "topEnd" }
      )
      return
    }

    if (!uploadedImage && descricao.length === 0) {
      toaster.push(
        <Message showIcon type="error" closable>
          Por favor, forneça uma imagem ou uma descrição.
        </Message>,
        { placement: "topEnd" }
      )
      return
    }

    setLoading(true)

    const updatedAviso = {
      ...avisoStore.selecionado,
      ...form,
    }

    try {
      await atualizar(updatedAviso, hide, clear)
    } finally {
      setLoading(false)
    }
  }

  const handleDescricaoChange = (descricao: string) => {
    setForm((prevForm) => ({
      ...prevForm,
      descricao: descricao,
    }))
    setRemainingChars(190 - descricao.length)
  }

  return (
    <Modal open={show} onClose={hide}>
      <Modal.Header closeButton>
        <ContainerImageUpload>
          <Uploader
            action="//jsonplaceholder.typicode.com/posts/"
            draggable
            listType="picture-text"
            autoUpload={false}
            onChange={handleUploadChange}
            onRemove={() => {
              setUploadedImage(null)
              setUploadedImageFile(null)
              setIsImageInvalid(false)
              setForm((prevForm) => ({
                ...prevForm,
                formato: undefined,
                content: undefined,
                imagem: null || "",
                removerImagem: true,
              }))
            }}
          >
            {uploadedImage ? (
              <>
                <img
                  src={uploadedImage}
                  alt="Uploaded"
                  style={{ width: 370, height: 400 }}
                />
              </>
            ) : (
              <div
                style={{
                  height: 400,
                  width: 370,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  Clique ou arraste a imagem para fazer upload
                  <TextAlert>(Formato da tela deverá ser: 720x1280) </TextAlert>
                </span>
              </div>
            )}
          </Uploader>
        </ContainerImageUpload>
      </Modal.Header>
      <Modal.Body>
        <InputNumber
          size="md"
          max={100}
          min={1}
          placeholder="Defina a quantidade de dias que seu anúncio ficará disponível"
          value={form?.validade || ""}
          onChange={(e: any) => setForm({ ...form, validade: e })}
          style={{ marginBottom: 5 }}
        />
        <Input
          placeholder="Descrição..."
          value={form?.descricao || ""}
          onChange={(e) => handleDescricaoChange(e)}
          maxLength={190}
        />
        <p style={{ marginLeft: 365, marginTop: 3, color: "#000" }}>
          ( {remainingChars} Caracteres restantes)
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={atualizarAviso}
          disabled={loading || isImageInvalid || remainingChars < 0}
        >
          {loading ? <Loader size="sm" /> : "Confirmar"}
        </Button>
        <Button appearance="default" onClick={hide} disabled={loading}>
          Fechar
        </Button>
        {form.imagem && (
          <Button
            appearance="subtle"
            style={{
              position: "absolute",
              bottom: 20,
              right: 443,
              backgroundColor: "red",
              color: "white",
            }}
            onClick={() => {
              setUploadedImage(null)
              setUploadedImageFile(null)
              setIsImageInvalid(false)
              setForm((prevForm) => ({
                ...prevForm,
                formato: undefined,
                content: undefined,
                imagem: null || "",
                removerImagem: true,
              }))
            }}
          >
            Remover Imagem
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default AtualizarAviso
