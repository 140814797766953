import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, List, Loader, Modal } from 'rsuite';
import useAcionamento from '../../hooks/Acionamento.hook';
import { useCamera } from '../../hooks/Camera.hook';
import { useMorador } from '../../hooks/Morador.hook';
import { useUnidade } from '../../hooks/Unidade.hook';
import { NovaOrdem } from '../../interfaces/Cameras.interface';
import { Unidade, UnidadeRequest } from '../../interfaces/Unidade.interface';
import { StoreContext } from '../../stores';
import { Ordem } from './styles/Ordem.style';

interface ModalProps {
    show: boolean;
    hide: () => void;
}

const AtualizarOrdem: FC<ModalProps> = ({ show, hide }) => {

    const { cameraStore, condominioStore, layoutStore } = useContext(StoreContext);
    const [ordem, setOrdem] = useState<NovaOrdem>();
    const { atualizarOrdem, atualizando } = useCamera();

    const salvar = () =>
    {
        if(ordem)
        {
            atualizarOrdem(ordem, () => {});
        }
    }

    const handleSortEnd = ({ oldIndex, newIndex } : any) =>
    {
        cameraStore.camerasCondominio = arrayMoveImmutable(cameraStore.camerasCondominio , oldIndex, newIndex);
        cameraStore.camerasCondominio.map((_, i) => _.ordem = i);
        var novaOrdem: NovaOrdem = {
            novaOrdem: cameraStore.camerasCondominio.map((c) => ({ id: c.id, ordem: c.ordem }))
        }

        setOrdem(novaOrdem);
    }

    const Listagem = () => {

        if(atualizando)
        {
          return(
            <div className={'d-flex justify-content-center mt-5'}>
              <Loader content={'Salvando nova ordem... aguarde!'} />
            </div>
          )
        }

        return(
            <List sortable onSort={handleSortEnd}>
                {
                    cameraStore.camerasCondominio.map((camera, i) =>
                    <List.Item style={{background: 'transparent', borderBottom: '1px solid rgba(0,0,0,0.2)', margin: 4}} className='p-3' key={i} index={i}>{camera.nomeDGuard?.toUpperCase()}</List.Item>)
                }
            </List>
        )
    }

    return(
        <Modal open={show} onClose={() => atualizando ? {} : hide()}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Atualizar ordem das câmeras do layout { layoutStore.selecionado?.nomeDGuard }</p>
                    <p className='modal-styled-subtitle'>
                        Arraste para cima ou para baixo para alterar a ordem
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Ordem>
                    {Listagem()}
                </Ordem>
            </Modal.Body>
            {/* <div className='p-3'>
                {Listagem()}
            </div> */}
            <Modal.Footer className='p-3'>
                <Button appearance='primary' color={'violet'} onClick={salvar} disabled={atualizando}>{atualizando ? 'Salvando' : 'Salvar'}</Button>
                <Button onClick={() => atualizando ? {} : hide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default observer(AtualizarOrdem);