import { useContext, useState } from "react";
import { toast } from "react-toastify";
import RamalAPI from "../api/RamalAPI";
import { StoreContext } from "../stores";
import { Permissoes, PermissoesRequest } from "../interfaces/Permissoes.interface";
import PermissoesAPI from "../api/PermissoesAPI";


export const usePermissoes = () => {

    const { permissoesStore } = useContext(StoreContext);
    const [salvando, setSalvando] = useState(false);
    const [atualizando, setAtualizando] = useState(false);
    const [deletando, setDeletando] = useState(false);
    const [permissoes, setPermissoes] = useState<PermissoesRequest>({});
    const [loading, setLoading] = useState(false);
    const [vazio, setVazio] = useState(false);

    const buscarPorId = async (moradorId: string) => {
        setLoading(true);
        try {
            const { data } = await PermissoesAPI.selecionarPorId(moradorId);
            if(Object.keys(data).length === 0)
            {
                setPermissoes({});
                setVazio(true);
                setLoading(false);
            }
            else
            {
                setPermissoes(data);
                setVazio(false);
                setLoading(false);
            }
        } catch(e) {
            //
            setLoading(false);
        }
    }
    
    const cadastrar = async (permissoes: Permissoes, hide: () => void) => {
        setSalvando(true);
        try {
            const { data, status } = await PermissoesAPI.cadastrar(permissoes);
            if(status === 200) {
                permissoesStore.permissoes = data;
                setSalvando(false);
                hide();
                toast.success('Permissão cadastrado com sucesso!')
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao cadastrar nova permissão')
        }
    }

    const atualizar = async (permissoes: Permissoes, hide: () => void) => {
        setSalvando(true);
        try {
            const { status } = await PermissoesAPI.atualizar(permissoes);
            if(status === 200) {
                permissoesStore.permissoes = permissoes;
                setSalvando(false);
                hide();
                toast.success('Permissão atualizado com sucesso!');
            }
        } catch(e) {
            //
            setSalvando(false);
            toast.error('Erro ao atualizar permissão');
        }
    }

    const deletar = async (permissoes: Permissoes, hide: () => void) => {
        setDeletando(true);
        try {
            const { status } = await RamalAPI.deletarRamal(permissoes.id);
            if(status === 200) {
                permissoesStore.permissoes = null;
                setDeletando(false);
                toast.success('Permissão deletado com sucesso!')
            }
        } catch(e) {
            setDeletando(false);
            toast.error('Erro ao deletar permissão')
        }
    }
    
    return {
        cadastrar,
        atualizar,
        deletar,
        salvando,
        atualizando,
        deletando,
        permissoes,
        setPermissoes,
        buscarPorId,
        loading,
        vazio
    }

}