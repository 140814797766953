import { makeAutoObservable, runInAction } from "mobx";
import AutomacaoAcionamentoAPI from "../api/AutomacaoAcionamentoAPI";
import { AutomacaoAcionamento } from "../interfaces/AutomacaoAcionamento.interface";

class AutomacaoAcionamentoStore {

    automacaoAcionamento: AutomacaoAcionamento[] = [];
    selecionado: AutomacaoAcionamento | null = null;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async listar(automacaoAcionamentoIdentificacaoId: string) {
        this.loading = true;
        try {
            const { data } = await AutomacaoAcionamentoAPI.listarPorAutomacaoAcionamentoIdentificacaoId(automacaoAcionamentoIdentificacaoId);
            runInAction(() => {
                if(data) this.automacaoAcionamento = data;
                else this.automacaoAcionamento = [];
                this.loading = false;
            });
        } catch(e) {
            this.loading = false;
        }
    }

    definirSelecionado(id: string) {
        let selecionado = this.automacaoAcionamento.find((a) => a.id === id);
        if(selecionado) {
            this.selecionado = selecionado;
        }
        else this.selecionado = null;
    }

}

export default AutomacaoAcionamentoStore;