import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const Box = styled(Card.Body)`
  padding-top: 15px;
  padding-bottom: 15px;
  .title
  {
    margin-top: 20px;
  }
`;

export const TextArea = styled.textarea`
  border: none;
  width: 100%;
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  font-size: 14px;
  margin-bottom: 10px;
  &::placeholder {
    color: #777 !important;
    font-size: 14px;
  }
  &:focus, &:focus{
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 5px 0 0 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700 !important;
`;

export const DatePicker = styled.input`
  background-color: transparent;
  color: ${(props) => props.theme.colors.text};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  &::-webkit-calendar-picker-indicator {
    filter: ${(props) => props.theme.title === 'dark' ? 'invert(1)' : 'invert(0)'};
  }
`;

export const Descricao = styled.div`
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
  white-space: -pre-wrap;     /* Opera <7 */   
  white-space: -o-pre-wrap;   /* Opera 7 */    
  word-wrap: break-word; 
  margin: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  cursor: pointer;
  .descricao
  {
    margin: 0;
    padding-top: 10px;
    padding-left: 10px;
    font-size: 12pt;
    font-weight: 700;
  }
  .datahora
  {
    margin: 0;
    padding-left: 10px;
    padding-bottom: 5px;
    // font-weight: 550;
  }
`;
