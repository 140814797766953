import { observer } from 'mobx-react-lite'
import React, { useState, useEffect, useContext, FC } from 'react'
import { VscLayersActive } from 'react-icons/vsc';
import { Dropdown, IconButton, InputPicker } from 'rsuite';
import { ContainerLista, HeaderItem } from '../styles/Guarita.style';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import { StoreContext } from '../../../stores';
import PlusIcon from '@rsuite/icons/Plus';
import NotFound from '../../utils/NotFound';
import { Table } from 'react-bootstrap';
import CadastrarControladora from '../../controladora/CadastrarControladora';
import AtualizarControladora from '../../controladora/AtualizarControladora';
import ModalConfirmacao from '../../utils/ModalConfirmacao';
import CadastrarSiam from './CadastrarSiam';
import AtualizarSiam from './AtualizarSiam';
import { useSiam } from '../../../hooks/Siam.hook';

interface Props
{
  setIndex: React.Dispatch<React.SetStateAction<number>>
}


const SiamListagem: FC<Props> = ({ setIndex }) => {

  const { siamStore, themeStore } = useContext(StoreContext);
  const { deletandoSiam, deletarControladora, deletarSiam } = useSiam();
  const [routeSIAM, setRouteSIAM] = useState('listasiam');
  const [modalCadastrarSiam, setModalCadastrarSiam] = useState(false);
  const [modalEditarSiam, setModalEditarSiam] = useState(false);
  const[cadastrarControladora, setCadastrarControladora] = useState(false);
  const[atualizarControladora, setAtualizarControladora] = useState(false);
  const [modalDeletarSiam, setModalDeletarSiam] = useState(false);
  const [modalDeletarControladora, setModalDeletarControladora] = useState(false);

  const toggleModalCadastrarSiam = () => setModalCadastrarSiam(!modalCadastrarSiam);
  const toggleModalAtualizarSiam = () => setModalEditarSiam(!modalEditarSiam);
  const toggleCadastrarControladora = () => setCadastrarControladora(!cadastrarControladora);
  const toggleAtualizarControladora = () => setAtualizarControladora(!atualizarControladora);
  const toggleModalDeletarSiam = () => setModalDeletarSiam(!modalDeletarSiam);
  const toggleModalDeletarControladora = () => setModalDeletarControladora(!modalDeletarControladora);

  const deletarControladoraSelecionado = () =>
  {
      if(siamStore.controladoraSelecionado)
      {
          deletarControladora(siamStore.controladoraSelecionado);
      }
      else
      {
          alert('Selecione a controladora para deletar');
      }
  }

  const deletarSiamSelecionado = () =>
  {
      if(siamStore.selecionado) {
          deletarSiam(siamStore.selecionado);
      }
  }

  const renderListaSIAM = () =>
  {
      return(
          <div>
              
              {
                  siamStore.siam.length === 0
                  ?
                  <NotFound message='Nenhum equipamento siam cadastrado' />
                  :
                  <div className='container-table'>
                        <Table striped  hover variant={themeStore.theme === 'dark'  ? 'dark' : 'none'} size="lg">
                          <thead>
                              <tr>
                                  <th scope="col">Nome</th>
                                  <th scope="col">Ip idbm</th>
                                  <th scope="col">Porta idbm</th>
                                  <th scope="col">Ip servidor</th>
                                  <th scope="col">Porta servidor</th>
                                  <th scope="col">Usuário</th>
                                  <th scope="col">Senha</th>
                                  <th scope="col">Modelo</th>
                                  <th scope="col">Fabricante</th>
                                  <th scope="col">Código</th>
                              </tr>
                          </thead>
                          <tbody>
                              {
                                  siamStore.siam.map((siam, i) => (
                                  <tr key={i} onClick={() => siamStore.definirSelecionado(siam.id)}> 
                                      <td>{siam.nome}</td>
                                      <td>{siam.ipIdbm}</td>
                                      <td>{siam.portaIdbm}</td>
                                      <td>{siam.ipServidor}</td>
                                      <td>{siam.portaServidor}</td>
                                      <td>{siam.usuario}</td>
                                      <td>{siam.senha}</td>
                                      <td>{siam.modelo}</td>
                                      <td>{siam.fabricante}</td>
                                      <td>
                                          <Dropdown appearance="primary" color="violet" title={'Ação'} size={'xs'}>
                                          <Dropdown.Item onClick={() => {
                                              toggleModalAtualizarSiam();
                                          }}>Atualizar</Dropdown.Item>
                                          <Dropdown.Item onClick={() => {
                                              toggleModalDeletarSiam();
                                          }}>Deletar</Dropdown.Item>
                                          </Dropdown>
                                      </td>
                                  </tr>
                                  ))
                              }
                          </tbody>
                      </Table>
                  </div>
              }
          </div>
          
      )
  }

  const renderControladorasSiam = () =>
  {
      return(
          <div>
              {
                  siamStore.controladora.length === 0
                  ?
                  <NotFound message='Nenhuma controladora siam cadastrado' />
                  :
                  <div className=''>
                      <Table>
                          <thead>
                              <tr>
                                  <th>Nome</th>
                                  <th>Id do dispositivo</th>
                                  <th>Ação</th>
                              </tr>
                          </thead>
                          <tbody>
                              {
                                  siamStore.controladora.map((siam, i) => (
                                  <tr key={i} onClick={() => siamStore.definirControladoraSelecionado(siam.id)}> 
                                      <td>{siam.nome}</td>
                                      <td>{siam.dispositivoId}</td>
                                      <td>
                                          <Dropdown appearance="primary" color="violet" title={'Ação'} size={'xs'}>
                                          <Dropdown.Item onClick={() => {
                                              toggleAtualizarControladora();
                                          }}>Atualizar</Dropdown.Item>
                                          <Dropdown.Item onClick={() => {
                                              toggleModalDeletarControladora();
                                          }}>Deletar</Dropdown.Item>
                                          </Dropdown>
                                      </td>
                                  </tr>
                                  ))
                              }
                          </tbody>
                      </Table>
                  </div>
              }
          </div>
          
      )
  }

  const renderRoutesSIAM = () =>
  {
      switch(routeSIAM)
      {
          case 'listasiam':
              return renderListaSIAM();
          case 'controladora':
              return renderControladorasSiam();
      }
      return(
          <div>ERRO</div>
      )
  }


  return (
    <ContainerLista className=''>
        <HeaderItem>
            <div className='d-flex box'>
                <div className='content d-flex'>
                    <VscLayersActive className='content-icon' />
                    <div>
                        <p className='subtitle'>Campo de cadastro e configurações do sistema SIAM</p>
                    </div>
                </div>

                <div style={{marginLeft: 30}} className='align-self-center'>
                    <p className='info-title'>Voltar para a lista</p>
                    <IconButton appearance="primary" color="violet" icon={<PagePreviousIcon />} onClick={() => setIndex(0)}>
                        Voltar
                    </IconButton>
                </div>

                <div style={{marginLeft: 20}} className='align-self-center'>
                    <div className='align-self-center'>
                        <p style={{margin: 0, width: 200}}>Menu</p>
                        <InputPicker placeholder={'Selecione o modo de cadastro'} value={routeSIAM} data={[{ label: 'Guaritas cadastrado', value: 'listasiam' }, { label: 'Controladoras', value: 'controladora' }]} onChange={(e) => setRouteSIAM(e)} style={{ width: 300 }} />
                    </div>
                </div>

                {
                    routeSIAM === 'listasiam'
                    ?
                    <div style={{marginLeft: 20}} className='align-self-center'>
                        <p style={{margin: 0, width: 200}}>Cadastrar nova guarita</p>
                        <div className='align-self-center mt-2'>
                            <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleModalCadastrarSiam} style={{marginBottom: 10}}>
                                Cadastrar novo guarita SIAM
                            </IconButton>
                        </div>
                    </div>
                    :
                    <div/>
                }

                {
                    routeSIAM === 'controladora'
                    ?
                    <div style={{marginLeft: 20}} className='align-self-center'>
                        <p style={{margin: 0, width: 200}}>Cadastrar nova guarita</p>
                        <div className='align-self-center mt-2'>
                        <IconButton appearance="primary" color="violet" icon={<PlusIcon />} onClick={toggleCadastrarControladora} style={{marginBottom: 10}}>
                            Cadastrar nova controladora
                        </IconButton>
                        </div>
                    </div>
                    :
                    <div/>
                }
                
            </div>
        </HeaderItem>
        <div className='mt-2'>
            {renderRoutesSIAM()}
        </div>

        <CadastrarSiam show={modalCadastrarSiam} hide={toggleModalCadastrarSiam} />
        <AtualizarSiam show={modalEditarSiam} hide={toggleModalAtualizarSiam} />

        <CadastrarControladora show={cadastrarControladora} hide={toggleCadastrarControladora} />
        <AtualizarControladora show={atualizarControladora} hide={toggleAtualizarControladora} />
        <ModalConfirmacao show={modalDeletarSiam} hide={toggleModalDeletarSiam} action={deletarSiamSelecionado} content={'Você tem certeza que deseja deletar esse equiapmento SIAM?'} loading={deletandoSiam} />
        <ModalConfirmacao show={modalDeletarControladora} hide={toggleModalDeletarControladora} action={deletarControladoraSelecionado} content={'Você tem certeza que deseja deletar esse controlador SIAM?'} loading={deletandoSiam} />
    </ContainerLista>
  )
}

export default observer(SiamListagem);