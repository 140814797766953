import styled from 'styled-components';

export const FormContainer = styled.form`
    .input
    {
        background: ${(props) => props.theme.colors.bgBox};
        color: ${(props) => props.theme.colors.text};
        border: 0;
        padding: 10px;
    }
`