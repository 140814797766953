import {
  Avatar,
  FormControlLabel,
  LinearProgress,
  Switch,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, FormEvent, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaWpforms } from "react-icons/fa";
import { StoreContext } from "../../stores";
import { useAcionamentoPerfil } from "../../hooks/AcionamentoPerfil.hook";
import { Acionamento } from "../../interfaces/Acionamento.interface";
import {
  IPerfil,
  IPerfilRequest,
} from "../../interfaces/AcionamentoPerfil.interface";
import { Button, Input, Loader, Modal, Toggle } from "rsuite";

interface PropsModal {
  show: boolean;
  hide: () => void;
}

interface IAcionamento {
  id: string;
  nome: string;
}

const CadastrarPerfil: FC<PropsModal> = ({ show, hide }) => {
  const { acionamentoStore, condominioStore } = useContext(StoreContext);
  const [form, setForm] = useState<IPerfilRequest>({ tipo: 0 });
  const [acionamentos, setAcionamentos] = useState<Acionamento[]>([]);
  const { salvar, salvando } = useAcionamentoPerfil();

  const cadastrar = () => {
    const payload = {
      ...form,
      id: condominioStore.selecionado?.id,
      nome: form.nome,
      acionamentosIds: acionamentos.map((a) => a.id),
    };
    salvar(payload as IPerfil, hide);
  };

  useEffect(() => {

}, [acionamentos]);

  useEffect(() => {
    if (condominioStore.selecionado) {
      setForm((prev) => ({
        ...prev,
        condominioId: condominioStore.selecionado?.id,
      }));
    }
  }, [condominioStore.selecionado]);

  const handleChange = (checked: boolean, acionamento: Acionamento) => {
    setAcionamentos((prev) => {
        const novoEstado = checked
          ? [...prev, acionamento].filter(
              (v, i, a) => a.findIndex((t) => t.id === v.id) === i
            )
          : prev.filter((a) => a.id !== acionamento.id);
        return novoEstado;
      });
  };

  const FormPerfil = () => {
    return (
      <div>
        <div>
          <p className={"title mt-2"}>Informe o nome do perfil</p>
          <Input
            placeholder="Nome do perfil"
            value={form.nome}
            onChange={(e) => setForm({ ...form, nome: e })}
          />
        </div>
        <div>
          <p className={"title mt-2"}>
            Selecione os acionamentos para esse perfil
          </p>
          <div className="d-flex flex-wrap">
            {acionamentoStore.acionamentos.length > 0 ? (
              acionamentoStore.acionamentos.map((acionamento, i) => {
                return (
                  <div key={i} className="m-2">
                    <p>{acionamento.nome || "Nome do acionamento"}</p>
                    <Toggle
                      checked={acionamentos.some(
                        (a) => a.id === acionamento.id
                      )}
                      onChange={(checked) => handleChange(checked, acionamento)}
                    />
                  </div>
                );
              })
            ) : (
              <p>Carregando acionamentos...</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal open={show} onClose={hide}>
      <Modal.Header closeButton>
        <div>
          <p className="modal-styled-title">Cadastre o novo perfíl</p>
          <p className="modal-styled-subtitle">
            Preencha todos os campos para que o cadastro possa ser realizado com
            sucesso
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>{FormPerfil()}</Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          disabled={salvando || !form.nome}
          onClick={cadastrar}
        >
          {salvando ? <Loader title="Salvando..." /> : "Salvar"}
        </Button>
        <Button appearance="default" disabled={salvando} onClick={hide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(CadastrarPerfil);
