import { Bravas, BravasCapturaControle, BravasDeletarGrupoDeAcesso, BravasGrupoDeAcesso, BravasGruposCadastrado, BravasInfoacessoCadastrado, BravasListaAcessoCadastrado, BravasRele } from "../interfaces/Bravas.interface";
import { Guarita } from "../interfaces/Guarita.interface";
import api from "../services/api/api";

class BravasAPI {

    listarPorCondominio = async (condominioId: string) => await api.get<Bravas[]>(`/Bravas/condominio`, { params: { condominioId } });
    obterInformacaoReleBravas = async (id: string) => await api.get<BravasRele>(`/BravasRele/id/${id}`);
    listarRelesPorBravasId = async (bravasId: string) => await api.get<BravasRele[]>(`/BravasRele/bravasId`, { params: { bravasId } });
    listarAcessosPorEquipamentoId = async (equipamentoId: string) => await api.get<BravasListaAcessoCadastrado>(`/Bravas/LerNomesDeAcessosCadastrados`, { params: { equipamentoId } });
    listarGruposCasdastrado = async (equipamentoId: string) => await api.get<BravasGruposCadastrado>(`/Bravas/LerNomesDeGrupoDeAcesso`, { params: { equipamentoId } });
    ObterInformacaoAcesso = async (equipamentoId: string, nome: string) => await api.get<BravasInfoacessoCadastrado>(`/Bravas/ObterAcesso`, { params: { equipamentoId, nome  } });
    atualizarAcessoPortaNativa = async (acesso: BravasInfoacessoCadastrado) => await api.put(`/Bravas/AtualizarAcesso`, acesso);
    cadastrar = async (bravas: Bravas) => await api.post(`/Bravas/AdicionarEquipamento`, bravas);
    atualizar = async (bravas: Bravas) => await api.put(`/Bravas/AtualizarEquipamento`, bravas);
    deletar = async (bravasId: string) => await api.delete(`/Bravas/${bravasId}`);
    reiniciar = async (bravasId: string) => await api.get(`/Bravas/ReiniciarEquipamento`, { params: { bravasId } });
    cadastrarGrupoDeAcesso = async (acesso: BravasGrupoDeAcesso) => await api.post<BravasGrupoDeAcesso>(`/AdicionarGrupoDeAcesso`, acesso);
    atualizarGrupoDeAcesso = async (acesso: BravasGrupoDeAcesso) => await api.put<boolean>(`/AtualizarGrupoDeAcesso`, acesso);
    deletarGrupoDeAcesso = async (acesso: BravasDeletarGrupoDeAcesso) => await api.delete(`/DeleteGrupoDeAcesso`, { params: acesso });
    capturarSerial = async (equipamentoId: string) => await api.get<BravasCapturaControle>(`/Bravas/CapturarEventos?equipamentoId=${equipamentoId}`);
    
}

export default new BravasAPI();