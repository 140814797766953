import { observer } from 'mobx-react-lite';
import { FC, FormEvent, useEffect, useState, useContext } from 'react';
import { Form, Table } from 'react-bootstrap';
import { Button, Dropdown, Input, Modal, SelectPicker } from 'rsuite';
import { StoreContext } from '../../../../stores';
import { useZkteco } from '../../../../hooks/Zkteco.hook';
import { ZktecoRele, ZktecoReleRequest } from '../../../../interfaces/Zkteco.interface';
import ModalConfirmacao from '../../../utils/ModalConfirmacao';
import AtualizarReleZK from './AtualizarReleZk';


interface ModalProps {
    show: boolean;
    hide: () => void;
}

const CadastrarReleZK: FC<ModalProps> = ({ show, hide }) => {

    const [form, setForm] = useState<ZktecoReleRequest>({});
    const { cadastrarRele, salvando, deletarRele, deletando } = useZkteco();
    const { zktecoStore, themeStore } = useContext(StoreContext);
    const [atualizarRele, setAtualizarRele] = useState(false);
    const [modalDeletarZkteco, setModalDeletarZkteco] = useState(false);

    const toggleModalDeletarZkteco = () => setModalDeletarZkteco(!modalDeletarZkteco);
    const toggleAtualizarRele = () => setAtualizarRele(!atualizarRele);
    const numberListRele = Array.from({ length: 8 }, (_, index) => index + 1);


    useEffect(() => {
        if (zktecoStore.selecionado)
        {
            zktecoStore.listarRelePorZkteco(zktecoStore.selecionado.id);
            setForm({
                zktecoId: zktecoStore.selecionado.id,
                condominioId: zktecoStore.selecionado.condominioId,
                numeroRele: 1,
            })
        }
    }, [zktecoStore.selecionado, zktecoStore.reles]);

    const clear = () =>
    {
        if(zktecoStore.selecionado)
        {
            setForm({
                zktecoId: zktecoStore.selecionado.id,
                numeroRele: 1,
            })
        }
    }

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(zktecoStore.selecionado)
        {
            cadastrarRele(form as ZktecoRele, hide, clear);
        }
    }

    const deletarMZktecoSelecionado = () =>
    {
        if(zktecoStore.selecionadoRele) {
            deletarRele(zktecoStore.selecionadoRele);
        }
    }
  

    const FormCard = () => {
        return (
            <Form onSubmit={(e) => salvar(e)}>
                <div>
                    <Form.Group className=" p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" value={form.nome || ''} className='shadow-none' placeholder="Ex: Rele 1" onChange={(e) => setForm({ ...form, nome: e })} />
                    </Form.Group>
                    <Form.Group className=" p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Tipo</Form.Label>
                        <Input required type="text" value={form.tipo || ''} className='shadow-none' placeholder="Ex: Porta / Auxiliar" onChange={(e) => setForm({ ...form, tipo: e })} />
                    </Form.Group>
                    <Form.Group className="p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Saída</Form.Label>
                        <SelectPicker block searchable={false} data={numberListRele.map(x => ({ label: x, value: x }))}  value={form.numeroRele || undefined} placeholder="1" onChange={(e) => setForm({ ...form, numeroRele: e ?? undefined })}/>
                    </Form.Group>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance="primary" disabled={salvando} type="submit">
                        {salvando ? 'Salvando...' : 'Salvar'}
                    </Button>
                    <Button disabled={salvando} type="button" onClick={hide} style={{ marginLeft: 10 }}>
                        Fechar
                    </Button>
                </div>
            </Form>
        )
    }

    const renderListRele = () => {
        return (
            <div className='box-table'>
                <div className='mt-1'>
                    <Table striped hover variant={themeStore.theme === 'dark' ? 'dark' : 'none'} size="lg">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Numero Relé</th>
                                <th scope="col">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                zktecoStore.releZkteco.map((x, i) => (
                                    <tr key={i} onClick={() => zktecoStore.definirSelecionadoRele(x.id)}>
                                        <td>{x.nome}</td>
                                        <td>{x.numeroRele}</td>
                                        <td>
                                            <Dropdown title={'Ação'} size={'xs'} appearance={'primary'} color={'violet'} placement='topEnd'>
                                                <Dropdown.Item onClick={() => {
                                                    toggleAtualizarRele();
                                                }}>Atualizar</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    toggleModalDeletarZkteco();
                                                }}>Deletar</Dropdown.Item>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    const RenderReles = () => {
        return (
            <div>
                <div>
                    <p className='modal-styled-title mt-4'>relés cadastrado</p>
                    {renderListRele()}
                </div>
            </div>
        )
    }

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastrar novo rele ZKTECO</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {FormCard()}
                    {RenderReles()}
                </div>
            </Modal.Body>

            <AtualizarReleZK show={atualizarRele} hide={toggleAtualizarRele} />
            <ModalConfirmacao show={modalDeletarZkteco} hide={toggleModalDeletarZkteco} action={deletarMZktecoSelecionado} content={'Você tem certeza que deseja deletar esse Rele?'} loading={deletando} />
        </Modal>
    )
}

export default observer(CadastrarReleZK);