import { Accordion, AccordionDetails, AccordionSummary, Avatar, Chip, CircularProgress, Drawer } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ListGroup, Table } from 'react-bootstrap';
import { BsBookmarkCheck, BsCheckCircle, BsX } from 'react-icons/bs';
import { StoreContext } from '../../../stores';
import { useDispositivo } from '../../../hooks/Dispositivo.hook';
import { ITag, TagRequest } from '../../../interfaces/Dispositivo.interface';
import { Morador } from '../../../interfaces/Morador.interface';
import Empty from '../../utils/Empty';
import Loading from '../../utils/Loading';
import { Container, ContainerTable, ListTags } from './style/CadastrarTag.style';
import { FaWpforms } from 'react-icons/fa';
import NotFound from '../../utils/NotFound';
import { VscGroupByRefType } from 'react-icons/vsc';
import { Content, Icon, Notificacao, Sidebar, Title } from '../../utils/RelatorioStyle';
import { MdErrorOutline } from 'react-icons/md';
import { SelectPicker, Modal, Loader, Toggle, TagGroup, Tag, Input, Button } from 'rsuite';
import { AiOutlineCheck, AiOutlineReload } from 'react-icons/ai';
import { BiBarcodeReader } from 'react-icons/bi';
import ModalRelatorioTag from '../../utils/ModalRelatorioTag';
import nice125 from '../../../assets/img/nice.png';
import nice13 from '../../../assets/img/nica13.png';
import intelbras from '../../../assets/img/intelbras13mhz.png';
import hikvision from '../../../assets/img/hikvision.png';
import intelbras125 from '../../../assets/img/intelbras125.png';
import { RadioTile, RadioTileGroup } from 'rsuite';

interface Props {
    show: boolean;
    hide: () => void;
    morador: Morador | null;
}

const CadastrarTag: FC<Props> = ({ show, hide, morador }) => {
    
    const { equipamentoStore, acionamentoPerfilStore, moradorStore, dispositivosStore, themeStore, bravasStore } = useContext(StoreContext);
    const { cadastrarTag, salvandoTag, deletarTag, deletando, openRelatorio, setOpenRelatorio, enviando, enviarTagMorador } = useDispositivo();
    const [tag, setTag] = useState<TagRequest>({}); 
    const [acao, setAcao] = useState('');
    const [mode, setMode] = useState('');
    const [modoTag, setModoTag] = useState(false);
    const [open, setOpen] = useState(false);
    const [tipoConversao, setTipoConversao] = useState('');
    const [equipamento, setEquipamento] = useState('');

    var equipamentosCondominio = equipamentoStore.equipamentosBiometria.map((e) => e.tipoNome);
    var equipamentos = equipamentosCondominio.filter(function(este, i) {
        return equipamentosCondominio.indexOf(este) === i;
    });
    
    useEffect(() => {
        if(morador) {
            dispositivosStore.listarTagPorMorador(morador.id);
            setTag({ 
                moradorId: morador.id,
                tipo: 2,
            });
            setMode('');
            setTipoConversao('');
        }
        if(morador?.acionamentoPerfilId)
        {
            acionamentoPerfilStore.obterPorId(morador?.acionamentoPerfilId);
        }
      setTipoConversao('');
    }, [morador]);

    const toggleOpen = () => setOpen(!open);

    useEffect(() => {
      setEquipamento('2');
    }, [show]);
  
    useEffect(() =>
      {
        switch(equipamento)
        {
          case 'bravas':
          {
            bravasStore.listar();
            bravasStore.selecionado = null;
            bravasStore.bravas = [];
          }
          
        }
      }, [equipamento]);
      const clear = () => {
          setTag({
              moradorId: morador?.id
          });
          setMode('');
          setModoTag(false);
      }

    const salvar = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setAcao("Tag cadastro");
        if(tag.serial === '') {
            delete tag.serial
        }
        else if(tag.wiegand === '') {
          delete tag.wiegand
        }
        else if(tag.serial && mode === 'desktop')
        {
            delete tag.wiegand
            tag.serial = tag.serial.substring(2, tag.serial.length)
        }

        if(tag.tipo === undefined)
        {
          tag.tipo = 2;
        }

        if(tag.sincronizar === undefined)
        {
          tag.sincronizar = false;
        }

        cadastrarTag(tag as ITag, hide, toggleOpen, clear);
    }

    const renderTags = () => {

        if(equipamentoStore.loading) {
            return <Loading message='Carregando tags do morador' />
        }

        if(dispositivosStore.tags.length === 0) {
            return <NotFound message='Nenhuma tag cadastrado nesse morador' />
        }

        if(deletando)
        {
            return <Loading message='Deletando tag...' />
        }

        return(
            <ContainerTable>
                <table className="table">
                    <thead>
                        <tr>
                            <th> Serial </th>
                            <th> Wiegand </th>
                            <th> Sincronização </th>
                            <th> Ação </th>
                        </tr>
                    </thead>

                    <tbody style={{border: 'none'}}>
                    {
                        dispositivosStore.tags.map((tag, index) =>{
                        return(
                            <tr key={index} onClick={ () => {
                                dispositivosStore.definirTagSelecionado(tag.id);
                                } }>
                                <td>{tag.serial ? tag.serial : '-'}</td>
                                <td>{tag.wiegand ? tag.wiegand : '-'}</td>
                                <td role={'button'} onClick={ () => 
                                {
                                    enviarTagMorador(tag.moradorId);
                                    setAcao("Tag sincronização");
                                } }>
                                    {
                                        enviando
                                        ?
                                        <Loader content={'Sincronizando tag... Aguarde!'} />
                                        :
                                        <AiOutlineReload size={20} role={'button'}/>
                                    }
                                </td>
                                <td onClick={ () => deletarTag(tag, clear) }>
                                    <BsX size={20} role={'button'}/>
                                </td>
                            </tr>
                        )
                        })
                    }
                    </tbody>
                </table>
            </ContainerTable>        
        )
    }

    const renderFormTag = () => {

        if(moradorStore.selecionado?.acionamentoPerfilId === '00000000-0000-0000-0000-000000000000') {
          return <NotFound message='Morador sem perfil cadastrado' />
        }
    
        const renderTipoConversao = () =>
        {
          switch(tipoConversao)
          {
    
    
            case 'wiegend':
              return(
                <div className=''>
                  <div className='header'>
                    <p className='title'>Wiegend</p>
                    <p className='subtitle'>Preencha a numeração que tem na tag do morador</p>
                  </div>
                  <Input
                    placeholder="Wiegand"
                    className={'mt-1'}
                    required
                    value={tag.wiegand || ''}
                    onChange={(e) => setTag({ ...tag, wiegand: e, serial: '' })}
                  />
                </div>
              )
    
            case 'serial':
              return(
                <div className=''>
                  <div className='header'>
                    <p className='title'>Serial</p>
                    <p className='subtitle'>Preencha a serial</p>
                  </div>
                  <Input
                    value={tag.serial || ''}
                    onChange={(e) => setTag({ ...tag, serial: e, wiegand: '' })}
                    placeholder={'Serial'}
                    className={'mt-1'}
                    required
                  />
                </div>
              )
          }
    
    
          return(
            <div className='d-flex justofy-content-center align-self-center'>
               <small className='align-self-center'>Selecione o modo de cadastro</small>
            </div>
          )
        }


      const dataIsSerial = [
          {
            label: 'Serial',
            value: 'serial'
          }
        ];

        const dataIsNotSerial = [
          {
            label: 'Serial',
            value: 'serial'
          },
          {
            label: 'Wiegend',
            value: 'wiegend'
          }
        ];
    
        return (
          <Container onSubmit={salvar}>
              <div className="form-group">
                <p>Acionamentos liberado para o perfil do morador</p>
                <div className='d-flex justify-content-start' style={{flexWrap: 'wrap'}}>
                  {
                    acionamentoPerfilStore.perfilMorador?.acionamentos?.map((acionamento, i) => (
                      <Chip key={i} size={'small'} variant="outlined" color={themeStore.theme === 'dark' ? 'success' : 'primary'}  className='m-1' icon={<BsCheckCircle size={20} />} label={acionamento.nome} />
                    ))
                  }
                </div>
              </div>
    
              
    
              <div className='header mt-1'>
                <p className='title'>Selecione a tag</p>
                <p className='subtitle'>Informe o tipo de tag que você deseja cadastra</p>
              </div>

              <RadioTileGroup defaultValue="2|controlid" inline aria-label="Create new project" 
                onChange={(e) => {
                  if(e)
                  {
                    setTag({ ...tag, tipo: parseInt(e.toString().split('|')[0]) })
                    setEquipamento(e.toString().split('|')[1])
                  }
                }}
              >
                <RadioTile label="NICE" value="2|controlid">
                  <div className='d-fleSx'>
                    <img className='tag' src={nice125} />
                    <p className='info-tag'>
                      TAG 125khz
                    </p>
                  </div>
                </RadioTile>
                <RadioTile label="NICE" value="1|nice">
                  <div className='d-fleSx'>
                    <img className='tag' src={nice13} />
                    <p className='info-tag'>
                      TAG 13mhz
                    </p>
                  </div>
                </RadioTile>
                <RadioTile label="INTELBRAS" value="1|intelbras13">
                  <div className='d-fleSx'>
                    <img className='tag' src={intelbras} />
                    <p className='info-tag'>
                      TAG 13mhz 
                    </p>
                  </div>
                </RadioTile>
                <RadioTile label="INTELBRAS" value="2|intelbras125">
                  <div className='d-fleSx'>
                    <img className='tag' src={intelbras125} />
                    <p className='info-tag'>
                      TAG 125khz 
                    </p>
                  </div>
                </RadioTile>
                <RadioTile label="HIKVISION" value="1|hikvision">
                  <div className='d-fleSx'>
                    <img className='tag' src={hikvision} />
                    <p className='info-tag'>
                      TAG 13mhz
                    </p>
                  </div>
                </RadioTile>
              </RadioTileGroup>
    
              {/* <br/> */}
    
              {
                !equipamento ? <div/>
                :
                <div className='d-flex justify-content-between mt-2'>
                  <div className='m-1' style={{width: '40%'}}>
                    <div className='header'>
                      <p className='title'>Wiegend / Serial</p>
                      <p className='subtitle'>Modo de cadastro</p>
                    </div>
                    <SelectPicker 
                      block
                      placeholder={'Selecione'}
                      className={'mt-1'}
                      cleanable={false}
                      data={tag.tipo === 1 ? dataIsSerial : dataIsNotSerial} 

                      onChange={(e) => {
                        if(e)
                        {
                          setTipoConversao(e)
                        }
                      }}
                    />
                  </div>
                  <div className='m-1' style={{width: '60%'}}>
                    {
                      renderTipoConversao()
                    }
                  </div>
                </div>
              }
    
              <div>
                <div className='header mt-2'>
                  <p className='title'>Sincronização</p>
                  <p className='subtitle'>Se habilitado a serial é enviado para os equipamentos, se não, fica salvo no banco de dados pra sincronizar depois</p>
                </div>
                <Toggle disabled={!tipoConversao} style={{marginTop: 10}} onChange={(e) => setTag({ ...tag, sincronizar: e })} checkedChildren={<AiOutlineCheck />} unCheckedChildren={<BsX />} />
              </div> 
              
              <div className="d-flex justify-content-end mt-3">
                <div className='d-flex'>
                  <Button type={'submit'} appearance='primary' color={'violet'} style={{marginRight: 5}} disabled={salvandoTag || !tipoConversao}>{salvandoTag ? 'Salvando...' : 'Salvar'}</Button>
                  <Button
                    type="button"
                    onClick={hide}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </Container>
        )
      }

    return (
        <Modal backdrop={'static'} keyboard={false} open={show} onClose={hide}>
            <Modal.Header>
                <div>
                    <p className='modal-styled-title'>Cadastro de tag</p>
                    <p className='modal-styled-subtitle'>
                    <p>Cadastrar nova tag no morador {moradorStore.selecionado?.nome.split(' ')[0]} {moradorStore.selecionado?.nome.split(' ')[1]}</p>
                    </p>
                </div>
                <p style={{marginTop: 10, marginBottom: '-10px', fontWeight: 600}}>Acessos</p>
                <TagGroup className='mt-1 mb-2'>
                    {
                        acionamentoPerfilStore.perfilMorador?.acionamentos?.map((perfil, i) =>
                        (
                            <Tag key={i} color="blue" size="sm">{perfil.nome}</Tag>
                        ))
                    }
                </TagGroup>
            </Modal.Header>
            {/* <Modal.Body> */}
                {
                    moradorStore.selecionado?.acionamentoPerfilId === '00000000-0000-0000-0000-000000000000'
                    ?
                        <div className='d-flex justify-content-center'>
                            <NotFound message='Morador sem perfil cadastrado' />
                        </div>
                    :
                    <>
                        {renderTags()}
                        {renderFormTag()}
                    </>
                }
            <ModalRelatorioTag show={openRelatorio} hide={() => setOpenRelatorio(false)} tipo={acao} />
        </Modal>
    )
}

export default observer(CadastrarTag);
