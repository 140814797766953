import { AssociarMorador, Morador, MoradorDispositivos, NovaOrdem } from "../interfaces/Morador.interface";
import api from "../services/api/api";

class MoradorAPI {
    listarPorId = async (moradorId: string) => await api.get<Morador>(`/Morador/${moradorId}`);
    obterPorNome = async (condominioid: string, nome: string) => await api.get<Morador[]>(`/Morador/condominioidnome/${condominioid}/${nome}`);
    listarMoradorPorCondominio = async (condominioId: string) => await api.get<Morador[]>(`/Morador/condominioid/${condominioId}`);
    listarMoradorPorUnidade = async (unidadeId: string) => await api.get<Morador[]>(`/Morador/unidade/${unidadeId}`);
    listarRelatorioDispositivoPorUnidade = async (unidadeId: string) => await api.get<MoradorDispositivos[]>(`/Morador/tag/${unidadeId}`);
    listarMoradorExcluidoPorUnidade = async (unidadeId: string) => await api.get<Morador[]>(`/Morador/ExcluidoUnidade/${unidadeId}`);
    cadastrar = async (morador: Morador) => await api.post(`/Morador`, morador);
    cadastrarUsuario = async (moradorId: string) => await api.post(`/Morador/CriarUsuario?moradorId=${moradorId}`);
    associarMoradorNovoCondominio = async (request: AssociarMorador) => await api.post(`/Morador/associarMoradorNovoCondominio`, request);
    atualizar = async (morador: Morador) => await api.put(`/Morador`, morador); 
    atualizarOrdem = async (novaOrdem: NovaOrdem) => await api.put(`/Morador/atualizarOrdem`, novaOrdem); 

    restaurar = async (id: string) => await api.put(`/Morador/restaurarmoradorexcluido/${id}`); 

    deletar = async (moradorId: string) => await api.delete(`/Morador/${moradorId}`); 
    tipoMorador = () => api.get(`/TipoMorador`);
}

export default new MoradorAPI();