import api from "../services/api/api";
import { Bloco } from "../interfaces/Bloco.interface";
import { AutomacaoAcionamentoIdentificacao } from "../interfaces/AutomacaoAcionamentoIdentificacao.interface";

class AutomacaoAcionamentoIdentificacaoAPI {

    listarPorCondominioId = async (condominioId: string) => await api.get<AutomacaoAcionamentoIdentificacao[]>(`/AutomacaoAcionamentoIdentificacao/ObterPorCondominio`, { params: { condominioId } }); 
    obterPorId = async (automacaoAcionamentoIdentificacaoId: string) => await api.get<AutomacaoAcionamentoIdentificacao>(`/AutomacaoAcionamentoIdentificacao/ObterPorId`, { params: { automacaoAcionamentoIdentificacaoId } }); 
    cadastrar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamentoIdentificacao) => await api.post(`/AutomacaoAcionamentoIdentificacao`, automacaoAcionamentoIdentificacao); 
    atualizar = async (automacaoAcionamentoIdentificacao: AutomacaoAcionamentoIdentificacao) => await api.put(`/AutomacaoAcionamentoIdentificacao`, automacaoAcionamentoIdentificacao); 
    deletar = async (automacaoAcionamentoIdentificacaoId: string) => await api.delete(`/AutomacaoAcionamentoIdentificacao/${automacaoAcionamentoIdentificacaoId}`, { params: { automacaoAcionamentoIdentificacaoId } }); 

}

export default new AutomacaoAcionamentoIdentificacaoAPI();