import React, { FC } from "react"
import { Spinner } from "react-bootstrap"
import { Loader } from "rsuite";


interface LoadingProps {
    message: string;
}

const Loading: FC<LoadingProps> = ({ message }) => {
    return(
        <div className="d-flex justify-content-center align-self-center">
            <Loader size="xs" content={<React.Fragment><span style={{color: '#fff'}}>{message}</span></React.Fragment>} />
        </div>
    )
}

export default Loading;