import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { BsCameraVideo } from 'react-icons/bs';
import { StoreContext } from '../../stores';
import { useCamera } from '../../hooks/Camera.hook';
import { Camera, CameraPerfilLista, CameraPerfilObj, CameraRequest } from '../../interfaces/Cameras.interface';
import { Layout } from '../../interfaces/Layout.interface';
import { FormCamera, SubTitle, Title } from './styles/Modal.style';
import { Button, Input, Loader, Modal, Popover, SelectPicker, Toggle, Whisper } from 'rsuite';
import LayoutAPI from '../../api/LayoutAPI';

interface Props {
    show: boolean;
    hide: () => void;
}

const CadastrarCameraPerfil:FC<Props> = ({ show, hide }) => {

    const { cameraStore, layoutStore, condominioStore } = useContext(StoreContext);
    const [form, setForm] = useState<CameraPerfilObj[]>([]);
    const [imgCam, setImgCam] = useState('');
    const [loading, setLoading] = useState(false);
    const { cadastrarCameraPerfil, salvando } = useCamera();

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        var json: CameraPerfilLista = 
        {
            perfis: form
        }

        json.perfis.forEach((c) => {
            if(cameraStore.perfilSelecionado)
            {
                c.cameraPerfilIdentificacaoId = cameraStore.perfilSelecionado?.id
            }
            else alert('erro')
        })

        cadastrarCameraPerfil(json, hide, clear);
    }

    useEffect(() =>
    {
        if(cameraStore.perfilSelecionado && show)
        {
            setForm([]);
            layoutStore.listar();
        }
    }, [cameraStore.perfilSelecionado]);

    const clear = () =>
    {
        setForm([]);
    }

    return (
        <Modal open={show} onClose={hide}>
            <Modal.Header style={{border: 0}} closeButton>
                <div>
                    <Title>Cadastro de câmera <BsCameraVideo/> </Title>
                    <SubTitle>Adicionar novas câmeras para esse perfil</SubTitle>
                </div>
            </Modal.Header>
            <Modal.Body>
                <FormCamera onSubmit={salvar}>
                    <p className='info'>Selecione as câmeras para o perfil</p>
                    <div>
                        <p className='title'>Layout das cameras<span className='text-danger'>*</span></p>
                        <SelectPicker
                            placeholder={'Selecione o layout'}
                            block
                            data={layoutStore.layouts.map((l) => ({ label: l.nomeDGuard, value: l.id }))}
                            onChange={(e) => {
                                if(e) cameraStore.listarPorLayout(e);
                                // cameraStore.definirPerfilIdentificacaoSelecionado
                            }}
                        />
                    </div>

                    {
                        cameraStore.cameras.length === 0
                        ?
                        ''
                        :
                        <div>
                            <p className='title'>Câmeras do layout</p>
                            <div className='d-flex flex-wrap'>
                            {
                                cameraStore.cameras.map((camera, i) =>
                                (
                                    <div key={i} className='m-2 p-1' style={{width: '20%', height: 80}}>
                                        <p>{camera.nomeDGuard}</p>
                                        <Toggle 
                                            checkedChildren="Habilitado" 
                                            unCheckedChildren="Desabilitado"
                                            // value={}
                                            disabled={cameraStore.camerasPerfil.find((c) => c.cameraId === camera.id) ? true : false}
                                            checked={form?.find(i =>i.cameraId === camera.id) ? true : false || cameraStore.camerasPerfil.find((c) => c.cameraId === camera.id) ? true : false}
                                            onChange={(e) => 
                                            {
                                                if(e && cameraStore.perfilSelecionado)
                                                {
                                                    setForm(form => [{ cameraId: camera.id, cameraPerfilIdentificacaoId: ''}, ...form])
                                                }
                                                else
                                                {
                                                    setForm(form => form.filter((c) => c.cameraId !== camera.id))
                                                }
                                            }}
                                        />
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    }

                    <div className='d-flex justify-content-end mt-4'>
                        <div className='d-flex'>
                            <Button disabled={salvando || form.length === 0} type={'submit'} appearance="primary" className='m-1'>
                                {
                                    salvando
                                    ?
                                    <Loader title={'Salvando...'} />
                                    :
                                    'Salvar'
                                }
                            </Button>
                            <Button type={'button'} className='m-1' onClick={hide}>Fechar</Button>
                        </div>
                    </div>
                </FormCamera>

            </Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarCameraPerfil);
