import React, { useState, useEffect, useContext } from "react";
import { BsX, BsTrash } from "react-icons/bs";
import { IoMdFingerPrint } from "react-icons/io";
import { StoreContext } from "../../../stores";
import {
  TransitionDiv,
  Title as TitleModal,
} from "./styles/ModalBiometria.stye";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { observer } from "mobx-react-lite";
import CadastrarBiometria from "./CadastrarBiometria";
import HikvisionCard from "../facial/hikvision/HikvisionCard";
import { IconButton, CircularProgress, Drawer } from "@mui/material";
import { useDispositivo } from "../../../hooks/Dispositivo.hook";
import NotFound from "../../utils/NotFound";
import Loading from "../../utils/Loading";
import { AiOutlineReload } from "react-icons/ai";
import { Button, Dropdown, Modal, Panel, Popover, Whisper } from "rsuite";
import CadastrarFacial from "../facial/hikvision/facial/CadastrarFacial";
import ModalRelatorioBiometria from "../../utils/ModalRelatorioBiometria";
import useBiometricUpload from "../../../hooks/useBiometricUpload";

interface ModalBiometriaProps {
  show: boolean;
  hide: () => void;
}

const ModalBiometria: React.FC<ModalBiometriaProps> = ({ show, hide }) => {
  const { moradorStore, equipamentoStore, condominioStore, dispositivosStore } =
    useContext(StoreContext);
  const {
    deletarBiometriaDigital,
    deletando,
    coletando,
    deletarBiometriaFacial,
    enviando,
    enviarBiometriaDigitalMorador,
    openRelatorio,
    setOpenRelatorio,
    sincronizarFotoMorador,
  } = useDispositivo();
  const [cadastrar, setCadastrar] = useState(false);
  const [routes, setRoutes] = useState("");
  const [acao, setAcao] = useState("");
  const { uploadBiometric, uploadStatus, resetUploadStatus, errors, loading } =
    useBiometricUpload();
  const [errosUpload, setErrosUpload] = useState<
    { equipamentoId: string; notificacao: string; local: string }[]
  >([]);

  useEffect(() => {
    if (condominioStore.selecionado && moradorStore.selecionado && show) {
      equipamentoStore.listarEquipamentosBiometriaPorMorador(
        moradorStore.selecionado.id
      );
      dispositivosStore.listarBiometriaDigitalPorMorador(
        moradorStore.selecionado.id
      );
      dispositivosStore.listarBiometriaFacialPorMorador(
        moradorStore.selecionado.id
      );
    }
  }, [condominioStore.selecionado, moradorStore.selecionado, show]);

  const moradorId = moradorStore.selecionado?.id;

  useEffect(() => {
    equipamentoStore.definirSelecionado(null);
    setCadastrar(false);
  }, [show]);

  const renderVazio = () => {
    return <NotFound message="Nenhuma digital cadastrada" />;
  };

  const renderBiometriaCadastrada = () => {
    if (dispositivosStore.loading) {
      return (
        <Loading message="Carregando biometrias cadastrada do morador..." />
      );
    } else if (deletando) {
      return <Loading message="Deletando biometria do morador..." />;
    }

    const renderDedos = (dedo: number) => {
      switch (dedo) {
        case 0:
          return "Dedo mínimo - esquerdo";
          break;
        case 1:
          return "Anelar - esquerdo";
          break;
        case 2:
          return "Dedo mínimo - esquerdo";
          break;
        case 3:
          return "Inidicador - esquerdo";
          break;
        case 4:
          return "polegar - esquerdo";
          break;
        case 5:
          return "polegar - direito";
          break;
        case 6:
          return "Inidicador - direito";
          break;
        case 7:
          return "Dedo médio - direito";
          break;
        case 8:
          return "Anelar - direito";
          break;
        case 9:
          return "Dedo mínimo - direito";
          break;
      }
    };

    const renderTipo = (tipo: number) => {
      switch (tipo) {
        case 0:
          return "Normal";
          break;
        case 1:
          return "Pânico";
          break;
      }
    };

    const renderTipoEquipamento = (tipoEquipamento: number) => {
      switch (tipoEquipamento) {
        case 0:
          return "GuaritaDireto";
          break;
        case 1:
          return "GuaritaServidor";
          break;
        case 2:
          return "ControlId";
          break;
        case 3:
          return "AnvizT5";
          break;
        case 4:
          return "HikvisonVandal";
          break;
        case 5:
          return "HikvisonFacial";
          break;
        case 6:
          return "IntelbrasFacial";
          break;
      }
    };

    const renderBiometria = () => {
      if (dispositivosStore.biometriaCadastrada.length === 0) {
        return renderVazio();
      }

      return (
        <table className="table animation">
          <thead>
            <tr>
              {/* <th> Dedo</th> */}
              <th> Tipo biometria </th>
              <th> Tipo equipamento </th>
              {/* <th> Sincronização </th> */}
              <th> Ação</th>
            </tr>
          </thead>

          <tbody>
            {dispositivosStore.biometriaCadastrada?.map((biometria, index) => (
              <tr key={index}>
                {/* <td>{renderDedos(biometria.dedo)}</td> */}
                <td>{renderTipo(biometria.tipo)}</td>
                <td>{renderTipoEquipamento(biometria.tipoEquipamento)}</td>
                {/*  <td role={'button'} onClick={() => {
                    enviarBiometriaDigitalMorador({
                      moradorId: biometria.moradorId,
                      dedo: biometria.dedo,
                      tipo: biometria.tipo,
                      template: biometria.template
                    });
                  }}>
                    {
                      enviando
                      ?
                      <Loader content={'Sincronizando biometria...'} />
                      :
                      <div>
                        <AiOutlineReload size={22} role={'button'} />
                        <span className='' style={{marginLeft: 10}}>Sincronizar biometria</span>
                      </div>
                    }
                  </td> */}
                <td
                  onClick={() => {
                    // setCadastrar(false);
                    // deletarBiometriaDigital(biometria)
                  }}
                >
                  <div role={"button"}>
                    <Whisper
                      placement="bottomStart"
                      trigger="click"
                      speaker={(
                        { onClose, left, top, className, ...rest },
                        ref
                      ) => {
                        const handleSelect = (eventKey: any) => {
                          onClose();
                        };
                        return (
                          <Popover
                            ref={ref}
                            className={className}
                            style={{ left, top }}
                            full
                          >
                            <Dropdown.Menu
                              onSelect={handleSelect}
                              style={{ padding: 5 }}
                            >
                              <Dropdown.Item
                                onClick={() => {
                                  enviarBiometriaDigitalMorador({
                                    moradorId: biometria.moradorId,
                                    dedo: biometria.dedo,
                                    tipo: biometria.tipo,
                                    template: biometria.template,
                                  });
                                  setAcao("Biometria Sincronizar");
                                }}
                                style={{ borderRadius: 7 }}
                                eventKey={4}
                              >
                                <p style={{ fontSize: 14, margin: 0 }}>
                                  {enviando
                                    ? "Sincronizando... Aguarde!"
                                    : "Sincronizar biometria para o equipamento"}
                                </p>
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{ borderRadius: 7 }}
                                eventKey={3}
                              >
                                <p
                                  style={{ fontSize: 14, margin: 0 }}
                                  onClick={() => {
                                    setCadastrar(false);
                                    deletarBiometriaDigital(biometria);
                                    setAcao("Biometria Exclusão");
                                  }}
                                >
                                  Deletar biometria
                                </p>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Popover>
                        );
                      }}
                    >
                      <Button size={"xs"}>Ação</Button>
                    </Whisper>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    return (
      <div className="d-flex justify-content-between">{renderBiometria()}</div>
    );
  };
  useEffect(() => {
    if (uploadStatus[moradorStore.selecionado?.id || ""] === "success") {
      // Recarrega as biometrias do morador ao enviar com sucesso
      dispositivosStore.listarBiometriaFacialPorMorador(
        moradorStore.selecionado?.id ?? ""
      );

      // Remove a mensagem de sucesso após 3 segundos
      const timer = setTimeout(() => {
        resetUploadStatus(moradorStore.selecionado?.id || "");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [uploadStatus, moradorStore.selecionado?.id]);

  const renderBiometriaFacial = () => {
    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file && moradorStore.selecionado?.id) {
        uploadBiometric(moradorStore.selecionado.id, file);
        event.target.value = ""; // Reseta o input
      }
    };

    const error = errors[moradorId || ""];
    if (!dispositivosStore.biometriaFacialCadastrada) {
      return (
        <div className="d-flex flex-column align-items-center">
          <NotFound message="Morador sem biometria facial" />
          <label htmlFor="upload-facial" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Button appearance="primary" as="span">
              {loading ? "Enviando..." : "Enviar biometria facial"}
            </Button>
            <input
              type="file"
              id="upload-facial"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleUpload}
            />
          </label>
          {uploadStatus[moradorStore.selecionado?.id || ""] === "success" && (
            <p style={{ color: "green", marginTop: "10px" }}>
              Upload realizado com sucesso!
            </p>
          )}
          {uploadStatus[moradorStore.selecionado?.id || ""] === "error" && (
            <p style={{ color: "red", padding: 10 }}>
              {error && Array.isArray(error) ? (
                <div>
                  {error.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              ) : null}
            </p>
          )}
        </div>
      );
    }
    return (
      <>
        {moradorStore.selecionado ? (
          <div className="p-3 d-flex justify-content-center">
            <Panel
              shaded
              bordered
              bodyFill
              style={{ display: "inline-block", width: 420 }}
            >
              <div className="d-flex justify-content-center">
                <img
                  src={dispositivosStore.biometriaFacialCadastrada?.template}
                  height="240"
                />
              </div>
              <Panel
                header={`${moradorStore.selecionado.nome?.split(" ")[0]} ${
                  moradorStore.selecionado.nome?.split(" ")[1]
                }`}
              >
                <div className={"d-flex"}>
                  {deletando || enviando ? (
                    <CircularProgress size={18} style={{ marginLeft: 10 }} />
                  ) : (
                    <div>
                      <div className="d-flex">
                        <p style={{ lineBreak: "auto", alignSelf: "center" }}>
                          Deletar facial
                        </p>
                        <IconButton
                          color="error"
                          onClick={() =>
                            deletarBiometriaFacial(
                              moradorStore.selecionado?.id as string
                            )
                          }
                        >
                          <BsTrash />
                        </IconButton>
                      </div>

                      <div className="d-flex mt-2">
                        <p style={{ lineBreak: "auto", alignSelf: "center" }}>
                          Sincronizar a facial com os outros equipamentos
                        </p>
                        <IconButton
                          color="success"
                          onClick={() =>
                            sincronizarFotoMorador({
                              moradorId: moradorStore.selecionado?.id as string,
                            })
                          }
                        >
                          <AiOutlineReload />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </div>
              </Panel>
            </Panel>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };
  const renderEquipamentos = () => {
    if (equipamentoStore.loading) {
      return <Loading message="Carregando equipamentos do condomínio..." />;
    }

    return (
      <table className="table animation">
        <thead>
          <tr>
            <th> Local </th>
            <th> Equipamento </th>
            <th> Ação </th>
          </tr>
        </thead>

        <tbody>
          {equipamentoStore.equipamentosBiometria?.map((equipamento, index) => {
            return (
              <tr key={index}>
                <td>{equipamento.nome}</td>
                <td>{equipamento.tipoNome}</td>
                <td
                  className={"d-flex justify-content-start"}
                  role={"button"}
                  onClick={() => {
                    equipamentoStore.definirSelecionado(
                      equipamento.equipamentoId
                    );
                    setCadastrar(true);
                  }}
                >
                  <IoMdFingerPrint
                    size={22}
                    role={"button"}
                    style={{ marginRight: 10 }}
                  />
                  <span className="font-weight-bold text-muted ml-2">
                    Cadastrar biometria
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderBiometrias = () => {
    if (
      moradorStore.selecionado?.acionamentoPerfilId ===
      "00000000-0000-0000-0000-000000000000"
    ) {
      return <NotFound message="Morador sem perfil cadastrado!" />;
    }
    switch (equipamentoStore.selecionado?.tipoNome) {
      case "HikvisionFacial":
        return (
          <HikvisionCard cadastrar={cadastrar} setCadastrar={setCadastrar} />
        );
      case "ControlId":
        return (
          <CadastrarBiometria
            cadastrar={cadastrar}
            setCadastrar={setCadastrar}
            setRoutes={setRoutes}
          />
        );
      case "AnvizT5":
        return (
          <CadastrarBiometria
            cadastrar={cadastrar}
            setCadastrar={setCadastrar}
            setRoutes={setRoutes}
          />
        );
      case "IntelbrasFacial":
        return (
          <CadastrarFacial setCadastrar={setCadastrar} setRoutes={setRoutes} />
        );
      case "ControlIdFacial":
        return (
          <CadastrarFacial setCadastrar={setCadastrar} setRoutes={setRoutes} />
        );
    }
    return <div>NENHUM EQUIPAMENTO RECONHECIDO</div>;
  };

  const renderBodyModal = () => {
    if (
      show &&
      moradorStore.selecionado?.acionamentoPerfilId ===
        "00000000-0000-0000-0000-000000000000"
    ) {
      return (
        <div className="d-flex justify-content-center">
          <NotFound message="Morador sem perfil cadastrado" />
        </div>
      );
    }

    return (
      <TransitionDiv className="">
        <SwitchTransition mode={"out-in"}>
          <CSSTransition
            key={cadastrar ? "Hello, world!" : "Goodbye, world!"}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
          >
            <>
              {cadastrar ? (
                <div className="animation">{renderBiometrias()}</div>
              ) : (
                <div className="m-0 p-0 animation">
                  <div
                    style={{ width: "100%" }}
                    className={
                      equipamentoStore.equipamentosBiometria.find(
                        (eq) =>
                          eq.tipoNome === "HikvisionFacial" ||
                          eq.tipoNome === "IntelbrasFacial"
                      )
                        ? ``
                        : ""
                    }
                  >
                    <div className="m-0 p-0 animation">
                      {equipamentoStore.equipamentosBiometria.find(
                        (eq) =>
                          eq.tipoNome === "HikvisionFacial" ||
                          eq.tipoNome === "IntelbrasFacial" ||
                          eq.tipoNome === "ControlIdFacial"
                      ) ? (
                        <>
                          <TitleModal>Biometria facial</TitleModal>
                          {renderBiometriaFacial()}
                        </>
                      ) : (
                        <div />
                      )}
                    </div>
                    <div style={{ width: "100%" }}>
                      <TitleModal>Biometrias digitais cadastradas</TitleModal>
                      {renderBiometriaCadastrada()}
                    </div>
                  </div>

                  <TitleModal className="mt-5">
                    Dispositivos do condomínio
                  </TitleModal>
                  {renderEquipamentos()}
                </div>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </TransitionDiv>
    );
  };

  return (
    <>
      <Modal open={show} size="md" onClose={hide}>
        <Modal.Header closeButton={coletando ? false : true}>
          <div className="mb-3">
            <p className="modal-styled-title">
              Cadastrar biometria digital/facial
            </p>
            <p className="modal-styled-subtitle"></p>
          </div>
        </Modal.Header>
        {renderBodyModal()}
      </Modal>

      <ModalRelatorioBiometria
        show={openRelatorio}
        hide={() => setOpenRelatorio(false)}
        tipo={acao}
      />
    </>
  );
};

export default observer(ModalBiometria);
