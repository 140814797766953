import { Container } from './styles/page.style';
import img404 from '../../assets/img/404.png'

const PageNotFound = () => {

  return (
    <Container>
      <div className='d-flex justify-content-center m-5'>
        <img src={img404} style={{height: 300, width: 300}} />
      </div>
      <div className='d-flex justify-content-center'>
        <div className='text-center'>
          <h5>Página não encontrada!</h5>
          <small><strong>A página que você está procurando não existe.</strong></small>
        </div>
      </div>
    </Container>
  )
}

export default PageNotFound;