import { Alert, Avatar, LinearProgress, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import react, { FC, FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaWpforms } from 'react-icons/fa'
import { Button, Input, Modal } from 'rsuite';
import useEquipamento from '../../../hooks/Equipamento.hook';
import { ControlId, ControlIdRequest } from '../../../interfaces/Equipamento.interface';

interface ModalProps {
    show: boolean;
    hide: () => void;
    condominioId?: string | null;
}

const CadastrarControlId: FC<ModalProps> = ({ show, hide, condominioId }) => { 

    const { cadastrarControlId, salvandoControlId } = useEquipamento();
    const [form, setForm] = useState<ControlIdRequest>({});

    useEffect(() => {
        if(condominioId) {
            setForm({
                ...form,
                condominioId: condominioId,
                tipoTag: 2
            });
        }
    }, [condominioId]);

    const salvar = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        cadastrarControlId(form as ControlId, hide);
    }

    const FormEquipamento = () => {
        return(
            <Form onSubmit={(e) => salvar(e)}>
                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Ip</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="192.168.XXX.XXX" value={form.ip || ''} onChange={(e) => setForm({...form, ip: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Porta</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="80" value={form.porta || ''} onChange={(e) => setForm({...form, porta: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Usuário</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Usuário" value={form.usuario || ''} onChange={(e) => setForm({...form, usuario: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Senha</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Ex: 2020" value={form.senha || ''} onChange={(e) => setForm({...form, senha: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Nome</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Nome" value={form.nome || ''} onChange={(e) => setForm({...form, nome: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Modelo</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Modelo" value={form.modelo || ''} onChange={(e) => setForm({...form, modelo: e})} />
                    </Form.Group>
                </div>

                <div className='d-flex'>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Tipo</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Tipo" value={form.tipo || ''} onChange={(e) => setForm({...form, tipo: e})} />
                    </Form.Group>
                    <Form.Group className="col-md-6 p-1" controlId="formBasicEmail">
                        <Form.Label><span className='text-danger'>*</span>Serial</Form.Label>
                        <Input required type="text" className='shadow-none' placeholder="Ex: 2020" value={form.serial || ''} onChange={(e) => setForm({...form, serial: e})} />
                    </Form.Group>
                </div>
                
                {/* <div>
                    <Form.Label><span className='text-danger'>*</span>Tipo de tag aceito</Form.Label>
                    <select
                        className='form-control shadow-none col-md-12'
                        value={form.tipoTag?.toString() || ''}
                        onChange={(e) => setForm({ ...form, tipoTag: e.target.value })}
                        required
                    >
                        <option value={''}>Selecione</option>
                        <option value={'0'}>Hikvision</option>
                        <option value={'1'}>Nice/ControlId/Anviz</option>
                    </select>
                </div> */}

                <div className='d-flex justify-content-end mt-4'>
                    <Button appearance='primary' disabled={salvandoControlId} type="submit">
                        { salvandoControlId ? 'Salvando...' : 'Salvar' }
                    </Button>
                    <Button disabled={salvandoControlId} type="button" onClick={hide} style={{marginLeft: 10}}>
                        Fechar
                    </Button>
                </div>
                {salvandoControlId ? <LinearProgress color="primary" style={{height: 2, marginTop: 15, position: 'absolute',width: '100%', bottom: 0, left: 0}} /> : ''}
            </Form>
        )
    }

    return(
        <Modal open={show} onClose={hide} size={'lg'}>
            <Modal.Header closeButton>
                <div>
                    <p className='modal-styled-title'>Cadastre novo control ID</p>
                    <p className='modal-styled-subtitle'>
                        Preencha todos os campos para que o cadastro possa ser realizado com sucesso
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body>{FormEquipamento()}</Modal.Body>
        </Modal>
    )
}

export default observer(CadastrarControlId);