import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react';
import { Button, Input, Modal } from 'rsuite';
import forgoutpassword from '../../assets/img/forgoutpassword.png'
import { Container } from './styles/ModalEsqueceuSenha.style';
import { useAuthentication } from '../../hooks/Auth.hook';

interface Props
{
    show: boolean;
    hide: () => void;
}

const ModalEsqueceuSenha : FC<Props> = ({ show, hide }) => {

    const { esqueceuSenhaUsuario, carregando } = useAuthentication();
    const [dados, setDados] = useState('');

    const onChangeAction = () =>
    {
        esqueceuSenhaUsuario(dados, hide);
    }

    return (
        <Modal open={show} onClose={carregando ? () => {} : hide}>
        <Modal.Header>
            
        </Modal.Header>
        <Modal.Body>
            <Container className='d-flex justify-content-center'>
                <img src={forgoutpassword} className='img' />
                <div className='align-self-center'>
                    <p className='title'>Esqueceu a senha?</p>
                    <p className='subtitle'>
                        Coloque seu cpf ou email pra recuperar a senha
                    </p>

                    <div>
                        <p className='title-form'>Coloque seu cpf/email</p>
                        <Input placeholder='Dados' className='input' onChange={setDados} />
                    </div>
                </div>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button appearance='primary' color={'violet'} disabled={!dados || carregando} onClick={onChangeAction}>Enviar</Button>
            <Button onClick={hide} disabled={carregando}>Fechar</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default observer(ModalEsqueceuSenha);
