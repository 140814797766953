import React, { FC, useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Uploader,
  InputNumber,
  Stack,
  toaster,
  Message,
  Loader,
} from "rsuite";
import { ContainerImageUpload, TextAlert } from "./styles/Aviso.style";
import useAviso from "../../hooks/Aviso.hook";
import { Aviso, AvisoRequest } from "../../interfaces/Aviso.interface";
import { StoreContext } from "../../stores";

interface ModalProps {
  show: boolean;
  hide: () => void;
  condominioId?: string;
}

const CadastrarAviso: FC<ModalProps> = ({ show, hide }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
  const [isImageInvalid, setIsImageInvalid] = useState(false);
  const [form, setForm] = useState<AvisoRequest>({});
  const [remainingChars, setRemainingChars] = useState(190); 
  const [loading, setLoading] = useState(false); 
  const { condominioStore } = useContext(StoreContext);
  const { cadastrar } = useAviso();

  useEffect(() => {
    if (show) {
      setUploadedImage(null);
      setIsImageInvalid(false);
      setRemainingChars(190);
      setLoading(false); 
      setForm({
        condominioId: condominioStore.selecionado?.id
      })
    }
  }, [show]);

  const handleUploadChange = (fileList: any) => {
    const file = fileList[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = () => {
            const { width, height } = image;
            if (width === 720 && height === 1280) {
              setUploadedImage(reader.result as string);
              setUploadedImageFile(file.blobFile);
              setIsImageInvalid(false);

              const base64Image = reader.result?.toString().split(",")[1];
              let formato: "jpg" | "jpeg" = "jpg";

              if (file.name.toLowerCase().endsWith(".jpeg")) {
                formato = "jpeg";
              }

              setForm((prevForm) => ({
                ...prevForm,
                formato: formato,
                content: base64Image,
              }));
            } else {
              setUploadedImage(null);
              setUploadedImageFile(null);
              setIsImageInvalid(true);
              toaster.push(
                <Message showIcon type="error" closable>
                  O formato da tela deverá ser: 720x1280
                </Message>,
                { placement: "topEnd", duration: 5000 }
              );
            }
          };
        }
      };
      reader.readAsDataURL(file.blobFile);
    }
  };

  useEffect(() => {
    if (condominioStore.selecionado) {
      setForm((prevForm) => ({
        ...prevForm,
        condominioId: condominioStore.selecionado?.id,
      }));
    }
  }, [condominioStore.selecionado]);

  const clear = () => {
    setForm({});
    setUploadedImage(null);
    setUploadedImageFile(null);
    setIsImageInvalid(false);
    setRemainingChars(190);
    setLoading(false); 
  };

  const salvar = async () => {
    setLoading(true); 
    try {
      if (!form.validade) {
        toaster.push(
          <Message showIcon type="error" closable>
            Campo validade é obrigátorio
          </Message>,
          { placement: "topEnd", duration: 5000 }
        );
        return;
      }
      if (!form.content && !form.descricao) {
        toaster.push(
          <Message showIcon type="error" closable>
            Imagem ou descrição precisa ser informada
          </Message>,
          { placement: "topEnd", duration: 5000 }
        );
        return;
      }

      if (!uploadedImage) {
        if (
          !form.descricao ||
          form.descricao.length < 3 ||
          form.descricao.length > 190
        ) {
          const errorMessage =
            (form.descricao ?? "").length > 190
              ? "Descrição muito extensa, limite máximo: 190 caracteres."
              : "A descrição deve conter no minímo 3 caracteres.";

          toaster.push(
            <Message showIcon type="error" closable>
              {errorMessage}
            </Message>,
            { placement: "topEnd", duration: 5000 }
          );
          return;
        }
      }

      if (
        uploadedImage &&
        form.descricao &&
        (form.descricao.length < 3 || form.descricao.length > 190)
      ) {
        const errorMessage =
          (form.descricao ?? "").length > 190
            ? "Descrição muito grande, limite máximo: 190 caracteres."
            : "A descrição deve ter pelo menos 3 caracteres.";

        toaster.push(
          <Message showIcon type="error" closable>
            {errorMessage}
          </Message>,
          { placement: "topEnd", duration: 5000 }
        );
        return;
      }

      await cadastrar(form as Aviso, hide, clear);
    } catch (error) {
      toaster.push(
        <Message showIcon type="error" closable>
          Ocorreu um erro ao salvar o aviso. Por favor, tente novamente.
        </Message>,
        { placement: "topEnd", duration: 5000 }
      );
    } finally {
      setLoading(false); 
    }
  };

  const handleDescricaoChange = (descricao: string) => {
    setForm((prevForm) => ({
      ...prevForm,
      descricao: descricao,
    }));
    setRemainingChars(190 - descricao.length);
  };
  return (
    <Modal open={show} onClose={hide}>
      <Modal.Header closeButton>
        <ContainerImageUpload>
          <Uploader
            action="//jsonplaceholder.typicode.com/posts/"
            draggable
            autoUpload={false}
            onChange={handleUploadChange}
            onRemove={() => {
              setUploadedImage(null);
              setUploadedImageFile(null);
              setIsImageInvalid(false);
              setForm((prevForm) => ({
                ...prevForm,
                formato: undefined,
                content: undefined,
                imagem: null || "",
                removerImagem: true,
              }))
            }}
          >
            {uploadedImage ? (
              <>
                <img
                  src={uploadedImage}
                  alt="Uploaded"
                  style={{ width: 370, height: 400 }}
                />
              </>
            ) : (
              <div
                style={{
                  height: 400,
                  width: 370,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  Clique ou arraste a imagem para fazer upload
                  <TextAlert>(Formato da tela deverá ser: 720x1280) </TextAlert>
                </span>
              </div>
            )}
          </Uploader>
        </ContainerImageUpload>
      </Modal.Header>
      <Modal.Body>
        <InputNumber
          size="md"
          max={100}
          min={1}
          placeholder="Defina a quantidade de dias que seu anúncio ficará disponível"
          onChange={(e: any) => setForm({ ...form, validade: e })}
          style={{ marginBottom: 5 }}
        />
        <Input
          placeholder="Descrição..."
          onChange={(e) => handleDescricaoChange(e)}
          maxLength={190}
        />
        <p style={{ marginLeft: 365, marginTop: 3, color: "#000" }}>
          ( {remainingChars} Caracteres restantes)
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          appearance="primary"
          onClick={salvar}
          disabled={loading || isImageInvalid || remainingChars < 0}
        >
          {loading ? <Loader size="sm" /> : "Confirmar"}
        </Button>
        <Button appearance="default" onClick={hide} disabled={loading}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CadastrarAviso;
