import { makeAutoObservable, runInAction, toJS } from 'mobx';
import DispositivosAPI from '../api/DispositivoAPI';
import { BiometriaStatus, ITag, RelatorioBiometria, Controle, ControleRelatorio, BiometriaFacial, BiometriaRetornoFacial } from '../interfaces/Dispositivo.interface';

class DispositivoStore {

  tags: ITag[] = [];
  tagsUnidade: ITag[] = [];
  tagSelecionado: ITag | null = null;
  controles: ControleRelatorio[]  = [];
  tagsPassiva: Controle | null  = null;
  controlesUnidade: ControleRelatorio[]  = [];
  controleSelecionado: ControleRelatorio | null = null;
  loading: boolean = false;
  relatorio: RelatorioBiometria | null = null;
  relatorioSincronizacao: RelatorioBiometria[] = [];
  biometriaCadastrada: BiometriaStatus[] = [];
  biometriaFacialCadastrada: BiometriaRetornoFacial | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async listarTagPorMorador(moradorId: string) {
    this.loading = true;
    try {
      const { data } = await DispositivosAPI.listarTagPorMorador(moradorId);
        runInAction(() => {
            if(data) this.tags = data;
            else this.tags = [];
            this.loading = false;
         });
    } catch (e) {
        this.tags = [];
        this.loading = false;
    }
  }

  async listarTagPorUnidade(unidadeId: string) {
    this.loading = true;
    try {
      const { data } = await DispositivosAPI.listarTagPorUnidade(unidadeId);
        runInAction(() => {
           if(data) this.tagsUnidade = data;
           else this.tagsUnidade = [];
            this.loading = false;
         });
    } catch (e) {
        this.tagsUnidade = [];
        this.loading = false;
    }
  }

  async listarTagPassivaPorVeiculo(veiculoId: string) {
    this.loading = true;
    try {
      const { data } = await DispositivosAPI.listarTagPassivaPorVeiculoId(veiculoId);
        runInAction(() => {
            this.tagsPassiva = data;
            this.loading = false;
         });
    } catch (e) {
        this.tagsPassiva = null;
        this.loading = false;
    }
  }

  async listarBiometriaDigitalPorMorador(moradorId: string) {
    this.loading = true;
    try {
      const { data } = await DispositivosAPI.listarBiometriaDigitalPorMoradorId(moradorId);
        runInAction(() => {
            this.biometriaCadastrada = data;
            this.loading = false;
        });
    } catch (e) {
        this.biometriaCadastrada = [];
        this.loading = false;
    }
  }

  async listarBiometriaFacialPorMorador(moradorId: string) {
    this.loading = true;
    try {
      const { data } = await DispositivosAPI.listarBiometriaFacialPorMoradorId(moradorId);
        runInAction(() => {
            this.biometriaFacialCadastrada = data;
            this.loading = false;
        });
    } catch (e) {
        this.biometriaFacialCadastrada = null;
        this.loading = false;
    }
  }

  async listarControlesPorUnidade(unidadeId: string) {
    this.loading = true;
    try {
      const { data } = await DispositivosAPI.listarControlesPorUnidadeId(unidadeId);
        runInAction(() => {
            if(data) this.controlesUnidade = data;
            else this.controlesUnidade = [];
            this.loading = false;
        });
    } catch (e) {
        this.loading = false;
    }
  }

  async listarControlePorVeiculo(veiculoId: string) {
    this.loading = true;
    try {
      const { data } = await DispositivosAPI.listarContorlePorVeiculo(veiculoId);
        runInAction(() => {
            if(data) this.controles = data;
            else this.controles = [];
            this.loading = false;
        });
    } catch (e) {
        this.controles = [];
        this.loading = false;
    }
  }

  async definirTagSelecionado(tagId: string) {
    let selecionado = this.tags.find(tag => tag.id === tagId);
    if(selecionado) {
        this.tagSelecionado = selecionado;
    }
  }

  async definirControleSelecionado(controleId: string) {
    let selecionado = this.controles.find(ctr => ctr.id === controleId);
    if(selecionado) {
        this.controleSelecionado = selecionado;
    }
  }

  async definirSelecionadoRelatorioSincronizacao(relatorio: RelatorioBiometria[]) {
    if(relatorio) {
        this.relatorioSincronizacao = relatorio;
    } else {
      this.relatorioSincronizacao = [];
    }
  }

  async selecionarRelatorio(relatorio: RelatorioBiometria | null) {
    if(relatorio) {
      this.relatorio = relatorio;
    } else {
      this.relatorio = null;
    }
  }

}

export default DispositivoStore;
